import {
  all, call, put, select, takeLatest, fork
 } from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchUserBySlug({ data }) {
  yield put(actions.userRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getUserBySlug, baseUrl, data.slug)
  yield (response) ? put(actions.userRequestSuccess(response)) : put(actions.userRequestFailure(error))
}

// Top Lists
function* fetchTopDeals({ data }) {
  yield put(actions.topDealsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_followed_user: _.get(data, "slug")
  }
  const { response, error } = yield call(requester.getTopDeals, baseUrl, params)
  yield (response) ? put(actions.topDealsRequestSuccess(response)) : put(actions.topDealsRequestFailure(error))
}

function* fetchTopCoupons({ data }) {
  yield put(actions.topCouponsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_followed_user: _.get(data, "slug")
  }
  const { response, error } = yield call(requester.getTopCoupons, baseUrl, params)
  yield (response) ? put(actions.topCouponsRequestSuccess(response)) : put(actions.topCouponsRequestFailure(error))
}

function* fetchTopSaleEvents({ data }) {
  yield put(actions.topSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_followed_user: _.get(data, "slug")
  }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topSaleEventsRequestSuccess(response)) : put(actions.topSaleEventsRequestFailure(error))
}

function* fetchTopBrands({ data }) {
  yield put(actions.topBrandsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  let params = {}
  if (data.user !== null && data.user.attributes.publicProfileSettings.showFollowedBrands) {
    params = {
      by_followed_user: data.user.id
    }
  }
  const { response, error } = yield call(requester.getTopBrands, baseUrl, params)
  yield (response) ? put(actions.topBrandsRequestSuccess(response)) : put(actions.topBrandsRequestFailure(error))
}

function* fetchTopDealhunters({ data }) {
  yield put(actions.topDealHuntersRequestStart())
  const baseUrl = yield select(getBaseUrl)
  let params = {}
  if (data.user !== null && data.user.attributes.publicProfileSettings.showFollowedSpotters) {
    params = {
      by_followed_user: data.user.id
    }
  }
  const { response, error } = yield call(requester.getTopDealHunters, baseUrl, params)
  yield (response) ? put(actions.topDealHuntersRequestSuccess(response)) : put(actions.topDealHuntersRequestFailure(error))
}

function* fetchTopStores({ data }) {
  yield put(actions.topStoresRequestStart())
  const baseUrl = yield select(getBaseUrl)
  let params = {}
  if (data.user !== null && data.user.attributes.publicProfileSettings.showFollowedStores) {
    params = {
      by_followed_user: data.user.id
    }
  }
  const { response, error } = yield call(requester.getTopStores, baseUrl, params)
  yield (response) ? put(actions.topStoresRequestSuccess(response)) : put(actions.topStoresRequestFailure(error))
}

function* fetchTopUpcomingSaleEvents({ data }) {
  yield put(actions.topUpcomingSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  let params = {}
  if (data.user !== null && data.user.attributes.publicProfileSettings.showFollowedUpcomingSales) {
    params = {
      by_followed_user: data.user.id,
      upcoming: true
    }
  }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topUpcomingSaleEventsRequestSuccess(response)) : put(actions.topUpcomingSaleEventsRequestFailure(error))
}

function* fetchData(action) {
  yield all([
    fork(fetchUserBySlug, action),
    fork(fetchTopStores, action),
    fork(fetchTopDealhunters, action),
    fork(fetchTopDeals, action),
    fork(fetchTopSaleEvents, action),
    fork(fetchTopCoupons, action),
    fork(fetchTopBrands, action),
    fork(fetchTopUpcomingSaleEvents, action)
  ])
}

function* watchPublicProfilePageSaga() {
yield takeLatest(actionTypes.LOAD_PUBLIC_PROFILE_PAGE, fetchData)
}

export {
  watchPublicProfilePageSaga
}
