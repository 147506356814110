import { combineReducers } from 'redux'

import createReducer from '../../../../components/utils/createReducer'

import * as actions from '../../actions/types'

// GOTO
const gotoUrlFetchSuccess = (state, { response }) => response

const goto_url = createReducer({})({
  [actions.GOTO_URL_REQUEST_SUCCESS]: gotoUrlFetchSuccess
})

const goto_url_request = createReducer(null)({
  [actions.GOTO_URL_REQUEST_START]: (_, { type }) => type,
  [actions.GOTO_URL_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.GOTO_URL_REQUEST_FAILURE]: (_, { type }) => type
})

export const getGotoUrl = state => state.gotoUrl

export default combineReducers({
  gotoUrl: goto_url,
  request: goto_url_request
})
