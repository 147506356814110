import React, { Component } from "react"
import { connect }    from "react-redux"
import axios from "axios"
import swal from "sweetalert"
import * as reducers from "../../../reducers"
import { gon } from "../../../../../../constant"

class PasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      invalid: true,
      form_data: {
        password: undefined,
        password_confirmation: undefined,
        current_password: undefined
      }
    }
  }

  submitForm = e => {
    e.preventDefault()
    const { form_data } = this.state
    const fetchData = async data => {
      const path = `${gon.base_url}/api/auth/password_change`
      return axios.put(path, data)
        .then(response => {
          if (response.status === 200) {
            swal({
              title: "Your password has been changed",
              button: "OK!"
            })
          }
        })
        .catch(error => (error))
    }

    fetchData(form_data)
    this.setState({
      invalid: true,
      form_data: {
        password: "",
        password_confirmation: "",
        current_password: ""
      }
    })
  }

  handleAttributeChange = event => {
    const {
      form_data
    } = this.state

    const {
      name,
      value
    } = event

    form_data[name] = value
    this.setState({ form_data })

    const {
      form_data: {
        current_password,
        password,
        password_confirmation
      }
    } = this.state

    if (password && password === password_confirmation && current_password) {
      this.setState({ invalid: false })
    } else {
      this.setState({ invalid: true })
    }
  }

  render() {
    const {
      invalid,
      form_data: {
        password,
        password_confirmation,
        current_password
      }
    } = this.state

    return (
      <section className="pt-4">
        <h4 className="mb-4">Your password</h4>
        <form onSubmit={this.submitForm}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Current Password</label>
                <input className="form-control" name="current_password" type="password" onChange={e => this.handleAttributeChange({ value: e.target.value, name: e.target.name })} value={current_password || ""} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>New Password</label>
                <input className="form-control" name="password" type="password" onChange={e => this.handleAttributeChange({ value: e.target.value, name: e.target.name })} value={password || ""} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Confirm New Password</label>
                <input className="form-control" name="password_confirmation" type="password" onChange={e => this.handleAttributeChange({ value: e.target.value, name: e.target.name })} value={password_confirmation || ""} />
              </div>
              <button
                className="btn btn-main rounded-pill"
                type="submit"
                disabled={invalid}>
                Update
              </button>
            </div>
          </div>
        </form>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  baseUrl: reducers.getBaseUrl(state)
})

export default connect(mapStateToProps, {})(PasswordForm)
