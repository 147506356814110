import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import { notifyPricedropStart, notifyPricedropSuccess, notifyPricedropFailure } from "../../actions"
import { notifyDealPricedrop } from "../../requesters"
import { getBaseUrl } from "../../reducers"

function* triggerNotifyPricedrop({ data }) {
  yield put(notifyPricedropStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(notifyDealPricedrop, baseUrl, data.identifier)
  yield (response) ? put(notifyPricedropSuccess(response)) : put(notifyPricedropFailure(error))
}

function* fetchData(data) {
  yield all([
    fork(triggerNotifyPricedrop, data)
  ])
}

function* watchTriggerNotifyPricedropSagas() {
  yield takeLatest(actionTypes.NOTIFY_PRICEDROP_TRIGGERED, fetchData)
}

export {
  watchTriggerNotifyPricedropSagas
}