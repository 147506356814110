import * as types from './types'

// Top Dep Data from Merchandised
export const merchTopDepRequestStart = data => ({
  type: types.MERCH_TOP_DEP_START,
  data
})

export const merchTopDepRequestSuccess = data => ({
  type: types.MERCH_TOP_DEP_SUCCESS,
  data
})

export const merchTopDepRequestFailure = error => ({
  type: types.MERCH_TOP_DEP_FAILURE,
  error
})
