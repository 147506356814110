import {
    all, call, put, select, takeLatest, fork
  } from "redux-saga/effects"
import * as actionTypes from "../../actions/types"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"


function* fetchSubcategory({ data }) {
  if (_.get(data, "subcategory") == null) {
    yield null
  } else {
    const baseUrl = yield select(getBaseUrl)
    yield call(requester.getSubcategory, baseUrl, _.get(data, "subcategory"))
  }
}

function* fetchData(action) {
  yield all([
    fork(fetchSubcategory, action)
  ])
}


function* watchDepartmentCategoryListSaga() {
  yield all([
    takeLatest(actionTypes.LOAD_DEPARTMENT_CATEGORY_LIST_PAGE, fetchData),
  ])
}

export {
  watchDepartmentCategoryListSaga
}
