import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import SidebarList from '../../../../components/SidebarList'
import ListingCard from '../ListingCard'

import { getBaseUrl } from '../../reducers'
import { getTopStores } from '../../requesters'

import MobileSidebarList from '../../../../components/MobileSideBarList'

function TopStoresMobileSidebarList (props) {
  const [topStoreIds, setTopStoreIds] = useState([])
  const [isFetchingTopStores, setIsFetchingTopStores] = useState(true)
  const baseUrl = useSelector(getBaseUrl)

  const {
    title,
    description,
    isMobileNavBar,
    department,
    category,
    subcategory,
    showAll,
    viewAllUrl
  } = props

  useEffect(() => {
    const params = {
      per_page: 10
    }

    async function fetchData () {
      const { response } = await getTopStores(baseUrl, params)
      if (_.get(response, 'data.store')) {
        setTopStoreIds(response.ids)
      }
      setIsFetchingTopStores(false)
    }
    fetchData()
  }, [])

  const topListComponents = (isMobileNavBar, showAll) => {
    const itemType = 'store'
    if (topStoreIds === undefined) { return ([]) }
    if (showAll) {
      return _.map(_.take(topStoreIds, topStoreIds.length), itemId => <ListingCard type={itemType} id={itemId} wrapperClass='' key={`${itemType}-${itemId}`} isMobileNavBar={isMobileNavBar} currentSection={`${title || 'Top Stores'}`} sectionPosition={1} />)
    }
    return _.map(_.take(topStoreIds, 30), itemId => <ListingCard type={itemType} id={itemId} wrapperClass='' key={`${itemType}-${itemId}`} isMobileNavBar={isMobileNavBar} currentSection={`${title || 'Top Stores'}`} sectionPosition={1} />)
  }

  let titleName
  let descriptionText

  if (subcategory) {
    titleName = `${subcategory.attributes.name} Stores`
  } else if (category) {
    titleName = `${category.attributes.name} Stores`
  } else if (department) {
    titleName = `${department.attributes.name} Stores`
  } else {
    titleName = 'Shop by store'
    descriptionText = 'View the best offers from your favourite stores'
  }

  if (isMobileNavBar) {
    return (
      <MobileSidebarList
        title={title || 'Top Stores'}
        description={description || descriptionText}
        type='Stores'
        viewAllUrl='/trending-stores'
        isLoading={isFetchingTopStores}
      >
        {topListComponents(isMobileNavBar, showAll)}
      </MobileSidebarList>
    )
  }
  return (
    <SidebarList
      title={title || titleName}
      description={description || descriptionText}
      viewAllUrl={viewAllUrl || '/trending-stores'}
      isLoading={isFetchingTopStores}
      showAll={showAll}
    >
      {topListComponents(false, showAll)}
    </SidebarList>
  )
}

export default TopStoresMobileSidebarList
