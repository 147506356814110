import * as types from "./types"

// UserSlider
export const showUserSlider = data => ({
  type: types.SHOW_USER_SLIDER,
  data
})

export const hideUserSlider = data => ({
  type: types.HIDE_USER_SLIDER,
  data
})

export const toggleUserSlider = data => ({
  type: types.TOGGLE_USER_SLIDER,
  data
})

// CatSlider
export const showCatSlider = data => ({
  type: types.SHOW_CAT_SLIDER,
  data
})

export const hideCatSlider = data => ({
  type: types.HIDE_CAT_SLIDER,
  data
})

export const toggleCatSlider = data => ({
  type: types.TOGGLE_CAT_SLIDER,
  data
})

export const setCatSliderDepartment = ({ department }) => ({
  type: types.CAT_SLIDER_SELECT_DEPARTMENT,
  department
})

export const resetCatSliderDepartment = data => ({
  type: types.CAT_SLIDER_RESET_DEPARTMENT,
  data
})