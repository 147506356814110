import React, { Component, createRef } from "react"

import { Link } from "react-router-dom"
import PageSection from "../../components/PageSection"
import Loader from "../../components/Loader"
import ChevronRight from "../../apps/App/components/ChevronRight"
import positionDataLayer from '../../components/dataLayers/positionDataLayer'

class SidebarList extends Component {
  constructor() {
    super()
    this.myRef = createRef()
  }

  renderViewAllButton = (currentSection, sectionPosition, sectionId) => {
    const {
      viewAllUrl,
      showAll
    } = this.props

    if (viewAllUrl && !showAll) {
      return (
        <div className="btn btn-outline-dark btn-view-all">
          <Link
            onClick={() => {
              positionDataLayer(null, 'view-all', 'view-all-link', null, currentSection, sectionPosition)
              sectionId && localStorage.setItem("hsref", sectionId)
            }}
            to={viewAllUrl}>
            <div className='d-flex justify-content-center align-items-center ml-n3 btn-view-all'>View more</div>
          </Link>
        </div>
      )
    }

    return null
  }

  render() {
    const {
      title,
      titleClass,
      description,
      isLoading,
      children,
      currentSection,
      sectionPosition,
      sectionId,
      homepageBrandStoreClass
    } = this.props

    // No data hide it all
    if (!isLoading && children.length === 0) { return (null) }
    return (
      <PageSection
        title={title}
        titleClass={titleClass}
        description={description}
        sectionId={sectionId}
        homepageBrandStoreClass={homepageBrandStoreClass}
        viewAll={this.renderViewAllButton(currentSection, sectionPosition, sectionId)}>
        <div className="listing-carousel">
          <div className="d-flex listing-group-carousel" ref={this.myRef}>
            <Loader isLoading={isLoading} />
            <ChevronRight offset={300} ref={this.myRef} direction="left" />
            {children}
            <ChevronRight offset={300} ref={this.myRef} direction="right" />
          </div>
        </div>
      </PageSection>
    )
  }
}

export default SidebarList
