import React, { Component } from "react"
import { connect } from "react-redux"
import axios from "axios"
import swal from "sweetalert"

import { avatarIcon } from "../../../../../components/Icons"
import * as reducers from "../../../reducers"
import { gon } from "../../../../../../constant"
import { currentUserUpdate, is } from "../../../actions"
import Loading from "../../../../../components/Loader"

class AvatarForm extends Component {
  constructor(props) {
    super(props)
    const { currentUser } = this.props
    this.state = {
      imagePreviewUrl: currentUser?.links?.avatar?.thumb?.url,
    }
  }

  handleImageSelect = e => {
    const { baseUrl, currentUserUpdate } = this.props
    if (e.target?.files[0]?.size > 5000000) {
      swal({
        title: "File Too Large",
        text: "Your avatar must be less than 5 MB in size.",
        button: "OK!"
      })
      return (null)
    }

    const saveAvatar = async avatar => {
      const path = `${baseUrl}/users`
      return axios.post(path, { avatar })
        .then(response => {
          if (response?.data?.current_user) {
              currentUserUpdate({...response.data.current_user, isLatest: true})
          }
          return { response }
        })
        // eslint-disable-next-line no-unused-expressions
        .catch(error => { error })
    }

    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onloadend = () => {
      this.setState({ imagePreviewUrl: reader.result })
      const { imagePreviewUrl } = this.state

      saveAvatar(imagePreviewUrl.substring(imagePreviewUrl.indexOf(",") + 1))
    }

    file && reader.readAsDataURL(file)
    return (null)
  }

  handleImageRemove = () => {
    const { baseUrl } = this.props
    const removeAvatar = async () => {
      const path = `${baseUrl}/users`
      return axios.post(path, {})
        .then(response => {
          if (response?.data?.current_user) {
              window.localStorage.setItem("current_user", JSON.stringify(response.data.current_user))
              gon.current_user = response.data.current_user
              this.props.currentUserUpdate({...response.data.current_user, isLatest: true})
          }

          return { response }
        })
        // eslint-disable-next-line no-unused-expressions
        .catch(error => { error })
    }

    this.setState({ imagePreviewUrl: avatarIcon })
    removeAvatar()
  }

  triggerInputFile = () => this.fileInput.click()

  render() {
    let {
      imagePreviewUrl,
    } = this.state


    const {
      isUserLoading,
      currentUser
    } = this.props

    imagePreviewUrl = currentUser?.links?.avatar?.thumb?.url

    return (
      <>

        {!isUserLoading.loading && currentUser && currentUser.type ?
         <>
        <h4>Your Profile Picture</h4>
        <p>
          5 MB file size limit (jpeg, png)
        </p>
        <div className="mt-4 d-flex align-items-center">
          <img className="mt-4 mt-md-0 mb-2 rounded-circle cover img-fluid profile-img cover" src={imagePreviewUrl} />
          <div className="actions ml-3">
            <button
              className="btn btn-main rounded-pill mr-3"
              type="submit"
              onClick={this.triggerInputFile}>
              Change
            </button>
            <button
              className="btn btn-outline-dark rounded-pill"
              type="submit"
              onClick={this.handleImageRemove}>
              Remove
            </button>
            <input
              name="images"
              ref={fileInput => { this.fileInput = fileInput }}
              type="file"
              id="fileUpload"
              multiple={false}
              onChange={this.handleImageSelect}
              style={{ display: "none" }}
              accept="image/png, image/jpeg, image/jpg" />
          </div>
        </div>
        </>: <div className="col-4 my-4"><Loading /> </div>}
      </>
    )
  }
}


const mapStateToProps = state => ({
  currentUser: reducers.getCurrentUser(state),
  baseUrl: reducers.getBaseUrl(state),
  isUserLoading: reducers.isUserLoading(state)
})

export default connect(mapStateToProps, { currentUserUpdate })(AvatarForm)
