export default function humanizeOffer(voucher) {
  const couponType = voucher.coupon_type || voucher.couponType || _.get(voucher, "attributes.coupon_type")
  const discountAmount = voucher.discount_amount || voucher.discountAmount || _.get(voucher, "attributes.discount_amount")

  switch (couponType) {
    case 'percentage_discount':
    case '% Discount':
      return discountAmount ? `${parseInt(discountAmount)}% OFF` : ''
    case 'dollar_discount':
    case '$ Discount':
      return `$${discountAmount % 1 === 0 ? Number(discountAmount).toFixed(0) : Number(discountAmount).toFixed(2)} OFF`
    case 'other':
    case 'Other':  
      return 'Special offer'
    case 'bonus_item':
    case 'Bonus Item':
      return 'Free Gift'
    case 'free_shipping':
    case 'Free Shipping':  
      return 'Free Shipping'
    default:
      return ''
  }
}
