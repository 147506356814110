import React, { Component, createRef } from "react"
import { connect } from "react-redux"

import TopLists from "../../../../components/TopLists"
import ListingCard from "../ListingCard"
import * as reducers from "../../reducers"
import ChevronRight from "../ChevronRight"

class TopPriceDropsList extends Component {
  constructor() {
    super()
    this.myRef = createRef()
  }

  topListComponents = () => {
    const { currentSection, sectionPosition, topDealList} = this.props
    let _topDealListId = topDealList && topDealList.map(deal => parseInt(deal.id))
    if (_.isEmpty(_topDealListId)) { return ([]) }
    return _.map(_.take(_topDealListId, 8), (priceDropId, index) => <ListingCard type="deal" id={priceDropId} wrapperClass="" position={index + 1} key={`top-deals-{}-${priceDropId}`} currentSection={currentSection} sectionPosition={sectionPosition} />)
  }

  render() {
    const {
      isFetchingTopPriceDrops,
      title,
      description,
      wrapperClass,
      viewAllUrl,
      department,
      category,
      subcategory,
      store,
      brand,
      currentSection,
      sectionPosition
    } = this.props

    let titleName

    if (subcategory) {
      titleName = subcategory.attributes.name
    } else if (category) {
      titleName = category.attributes.name
    } else if (department) {
      titleName = department.attributes.name
    } else {
      titleName = ""
    }

    if (!_.isEmpty(this.topListComponents())) {
      return (
        <TopLists
          title={title || `${titleName} Price Drops`}
          description={description || `Take advantage of current ${store?.attributes?.dealsCount || brand?.attributes?.dealsCount} Price Drops.`}
          isLoading={isFetchingTopPriceDrops}
          wrapperClass={wrapperClass || "row"}
          currentSection={currentSection}
          sectionPosition={sectionPosition}
          viewAllUrl={viewAllUrl}
          titleClass="mb-1"
          type="price drops">
          <div className="d-flex listing-group-carousel" ref={this.myRef}>
            <ChevronRight offset={300} ref={this.myRef} direction="left" />
            { this.topListComponents() }
            <ChevronRight offset={300} ref={this.myRef} direction="right" />
          </div>
        </TopLists>
      )
    }
    return null
  }
}

const mapStateToProps = state => ({
  topPriceDropIds: reducers.getTopDealIds(state),
  isFetchingTopPriceDrops: reducers.isFetchingTopDeals(state)
})

export default connect(mapStateToProps, {})(TopPriceDropsList)
