/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getCalendarSaleEvents = (baseUrl, data = {}) => {
  const path = `${baseUrl}/sale_events`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({
      response: normalize(data),
      status,
      order: _.map(_.get(data, "data", []), item => parseInt(item.id, 10))
    }))
    .catch(error => { error })
}