import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import * as actions from "../../../actions/types"

const categoryFetchSuccess = (state, { data }) => ({ ...state, ...data.category })

const byId = createReducer({})({
  [actions.RESOURCE_FETCHED_SUCCESS]: categoryFetchSuccess
})

export const getCategoryById = (state, categoryId) => state.byId[categoryId]
export const getCategoryIds = state => Object.keys(state.byId)
export const getCategoryByDepartmentId = (state, departmentId) => _.find(state.byId, { attributes: { departmentId } })
export const getCategoryBySlug = (state, slug) => _.find(state.byId, { attributes: { slug } })

export default combineReducers({
  byId
})
