const displayFormattedValue = (count) => {
  let modifiedCountValue = count
  if (/^[0-9]*$/.test(count)) {
    if (count <= 999) modifiedCountValue = count < 0 ? '' : count
    else if (count >= 1000 && count < 1000000) {
      const r = count % 1000
      const n = (count - r) / 1000
      const number = r > 500 ? n + 1 : n
      modifiedCountValue = number + 'K'
    } else if (count >= 1000000 && count < 999999999) {
      const r = count % 1000000
      const n = (count - r) / 1000000
      const number = r > 500000 ? n + 1 : n
      modifiedCountValue = number + 'M'
    } else {
      modifiedCountValue = '999M+'
    }
  }
  return modifiedCountValue
}

const statTextDisplay = (label) => {
  let text=''
  switch (label) {
    case 'dealsCount' :
    case 'deals_count' :
      text= 'Price drops';
       break;
    case 'saleEventsCount':
      case 'sale_events_count':
        text= 'Sales Events'
        break;
    case 'couponsCount':
      case 'coupons_count':
        text = 'Promo Codes'
      break
    case 'productsCount':
      case 'products_count':
        text ='Products'
        break
    case 'followersCount':
      case 'followers_count':
        text= 'Followers'
        break
    default:
      return null
  }
  return text

}
export const renderStoreBrandStats = (stats,list='') => {
  return (
    <div className={`d-flex justify-content-around align-items-center store-brand-header px-3 ${list && 'store-brand-header-list'}`}>
      {
        stats?.map((stat, key) => {
          return (
              <span className={`store-brand-stats ${list && 'store-brand-stats-list'}`} key={key}>
              <b>{displayFormattedValue(stat.stat)}</b>&nbsp;{statTextDisplay(stat.label)}
             </span>

          )
        })
      }
    </div>
  )
}

const renderCounts = (count) => {
  let modifiedCountValue = count
  if (/^[0-9]*$/.test(count)) modifiedCountValue = count > 0 ? count : ''
  return modifiedCountValue
}

export const getOrderStats = (data,allowedKeys,objectOrder)=>{

    const filtered = Object.keys(data).filter(key => allowedKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key]
        return Object.assign(objectOrder, obj)
      }, {})
    const array = []
    Object.keys(filtered).forEach((singleKey) => {
      if (renderCounts(filtered[singleKey]) !== '') array.push({ stat: filtered[singleKey], label: singleKey })
    })
    return _.take(array, 3)
}