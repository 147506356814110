import * as types from "./types"

// Tickers
export const activeTickersRequestStart = data => ({
  type: types.ACTIVE_TICKERS_REQUEST_START,
  data
})

export const activeTickersRequestSuccess = data => ({
  type: types.ACTIVE_TICKERS_REQUEST_SUCCESS,
  data
})

export const activeTickersRequestFailure = error => ({
  type: types.ACTIVE_TICKERS_REQUEST_FAILURE,
  error
})

//SEO
export const seoContentRequestStart = data => ({
  type: types.SEO_CONTENT_REQUEST_START,
  data
})

export const seoContentRequestSuccess = data => ({
  type: types.SEO_CONTENT_REQUEST_SUCCESS,
  data
})

export const seoContentRequestFailure = error => ({
  type: types.SEO_CONTENT_REQUEST_FAILURE,
  error
})