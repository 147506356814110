import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Spinner } from "react-bootstrap"

import { backArrow } from '../../../../../components/Icons';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors([]);

    try {
      const response = await axios.put(`${gon.urls.api}/auth/password_verification`, { 
        user: { email } 
      });

      if (response.status === 200) {
        setSuccessMessage(response.data.message);
      } else {
        const errorMessage = response.errors?.[0] || 
                           response.data?.error || 
                           response.data?.message || 
                           'Error while sending password verification email. Please try again.';
        setErrors([errorMessage]);
      }
    } catch {
      setErrors(['Error while sending password verification email. Please try again.']);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderErrors = () => {
    if (!errors.length) return null;
    
    return (
      <div className='alert alert-danger'>
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          <span aria-hidden='true'>×</span>
        </button>
        <h4 className='alert-heading'>Error occurred:</h4>
        <ul className='mb-0'>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </div>
    );
  };

  const renderSuccessMessage = () => (
    <div className="alert alert-success text-center">
      <p className="mb-3">{successMessage}</p>
      <button
        className="btn btn-success btn-lg rounded-pill px-5 mt-2"
        onClick={() => history.push('/auth')}
        style={{ minWidth: '120px' }}
      >
        OK
      </button>
    </div>
  );

  const renderForm = () => (
    <>
      <div className='text-center mt-4 mb-3'>
        <strong>Forgot your password?</strong>
      </div>
      <form className='new_user' id='new_user' onSubmit={handleSubmit} noValidate>
        {renderErrors()}
        <div className='form-group'>
          <input
            autoFocus
            placeholder='Email'
            autoComplete='email'
            className='form-control form-control--theme'
            type='email'
            value={email}
            name='user[email]'
            id='user_email'
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className='form-group text-center pt-4'>
          <button
            type='submit'
            className={`btn btn-main device-links t-14 rounded-pill btn-lg ${isSubmitting ? 'disabled' : ''}`}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner animation="border" variant="secondary" size="sm" />
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
    </>
  );

  return (
    <div className='login-register'>
      <div className='d-flex justify-content-center'>
        <div className='card-container'>
          <div className='card'>
            <div className='card-body mb-4'>
              <div className='back-button-container'>
                <button 
                  className='btn btn-link d-flex align-items-center' 
                  onClick={() => history.push('/auth')}
                >
                  <img className='back-img' src={backArrow} alt='Back' />
                  Back
                </button>
              </div>
              <div className='text-center mb-3'>
                <img src='/assets/images/branding/auth-logo.svg' alt='Auth Logo' />
              </div>

              {successMessage ? renderSuccessMessage() : renderForm()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
