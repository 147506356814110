import React from "react"
import { toast } from "react-toastify"

const toastId = "redirect-with-error"
const containerId = "main"
const toastOptions =  {
  toastId,
  containerId,
  onClose: () => window.location.replace("/")
}

function handleAxiosRequestErrors(error) {
  // Removed error code 404 here because we have handle via page <InvalidRoute />
  // Kept this function to handle other errors


}

export default handleAxiosRequestErrors
