import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchStoreBySlug({ data }) {
  yield put(actions.storeRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getStoreByIdentifier, baseUrl, data.store)
  yield response ? put(actions.storeRequestSuccess(response)) : put(actions.storeRequestFailure(error))
}

function* fetchTopDeals({ data }) {
  yield put(actions.topDealsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { by_store: _.get(data, "store") }
  const { response, error } = yield call(requester.getTopDeals, baseUrl, params)
  yield response ? put(actions.topDealsRequestSuccess(response)) : put(actions.topDealsRequestFailure(error))
}

function* fetchTopCoupons({ data }) {
  yield put(actions.topCouponsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { by_store: _.get(data, "store") }
  const { response, error } = yield call(requester.getTopCoupons, baseUrl, params)
  yield response ? put(actions.topCouponsRequestSuccess(response)) : put(actions.topCouponsRequestFailure(error))
}

function* fetchTopSaleEvents({ data }) {
  yield put(actions.topSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { by_store: _.get(data, "store") }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield response ? put(actions.topSaleEventsRequestSuccess(response)) : put(actions.topSaleEventsRequestFailure(error))
}

function* fetchTopDealHunters({ data }) {
  yield put(actions.topDealHuntersRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_store: _.get(data, "store"),
    per_page: 8
  }
  const { response, error } = yield call(requester.getTopDealHunters, baseUrl, params)
  yield (response) ? put(actions.topDealHuntersRequestSuccess(response)) : put(actions.topDealHuntersRequestFailure(error))
}

function* fetchTopBrands({ data }) {
  yield put(actions.topBrandsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_store: _.get(data, "store"),
    per_page: 8
  }
  const { response, error } = yield call(requester.getTopBrands, baseUrl, params)
  yield (response) ? put(actions.topBrandsRequestSuccess(response)) : put(actions.topBrandsRequestFailure(error))
}

function* fetchTopUpcomingSaleEvents({ data }) {
  yield put(actions.topUpcomingSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_store: _.get(data, "store"),
    upcoming: true
  }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topUpcomingSaleEventsRequestSuccess(response)) : put(actions.topUpcomingSaleEventsRequestFailure(error))
}

function* fetchNewsletters({ data }) {
  yield put(actions.newslettersRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_store: _.get(data, "store")
  }
  const { response, error } = yield call(requester.getNewsletters, baseUrl, params)
  yield (response) ? put(actions.newslettersRequestSuccess(response)) : put(actions.newslettersRequestFailure(error))
}

function* fetchData(data) {
  yield all([
    fork(fetchStoreBySlug, data),
    fork(fetchTopDeals, data),
    fork(fetchTopCoupons, data),
    fork(fetchTopSaleEvents, data),
    fork(fetchTopDealHunters, data),
    fork(fetchTopBrands, data),
    fork(fetchTopUpcomingSaleEvents, data),
    fork(fetchNewsletters, data)

  ])
}

function* watchStorePageSaga() {
  yield takeLatest(actionTypes.LOAD_STORE_PAGE, fetchData)
}

export {
  watchStorePageSaga
}