import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import * as actions from "../../../actions/types"

const subCategoryFetchSuccess = (state, { data }) => ({ ...state, ...data.subcategory })

const byId = createReducer({})({
  [actions.RESOURCE_FETCHED_SUCCESS]: subCategoryFetchSuccess,
  [actions.LOCATION_CHANGED]: () => ({})
})

export const getSubcategoryById = (state, subcategoryId) => state.byId[subcategoryId]
export const getSubcategoryIds = state => Object.keys(state.byId)
export const getSubcategoryByCategoryId = (state, categoryId) => _.find(state.byId, { attributes: { categoryId } })
export const getSubcategoryBySlug = (state, slug) => _.find(state.byId, { attributes: { slug } })

export default combineReducers({
  byId
})
