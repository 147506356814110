import React, { useState } from 'react'
import axios from "axios"
import queryString from "query-string"
import { useHistory } from 'react-router-dom'
import { Spinner } from "react-bootstrap"
import { backArrow } from '../../../../../components/Icons'

function ChangePassword({location}) {
  const params = queryString.parse(location?.search)
  const resetPasswordToken = params?.reset_password_token
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [errors, setErrors] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const history = useHistory()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors([])
    setIsSubmitting(true)

    try {
      const response = await axios.put(`${gon.urls.api}/auth/password_update`, {
        token: resetPasswordToken,
        password: password,
        password_confirmation: confirmPassword,
      })

      if (response.status === 200) {
        console.log('response', response)
        setShowSuccess(true)
      } else {
        const errorMessage = response.data?.errors || 
                           [response.data?.error || response.data?.message || 
                           'Error while resetting password. Please try again.']
        setErrors(errorMessage)
      }
    } catch (error) {
      setErrors(["Error while resetting password. Please try again."])
    } finally {
      setIsSubmitting(false)
    }
  }

  const renderErrors = () => {
    if (!errors.length) return null
    
    return (
      <div className='alert alert-danger'>
        <button type='button' className='close' data-dismiss='alert' aria-label='Close'>
          <span aria-hidden='true'>×</span>
        </button>
        <h4 className='alert-heading'>Error occurred:</h4>
        <ul className='mb-0'>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </div>
    )
  }

  if (showSuccess) {
    return (
      <div className='login-register'>
        <div className='d-flex justify-content-center'>
          <div className='card-container'>
            <div className='card'>
              <div className='card-body mb-4'>
                <div className='text-center mb-3'>
                  <img src='/assets/images/branding/auth-logo.svg' alt='Auth Logo' />
                </div>
                <div className='text-center mt-4'>
                  <h5>Password Changed Successfully!</h5>
                  <p>Your password has been changed. Please use your new password to log in.</p>
                  <button 
                    className='btn btn-main rounded-pill'
                    onClick={() => history.push('/auth')}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='login-register'>
      <div className='d-flex justify-content-center'>
        <div className='card-container'>
          <div className='card'>
            <div className='card-body mb-4'>
              <div className='back-button-container'>
                <button 
                  className='btn btn-link d-flex align-items-center' 
                  onClick={() => history.push('/auth')}
                >
                  <img className='back-img' src={backArrow} alt='Back' />
                  Back
                </button>
              </div>
              <div className='text-center mb-3'>
                <img src='/assets/images/branding/auth-logo.svg' alt='Auth Logo' />
              </div>

              <div className='text-center mt-4 mb-3'>
                <strong>Change your password</strong>
              </div>

              <form onSubmit={handleSubmit} noValidate>
                {renderErrors()}
                
                <div className='form-group'>
                  <input
                    autoFocus
                    placeholder='New password'
                    className='form-control form-control--theme'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <small className="form-text text-muted">(6 characters minimum)</small>
                </div>

                <div className='form-group'>
                  <input
                    placeholder='Confirm new password'
                    className='form-control form-control--theme'
                    type='password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>

                <div className='form-group text-center pt-4'>
                  <button
                    type='submit'
                    className={`btn btn-main device-links t-14 rounded-pill btn-lg ${isSubmitting ? 'disabled' : ''}`}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner animation="border" variant="secondary" size="sm" />
                    ) : (
                      'Change Password'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword