export const INIT_APPLICATION = '@@app/INIT'
export const RESOURCE_FETCHED_SUCCESS = '@@axios/DATA_RETRIEVED'

export const LOCATION_CHANGED = '@@router/LOCATION_CHANGE'

// Pages
export const REQUEST_LISTINGS_PAGE = 'Page/ListingsPage'
export const REQUEST_PROFILE_ACTIVITY_PAGE = 'Page/ProfileActivityPage'

export const LOAD_LISTINGS_PAGE = 'LoadPage/ListingsPage'
export const LOAD_NEW_LISTINGS_PAGE = 'LoadPage/NewListingsPage'
export const LOAD_SUBMIT_NEW_LISTINGS_PAGE = 'LoadPage/SubmitNewListingsPage'
export const LOAD_RANKINGS_PAGE = 'LoadPage/RankingsPage'
export const LOAD_FOLLOWING_RANKINGS_PAGE = 'LoadPage/FollowingRankingsPage'
export const LOAD_PROFILE_ACTIVITY_PAGE = 'LoadPage/ProfileActivityPage'
export const LOAD_PROFILE_FOLLOWING_PAGE = 'LoadPage/ProfileFollowingPage'
export const LOAD_PUBLIC_PROFILE_PAGE = 'LoadPage/PublicProfilePage'
export const LOAD_PROFILE_CALENDAR_PAGE = 'LoadPage/ProfileCalendarPage'
export const LOAD_BRAND_PAGE = 'LoadPage/BrandPage'
export const LOAD_STORE_PAGE = 'LoadPage/StorePage'
export const LOAD_DEAL_PAGE = 'LoadPage/DealPage'
export const LOAD_COUPON_PAGE = 'LoadPage/CouponPage'
export const LOAD_SALE_EVENT_PAGE = 'LoadPage/SaleEventPage'
export const LOAD_DEPARTMENT_CATEGORY_PAGE = 'LoadPage/DepartmentCategoryPage'
export const LOAD_DEPARTMENT_CATEGORY_LIST_PAGE = 'LoadPage/DepartmentCategoryListPage'

// Ranking Page
export const REFRESH_RANKING_LIST           = 'RankingPage/RefreshList'
export const REFRESH_FOLLOWING_RANKING_LIST = 'FollowingRankingPage/RefreshList'

// Lists
export const TOP_DEALS_REQUEST_START    = 'TopLists/RequestTopDealsStart'
export const TOP_DEALS_REQUEST_SUCCESS  = 'TopLists/RequestTopDealsSuccess'
export const TOP_DEALS_REQUEST_FAILURE  = 'TopLists/RequestTopDealsFailure'

export const NEW_DEALS_REQUEST_START    = 'TopLists/RequestNewDealsStart'
export const NEW_DEALS_REQUEST_SUCCESS  = 'TopLists/RequestNewDealsSuccess'
export const NEW_DEALS_REQUEST_FAILURE  = 'TopLists/RequestNewDealsFailure'
export const NEW_DEALS_REFRESH          = 'TopLists/RequestNewDealsRefresh'

export const TOP_PRICE_DROPS_REQUEST_START    = 'TopLists/RequestTopPriceDropsStart'
export const TOP_PRICE_DROPS_REQUEST_SUCCESS  = 'TopLists/RequestTopPriceDropsSuccess'
export const TOP_PRICE_DROPS_REQUEST_FAILURE  = 'TopLists/RequestTopPriceDropsFailure'

export const TOP_SALE_EVENTS_CLEAR = 'TopLists/RequestTopSaleEventsClear'
export const TOP_SALE_EVENTS_REQUEST_START = 'TopLists/RequestTopSaleEventsStart'
export const TOP_SALE_EVENTS_REQUEST_SUCCESS = 'TopLists/RequestTopSaleEventsSuccess'
export const TOP_SALE_EVENTS_REQUEST_FAILURE = 'TopLists/RequestTopSaleEventsFailure'

export const NEW_SALE_EVENTS_REQUEST_START    = 'TopLists/RequestNewSaleEventsStart'
export const NEW_SALE_EVENTS_REQUEST_SUCCESS  = 'TopLists/RequestNewSaleEventsSuccess'
export const NEW_SALE_EVENTS_REQUEST_FAILURE  = 'TopLists/RequestNewSaleEventsFailure'
export const TOP_CURRENT_SALE_EVENTS_REQUEST_START   = 'TopLists/RequestTopCurrentSaleEventsStart'
export const TOP_CURRENT_SALE_EVENTS_REQUEST_SUCCESS = 'TopLists/RequestTopCurrentSaleEventsSuccess'
export const TOP_CURRENT_SALE_EVENTS_REQUEST_FAILURE = 'TopLists/RequestTopCurrentSaleEventsFailure'
export const TOP_UPCOMING_SALE_EVENTS_REQUEST_START   = 'TopLists/RequestTopUpcomingSaleEventsStart'
export const TOP_UPCOMING_SALE_EVENTS_REQUEST_SUCCESS = 'TopLists/RequestTopUpcomingSaleEventsSuccess'
export const TOP_UPCOMING_SALE_EVENTS_REQUEST_FAILURE = 'TopLists/RequestTopUpcomingSaleEventsFailure'

export const TOP_CASH_BACKS_REQUEST_START    = 'TopLists/RequestTopCashBacksStart'
export const TOP_CASH_BACKS_REQUEST_SUCCESS  = 'TopLists/RequestTopCashBacksSuccess'
export const TOP_CASH_BACKS_REQUEST_FAILURE  = 'TopLists/RequestTopCashBacksFailure'

export const TOP_STORES_REQUEST_START    = 'TopLists/RequestTopStoresStart'
export const TOP_STORES_REQUEST_SUCCESS  = 'TopLists/RequestTopStoresSuccess'
export const TOP_STORES_REQUEST_FAILURE  = 'TopLists/RequestTopStoresFailure'

export const TOP_BRANDS_REQUEST_START    = 'TopLists/RequestTopBrandsStart'
export const TOP_BRANDS_REQUEST_SUCCESS  = 'TopLists/RequestTopBrandsSuccess'
export const TOP_BRANDS_REQUEST_FAILURE  = 'TopLists/RequestTopBrandsFailure'

export const TOP_DEAL_HUNTERS_REQUEST_START    = 'TopLists/RequestTopDealHuntersStart'
export const TOP_DEAL_HUNTERS_REQUEST_SUCCESS  = 'TopLists/RequestTopDealHuntersSuccess'
export const TOP_DEAL_HUNTERS_REQUEST_FAILURE  = 'TopLists/RequestTopDealHuntersFailure'

export const TOP_COUPONS_REQUEST_START    = 'TopLists/RequestTopCouponsStart'
export const TOP_COUPONS_REQUEST_SUCCESS  = 'TopLists/RequestTopCouponsSuccess'
export const TOP_COUPONS_REQUEST_FAILURE  = 'TopLists/RequestTopCouponsFailure'

export const NEW_COUPONS_REQUEST_START    = 'TopLists/RequestNewCouponsStart'
export const NEW_COUPONS_REQUEST_SUCCESS  = 'TopLists/RequestNewCouponsSuccess'
export const NEW_COUPONS_REQUEST_FAILURE  = 'TopLists/RequestNewCouponsFailure'

// Sort/Filter Ranking Lists
export const TOP_SALE_EVENTS_REFRESH = 'TopLists/RefreshTopSaleEvents'
export const TOP_COUPONS_REFRESH     = 'TopLists/RefreshTopCoupons'
export const TOP_BRANDS_REFRESH      = 'TopLists/RefreshTopBrands'
export const TOP_DEALS_REFRESH       = 'TopLists/RefreshTopDeals'
export const TOP_STORES_REFRESH      = 'TopLists/RefreshTopStores'
export const ALL_DEALS_REFRESH       = 'AllLists/RefreshAllDeals'

// Submit Listing
export const SUBMIT_LISTING_REQUEST_START    = 'SubmitListing/RequestSubmitListingStart'
export const SUBMIT_LISTING_REQUEST_SUCCESS    = 'SubmitListing/RequestSubmitListingSuccess'
export const SUBMIT_LISTING_REQUEST_FAILURE    = 'SubmitListing/RequestSubmitListingFailure'

// Tickers
export const ACTIVE_TICKERS_REQUEST_START = 'cms/RequestActiveTickersStart'
export const ACTIVE_TICKERS_REQUEST_SUCCESS = 'cms/RequestActiveTickersSuccess'
export const ACTIVE_TICKERS_REQUEST_FAILURE = 'cms/RequestActiveTickersFailure'

// SEO
export const SEO_CONTENT_REQUEST_START = 'seo/RequestSeoContentStart'
export const SEO_CONTENT_REQUEST_SUCCESS = 'seo/RequestSeoContentSuccess'
export const SEO_CONTENT_REQUEST_FAILURE = 'seo/RequestSeoContentFailure'

// Brand
export const BRAND_REQUEST_START    = 'Brands/RequestBrandStart'
export const BRAND_REQUEST_SUCCESS  = 'Brands/RequestBrandSuccess'
export const BRAND_REQUEST_FAILURE  = 'Brands/RequestBrandFailure'

// Store
export const STORE_REQUEST_START    = 'Stores/RequestStoreStart'
export const STORE_REQUEST_SUCCESS  = 'Stores/RequestStoreSuccess'
export const STORE_REQUEST_FAILURE  = 'Stores/RequestStoreFailure'

// User
export const USER_REQUEST_START    = 'Users/RequestUserStart'
export const USER_REQUEST_SUCCESS  = 'Users/RequestUserSuccess'
export const USER_REQUEST_FAILURE  = 'Users/RequestUserFailure'

// User Affinity
export const USER_AFFINITY_REQUEST_START = 'UserAffinity/RequestUserStart'
export const USER_AFFINITY_REQUEST_SUCCESS = 'UsersAffinity/RequestUserSuccess'
export const USER_AFFINITY_REQUEST_FAILURE = 'UsersAffinity/RequestUserFailure'

// Current User
export const UPDATE_CURRENT_USER = 'Users/RequestCurrentUserUpdate'
export const GET_CURRENT_USER_START = 'Users/RequestCurrentUserStart'

// User Activity
export const USER_ACTIVITY_REQUEST_START    = 'UserActivities/RequestStart'
export const USER_ACTIVITY_REQUEST_SUCCESS  = 'UserActivities/RequestSuccess'
export const USER_ACTIVITY_REQUEST_FAILURE  = 'UserActivities/RequestFailure'
export const USER_ACTIVITY_REQUEST_NEXT_PAGE = 'UserActivities/RequestNextPage'

// Tags
export const TAGS_REQUEST_START = 'Tags/RequestTagStart'
export const TAGS_REQUEST_SUCCESS = 'Tags/RequestTagSuccess'
export const TAGS_REQUEST_FAILURE = 'Tags/RequestTagFailure'

// Departments
export const DEPARTMENTS_FETCH = 'Departments/FetchDepartments'
export const DEPARTMENTS_REQUEST_START = 'Departments/RequestDepartmentStart'
export const DEPARTMENTS_REQUEST_SUCCESS = 'Departments/RequestDepartmentSuccess'
export const DEPARTMENTS_REQUEST_FAILURE = 'Departments/RequestDepartmentFailure'

// Categories
export const CATEGORIES_FETCH = 'Categories/FetchCategories'
export const CATEGORIES_REQUEST_START = 'Categories/RequestCategoryStart'
export const CATEGORIES_REQUEST_SUCCESS = 'Categories/RequestCategorySuccess'
export const CATEGORIES_REQUEST_FAILURE = 'Categories/RequestCategoryFailure'

// Subcategories
export const SUBCATEGORIES_FETCH = 'Subcategories/FetchSubcategories'
export const SUBCATEGORIES_REQUEST_START = 'Subcategories/RequestSubcategoryStart'
export const SUBCATEGORIES_REQUEST_SUCCESS = 'Subcategories/RequestSubcategorySuccess'
export const SUBCATEGORIES_REQUEST_FAILURE = 'Subcategories/RequestSubcategoryFailure'

// Deal
export const DEAL_REQUEST_START = 'Deals/RequestDealStart'
export const DEAL_REQUEST_SUCCESS = 'Deals/RequestDealSuccess'
export const DEAL_REQUEST_FAILURE = 'Deals/RequestDealFailure'

// Sibling Deal
export const SIBLING_DEALS_REQUEST_START = 'Deals/RequestSiblingDealsStart'
export const SIBLING_DEALS_REQUEST_SUCCESS = 'Deals/RequestSiblingDealsSuccess'
export const SIBLING_DEALS_REQUEST_FAILURE = 'Deals/RequestSiblingDealsFailure'

// Coupon
export const COUPON_REQUEST_START = 'Coupons/RequestCouponStart'
export const COUPON_REQUEST_SUCCESS = 'Coupons/RequestCouponSuccess'
export const COUPON_REQUEST_FAILURE = 'Coupons/RequestCouponFailure'

// SaleEvent
export const SALE_EVENT_REQUEST_START = 'SaleEvents/RequestSaleEventStart'
export const SALE_EVENT_REQUEST_SUCCESS = 'SaleEvents/RequestSaleEventSuccess'
export const SALE_EVENT_REQUEST_FAILURE = 'SaleEvents/RequestSaleEventFailure'

// Notify Pricedrop
export const NOTIFY_PRICEDROP_TRIGGERED = 'Deals/NotifyPricedropTriggered'
export const NOTIFY_PRICEDROP_START = 'Deals/NotifyPricedropRequestStart'
export const NOTIFY_PRICEDROP_SUCCESS = 'Deals/NotifyPricedropRequestSuccess'
export const NOTIFY_PRICEDROP_FAILURE = 'Deals/NotifyPricedropRequestFailure'

// Voting
export const VOTING_TRIGGERED = 'Voting/Triggered'
export const VOTING_START = 'Voting/RequestStart'
export const VOTING_SUCCESS = 'Voting/RequestSuccess'
export const VOTING_FAILURE = 'Voting/RequestFailure'

// Following
export const FOLLOWING_TRIGGERED = 'Following/Triggered'
export const FOLLOWING_START = 'Following/RequestStart'
export const FOLLOWING_SUCCESS = 'Following/RequestSuccess'
export const FOLLOWING_FAILURE = 'Following/RequestFailure'
export const FOLLOWING_NOTIFY_END = 'Following/NotificationEnd'

// Unique Codes
export const USED_TRIGGERED = 'Used/Triggered'
export const USED_START = 'Used/RequestStart'
export const USED_SUCCESS = 'Used/RequestSuccess'
export const USED_FAILURE = 'Used/RequestFailure'

// Comments
export const LOAD_COMMENTS = 'Comments/LoadComments'
export const COMMENTS_REQUEST_START = 'Comments/RequestStart'
export const COMMENTS_REQUEST_SUCCESS = 'Comments/RequestSuccess'
export const COMMENTS_REQUEST_FAILURE = 'Comments/RequestFailure'
export const COMMENT_SUBMITTED = 'Comments/Submitted'
export const COMMENT_SUBMIT_START = 'Comments/SubmitRequestStart'
export const COMMENT_SUBMIT_SUCCESS = 'Comments/SubmitRequestSuccess'
export const COMMENT_SUBMIT_FAILURE = 'Comments/SubmitRequestFailure'
export const RESET_COMMENTS_COUNT = 'Comments/ResetCommentsCount'

// data Requesting Status
export const DATA_REQUESTING = 'Data/Requesting'
export const DATA_READY = 'Data/Ready'

// search
export const SEARCH_SET_ACTIVE = 'Search/SetActive'
export const SEARCH_SET_KEYWORD = 'Search/SetKeyword'
export const SEARCH_GET_KEYWORD = 'Search/GetKeyword'
export const SEARCH_CLEAR_KEYWORD = 'Search/ClearKeyword'
export const SEARCH_REQUEST_NEXTPAGE = 'Search/RequestNextpage'
export const SEARCH_REQUEST_SUCCESS = 'Search/RequestSuccess'
export const SEARCH_REQUEST_FAILURE = 'Search/RequestFailure'
export const SORT = 'Search/Sort'
export const SEARCH_SET_ISOVERLAY_OPEN = 'Search/SetOverlay'

export const SHOW_USER_SLIDER = 'MobileMenu/ShowUserSlider'
export const HIDE_USER_SLIDER = 'MobileMenu/HideUserSlider'
export const TOGGLE_USER_SLIDER = 'MobileMenu/ToggleUserSlider'

export const SHOW_CAT_SLIDER = 'MobileMenu/ShowCatSlider'
export const HIDE_CAT_SLIDER = 'MobileMenu/HideCatSlider'
export const TOGGLE_CAT_SLIDER = 'MobileMenu/ToggleCatSlider'
export const CAT_SLIDER_SELECT_DEPARTMENT = 'MobileMenu/SetDepartment'
export const CAT_SLIDER_RESET_DEPARTMENT = 'MobileMenu/ResetDepartment'

// Todays Top Pick
export const TOP_PICK_REQUEST_START = 'TopPick/RequestStart'
export const TOP_PICK_REQUEST_SUCCESS = 'TopPick/RequestSuccess'
export const TOP_PICK_REQUEST_FAILURE = 'TopPick/RequestFailure'

// Manual Merchandise Pick

export const CUSTOMWIDGETS_PICK_REQUEST_START = 'CustomWidgets/RequestStart'
export const CUSTOMWIDGETS_PICK_REQUEST_SUCCESS = 'CustomWidgets/RequestSuccess'
export const CUSTOMWIDGETS_PICK_REQUEST_FAILURE = 'CustomWidgets/RequestFailure'

// Your Top Pick
export const YOUR_TOP_PICK_REQUEST_START = 'YourTopPick/RequestStart'
export const YOUR_TOP_PICK_REQUEST_SUCCESS = 'YourTopPick/RequestSuccess'
export const YOUR_TOP_PICK_REQUEST_FAILURE = 'YourTopPick/RequestFailure'
export const YOUR_TOP_PICK_ORDER_STORE = 'YourTopPick/OrderStore'
export const YOUR_TOP_PICK_RESET = 'YourTopPick/Reset'

// CBA Exclusive Pick
export const CBA_EXCLUSIVE_PICK_REQUEST_START = 'CbaExclusivePick/RequestStart'
export const CBA_EXCLUSIVE_PICK_REQUEST_SUCCESS = 'CbaExclusivePick/RequestSuccess'
export const CBA_EXCLUSIVE_PICK_REQUEST_FAILURE = 'CbaExclusivePick/RequestFailure'

// Followings
export const FOLLOWED_DEALS_REQUEST_START = 'Following/DealRequestStart'
export const FOLLOWED_DEALS_REQUEST_SUCCESS = 'Following/DealRequestSuccess'
export const FOLLOWED_DEALS_REQUEST_FAILURE = 'Following/DealRequestFailure'
export const FOLLOWED_COUPONS_REQUEST_START = 'Following/CouponRequestStart'
export const FOLLOWED_COUPONS_REQUEST_SUCCESS = 'Following/CouponRequestSuccess'
export const FOLLOWED_COUPONS_REQUEST_FAILURE = 'Following/CouponRequestFailure'
export const FOLLOWED_SALE_EVENTS_REQUEST_START = 'Following/SaleEventRequestStart'
export const FOLLOWED_SALE_EVENTS_REQUEST_SUCCESS = 'Following/SaleEventRequestSuccess'
export const FOLLOWED_SALE_EVENTS_REQUEST_FAILURE = 'Following/SaleEventRequestFailure'
export const FOLLOWED_BEST_SELLERS_REQUEST_START = 'Following/BestSellerRequestStart'
export const FOLLOWED_BEST_SELLERS_REQUEST_SUCCESS = 'Following/BestSellerRequestSuccess'
export const FOLLOWED_BEST_SELLERS_REQUEST_FAILURE = 'Following/BestSellerRequestFailure'

export const LOAD_SALE_EVENT_CALENDAR_PAGE = 'Calendar/SaleEvent/PageLoad'
export const CALENDAR_REMOVE_SALE_EVENT = 'Calendar/RemoveSaleEvent'
export const CALENDAR_UPDATE_ACTIVE_LIST = 'Calendar/RemoveSaleEvent/Active/UpdateList'
export const CALENDAR_UPDATE_UPCOMING_LIST = 'Calendar/RemoveSaleEvent/Upcoming/UpdateList'
export const CALENDAR_SALE_EVENT_ACTIVE_REQUEST_START = 'Calendar/SaleEvent/Active/RequestStart'
export const CALENDAR_SALE_EVENT_ACTIVE_REQUEST_SUCCESS = 'Calendar/SaleEvent/Active/RequestSuccess'
export const CALENDAR_SALE_EVENT_ACTIVE_REQUEST_FAILURE = 'Calendar/SaleEvent/Active/RequestFailure'
export const CALENDAR_SALE_EVENT_UPCOMING_REQUEST_START = 'Calendar/SaleEvent/Upcoming/RequestStart'
export const CALENDAR_SALE_EVENT_UPCOMING_REQUEST_SUCCESS = 'Calendar/SaleEvent/Upcoming/RequestSuccess'
export const CALENDAR_SALE_EVENT_UPCOMING_REQUEST_FAILURE = 'Calendar/SaleEvent/Upcoming/RequestFailure'

// SaleEvent Notification
export const SALE_EVENT_NOTIFICATION_UPDATE = 'SaleEvent/Notification/Update'
export const SALE_EVENT_NOTIFICATION_UPDATE_SUCCESS = 'SaleEvent/Notification/UpdateSuccess'
export const SALE_EVENT_NOTIFICATION_UPDATE_FAILURE = 'SaleEvent/Notification/UpdateFailure'

// Newsletters
export const NEWSLETTERS_REQUEST_START = 'Newsletters/RequestNewslettersStart'
export const NEWSLETTERS_REQUEST_SUCCESS = 'Newsletters/RequestNewslettersSuccess'
export const NEWSLETTERS_REQUEST_FAILURE = 'Newsletters/RequestNewslettersFailure'

export const EXPERIMENTS_REQUEST_START = 'Experiments/RequestExperimentsStart'
export const EXPERIEMNTS_REQUEST_SUCCESS = 'Experiments/RequestExperimentsSuccess'
export const EXPERIEMNTS_REQUEST_FAILURE = 'Experiments/RequestExperimentsFailure'
// Goto Url
export const GOTO_URL_REQUEST_START = 'Goto/GotoUrlRequestStart'
export const GOTO_URL_REQUEST_SUCCESS = 'Goto/GotoUrlRequestSuccess'
export const GOTO_URL_REQUEST_FAILURE = 'Goto/GotoUrlRequestFailure'

// Affinity Top Department
export const AFFI_TOP_DEP_START = 'AFFI/AffiTopDepStart'
export const AFFI_TOP_DEP_SUCCESS = 'AFFI/AffiTopDepSuccess'
export const AFFI_TOP_DEP_FAILURE = 'AFFI/AffiTopDepFailure'
export const TOP_DEP_ORDER_STORE = 'AFFI/TopDepOrderStore'

// Affinity Top Category
export const AFFINITY_TOP_CATEGORY_START = 'AFFINITY/AffinityTopCategoryStart'
export const AFFINITY_TOP_CATEGORY_SUCCESS = 'AFFINITY/AffinityTopCategorySuccess'
export const AFFINITY_TOP_CATEGORY_FAILURE = 'AFFINITY/AffinityTopCategoryFailure'

// Merchandised Top Department
export const MERCH_TOP_DEP_START = 'MERCH/MerchTopDepStart'
export const MERCH_TOP_DEP_SUCCESS = 'MERCH/MerchTopDepSuccess'
export const MERCH_TOP_DEP_FAILURE = 'MERCH/MerchTopDepFailure'
export const GET_USER_EMAIL = 'Pesonalising/GetUserEmail'
export const SET_USER_EMAIL = 'Pesonalising/SetUserEmail'
export const SET_PERSONALISE_MODAL = 'Pesonalising/SetPersonaliseModal'
export const SET_INTIALLISE_PERSONALISE = 'Pesonalising/SetPersonaliseInitialise'

// Todays Top Pick
export const NARRAT_TOP_PICK_REQUEST_START = 'NarratTopPick/RequestStart'
export const NARRAT_TOP_PICK_REQUEST_SUCCESS = 'NarratTopPick/RequestSuccess'

// Home Page carousel
export const SET_CAROUSEL_LOADING = 'HomePage/SetCarouselLoading'
export const SET_CAROUSEL_LOADED = 'HomePage/SetCarouselLoaded'
export const RESET_CAROUSEL_LOADED = 'HomePage/ReSetCarouselLoaded'

// Common 
export const BE_CDN_LOADED = 'Common/CDN_LOADED'
