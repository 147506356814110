import React from "react"
import { useDispatch } from "react-redux"
import Loader from "../../components/Loader"
import ViewSaleButton from "../Buttons/ViewSaleButton"
import { hideUserSlider } from "../../apps/App/actions"

function MobileSidebarList(props) {
  const {
    title,
    type,
    description,
    isLoading,
    children,
    viewAllUrl
  } = props
  const dispatch = useDispatch()
  const text = `View All ${type}`

  // No data hide it all
  if (!isLoading && children.length === 0) { return (null) }
  const content = children.map(
    child => <div onClick={() => { dispatch(hideUserSlider()) }} key={`table-row-${child.key}`} className="col-6 nav-brand-store-item">{child}</div>
  )

  return (
    <section>
      <h3>{ title }</h3>
      {
        description === undefined || description.length > 0
        ? <p>{ description }</p>
        : null
      }
      <div className="card mt-3 border-0 listing-group">
        <Loader isLoading={isLoading} />
        <div className="row">
          {content}
        </div>
      </div>
      {
        viewAllUrl && (
          <ViewSaleButton
            className="mr-3 btn rounded-pill btn-lg btn-main btn-view"
            text={text}
            onClick={() => { dispatch(hideUserSlider()) }}
            url={viewAllUrl} />
        )
      }
    </section>
  )
}

export default MobileSidebarList
