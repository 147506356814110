import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { getCategoriesByDepartmentId, getResource } from "../../../reducers"

export default function CategorySelect({ department, hideSidebar }) {
  const categoryIds = useSelector(state => getCategoriesByDepartmentId(state, department.id))

  return (
    <div className="row no-gutters">
      <div className="col-xl-6">
        <ul className="list-unstyled list-group category-list">
          {categoryIds.map(id => (
            <CategoryOption categoryId={id} department={department} hideSidebar={hideSidebar} key={`category-${id}`} />
          ))}
        </ul>
      </div>
    </div>
  )
}

function CategoryOption({ categoryId, department, hideSidebar }) {
  const category = useSelector(state => getResource(state, { id: categoryId, type: "category" }))

  if (!category) return null

  return (
    <Link
      to={`/shop/${department.attributes.slug}/${category.attributes.slug}`}
      key={`${category.attributes.slug}`}
      onClick={hideSidebar}>
      <li className="list-group-item">
        <div className="truncate">
          {category.attributes.name}
        </div>
      </li>
    </Link>
  )
}
