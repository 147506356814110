import React from "react"
import { Link } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Pluralize from "react-pluralize"

import FollowButton from "../../../apps/App/components/buttons/FollowButton"

export default function DealHunterNormalCard({
  wrapperClass, item,
  item: {
    attributes,
    links
  },
  showStats
}) {
  return (
    <div className={`listing-card card-hunter-sm ${wrapperClass}`}>
      <div className="card shadow-none border-0 ">
        <div className="card-body d-xl-flex align-items-center justify-content-xl-between">
          <Link
            to={links.userUrl}>
            <div className="d-xl-flex text-center text-xl-left">
              <div className="card-image">
                <LazyLoadImage
                  effect="opacity"
                  className="mr-xl-2 sidebar-logo rounded-circle"
                  alt={attributes.name}
                  src={links.avatar.thumb.url} />
              </div>
              <div className="card-content">
                <div className="text-truncate">{attributes.username}</div>
                <div className={`small text-muted ${showStats ? "" : "d-none d-xl-block"} text-capitalize`}>
                  <Pluralize singular="follower" count={attributes.followersCount} />
                  {" "}
                  /
                  {" "}
                  <Pluralize singular="vote" count={attributes.upvotedCount} />
                  <br />
                  <Pluralize singular="offer" count={attributes.dealsCount} />
                  {" submitted"}
                </div>
              </div>
            </div>
          </Link>
          <FollowButton item={item} buttonClasses="btn btn-follow" />
        </div>
      </div>
    </div>
  )
}
