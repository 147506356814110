import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchFollowedDeals(baseUrl, params) {
  yield put(actions.followedDealsRequestStart())
  const { response, error } = yield call(requester.getDeals, baseUrl, params)
  yield (response) ? put(actions.followedDealsRequestSuccess(response)) : put(actions.followedDealsRequestFailure(error))
}

function* fetchFollowedCoupons(baseUrl, params) {
  yield put(actions.followedCouponsRequestStart())
  const { response, error } = yield call(requester.getCoupons, baseUrl, params)
  yield (response) ? put(actions.followedCouponsRequestSuccess(response)) : put(actions.followedCouponsRequestFailure(error))
}

function* fetchFollowedSalesEvents(baseUrl, params) {
  yield put(actions.followedSaleEventsRequestStart())
  const { response, error } = yield call(requester.getSaleEvents, baseUrl, params)
  yield (response) ? put(actions.followedSaleEventsRequestSuccess(response)) : put(actions.followedSaleEventsRequestFailure(error))
}

function* fetchListData({ data }) {
  const baseUrl = yield select(getBaseUrl)

  const params = {
    page: _.get(data, "page", 1),
    by_followed_user: _.get(data, "userId"),
    "by_order[column]": data.listType === "deals" ? "last_price_date" : "updated_at",
    "by_order[direction]": "desc"
  }

  yield {
    deals: call(fetchFollowedDeals, baseUrl, params),
    vouchers: call(fetchFollowedCoupons, baseUrl, params),
    "sales-events": call(fetchFollowedSalesEvents, baseUrl, params)
  }[data.listType]
}

// Top Brands
function* fetchTopBrandsSidebarList({ data }) {
  if (data.listType !== "trending-brands") {
    yield put(actions.topBrandsRequestStart())
    const baseUrl = yield select(getBaseUrl)

    const params = {
      per_page: 8
    }

    const { response, error } = yield call(requester.getTopBrands, baseUrl, params)
    yield (response) ? put(actions.topBrandsRequestSuccess(response)) : put(actions.topBrandsRequestFailure(error))
  }
}

// Top Stores
function* fetchTopStoresSidebarList({ data }) {
  if (data.listType !== "top-stores") {
    yield put(actions.topStoresRequestStart())
    const baseUrl = yield select(getBaseUrl)

    const params = {
      per_page: 8
    }

    const { response, error } = yield call(requester.getTopStores, baseUrl, params)
    yield (response) ? put(actions.topStoresRequestSuccess(response)) : put(actions.topStoresRequestFailure(error))
  }
}

// Top DealHunters
function* fetchTopDealHuntersSidebarList({ data }) {
  if (data.listType !== "top-spotters") {
    yield put(actions.topDealHuntersRequestStart())
    const baseUrl = yield select(getBaseUrl)

    const params = {
      per_page: 8
    }

    const { response, error } = yield call(requester.getTopDealHunters, baseUrl, params)
    yield (response) ? put(actions.topDealHuntersRequestSuccess(response)) : put(actions.topDealHuntersRequestFailure(error))
  }
}

// Top Upcoming Sales
function* fetchTopUpcomingSaleEventsSidebarList() {
  yield put(actions.topUpcomingSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)

  const params = {
    upcoming: true,
    current: false
  }

  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield response ? put(actions.topUpcomingSaleEventsRequestSuccess(response)) : put(actions.topUpcomingSaleEventsRequestFailure(error))
}

function* fetchListUser({ data }) {
  yield put(actions.userRequestStart())
  const baseUrl = yield select(getBaseUrl)

  const { response, error } = yield call(requester.getUserByIdentifier, baseUrl, data.pageUserSlug)
  yield response ? put(actions.userRequestSuccess(response)) : put(actions.userRequestFailure(error))
}

function* fetchData(action) {
  yield all([
    fork(fetchListData, action),
    fork(fetchListUser, action),
    fork(fetchTopBrandsSidebarList, action),
    fork(fetchTopStoresSidebarList, action),
    fork(fetchTopDealHuntersSidebarList, action),
    fork(fetchTopUpcomingSaleEventsSidebarList, action)
  ])
}

function* watchFollowingRankingsPageSaga() {
  yield all([
    takeLatest(actionTypes.LOAD_FOLLOWING_RANKINGS_PAGE, fetchData),
    takeLatest(actionTypes.REFRESH_FOLLOWING_RANKING_LIST, fetchListData)
  ])
}

export {
  watchFollowingRankingsPageSaga
}
