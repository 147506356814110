import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import { setSaleEventNotification } from "../../requesters"
import { getBaseUrl } from "../../reducers"

function* updateNotification({ data }) {
  const baseUrl = yield select(getBaseUrl)

  const { response, error } = yield call(setSaleEventNotification, baseUrl, data)
  if (response) {
    yield put({ type: actionTypes.SALE_EVENT_NOTIFICATION_UPDATE_SUCCESS, response })
  } else {
    yield put({ type: actionTypes.SALE_EVENT_NOTIFICATION_UPDATE_FAILURE, error })
  }
}

function* putData(data) {
  yield all([
    fork(updateNotification, data)
  ])
}

function* watchSaleEventNotificationUpdateSaga() {
  yield takeLatest(actionTypes.SALE_EVENT_NOTIFICATION_UPDATE, putData)
}

export {
  watchSaleEventNotificationUpdateSaga
}