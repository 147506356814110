import { isIOS } from '../../../javascript/apps/App/utilis/detectBrowser'

export const storeLinkClick = () => {
  console.log('event call')
  const label = isIOS() ? 'ios-app-store-link' : 'chrome-store-link'
  snowplow('trackStructEvent', 'ux', 'click', label, null, null, [])
}

export const beActiveClickEvents = (label = '') => {
  console.log('event call with lable', label)
  snowplow('trackStructEvent', 'ux', 'click', label, null, null, [])
}