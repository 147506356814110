import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useInView } from 'react-intersection-observer'
import moment from 'moment'

import {
  RankLabel,
  FooterListingCounts,
} from "../../../../components/Labels"
import ShareButtons from "../../../../apps/App/components/buttons/ShareButtons"
import positionDataLayer from '../../../dataLayers/positionDataLayer'
import { USE_IN_VIEW_OPTIONS } from '../../../../../constant'
import { megaPhone } from '../../../Icons'

export default function NormalSaleCard (props) {
  const {
    position,
    shareable,
    showRank,
    wrapperClass,
    currentSection,
    sectionPosition,
    item,
    sectionId,
    isPersonalised,
    isSponsored
  } = props

  const {
    type,
    id,
    attributes,
    links
  } = item

  const {
    name,
    commentsCount,
    impressionsCount,
    upvotesCount,
    storeName,
    expireAt,
    offerText,
    endIn
  } = attributes

  let saleEventUrl = links.saleEventUrl
  let isDealUrl = false
  if(links?.url?.indexOf('littlebirdie.') > -1) {
    saleEventUrl = `/${links.url.replace( /^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//, '' )}`;
    isDealUrl = true
  }
  else if(item?.url?.indexOf('littlebirdie.') > -1) {
    saleEventUrl = `/${item.url.replace( /^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//, '' )}`;
    isDealUrl = true
  }

  const [refNormalSaleCard, inView] = useInView(USE_IN_VIEW_OPTIONS)

  useEffect(() => {
    if (inView) {
      positionDataLayer(item, 'product-impression', 'product-link', position, currentSection, sectionPosition, isPersonalised)
    }
  }, [inView])

  const endDate = endIn ? endIn?.split('-')?.[0] : `Ends ${moment(expireAt).format('DD/MM/YYYY')}`
  return (
    <>
      <div className={`listing-card featured ${wrapperClass}`} ref={refNormalSaleCard}>
        {(position && showRank) && (
          <RankLabel rank={position} />
        )}
        <Link
          className='link-wrapper'
          to={saleEventUrl}
          onClick={() => {
            positionDataLayer(item, 'card-click', 'product-link', position, currentSection, sectionPosition, isPersonalised)
            sectionId && localStorage.setItem("hsref", sectionId)
          }}
        >
          <div className='card card-sale'>
            <div className='card-img-top-container'>
              <LazyLoadImage
                alt={attributes.name}
                src={links.imageUrl}
              />
            </div>
            <div className='card-body'>
              <div className='store-info-container'>
                <span className='st-header'>{storeName}</span>
                <span className='card-text'>{name}</span>
              </div>
              <div className='price-info-container'>
                <span className='offer-text m-0 p-0'>{offerText}</span>
                <div className='d-flex justify-content-between'>
                  <span className='end-date'>{endDate}</span>
                  <FooterListingCounts {...{
                    type, id, name, commentsCount, upvotesCount, impressionsCount, wrapperClass: 'list-inline mb-0 d-flex'
                  }}
                  />
                </div>
              </div>
            </div>
            <div className={`card-bottom ${!isSponsored && 'card-bottom-align'}`}>
              <div className={`bottom-text ${isSponsored && 'd-flex justify-content-between px-2'}`}>
                {isDealUrl ? 'View Product': 'View Sale Event'}
                {isSponsored &&
                  <span className='sponsored-badge'>
                    <img src={megaPhone} width='16px' height='16px' />
                    Sponsored
                  </span>}
              </div>
            </div>
          </div>
        </Link>
      </div>
      {shareable && <ShareButtons name={name} eventId={id} url={links.saleEventUrl} />}
    </>
  )
}
