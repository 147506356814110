import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import * as actionTypes from "../../../actions/types"

const brandsFetchSuccess = (state, { data }) => ({ ...state, ...data.brand })

const byId = createReducer({})({
  [actionTypes.RESOURCE_FETCHED_SUCCESS]: brandsFetchSuccess,
  [actionTypes.BRAND_REQUEST_SUCCESS]: brandsFetchSuccess
})

export const getBrandById = (state, brandId) => state.byId[brandId]
export const getBrandBySlug = (state, slug) => _.find(state.byId, { attributes: { slug } })

export default combineReducers({
  byId
})
