import React from "react"
import { Link } from "react-router-dom"
import Pluralize from "react-pluralize"


import FollowButton from "../../../apps/App/components/buttons/FollowButton"

export default function DealHunterCenterCard({
  item,
  item: {
    attributes,
    links
  }
}) {
  return (
    <div className="col-4 col-md-3 col-lg-2 listing-card">
      <Link className="link-wrapper" to={`/spotter/${attributes.slug}`}>
        <div className="card card-brand card-spotter">
          <div className="card-img-top text-center pt-2">
            <img className="listing-img rounded-circle w-auto img-fluid" src={links.avatar.url} />
          </div>
          <div className="card-body text-center">
            <div className="card-title">{attributes.username}</div>
            <ul className="card-text list-unstyled text-muted">
              <li>
                <Pluralize singular="Follower" count={attributes.followersCount} />
                {" "}
                /
                {" "}
                <Pluralize singular="Vote" count={attributes.upvotedCount} />
              </li>
              <Pluralize singular="Offer" count={attributes.dealsCount} />
              {" Submitted"}
            </ul>
            <FollowButton item={item} buttonClasses="btn btn-follow" />
          </div>
        </div>
      </Link>
    </div>
  )
}
