import { PureComponent } from "react"
import { withRouter } from "react-router-dom"

class ScrollToTop extends PureComponent {
  componentDidMount = () => window.scrollTo(0, 0);

  componentDidUpdate = prevProps => {
    const {
      location
    } = this.props

    if (location !== prevProps.location) window.scrollTo(0, 0)
  };

  render = () => null
}

export default withRouter(ScrollToTop)