import React from 'react'

import NormalCouponCard from './NormalCouponCard'

export default function CouponCard ({
  userSignedIn,
  wrapperClass,
  position,
  item,
  showRank,
  currentSection,
  sectionPosition,
  sectionId,
  isPersonalised,
  isSponsored
}) {
  if (!item) {
    return null
  }
  return (
    <NormalCouponCard {...{
      wrapperClass, position, item, showRank, currentSection, sectionPosition, sectionId, isPersonalised, isSponsored
    }}
    />
  )
}
