import { combineReducers } from 'redux'

import * as actionTypes from '../../actions/types'

const initState = {
  loading: false,
  data: []
}

const request = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.CUSTOMWIDGETS_PICK_REQUEST_START: {
      return { ...state, loading: true }
    }
    case actionTypes.CUSTOMWIDGETS_PICK_REQUEST_SUCCESS: {
      const { response } = action
      const data = response?.data || []

      const isDuplicateData = _.some(state.data, cw => {
        return cw.id === data[0]?.id
      }) || false

      return {
        ...state,
        data: isDuplicateData ? state.data : [...state.data, ...data],
        loading: false
      }
    }
    case actionTypes.CUSTOMWIDGETS_PICK_REQUEST_FAILURE: {
      return { ...state, loading: false }
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  request
})
