import * as types from "./types"

export const userActivityRequestNextPage = ({ identifier }) => ({
  type: types.USER_ACTIVITY_REQUEST_NEXT_PAGE,
  identifier
})

export const userActivityRequestStart = data => ({
  type: types.USER_ACTIVITY_REQUEST_START,
  data
})

export const userActivityRequestSuccess = data => ({
  type: types.USER_ACTIVITY_REQUEST_SUCCESS,
  data
})

export const userActivityRequestFailure = error => ({
  type: types.USER_ACTIVITY_REQUEST_FAILURE,
  error
})