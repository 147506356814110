import PropTypes from 'prop-types'
function positionDataLayer (item, eventType, linkType, position, currentSection = null, sectionPosition = null, isPersonalised) {
  const { id } = item || {}
  const itemName = item?.attributes?.name || item?.name || null

  const departmentName = item?.relationships?.department?.attributes?.name || item?.department_name || null
  const categoryName = item?.relationships?.category?.attributes?.name || item?.category_name || null
  const subcategoryName = item?.relationships?.subcategory?.attributes?.name || item?.subcategory_name || null

  const names = [departmentName, categoryName, subcategoryName]

  const {
    id: brandId,
    attributes: {
      name: brandName
    } = {}
  } = item?.relationships?.brand || {} // prevent throwing an error if there is no brand

  const {
    id: storeId,
    attributes: {
      name: storeName
    } = {}
  } = item?.relationships?.store || {}

  const cardSchema = {}
  // if card is of type DEAL
  if (item?.type === 'deal') {
    const slug = item?.slug || item?.attributes?.slug
    const productUrl = `${window.location.origin}/shop/deal/${slug}`
    const dealData = {
      id: String(id || null),
      price: parseFloat(item?.attributes?.price || item?.price, 10),
      currency: 'AUD',
      url: productUrl?.substring(0, 255) || ''
    }

    cardSchema.AdditionalContext = [
      {
        schema: 'iglu:com.littlebirdie/product/jsonschema/1-0-0',
        data: {
          id: String(id || null),
          name: itemName,
          category: names
        }
      },
      {
        schema: 'iglu:com.littlebirdie/deal/jsonschema/1-0-0',
        data: { deal: [dealData] }
      },
      {
        schema: 'iglu:com.littlebirdie/brand/jsonschema/1-0-0',
        data: {
          id: String(brandId || item?.brand_id || null),
          name: brandName || item?.brand_name || null
        }
      },
      {
        schema: 'iglu:com.littlebirdie/retailer/jsonschema/1-0-0',
        data: {
          id: String(storeId || item?.store_id || null),
          name: storeName || item?.store_name || null
        }
      }
    ]
  }
  // if card is of type SALE EVENT
  if (item?.type === 'saleEvent') {
    const {
      id,
      attributes: {
        name: saleEventName,
        startAt,
        expireAt
      }
    } = item || {}
    const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
    const startDate = startAt ? startAt.match(regex)[0] : null
    const expireDate = expireAt ? expireAt.match(regex)[0] : null
    cardSchema.AdditionalContext = [
      {
        schema: 'iglu:com.littlebirdie/sale/jsonschema/1-0-0',
        data: {
          id: String(id),
          name: saleEventName,
          category: names,
          start_date: startDate,
          end_date: expireDate
        }
      },
      {
        schema: 'iglu:com.littlebirdie/brand/jsonschema/1-0-0',
        data: {
          id: String(brandId || item?.brand_id || null),
          name: brandName || item?.brand_name || null
        }
      },
      {
        schema: 'iglu:com.littlebirdie/retailer/jsonschema/1-0-0',
        data: {
          id: String(storeId || item?.store_id || null),
          name: storeName || item?.store_name || null
        }
      }
    ]
  }
  // if card is of type COUPON or Unique Coupon
  if (item?.type?.toLowerCase() === 'coupon' || item?.type?.toLowerCase() === 'uniquecoupon') {
    cardSchema.AdditionalContext = [
      {
        schema: 'iglu:com.littlebirdie/coupon/jsonschema/1-0-0',
        data: {
          id: String(id),
          category: names,
          rank_global: item?.attributes?.fixedGlobalScore || item?.fixed_global_score || null,
          rank_department: item?.attributes?.fixedDepartmentScore || item?.fixed_department_score || null,
          name: item?.attributes?.name || item?.name || null
        }
      },
      {
        schema: 'iglu:com.littlebirdie/retailer/jsonschema/1-0-0',
        data: {
          id: String(storeId || null),
          name: storeName || null
        }
      }
    ]
  }
  // if card is of type Brand
  if (item?.type === 'brand') {
    cardSchema.AdditionalContext = [
      {
        schema: 'iglu:com.littlebirdie/brand/jsonschema/1-0-0',
        data: {
          id: String(brandId || item?.id || null),
          name: brandName || item?.attributes?.name || null
        }
      }
    ]
  }
  // if card is of type Store
  if (item?.type === 'store') {
    cardSchema.AdditionalContext = [
      {
        schema: 'iglu:com.littlebirdie/retailer/jsonschema/1-0-0',
        data: {
          id: String(storeId || item?.id || null),
          name: storeName || item?.attributes?.name || null
        }
      }
    ]
  }
  if (item?.component_type === 'comparison-table') {
    cardSchema.AdditionalContext = [
      {
        schema: 'iglu:com.littlebirdie/product/jsonschema/1-0-0',
        data: {
          id: String(item?.attributes?.productId || null),
          name: itemName,
          category: names
        }
      },
      {
        schema: 'iglu:com.littlebirdie/brand/jsonschema/1-0-0',
        data: {
          id: String(brandId || null),
          name: brandName || null
        }
      },
      {
        schema: 'iglu:com.littlebirdie/retailer/jsonschema/1-0-0',
        data: {
          id: String(item?.storeData?.attributes?.store_id || null),
          name: item?.storeData?.attributes?.store_name || null
        }
      },
      {
        schema: 'iglu:com.littlebirdie/deal/jsonschema/1-0-0',
        data: {
          deal: [
            {
              id: String(item?.storeData?.id || null),
              price: parseFloat(item?.storeData?.attributes?.price || 10),
              currency: 'AUD',
              rank_global: item?.storeData?.attributes?.fixed_department_score || null,
              rank_department: item?.storeData?.attributes?.fixed_global_score || null
            }
          ]
        }
      }
    ]
  }

  // Track Card click event
  if (eventType === 'card-click') {
    snowplow('trackStructEvent', 'ux', 'interaction', null, isPersonalised ? 'for-you': null, null,
      [
        {
          schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
          data: {
            name: 'click', // Type of Interaction i.e click or impression
            object_type: item?.type || null, // the offer type e.g deal, sale event, voucher, collection
            object_position: position || null, // the position of the offer in the component
            component_name: _.startCase(currentSection) || null, // the name of the component where the interaction with the object occured e.g TodaysTopPicks, SearchResults etc.
            component_position: parseInt(sectionPosition, 10) || null // The position of the component on the page where 1 is top of the page
          }
        },
        ...cardSchema.AdditionalContext
      ])
  }

  // Track card impression event
  if (eventType === 'product-impression') {
    snowplow('trackStructEvent', 'ux', 'interaction', null, isPersonalised ? 'for-you': null, null,
      [
        {
          schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
          data: {
            name: 'view', // Type of Interaction i.e click or impression/view
            object_type: item?.type || null, // the offer type e.g deal, sale event, voucher, collection
            object_position: position || null, // the position of the offer in the component
            component_name: _.startCase(currentSection) || null, // the name of the component where the interaction with the object occured e.g TodaysTopPicks, SearchResults etc.
            component_position: parseInt(sectionPosition, 10) || null // The position of the component on the page where 1 is top of the page
          }
        },
        ...cardSchema.AdditionalContext
      ])
  }

  // Track View All click event
  if (eventType === 'view-all') {
    snowplow('trackStructEvent', 'ux', 'interaction', null, null, null,
      [
        {
          schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
          data: {
            name: 'click', // Type of Interaction i.e click or impression
            object_type: 'view-all', // the offer type e.g deal, sale event, voucher, collection
            object_position: null, // the position of the offer in the component
            component_name: _.startCase(currentSection) || null, // the name of the component where the interaction with the object occured e.g TodaysTopPicks, SearchResults etc.
            component_position: parseInt(sectionPosition, 10) || null // The position of the component on the page where 1 is top of the page
          }
        }
      ])
  }

  // Track show more of stores in comparision table
  if (eventType === 'show-more') {
    snowplow('trackStructEvent', 'ux', 'interaction', null, null, null,
      [
        {
          schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
          data: {
            name: 'click', // Type of Interaction i.e click or impression
            object_type: 'show-more', // the offer type e.g deal, sale event, voucher, collection
            object_position: null, // the position of the offer in the component
            component_name: 'Comparison Table', // the name of the component where the interaction with the object occured e.g TodaysTopPicks, SearchResults etc.
            component_position: 2 // The position of the component on the page where 1 is top of the page
          }
        }
      ])
  }

  // Track card impression event
  if (eventType === 'offer-comparision-table') {
    snowplow('trackStructEvent', 'ux', 'interaction', linkType, null, null, [
      {
        schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
        data: {
          name: 'view', // Type of Interaction i.e click or impression/view
          object_type: 'deal', // the offer type e.g deal, sale event, voucher, collection
          object_position: position || null, // the position of the offer in the component
          component_name: 'Comparison Table', // the name of the component where the interaction with the object occured e.g TodaysTopPicks, SearchResults etc.
          component_position: 2 // The position of the component on the page where 1 is top of the page
        }
      },
      ...cardSchema.AdditionalContext
    ])
  }
  // comparision table Go to store  button
  if (eventType === 'track-event') {
    snowplow('trackStructEvent', 'ux', 'product-link-click', linkType, 'comparison-table', position, [
      ...cardSchema.AdditionalContext
    ])
  }

  if (eventType === 'department-view-all') {
    snowplow('trackStructEvent', 'ux', 'interaction', linkType, null, null, [
      {
        schema: 'iglu:com.littlebirdie/interaction/jsonschema/1-0-0',
        data: {
          name: 'clear', // Type of Interaction i.e click or impression
          object_type: linkType || null, // the view all type e.g all, price drops, voucher
          object_position: position || null, // the position of the view all button in the component
          component_name: _.startCase(currentSection) || null, // the name of the component where the interaction with the object occured
        }
      },
    ])
  }
}

positionDataLayer.PropTypes = {
  item: PropTypes.object.isRequired,
  eventType: PropTypes.string.isRequired,
  linkType: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  parent: PropTypes.string.isRequired
}

export default positionDataLayer
