const currencyFormatter = require("currency-formatter")

export const PriceLabel = ({
 price, currency = "AUD", hide_zero = false
}) => {
  if (hide_zero) return (null)
  return currencyFormatter.format(price, { code: currency })
}

export const priceString = ({
  price, currency = "AUD"
}) => currencyFormatter.format(price, { code: currency })