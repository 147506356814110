import * as types from "./types"

export const setSearchActive = data => {
  return {
    type: types.SEARCH_SET_ACTIVE,
    data
  }
}

export const searchClearKeyword = data => ({
  type: types.SEARCH_CLEAR_KEYWORD,
  data
})

export const setOverlaySearch = data => {
  return {
    type: types.SEARCH_SET_ISOVERLAY_OPEN,
    data: { isOpen: data }
  }
}

export const searchSetKeyword = data => {
  return ({
    type: types.SEARCH_SET_KEYWORD,
    data
  })
}

export const searchRequestNextPage = data => ({
  type: types.SEARCH_REQUEST_NEXTPAGE,
  data,
  itemType: data.itemType
})

export const searchRequestSuccess = data => ({
  type: types.SEARCH_REQUEST_SUCCESS,
  data,
  itemType: data.itemType
})

export const searchRequestFailure = data => ({
  type: types.SEARCH_REQUEST_FAILURE,
  data,
  itemType: data.itemType
})
