import { combineReducers } from "redux"

import * as actionTypes from "../../actions/types"

const request = (state = null, action) => {
  switch (action.type) {
    case actionTypes.YOUR_TOP_PICK_REQUEST_START: {
      return actionTypes.YOUR_TOP_PICK_REQUEST_START
    }
    case actionTypes.YOUR_TOP_PICK_REQUEST_SUCCESS: {
      return actionTypes.YOUR_TOP_PICK_REQUEST_SUCCESS
    }
    case actionTypes.YOUR_TOP_PICK_REQUEST_FAILURE: {
      return actionTypes.YOUR_TOP_PICK_REQUEST_FAILURE
    }
    case actionTypes.YOUR_TOP_PICK_RESET: {
      return actionTypes.YOUR_TOP_PICK_RESET
    }
    default: {
      return state
    }
  }
}

const byIds = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.YOUR_TOP_PICK_REQUEST_SUCCESS: {
      const { response } = action
      return response || {}
    }
    case actionTypes.YOUR_TOP_PICK_REQUEST_FAILURE: {
      return {}
    }
    case actionTypes.YOUR_TOP_PICK_RESET: {
      return {}
    }
    default: {
      return state
    }
  }
}

const resultOrder = (state = [], action) => {
  switch (action.type) {
    case actionTypes.YOUR_TOP_PICK_ORDER_STORE: {
      return action?.data || []
    }
    case actionTypes.USER_AFFINITY_REQUEST_SUCCESS: {
      return []
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  byIds,
  request,
  resultOrder
})
