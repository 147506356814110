import * as types from "./types"

export const storeRequestStart = data => ({
  type: types.STORE_REQUEST_START,
  data
})

export const storeRequestSuccess = data => ({
  type: types.STORE_REQUEST_SUCCESS,
  data
})

export const storeRequestFailure = error => ({
  type: types.STORE_REQUEST_FAILURE,
  error
})

export const topStoresRefresh = data => ({
  type: types.TOP_STORES_REFRESH,
  data
})