import React, { useEffect, useRef } from 'react'
import { crossIcon } from '../../../../components/Icons'

const ModalWrapper = ({ children, id, padding = "0 18px 18px", minHeight = "45px", modalClass = '' }) => {
  const modalCloseRef = useRef(null)
  const swipeDownRef = useRef(null)

  useEffect(() => {
    let touchstartY = 0
    let touchendY = 0

    const gestureZone = swipeDownRef.current

    gestureZone.addEventListener('touchstart', e => {
      touchstartY = e.changedTouches[0].screenY
    }, false)

    gestureZone.addEventListener('touchend', e => {
      touchendY = e.changedTouches[0].screenY
      if (touchendY - touchstartY > 15) {
        modalCloseRef.current.click()
      }
    }, false)

    return () => {
      gestureZone.addEventListener('touchstart')
      gestureZone.addEventListener('touchend')
    }
  }, [])
  const handleCloseModalWrapper = () => {
    modalCloseRef.current.click()
  }

  return (
    <div className={`modal toaster-modal fade ${modalClass}`} id={id} tabIndex='-1' role='dialog' aria-labelledby='ToasterModalLabel' aria-hidden='true' onBlur={handleCloseModalWrapper}>
      <div className={`modal-dialog ${id === 'cmp-store-reviews' && 'modal-store-reviews'}`} role='document'>
        <div className='modal-content'>
          <div ref={swipeDownRef} id={`swipe-down-${id}`} style={{ minHeight }}>
            <hr className='lb-indicator d-md-none' />
            <img ref={modalCloseRef} className='close-icon' data-dismiss='modal' src={crossIcon} />
          </div>
          <div style={{ padding }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalWrapper
