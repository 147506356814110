import { combineReducers } from "redux"

import * as actions from "../../actions/types"

const departmentCategories = (state = {}, action) => {
  switch (action.type) {
    case actions.CATEGORIES_REQUEST_SUCCESS:
      return { ...state, [`${action.departmentId}Categories`]: { ids: action.order, request: action.type } }
    default:
      return state
  }
}

export const getCategoriesByDepartmentId = (state, id) => (state.departmentCategories[`${id}Categories`] ? _.values(state.departmentCategories[`${id}Categories`].ids) : null)
export const isFetchingCategoriesByDepartment = (state, id) => {
  if (!state.departmentCategories[`${id}Categories`]) return true
  return state.departmentCategories[`${id}Categories`] && _.includes([undefined, null, actions.CATEGORIES_REQUEST_START], state.departmentCategories[`${id}Categories`].request)
}

export default combineReducers({
  departmentCategories
})
