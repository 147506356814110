import React from 'react'
import { numberOne, numberTwo, numberThree, numberFour, numberFive, numberSix, numberSeven, numberEight, numberNine, numberTen } from '../Icons/index'

const displayRankNumberImg = (rank) => {
  switch (rank) {
    case 1:
      return numberOne
    case 2:
      return numberTwo
    case 3:
      return numberThree
    case 4:
      return numberFour
    case 5:
      return numberFive
    case 6:
      return numberSix
    case 7:
      return numberSeven
    case 8:
      return numberEight
    case 9:
      return numberNine
    case 10:
      return numberTen
    default:
      return null
  }
}

export default function RankLabel ({ rank }) {
  if (Number.isNaN(rank) || rank > 50) return null
  return <div className='deal-rank'><img src={displayRankNumberImg(rank)} alt='icon' /></div>
}