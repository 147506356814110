import React from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { gon } from "../../../constant"
import {Link} from 'react-router-dom'
import { handleNativeRedirectWebView } from '../../apps/App/utilis/NativeDataShare'

export default function ViewSaleButton({
 className, text, url, handleClick, type, codeToCopy, info
}) {
  const target = "_blank"
  const handleGoTO = (event) => {
    event.preventDefault()
    handleClick()
    if (window.ReactNativeWebView) {
      handleNativeRedirectWebView(`{"type":"goto", "path":"${window.location.href}", "state": ${JSON.stringify({ ...info, type, url })}}`)
    } else {
      window.localStorage.setItem("goto_info", JSON.stringify({ ...info, type, url }))
      const win = window.open(`/goto/${type}`, "_blank")
      win.focus()
    }
  }
  const linkElement = (type?.toLowerCase() === "coupon" || type?.toLowerCase() === "uniquecoupon")
    ? (
      <CopyToClipboard text={codeToCopy}>
        <Link
          className={className}
          rel='noreferrer'
          to={`/goto/${type}`}
          onClick={(event) => handleGoTO(event)}
        >
          {text}
        </Link>
      </CopyToClipboard>
      )
    : (
      <Link
        className={className}
        rel='noreferrer'
        to={`/goto/${type}`}
        onClick={(event) => handleGoTO(event)}
      >
        {text}
      </Link>
      )
  return linkElement
}
