import React from "react"

export default function IconBox({
 title, icon, imageClassName, hoverIcon, hoverState
}) {
    if (hoverState) {
        return (
          <React.Fragment>
            <div className="nav-item-image">
              <img src={hoverIcon} className={imageClassName} />
            </div>
            <div className="nav-item-text">
              {title}
            </div>
          </React.Fragment>
        )
    }

        return (
          <React.Fragment>
            <div className="nav-item-image">
              <img src={icon} className={imageClassName} />
            </div>
            <div className="nav-item-text">
              {title}
            </div>
          </React.Fragment>
        )
}