import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import moment from 'moment'
import { useInView } from 'react-intersection-observer'

import {
  RankLabel,
  FooterListingCounts
} from '../../../components/Labels'

import {
  priceDecrease15,
  priceDecrease16to30,
  priceDecrease31plus,
  priceIncrease15,
  priceIncrease16to30,
  priceIncrease31plus,
  megaPhone
} from '../../../components/Icons'

import SimilarDealCard from './SimilarDealCard'
import changePriceDropLabel from '../../utils/changePriceDropLabel'
import positionDataLayer from '../../dataLayers/positionDataLayer'
import { USE_IN_VIEW_OPTIONS } from '../../../../constant'

const numberFormatter = new Intl.NumberFormat('en-US')

function dollerCents (price) {
  const cents = price?.toString()?.split('.')?.[1] || 0
  return cents > 0 ? cents?.length === 1 ? cents * 10 : cents : ''
}

function discountText (discount) {
  // api returns discount as +ve so multiplied by -100 to
  // keep logic same as per ElasticListingCard/DealCard
  return (discount * 100).toFixed()
}

const formattedPercent = new Intl.NumberFormat('en-US', { signDisplay: 'exceptZero' })

export default function DealCard ({
  wrapperClass,
  currentSection,
  sectionPosition,
  position,
  item,
  isSideBarItem,
  showRank,
  sectionId,
  isPersonalised,
  isSponsored
}) {
  const {
    type, id, attributes,
    attributes: {
      name,
      commentsCount, impressionsCount,
      priceShift, price_shift, pricePrev,
      price, upvotesCount, lastPriceDate
    },
    links,
    relationships: {
      brand
    }
  } = item

  const [refDealCard, inView] = useInView(USE_IN_VIEW_OPTIONS)
  useEffect(() => {
    if (inView) {
      positionDataLayer(item, 'product-impression', 'product-link', position, currentSection, sectionPosition, isPersonalised)
    }
  }, [inView])

  if (isSideBarItem) {
    return (
      <SimilarDealCard deal={item} />
    )
  }

  const newDate = new Date(0)
  const formatedLastPriceDate = /[a-zA-Z]/g.test(lastPriceDate) ? new Date(lastPriceDate) : newDate.setUTCSeconds(parseInt(lastPriceDate.substring(0, 10), 10))
  const discountInPercentage = discountText(price_shift || priceShift)

  const minDiscount = Math.abs(parseInt(discountInPercentage, 10))
  const showDiscount = moment().diff(moment(lastPriceDate), 'days') <= 28 ? /\/top-price-drop/i.test(window.location.pathname) ? minDiscount >= 5 : minDiscount >= 1 : false

  return (
    <div className={`listing-card ${wrapperClass}`} ref={refDealCard}>
      {position && showRank && (
        <RankLabel rank={position} />
      )}
      <div className='card card-price-drop'>
        <Link
          className='link-wrapper testing-this'
          to={links.dealUrl}
          onClick={() => {
            positionDataLayer(item, 'card-click', 'product-link', position, currentSection, sectionPosition, isPersonalised)
            sectionId && localStorage.setItem('hsref', sectionId)
          }}
        >
          <div className='card-img-top deal'>
            {brand?.links?.logoUrl && (
              <div className='brand-logo'>
                <LazyLoadImage
                  className='img-fluid'
                  alt={brand.attributes.name}
                  src={brand.links.logoUrl}
                />
              </div>
            )}
            <LazyLoadImage
              className='img-fluid'
              alt={name}
              src={links.imageUrl}
            />
          </div>
          <div className='card-body deal'>
            <div className='deal-name'>
              <p className='card-text mb-0 testing-this'>
                {name?.length <= 80 ? name : name?.substring(0, 80) + '...'}
              </p>
              <FooterListingCounts {...{
                type,
                id,
                name,
                upvotesCount,
                commentsCount,
                impressionsCount,
                wrapperClass: 'list-inline mb-0 d-flex justify-content-around'
              }}
              />
            </div>
            <div className='card-title'>
              <div className='d-flex justify-content-between align-items-center h-100'>
                <div className='col-6 price-details'>
                  <h4 className='d-flex price-now'>
                    <span className='doller-symbol'>$</span>{`${numberFormatter.format(Math.floor(price))}`}<span className='cents'>{`${dollerCents(price)}`}</span>
                  </h4>
                  {showDiscount && (
                    <div className='d-flex price-was align-items-center text-nowrap'>
                      <span className='mr-1'>Was </span>
                      <span>
                        {`${pricePrev?.split('.')?.[0]}${pricePrev?.split('.')?.[1] > 0 ? '.' + pricePrev?.split('.')?.[1] : ''}`}
                      </span>
                      <span className='last-price-date'>
                        {
                          (!formatedLastPriceDate || ((new Date()) - formatedLastPriceDate)) < 30000
                            ? <div>Just Now</div>
                            : <div>{changePriceDropLabel(moment(formatedLastPriceDate).fromNow())}</div>
                        }
                      </span>
                    </div>
                  )}
                </div>
                {showDiscount && (
                  <div className={`col-6 deal-price-drop-label ${discountInPercentage > 0 && 'deal-price-up-label'}`}>
                    <div className='d-flex align-items-center justify-content-around h-100 px-3'>
                      {discountInPercentage < 0 ? <img src={discountInPercentage < -30 ? priceDecrease31plus : discountInPercentage < -15 ? priceDecrease16to30 : priceDecrease15} /> : null}
                      {discountInPercentage > 0 ? <img src={discountInPercentage > 30 ? priceIncrease31plus : discountInPercentage > 15 ? priceIncrease16to30 : priceIncrease15} /> : null}
                      <div className='pricedrop'>
                        {Math.abs(discountInPercentage) > 500 ? discountInPercentage < 500 ? '-500%+' : '500%+' : formattedPercent.format(discountInPercentage) + '%'}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer'>
            <div className={`card-meta-info ${isSponsored && 'justify-content-between'}`}>
              {
                attributes?.stores?.length > 1
                  ? <span style={{ maxWidth: isSponsored && '122px', lineHeight: isSponsored && '16px' }}>Compare prices from <b>{attributes?.stores?.length} stores</b></span>
                  : <span>From <b>{attributes?.stores?.[0]?.name || attributes?.store_name}</b></span>
              }
               {isSponsored &&
                <span className='sponsored-badge'>
                  <img src={megaPhone} width='16px' height='16px' />
                  Sponsored
                </span>}
              
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}
