/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"
import _ from "lodash"

export const getUserByIdentifier = (baseUrl, identifier) => {
  const path = `${baseUrl}/users/${identifier}`

  return axios.get(path, {})
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const getUserBySlug = (baseUrl, identifier) => {
  const path = `${baseUrl}/users/spotter`

  return axios.post(path, { identifier })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}


export const getUsers = (baseUrl, data = {}) => {
  const path = `${baseUrl}/users`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({
      response: {
        data: normalize(data),
        ids: _.get(data, "meta.order", [])
      },
      status
    }))
    .catch(error => { error })
}

export const updateUser = (baseUrl, data) => {
  const path = `${baseUrl}/users/update`

  return axios.patch(path, data)
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const getAffinityData = (baseUrl, data) => {
  const path = `${baseUrl}/search_elastic/affinities`

  return axios.post(path, data)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}
