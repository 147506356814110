import { combineReducers } from "redux"

import * as actionTypes from "../../actions/types"

const newCalendar = (state = false, action) => {
  if (action?.data?.data?.follow_type === "calendar") {
    switch (action.type) {
      case actionTypes.FOLLOWING_SUCCESS:
        return !action.data.data.followed
      case actionTypes.FOLLOWING_NOTIFY_END:
        return false
      default:
        return state
    }
  }
  return false
}

const newFollowing = (state = false, action) => {
  const isCallbackForCalendarRemoval = action?.data?.data?.type === "saleEvent"
  if (action?.data?.data?.follow_type === undefined) {
    switch (action.type) {
      case actionTypes.FOLLOWING_SUCCESS:
        return isCallbackForCalendarRemoval ? false : !action.data.data.followed
      case actionTypes.FOLLOWING_NOTIFY_END:
        return false
      case actionTypes.CALENDAR_REMOVE_SALE_EVENT:
        return false
      default:
        return state
    }
  }
  return false
}

export default combineReducers({
  newFollowing,
  newCalendar
})
