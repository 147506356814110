import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import * as actions from "../../../actions/types"

const tagsFetchSuccess = (state, { data }) => ({ ...state, ...data.tag })

const byId = createReducer({})({
  [actions.RESOURCE_FETCHED_SUCCESS]: tagsFetchSuccess,
  [actions.LOCATION_CHANGED]: () => ({})
})

export const getTagById = (state, tagId) => state.byId[tagId]
export const getTagIds = state => Object.keys(state.byId)

export default combineReducers({
  byId
})
