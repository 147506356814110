import {
  call, put, select, takeEvery
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* loadComments({ data }) {
  yield put(actions.commentsRequestStart('Electronics'))
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getComments, baseUrl, data)
  yield (response) ? put(actions.commentsRequestSuccess(response)) : put(actions.commentsRequestFailure(error))
}

function* watchCommentsListSagas() {
  yield takeEvery(actionTypes.LOAD_COMMENTS, loadComments)
}

export {
  watchCommentsListSagas
}