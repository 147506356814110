import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import {
  RESOURCE_FETCHED_SUCCESS, DEAL_REQUEST_FAILURE, DEAL_REQUEST_START, DEAL_REQUEST_SUCCESS
} from "../../../actions/types"

const dealsFetchSuccess = (state, { data }) => ({ ...state, ...data.deal })

// Top Deals
const byId = createReducer({})({
  [RESOURCE_FETCHED_SUCCESS]: dealsFetchSuccess
})
const getDealStatusCode = (state = {}, action) => {
  switch (action.type) {
    case DEAL_REQUEST_START:
      return {}
    case DEAL_REQUEST_SUCCESS:
      return { ...action.data }
    case DEAL_REQUEST_FAILURE:
      return { ...action.error }
    default:
      return state
  }
}

export const getDealById = (state, dealId) => state.byId[dealId]
export const getDealsByIds = (state, dealIds) => _.filter(state.byId, dealIds)
export const getDealBySlug = (state, slug) => _.find(state.byId, { attributes: { slug } })

export default combineReducers({
  byId,
  getDealStatusCode
})
