import React from "react"
import { useDispatch } from "react-redux"
import StoreField from "./StoreField"
import AccountField from "./AccountField"
import AboutField from "./AboutField"
import BrandField from "./BrandField"
import DepartmentField from "./DepartmentField"
import CategoryField from "./CategoryField"
import { hideUserSlider } from "../../../actions"

export default function MobileNavBarContentBox({ activeField, user, handleBackButton }) {
    const dispatch = useDispatch()
    switch (activeField) {
        case "account":
            return (
              <AccountField user={user}></AccountField>
            )
        case "brand":
            return (
              <BrandField user={user}></BrandField>
            )
        case "categories":
            return (
              <DepartmentField hideSidebar={() => { dispatch(hideUserSlider()) }} handleBackButton={handleBackButton} />
            )
        case "store":
            return (
              <StoreField user={user}></StoreField>
            )
        case "about":
            return (
              <AboutField user={user}></AboutField>
            )
        case "category":
            return (
              <CategoryField user={user}></CategoryField>
            )
        default:
            return (
              <AccountField user={user}></AccountField>
            )
    }
}
