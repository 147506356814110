import React, { Component } from 'react'
import { connect } from 'react-redux'
import Loader from '../../../../../components/Loader'

import * as reducers from '../../../reducers'

class EmailForm extends Component {
  render () {
    const {
      currentUser,
      isUserLoading
    } = this.props

    return (
      <section>
        {!isUserLoading.loading && currentUser && currentUser.type
          ? <>
            <h4>Your email address</h4>
            <p className="mb-0">{currentUser.attributes?.email}</p>
          </>
          : <div className='col-4 my-4'><Loader /></div>}
      </section>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: reducers.getCurrentUser(state),
  isUserLoading: reducers.isUserLoading(state)

})

export default connect(mapStateToProps, {})(EmailForm)
