import * as types from "./types"

export const calendarRemoveSaleEvent = id => ({
  type: types.CALENDAR_REMOVE_SALE_EVENT,
  id
})

export const calendarUpdateActiveList = data => ({
  type: types.CALENDAR_UPDATE_ACTIVE_LIST,
  data
})

export const calendarUpdateUpcomingList = data => ({
  type: types.CALENDAR_UPDATE_UPCOMING_LIST,
  data
})

export const calendarSaleEventActiveRequestStart = data => ({
  type: types.CALENDAR_SALE_EVENT_ACTIVE_REQUEST_START,
  data
})

export const calendarSaleEventActiveRequestSuccess = data => ({
  type: types.CALENDAR_SALE_EVENT_ACTIVE_REQUEST_SUCCESS,
  data
})

export const calendarSaleEventActiveRequestFailure = error => ({
  type: types.CALENDAR_SALE_EVENT_ACTIVE_REQUEST_FAILURE,
  error
})

export const calendarSaleEventUpcomingRequestStart = data => ({
  type: types.CALENDAR_SALE_EVENT_UPCOMING_REQUEST_START,
  data
})

export const calendarSaleEventUpcomingRequestSuccess = data => ({
  type: types.CALENDAR_SALE_EVENT_UPCOMING_REQUEST_SUCCESS,
  data
})

export const calendarSaleEventUpcomingRequestFailure = error => ({
  type: types.CALENDAR_SALE_EVENT_UPCOMING_REQUEST_FAILURE,
  error
})