import React from "react"

export default function ShareButtonMobile({ content }) {
  const supportNativeShare = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && window.location.protocol === "https:")
  if (supportNativeShare) {
    const shareData = {
      title: document.title,
      url: window.location.href
    }
    return (
      <button
        type="button"
        className="btn rounded-pill btn-outline-dark ml-2 d-md-none"
        onClick={() => navigator.share(shareData)}>
        {content}
      </button>
    )
  }
  return null
}
