/* eslint-disable no-unused-expressions */
import axios from 'axios'
import { gon } from '../../../../constant'

export const getCustomWidget = (baseUrl, data = {}) => {
  const path = `${baseUrl || gon.urls.api}/custom_widgets`

  return axios.get(path, { params: data.data })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}
