import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

export function ListingImageStoreLogo({ store }) {
  const storeName = _.get(store, "attributes.name")
  const storeLogoUrl = _.get(store, "links.logoUrl")
  if (storeLogoUrl) {
    return (
      <LazyLoadImage
        className="img-fluid store-img"
        alt={storeName}
        src={storeLogoUrl} />
    )
  }
  return null
}

export function ListingTitleStoreName({ store }) {
  const storeName = _.get(store, "attributes.name")
  if (storeName) return storeName
  return "-"
}