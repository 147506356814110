/* eslint-disable no-unused-expressions */
import axios from 'axios'

const createParams = () => {
  const affinitiesData = JSON.parse(sessionStorage.getItem('user_affinities'))
  const params = { affinity: affinitiesData[0]?.affinity, gender: affinitiesData[0]?.gender }
  return params
}

export const getYourTodaysTopPicks = (baseUrl, data = {} ) => {
  const path = `${baseUrl}/search_elastic/user_affinity_search`

  return axios.post(path, createParams())
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}


export const getDepartmentTopPicks = (baseUrl, data = {} ) => {
  const path = `${baseUrl}/search_elastic/user_affinity_search`

  return axios.post(path, data)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getCategoryTopPicks = (baseUrl, data = {}) => {
  const path = `${baseUrl}/search_elastic/user_affinity_search`

  return axios.post(path, data)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}
