import React from "react"
import { useDispatch } from "react-redux"

import {
  emailIcon,
  socialFacebook,
  socialTwitter
} from "../../../../components/Icons"
import MobileShareButton from "../../../../components/Buttons/MobileShareButton"
import { FacebookShareButton } from "react-share"
import { calendarRemoveSaleEvent } from "../../actions"

export default function ShareButtons({ name, url, eventId }) {
  const dispatch = useDispatch()

  function popUpWindow(link) {
    window.open(link, "popup", "width=600,height=600")
    return false
  }
  const windowHref = `${window.location.host}${url}` || window.location.href
  const facebookShareLink = encodeURI(windowHref)
  const twitterShareLink = encodeURI(`https://twitter.com/share?text=Check out the latest sale I found on Little Birdie.&hashtags=LittleBirdie&url=${windowHref}`)
  const emailShareLink = encodeURI(`mailto:?subject=Check out the latest sale I found on Little Birdie&body=I just found an awesome deal on ${name}. Check it out at ${windowHref}.`)

  return (
    <div className="share">
      <div className="d-flex align-items-center">
        <MobileShareButton content={<div className="mr-3 text-muted">Share</div>} />
        <a className="link-wrapper" href={emailShareLink}><img className="mr-3" src={emailIcon} /></a>
        <FacebookShareButton
          url={facebookShareLink}
          quote="Check out the latest sale I found on Little Birdie"
          className="link-wrapper">
          <img src={socialFacebook} />
        </FacebookShareButton>
        <button
          type="button"
          className="btn btn-wrapper"
          onClick={() => popUpWindow(twitterShareLink)}>
          <img className="mr-3 ml-3" src={socialTwitter} />
        </button>
        {eventId && <button type="button" onClick={() => { dispatch(calendarRemoveSaleEvent(eventId)) }} className="btn btn-wrapper mr-3 text-muted">Remove</button>}
      </div>
    </div>
  )
}
