import React, { Component } from "react"
import { connect } from "react-redux"

import SidebarList from "../../../../components/SidebarList"
import ListingCard from "../ListingCard"

import * as reducers from "../../reducers"
import MobileSidebarList from "../../../../components/MobileSideBarList"
import { setCarouselLoading, setCarouselLoaded } from '../../actions/carousel'

class TopStoresSidebarList extends Component {
  constructor(props) {
    super(props)
    props.setCarouselLoading({ carousel: props.sectionId })
  }

  componentDidMount(){
    this.props.setCarouselLoaded({ carousel: this.props.sectionId })
  }

  topListComponents = isMobileNavBar => {
    const { topStoreIds, showAll, currentSection, sectionPosition, sectionId } = this.props
    const itemType = "store"
    if (topStoreIds === undefined) { return ([]) }
    if (showAll) {
      return _.map(_.take(topStoreIds, topStoreIds.length), (itemId, index) => <ListingCard sectionId={sectionId} type={itemType} id={itemId} wrapperClass="" key={`${itemType}-${itemId}`} isMobileNavBar={isMobileNavBar} position={ index+1 } currentSection={currentSection} sectionPosition={sectionPosition} />)
    }
    return _.map(_.take(topStoreIds, 30), (itemId, index) => <ListingCard sectionId={sectionId} type={itemType} id={itemId} wrapperClass="" key={`${itemType}-${itemId}`} isMobileNavBar={isMobileNavBar} position={ index+1 } currentSection={currentSection} sectionPosition={sectionPosition} />)
  }

  render() {
    const {
      isFetchingTopStores,
      title,
      description,
      isMobileNavBar,
      department,
      category,
      subcategory,
      showAll,
      viewAllUrl,
      currentSection,
      sectionPosition,
      sectionId
    } = this.props

    let titleName
    let descriptionText

    if (subcategory) {
      titleName = `${subcategory.attributes.name} Stores`
    } else if (category) {
      titleName = 'Stores'
    } else if (department) {
      titleName = `${department.attributes.name} Stores`
      descriptionText = 'View the best offers from your favourite stores'
    } else {
      titleName = `Shop by store`
      descriptionText = 'View the best offers from your favourite stores'
    }

    if (isMobileNavBar) {
      return (
        <MobileSidebarList
          title={title || "Top Stores"}
          description={description || descriptionText}
          type="Stores"
          viewAllUrl="/trending-stores"
          currentSection={currentSection}
          sectionPosition={sectionPosition}
          isLoading={isFetchingTopStores}
          sectionId={sectionId}
          >
          { this.topListComponents(isMobileNavBar) }
        </MobileSidebarList>
      )
    }
    return (
      <SidebarList
        title={title || titleName}
        description={description || descriptionText}
        viewAllUrl={viewAllUrl || "/trending-stores"}
        isLoading={isFetchingTopStores}
        currentSection={currentSection}
        sectionPosition={sectionPosition}
        homepageBrandStoreClass='homepageBrandStore'
        showAll={showAll}
        sectionId={sectionId}
        >
        { this.topListComponents() }
      </SidebarList>
    )
  }
}

const mapStateToProps = state => ({
  topStoreIds: reducers.getTopStoreIds(state),
  isFetchingTopStores: reducers.isFetchingTopStores(state),
})

export default connect(mapStateToProps, { setCarouselLoading, setCarouselLoaded })(TopStoresSidebarList)
