import * as types from "./types"

export const triggerFollowing = data => ({
  type: types.FOLLOWING_TRIGGERED,
  data
})

export const followingStart = data => ({
  type: types.FOLLOWING_START,
  data
})

export const followingSuccess = data => ({
  type: types.FOLLOWING_SUCCESS,
  data
})

export const followingNotifyEnd = data => ({
  type: types.FOLLOWING_NOTIFY_END,
  data
})

export const followingFailure = data => ({
  type: types.FOLLOWING_FAILURE,
  data
})

export const followedDealsRequestStart = data => ({
  type: types.FOLLOWED_DEALS_REQUEST_START,
  data
})

export const followedDealsRequestSuccess = data => ({
  type: types.FOLLOWED_DEALS_REQUEST_SUCCESS,
  data
})

export const followedDealsRequestFailure = error => ({
  type: types.FOLLOWED_DEALS_REQUEST_FAILURE,
  error
})

export const followedCouponsRequestStart = data => ({
  type: types.FOLLOWED_COUPONS_REQUEST_START,
  data
})

export const followedCouponsRequestSuccess = data => ({
  type: types.FOLLOWED_COUPONS_REQUEST_SUCCESS,
  data
})

export const followedCouponsRequestFailure = error => ({
  type: types.FOLLOWED_COUPONS_REQUEST_FAILURE,
  error
})

export const followedSaleEventsRequestStart = data => ({
  type: types.FOLLOWED_SALE_EVENTS_REQUEST_START,
  data
})

export const followedSaleEventsRequestSuccess = data => ({
  type: types.FOLLOWED_SALE_EVENTS_REQUEST_SUCCESS,
  data
})

export const followedSaleEventsRequestFailure = error => ({
  type: types.FOLLOWED_SALE_EVENTS_REQUEST_FAILURE,
  error
})
