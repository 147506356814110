import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import { usedStart, usedSuccess, usedFailure } from "../../actions"
import { setUsedCode } from "../../requesters"
import { getBaseUrl } from "../../reducers"

function* triggerUsed({ data }) {
  yield put(usedStart(data))
  const baseUrl = yield select(getBaseUrl)

  const { response, error } = yield call(setUsedCode, baseUrl, data)
  if (response) {
    yield put(usedSuccess({ ...response, data }))
  } else {
    yield put(usedFailure(error))
  }
}

function* fetchData(data) {
  yield all([
    fork(triggerUsed, data)
  ])
}

function* watchTriggerUsedSaga() {
  yield takeLatest(actionTypes.USED_TRIGGERED, fetchData)
}

export {
  watchTriggerUsedSaga
}