import React from 'react'
import PublicProfileSettings from './PublicProfileSettings'
import Loader from '../../../../components/Loader'

export default function PublicProfileLoader ({ about, aboutChange, currentUser }) {

  return (
    currentUser?.type
      ? <PublicProfileSettings
          aboutChange={aboutChange}
          about={about}
          currentUser={currentUser}
        />
      : <section>
        <h3>My Public Profile</h3>
        <div className='card shadow-sm border-0 mt-3'>
          <div className='card-body px-md-5'>
            <Loader />
          </div>
        </div>
      </section>
  )
}
