import * as types from "./types"

export const triggerVoting = data => ({
  type: types.VOTING_TRIGGERED,
  data
})

export const votingStart = data => ({
  type: types.VOTING_START,
  data
})

export const votingSuccess = data => ({
  type: types.VOTING_SUCCESS,
  data
})

export const votingFailure = data => ({
  type: types.VOTING_FAILURE,
  data
})
