/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getComments = (baseUrl, { type, id, params }) => {
  const path = `${baseUrl}/commenting/${type}/${id}`

  return axios.get(path, { params })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => ({ error }))
}

export const postComment = (baseUrl, { type, id, params }) => {
  const path = `${baseUrl}/commenting/${type}/${id}`

  return axios.post(path, { message: params.message, association: params.association })
    .then(({ data, status }) => ({ response: normalize(data), metadata: data.meta, status, error: data.errors }))
    .catch(error => { error })
}
