import React, { Component } from "react"

import {
  CurrentSaleCard,
  UpcomingSaleCard
} from "./SaleEventMiniCard"
import SaleEventNormalCard from "./SaleEventNormalCard"

const ListingCardStyles = {
  normal: SaleEventNormalCard,
  current: CurrentSaleCard,
  upcoming: UpcomingSaleCard
}

class SaleEventCard extends Component {
  rankLabel = () => {
    const {
      position,
      item: {
        attributes
      }
    } = this.props

    const rank = position || attributes.rank

    if (rank > 50) { return (null) }
    return (<div className="deal-rank">{rank}</div>)
  }

  render() {
    const {
      wrapperClass,
      item,
      position,
      currentSection,
      sectionPosition,
      showRank,
      cardStyle,
      userSignedIn,
      sectionId,
      isPersonalised,
      isSponsored
    } = this.props
    
    const style = cardStyle === "center" ? "normal" : cardStyle || "normal"
    const SaleEventCardComponent = ListingCardStyles[style]

    if (typeof ListingCardStyles[style] !== "undefined" && style !== undefined) {
      return (
        <SaleEventCardComponent
          item={item}
          position={position}
          showRank={showRank}
          wrapperClass={wrapperClass}
          userSignedIn={userSignedIn}
          currentSection={currentSection}
          sectionPosition={sectionPosition}
          sectionId={sectionId}
          isPersonalised={isPersonalised}
          isSponsored={isSponsored}
        />
      )
    }
    return (null)
  }
}

export default SaleEventCard
