import * as types from "./types"

export const setCarouselLoading = data => ({
    type: types.SET_CAROUSEL_LOADING,
    data
})

export const setCarouselLoaded = data => ({
    type: types.SET_CAROUSEL_LOADED,
    data
})

export const resetCarouselLoaded = data => ({
    type: types.RESET_CAROUSEL_LOADED,
    data
})