import React, { useRef } from 'react'
import useScrollToSection from '../../apps/App/components/BiInfiniteScroll/useScrollToSection';

export default function PageSection ({
  title, description, children, sectionClass, extraClass, viewAll, isHomePage, homepageBrandStoreClass, sectionId
}) {

  const sectionRef = useRef(null)
  useScrollToSection({ ref: sectionRef, eleId: 'hsref' })

  return (
    isHomePage
      ? (
        <>
          <div id={sectionId} ref={sectionRef} className='container'>
            <div className='listing-group-header'>
              <div className='d-flex align-items-start justify-content-between'>
                <div>
                  <h2>{title}</h2>
                  {
                    description === undefined || description.length > 0
                      ? <p className='subtitle'>{description}</p>
                      : null
                  }
                </div>
                <div className='d-none d-md-block'>{viewAll}</div>
              </div>
            </div>
          </div>
          {children}
          <div className='d-xs-block d-md-none d-flex justify-content-center mb-5'>{viewAll}</div>
        </>
        )
      : (
        <section id={sectionId} ref={sectionRef} className={extraClass ? `${sectionClass} ${extraClass}` : (sectionClass || `section-group ${homepageBrandStoreClass && homepageBrandStoreClass}`)}>
          <div className='container'>
            <div className='listing-group-header'>
              <div className='d-flex align-items-start justify-content-between'>
                <div>
                  <h2>{title}</h2>
                  {
                    description === undefined || description.length > 0
                      ? <p className='subtitle'>{description}</p>
                      : null
                  }
                </div>
                <div className='d-none d-md-block'>{viewAll}</div>
              </div>
            </div>
          </div>
          {children}
          <div className='d-xs-block d-md-none d-flex justify-content-center mb-5'>{viewAll}</div>
        </section>
        )
  )
}
