/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const claimUniqueCouponByIdentifier = (baseUrl, identifier) => {
  const path = `${baseUrl}/unique_coupons/${identifier}`

  return axios.get(path, {})
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const getUniqueCodeByUser = (baseUrl, identifier) => {
  const path = `${baseUrl}/unique_coupons/${identifier}/unique_codes`

  return axios.get(path, {})
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const setUsedCode = (baseUrl, identifier) => {
  const path = `${baseUrl}/unique_codes/${identifier}/use`

  return axios.patch(path, {})
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}
