import React, { Component } from "react"
import { connect } from "react-redux"
import moment from 'moment'
import {
  // DealCard,
  DealHunterCard,
  CouponCard,
  StoreCard,
  BrandCard,
  SaleEventCard,
  CollectionCard
} from "../../../../components/ListingCards"

import { Link } from "react-router-dom"
import { getResource, getCurrentUser } from "../../reducers"
import humanizeOffer from "../../../../components/utils/humanizeOffer"

{/* ToDo: Remove Deal: Remove Deal Card Render */}
const ListingCardComponents = {
  // deal: DealCard,
  user: DealHunterCard,
  coupon: CouponCard,
  uniqueCoupon: CouponCard,
  store: StoreCard,
  brand: BrandCard,
  saleEvent: SaleEventCard,
  newsletter: CollectionCard,
  customList: CollectionCard
}

class ListingCard extends Component {
  itemUrl = (type, item) => {
    switch (type) {
      case "brand":
        return item.links.brandUrl
      case "store":
        return item.links.storeUrl
      default:
        return `/shop/${_.get(item, "attributes.slug")}`
    }
  }

  formattedPrice = (price) => {
    if (price) {
      return (price).toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
      })
    }
    return null
  }

  discountText = (discount) => {
    return (discount * -100).toFixed()
  }

  getSaledurationPeriod = (saleStart, saleEnd) => {
    return `Started ${moment(saleStart).format('DD/MM/YYYY')} - Ends ${moment(saleEnd).format('DD/MM/YYYY')}`
  }


  getListItem = (listItem, type) => {
    {/* ToDo: Remove Deal: Remove Deal Card Render */}
    // if(type === 'deal'){
    //   return {id: listItem.id, type, attributes: {...listItem, formattedPrice: this.formattedPrice(listItem.price), commentsCount: listItem.comments_count, impressionsCount: listItem.impressionsCount,
    //     pricePrev: this.formattedPrice(listItem.price_prev), upvotesCount: listItem.upvotes_count, lastPriceDate: listItem.last_price_date, showDiscount: listItem.price < listItem.price_prev, discount: Math.abs(listItem.price_shift)},
    //     links: {dealUrl: '/shop/deal/'+listItem.slug, imageUrl: listItem.image_url},
    //     relationships: {brand: {attributes: {name: listItem.brand_name}, links: {logoUrl: listItem.brand_logo}}}}
    // }
    if (type === 'saleEvent') {
      return {id: listItem.id, type, url: listItem.url,  attributes: {...listItem, offerText: listItem.offer_text, formattedPrice: this.formattedPrice(listItem.price), commentsCount: listItem.comments_count, impressionsCount: listItem.impressionsCount,
        durationPeriod: this.getSaledurationPeriod(listItem.start_at, listItem.expire_at),
        expireAt: listItem.expire_at, startAt: listItem.start_at,
        pricePrev: this.formattedPrice(listItem.price_prev), upvotesCount: listItem.upvotes_count, lastPriceDate: listItem.last_price_date, showDiscount: listItem.price < listItem.price_prev, discount: Math.abs(listItem.price_shift),
        storeName: listItem.store_name},
        links: {saleEventUrl: `/shop/sale-event/${listItem.slug}`, imageUrl: listItem.sale_event_image},
        relationships: {brand: {attributes: {name: listItem.brand_name}, links: {logoUrl: listItem.brand_logo}}}}
    }
    if(type === 'coupon'){
      return {id: listItem.id, type, attributes: {...listItem, formattedPrice: this.formattedPrice(listItem.price), commentsCount: listItem.comments_count, impressionsCount: listItem.impressionsCount, offer:humanizeOffer(listItem),
        pricePrev: this.formattedPrice(listItem.price_prev), upvotesCount: listItem.upvotes_count, lastPriceDate: listItem.last_price_date, showDiscount: listItem.price < listItem.price_prev, discount: Math.abs(listItem.price_shift), expireAt:listItem.expire_at},
        links: {couponUrl: '/shop/voucher/'+listItem.slug, imageUrl: listItem.coupon_image, largeImage: listItem.large_image},
        relationships: {store: {attributes: {name: listItem.store_name}, links: {logoUrl: listItem.store_logo}}}}
    }
  }

  render() {
    const {
      wrapperClass,
      currentSection,
      sectionPosition,
      type,
      item,
      position,
      showRank,
      isMobileNavBar,
      cardStyle,
      isRankingCard,
      isSideBarItem,
      currentUser,
      isYourTopList,
      listItem,
      sectionId,
      isPersonalised,
      sponsoredFlag
    } = this.props

    const ListingCardComponent = ListingCardComponents[type]

    if (isMobileNavBar) {
      return (
        <div className="">
          <Link to={this.itemUrl(type, item)}>
            <img className="img-fluid" src={item.links.logoUrl} />
            <div className="text-truncate">{_.get(item, "attributes.name")}</div>
          </Link>
        </div>
      )
    }

    if (typeof ListingCardComponents[type] !== "undefined" && (item !== undefined || listItem !== undefined)) {
      return (
        <ListingCardComponent
          item={isYourTopList ? this.getListItem(listItem, type) : item}
          position={position}
          showRank={showRank}
          wrapperClass={wrapperClass}
          currentSection={currentSection}
          sectionPosition={sectionPosition}
          cardStyle={cardStyle}
          isSideBarItem={isSideBarItem}
          isRankingCard={isRankingCard}
          sectionId={sectionId}
          userSignedIn={currentUser !== undefined}
          isSponsored={sponsoredFlag}
          isPersonalised={isPersonalised} />
      )
    }
    return (null)
  }
}

const mapStateToProps = (state, { type, id }) => ({
  item: getResource(state, { type, id }),
  currentUser: getCurrentUser(state)
})

export default connect(mapStateToProps, {})(ListingCard)
