import React, {useState} from "react";
import {Link} from "react-router-dom";
import {
    birdFlaying,
    extUpArrow,
    infoFilled,
    permissionHeader, 
    playCircleWhite,
    postInstall2
} from "../../../../components/Icons";

const PageBadge = ({total, current}) => {
    return(
        <div className='page-badge'>
            <span className='badge-text'>{current}/{total}</span>
        </div>
    )
}


const PermissionWithBar = () => {

    return(
        <>
            <div className={'be-view enable'} style={{marginTop: '-56px'}}>
                <div style={{position: 'relative'}}>
                    <img src={birdFlaying} alt='bird-img' className='bird-flaying' />
                    <img src={extUpArrow} alt='uparrow' className='up-arrow' />
                    <img src={permissionHeader} alt="header" style={{width:'100%'}} />
                    <span className='title header' style={{top: '-15px'}}>
                        <span className={'sub-text'}> Always Allow </span>
                        <div>on every website</div>
                        <div className='sub-header'>To compare prices while you shop, you need to grant permission to Little Birdie</div>
                    </span>

                </div>

                <PageBadge total={2} current={2} />
                <div className={'enable-extension'}>
                    <div>
                        <ul className={'steps'}>    
                            <li className={'step'}>
                                <span className='step-sub-title'>
                                    3.{' '}
                                </span>
                                Follow birdie to the top right corner and tap "review"
                                <div>
                                    <Link className='sub-text' style={{textDecoration: 'underline'}} to={'/mobile/ext/permissions-details'}>Wait, I don’t see a review button</Link>
                                </div>
                            </li>

                            <li className={'step'}>
                                <span className='step-sub-title'>
                                    4.{' '}
                                </span>
                                Select <span className='bold'> Always Allow </span>
                            </li>

                            <li className={'step'}>
                                <span className='step-sub-title'>
                                    5.{' '}
                                </span>
                                Select <span className='bold'>  Always Allow on every website </span>
                            </li>

                        </ul>
                    </div>

                    <div className='privacy-policy' style={{marginTop:'20px'}}>We do not sell your personal information. Ever. It’s all in our <Link className='privacy-link' to="/privacy">privacy policy.</Link></div>

                </div>
            </div>
        </>
    )
}

export default PermissionWithBar;
