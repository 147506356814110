import { combineReducers } from "redux"

import createReducer from "../../../../components/utils/createReducer"

import * as actions from "../../actions/types"

// Tickers
const activeTickerFetchSuccess = (state, { data }) => _.map(_.orderBy(data.ticker, "attributes.position"), item => parseInt(item.id, 10)) || state
const seoContentFetchSuccess = (state, { data }) =>  data

const ticker_ids = createReducer([])({
  [actions.ACTIVE_TICKERS_REQUEST_SUCCESS]: activeTickerFetchSuccess
})

const ticker_request = createReducer(null)({
  [actions.ACTIVE_TICKERS_REQUEST_START]: (_, { type }) => type,
  [actions.ACTIVE_TICKERS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.ACTIVE_TICKERS_REQUEST_FAILURE]: (_, { type }) => type
})

const seo_request = createReducer(null)({
  [actions.SEO_CONTENT_REQUEST_START]: (_, { type }) => type,
  [actions.SEO_CONTENT_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.SEO_CONTENT_REQUEST_FAILURE]: (_, { type }) => type
})

const seo_content = createReducer({})({
  [actions.SEO_CONTENT_REQUEST_SUCCESS]: seoContentFetchSuccess
})

export const getActiveTickerIds = state => _.values(state.tickers.ids)
export const getSeoContent = state => state.seo
export const isFetchingSeoContents = state => _.includes([undefined, null, actions.SEO_CONTENT_REQUEST_START], state.seo_request)


export default combineReducers({
  tickers: combineReducers({ ids: ticker_ids, request: ticker_request }),
  seo: seo_content,
  seo_request
})
