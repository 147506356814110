import {
  all, fork
} from "redux-saga/effects"

import { watchCommentsListSagas } from "./CommentsListSagas"
import { watchCommentSubmitSaga } from "./CommentSubmitSaga"

export default function* RootSaga() {
  yield all([
    fork(watchCommentsListSagas),
    fork(watchCommentSubmitSaga)
  ])
}