import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import { getUserActivityByIdentifier } from "../../requesters"

function* fetchUserActivityBySlug({ identifier }, reloadPage) {
  const requestPerPage = yield select(state => state.data.userActivities.itemsPerPage)
  const requestPage = reloadPage ? 1 : yield select(state => (_.get(state, `data.userActivities.bySlug.${identifier}.currentPage`, 0) + 1))
  yield put(actions.userActivityRequestStart())

  const baseUrl = yield select(getBaseUrl)
  const {
    response, error, meta, headers
  } = yield call(getUserActivityByIdentifier, {
    baseUrl, identifier, requestPage, requestPerPage
  })

  if (response && !_.isEmpty(response)) {
    const data = _.map(meta.order, id => (_.get(response, `userActivity.${id}`)))
    yield put(actions.userActivityRequestSuccess({
      identifier, data, headers
    }))
  } else {
    yield put(actions.userActivityRequestFailure(error))
  }
}

function* fetchData(data) {
  yield all([
    fork(fetchUserActivityBySlug, data)
  ])
}

function* reloadPage(data) {
  yield all([
    fork(fetchUserActivityBySlug, data, true)
  ])
}

function* watchUserActivityRequestSaga() {
  yield takeLatest(actionTypes.LOAD_PROFILE_ACTIVITY_PAGE, reloadPage)
  yield takeLatest(actionTypes.USER_ACTIVITY_REQUEST_NEXT_PAGE, fetchData)
}

export {
  watchUserActivityRequestSaga
}