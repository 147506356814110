import React, { forwardRef, useEffect, useState } from 'react'
import $ from 'jquery'
import {
  chevronRight,
  chevronLeft
} from '../../../components/Icons'

function ChevronRight (props, ref) {
  const { offset, className } = props
  const [scrollWidth, setScrollWidth] = useState()
  const [clientWidth, setClientWidth] = useState()
  const [scrollLeft, setScrollLeft] = useState(0)

  useEffect(() => {
    setScrollWidth(ref.current?.scrollWidth)
    setClientWidth(ref.current?.clientWidth)
  }, [ref.current?.scrollWidth, ref.current?.clientWidth])

  function handleRightClick(scrollOffset) {
    ref.current.scrollLeft += ((ref.current?.clientWidth * 0.5) + scrollOffset)
    setScrollLeft(ref.current.scrollLeft)
  }

  function handleLeftClick(scrollOffset) {
    ref.current.scrollLeft -= ((ref.current?.clientWidth * 0.5) + scrollOffset)
    setScrollLeft(ref.current.scrollLeft)
  }

  $(ref.current).on('scroll', () => {
    setScrollLeft(ref.current?.scrollLeft)
  })

  return (
    <>
      {
        (scrollWidth > clientWidth && (scrollWidth - scrollLeft) > clientWidth)
        && (
          <div className={`carousel-arrow carousel-arrow-right ${className && className}`} onClick={() => handleRightClick(offset)}>
            <img src={chevronRight} className='carousel-arrow-img' />
          </div>
        )
      }
      {
        (scrollWidth > clientWidth && scrollLeft > 0)
        && (
          <div
            className={`carousel-arrow carousel-arrow-left ${className && className}`}
            onClick={() => handleLeftClick(offset)}>
            <img src={chevronLeft} className='carousel-arrow-img' />
          </div>
        )
      }
    </>
  )
}
export default forwardRef(ChevronRight)
