import { createIntl, createIntlCache } from "@formatjs/intl"
import React from "react"
import moment from "moment"

import { LittleBirdieTag } from "../../components/Icons"

const cache = createIntlCache()
const intl = createIntl(
  {
    locale: "en-EN",
    messages: {},
  },
  cache
)

export const discountText = ({ discount }) => intl.formatNumber((discount), {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
})

export default function DiscountLabel({
 showDiscount, lastPriceLabel, discount, hideBirdie, lastPriceDate
}) {
  if (showDiscount) {
    return (
      <>
        <div className="price-drop-label">
          <div className="d-flex align-items-center h-100">
            <div className="small">
              {lastPriceDate ? (<div>{((new Date()) - lastPriceDate) < 30000 ? <div>Just Now</div> : <div>{moment(lastPriceDate).fromNow()}</div>}</div>)
              : (<div>{` ${lastPriceLabel.match(/just now/i) ? lastPriceLabel : `${lastPriceLabel} ago`}`}</div>)
              }
            </div>
            <div className="pricedrop">{discountText({ discount })}</div>
          </div>
        </div>
      </>
    )
  }
  return <div className="mb-4" />
}
