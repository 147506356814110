import React from 'react'
import { Helmet } from 'react-helmet'

export default function Meta ({
  title,
  description,
  og_description,
  og_title,
  name
}) {
  return (
    <Helmet>
      <title>{title || 'Little Birdie | Get the best price'}</title>
      <link rel='canonical' href={`${window?.location?.origin}${window?.location?.pathname}`} />
      <meta property='type' content='website' />
      <meta property='title' content={title || 'Little Birdie | Get the best price'} />
      <meta property='description' content={description} />
      <meta property='og:url' content={window.location.href} />
      <meta name={name} content={description} />
      <meta property='og:title' content={og_title || 'Little Birdie | Get the best price'} />
      <meta property='og:image' content='https://lb-media.s3-ap-southeast-2.amazonaws.com/og-content/share.jpg' />
      <meta property='og:description' content={og_description || description} />
      <meta property='og:type' content='website' />
    </Helmet>
  )
}
