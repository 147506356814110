import React from 'react'

import RestrictedSaleCard from './RestrictedSaleCard'
import NormalSaleCard from './NormalSaleCard'

export default function SaleEventNormalCard ({
  wrapperClass,
  position,
  currentSection,
  sectionPosition,
  showRank,
  shareable,
  item,
  userSignedIn,
  sectionId,
  isPersonalised,
  isSponsored
}) {
  if (!item) {
    return null
  }
  // if (item?.attributes?.memberContent === true) {
  //   return (
  //     <RestrictedSaleCard {...{sectionId,
  //       item, position, wrapperClass, userSignedIn, showRank, currentSection, sectionPosition, isPersonalised,isSponsored
  //     }} />
  //   )
  // }
  return (
    <NormalSaleCard {...{
      sectionId,
      item,
      position,
      shareable,
      wrapperClass,
      showRank,
      currentSection,
      sectionPosition,
      isPersonalised,
      isSponsored
    }}
    />
  )
}
