import * as types from "./types"

export const topPicksRequestStart = () => ({
  type: types.TOP_PICK_REQUEST_START
})

export const topPicksRequestSuccess = ({ response }) => ({
  type: types.TOP_PICK_REQUEST_SUCCESS,
  response
})

export const topPicksRequestFailure = error => ({
  type: types.TOP_PICK_REQUEST_FAILURE,
  error
})

export const narratTopPicksRequestStart = () => ({
  type: types.NARRAT_TOP_PICK_REQUEST_START
})
