import React, { Component } from "react"
import { connect } from "react-redux"
import PageSection from "../../../../components/PageSection"
import { labelTag } from "../../../../components/Icons"
import ListingCard from "../ListingCard"

import * as reducers from "../../reducers"

class NewslettersList extends Component {
  topListComponents = () => {
    const { newsletterIds } = this.props
    if (_.isEmpty(newsletterIds)) { return ([]) }
    return _.map(newsletterIds, (newsletterId, index) => (<ListingCard type="newsletter" id={newsletterId} key={`newsletter-{}-${index}`} currentSection='NewsLetterList' sectionPosition={1} />))
  }

  render() {
    const {
      title,
      description,
      newsletterIds
    } = this.props

    return (
      <>
        {newsletterIds.length > 0 && (
          <PageSection
            title={title || "Our Top 20 Newsletters"}
            description={description || "Check out our pick of the very best shopping newsletters out there!"}
            icon={labelTag}
            iconClass="label-tag"
            titleClass="mb-1">
            <div className="listing-group listing-group-carousel listing-group-collection px-2 px-md-0">
              <div className="d-flex">
                { this.topListComponents() }
              </div>
            </div>
          </PageSection>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  newsletterIds: reducers.getNewsletterIds(state),
  isFetchingNewsletters: reducers.isFetchingNewsletters(state)
})

export default connect(mapStateToProps, {})(NewslettersList)
