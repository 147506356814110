import React from "react"

import {
  LittleBirdieTag
} from "../../../components/Icons"
import RankLabel from "../../Labels/RankLabel"

export default function CollectionCard ({
  item: {
    type,
    attributes,
    attributes: {
      title, subtitle
    },
    links,
    relationships
  },
  wrapperClass, position, showRank
}) {
  return (
    <div className={`listing-card listing-collection ${wrapperClass}`}>
      {
        type === "newsletter" && (
          <div className="card-newsletter">
            <small>{attributes.startDate}</small>
            <div className="card card-collection">
              <div className="card-collection-image">
                <a href={links.listUrl || links.newsletterUrl}>
                  <img className="" src={links.newsletterImage.medium.url} />
                </a>
              </div>
            </div>
            <div className="card-newsletter-subject">
              {`${relationships.store.attributes.name}: ${attributes.subject}`}
            </div>
          </div>
        )
      }
      {
        type === "customList" && (
          <>
            {(position && showRank) && (
              <RankLabel rank={position} />
            )}
            <div className="card card-collection p-3">
              <div className="collection-tag text-center">
                <img src={LittleBirdieTag} />
                <h3 className="mb-0">Collection</h3>
              </div>
              <div className="card-collection-image">
                <a href={links.listUrl}>
                  <img className="" src={links.coverImage.url} />
                </a>
              </div>
              Best of
              {title && <h3 className="card-collection-subject text-truncate">{title}</h3>}
              {subtitle && <h2 className="card-collection-subject text-truncate">{subtitle}</h2>}
            </div>
          </>
        )
      }
    </div>
  )
}
