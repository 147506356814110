import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import * as actions from "../../../actions/types"

const commentsFetchSuccess = (state, { data }) => ({ ...state, ...data.comment })

const byId = createReducer({})({
  [actions.RESOURCE_FETCHED_SUCCESS]: commentsFetchSuccess,
  [actions.COMMENTS_REQUEST_SUCCESS]: commentsFetchSuccess,
  [actions.LOCATION_CHANGED]: () => ({})
})

export const getCommentById = (state, commentId) => state.byId[commentId]

export default combineReducers({
  byId
})
