import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import { votingStart, votingSuccess, votingFailure } from "../../actions"
import { trigerVote } from "../../requesters"
import { getBaseUrl } from "../../reducers"

function* triggerVoting({ data }) {
  yield put(votingStart())
  const baseUrl = yield select(getBaseUrl)

  const { response, error } = yield call(trigerVote, baseUrl, data)
  if (response) {
    yield put(votingSuccess(response))
  } else {
    yield put(votingFailure(error))
  }
}

function* fetchData(data) {
  yield all([
    fork(triggerVoting, data)
  ])
}

function* watchTriggerVotingSaga() {
  yield takeLatest(actionTypes.VOTING_TRIGGERED, fetchData)
}

export {
  watchTriggerVotingSaga
}