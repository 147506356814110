import { combineReducers } from 'redux'

import * as actions from '../../actions/types'
import { activeExperiments } from '../../components/MVT/activeExperiments'

const experiments = (state = {}, action) => {
  switch (action.type) {
    case actions.EXPERIMENTS_REQUEST_START:
      return { activeExperiments, data: {}, isLoading: true }
    case actions.EXPERIEMNTS_REQUEST_SUCCESS:
      return { ...state, activeExperiments, isLoading: false, data: action.data }
    case actions.EXPERIEMNTS_REQUEST_FAILURE:
      return { activeExperiments, isLoading: false, data: [] }
    default:
      return state
  }
}

export default combineReducers({
  experiments
})
