import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import Loader from "../../../../../components/Loader"
import activeAndSortedList from "../../../../../components/utils/activeAndSortedList"
import * as reducers from "../../../reducers"


class CategoryField extends Component {
  state = {
    selected_department: undefined
  }

  static getDerivedStateFromProps(props, state) {
    const { selected_department } = state

    // Set Initial Selection
    if (selected_department === undefined) {
      return { selected_department: activeAndSortedList(Object.values(props.departments))[0] }
    }
    return null
  }

  renderDepartmentLinks = () => {
    const { departments } = this.props
    const { selected_department } = this.state
    const departmentId = _.get(selected_department, "id")

    const orderedActiveDepartmentsArr = activeAndSortedList(Object.values(departments))

    if (departments == null) { return (<Loader />) }

    return (
      orderedActiveDepartmentsArr.map(department => (
        <Link
          key={`${department.attributes.slug}`}
          to={`/shop/${department.attributes.slug}`}
          onMouseEnter={() => this.setState({ selected_department: department })}>
          <li className={department.id === departmentId ? "list-group-item active" : "list-group-item"}>
            {department.links.icon.tiny.url && (
              <span className="dep-icon">
                <img className="nav-img" width={20} src={department.links.icon.url} />
              </span>
                  )}
            {department.attributes.name}
          </li>
        </Link>
      ))
    )
  }

  renderCategories = () => {
    const { selected_department } = this.state

    if (selected_department === undefined) { return (null) }

    const sortedActiveDepCategories = activeAndSortedList(Object.values(selected_department.relationships.categories))

    function GroupCategories(categories) {
      const groups = []
      let currentGroup = []
      categories.forEach(cat => {
        currentGroup.push(cat)
        if (cat.attributes.navbarSpacer) {
            groups.push(currentGroup)
            currentGroup = []
        }
      })
      if (Array.isArray(currentGroup) && currentGroup.length) {
        groups.push(currentGroup)
      }
      return groups
    }

    function CategortyLinks({ cats }) {
      return cats.map(cat => (
        <Link
          to={`/shop/${selected_department.attributes.slug}/${cat.attributes.slug}`}
          key={`${cat.attributes.slug}`}>
          <li>
            {cat.attributes.name}
          </li>
        </Link>
      ))
    }

    const groupedCategories = GroupCategories(sortedActiveDepCategories)

    return (
      <div className="py-3 px-4">
        <div className="row no-gutters">
          <div className="col-xl-6">
            <Link
              to={`/shop/${selected_department.attributes.slug}`}>
              <div>{`See all ${selected_department.attributes.name} offers`}</div>
            </Link>
            <div className="h7-bar mt-0"></div>
          </div>
        </div>
        <div className="row no-gutters">
          {
            groupedCategories.map(cat => (
              <div className="col-xl-6 mb-4" key={`group-${cat[0].attributes.slug}`}>
                <ul className="list-unstyled department-list">
                  <CategortyLinks cats={cat} />
                </ul>
              </div>
            ))
          }
        </div>
      </div>
    )
  }

  renderNavbarInner = () => {
    // const { departments } = this.props
    const { selected_department } = this.state

    return (
      <>
        <div className="col-xl-8">
          { selected_department && this.renderCategories() }
        </div>
      </>
    )
  }

  render() {
    const { departments } = this.props

    return (
      <>
        <div className="side-block-field">
          {
            (_.isEmpty(departments))
            ? <div className="mt-4 mb-4"><Loader /></div>
            : this.renderNavbarInner()
          }
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  departments: reducers.getAllDepartments(state)
})

export default connect(mapStateToProps, {})(CategoryField)
