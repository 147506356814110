import axios from 'axios'
import { gon } from '../../../constant'
import sendAuthToExtension from './SendAuthToExtension'

export default function DoLogout (postLogoutUrl = null) {
  const path = `${gon.urls.api}/auth/logout`

  if (window.localStorage.getItem('auth_token')) {
    return axios.delete(path)
      .then(response => {
        window.sessionStorage.removeItem('fromExtension')
        window.sessionStorage.removeItem('user_affinities')
        window.localStorage.removeItem('current_user')
        window.localStorage.removeItem('auth_token')
        sendAuthToExtension(null)
        window.localStorage.removeItem('csrf_token')
        window.localStorage.removeItem('firstname')
        window.localStorage.removeItem('gender')
        window.location.href = postLogoutUrl || '/'

        return response
      })
  } else {
    sendAuthToExtension(null)
    window.location.href = postLogoutUrl || '/'
  }
}
