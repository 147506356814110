import {
  all, call, put, select, fork, takeEvery
} from 'redux-saga/effects'

import * as actionTypes from '../../actions/types'
import * as actions from '../../actions'
import { getBaseUrl } from '../../reducers'
import * as requester from '../../requesters'

function* fetchDataByDep({ data }) {
  const saleParams = {
    by_department: data?.slug,
    per_page: 10
  }
  const dealParams = {
    is_top_deal: true,
    per_page: 20,
    from: 0,
    order: 'desc',
    value: 'fixed_department_score',
    department_names: [data?.name],
    price_shift: [-1, 0]
  }

  const baseUrl = yield select(getBaseUrl)

  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, saleParams)
  if (response) {
    const dealResponse = yield call(requester.getTopElasticDeals, baseUrl, dealParams)
    if (dealResponse) {
      const vResponse = yield call(requester.getTopCoupons, baseUrl, saleParams)
      yield put(actions.merchTopDepRequestSuccess({ response: { delResponse: dealResponse?.response?.data || [], saleResponse: response?.ids || [], voucherResponse: vResponse?.response?.ids || [] }, dep: data?.slug }))
    } else {
      yield put(actions.merchTopDepRequestFailure({ error, dep: data?.slug }))
    }
  } else {
    yield put(actions.merchTopDepRequestFailure({ error, dep: data?.slug }))
  }
}

function* fetchData (data) {
  yield all([
    fork(fetchDataByDep, data)
  ])
}

function* watchMerchTopDepSaga () {
  yield takeEvery(actionTypes.MERCH_TOP_DEP_START, fetchData)
}

export {
  watchMerchTopDepSaga
}
