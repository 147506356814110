import { combineReducers } from 'redux'
import createReducer from '../../../components/utils/createReducer'
import * as actionTypes from '../actions/types'

export const beCdnLoaded = createReducer(false)({
  [actionTypes.BE_CDN_LOADED]: (state, { data }) => data
})

export default combineReducers({
  beCdnLoaded
})
