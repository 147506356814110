import { combineReducers } from "redux"

import deals, * as fromDeals from "./deals"
import users, * as fromUsers from "./users"
import brands, * as fromBrands from "./brands"
import stores, * as fromStores from "./stores"
import saleEvents, * as fromSaleEvents from "./sale_events"
import coupons, * as fromCoupons from "./coupons"
import tags, * as fromTags from "./tags"
import comments, * as fromComments from "./comments"
import departments, * as fromDepartments from "./departments"
import categories, * as fromCategories from "./categories"
// import siblingDeals, * as fromSiblingDeals from "./siblingDeals"
import subcategories, * as fromSubcategories from "./sub_categories"
import userActivities from "./user_activities"
import newsletters, * as fromNewsletters from "./newsletters"
import customList, * as fromCustomList from "./custom_list"
import affinityData, * as fromAffinityData from './users'
import tickers, * as fromTickers from "./tickers"

// Functions to fetch data
export const getResourceFromType = (state, { type = undefined, id = undefined, ids = undefined }) => {
  if (type === undefined) { return null }

  return {
    deal: fromDeals.getDealById(state.deals, id),
    deals: fromDeals.getDealsByIds(state.deals, ids),
    user: fromUsers.getUserById(state.users, id),
    brand: fromBrands.getBrandById(state.brands, id),
    store: fromStores.getStoreById(state.stores, id),
    saleEvent: fromSaleEvents.getSaleEventById(state.saleEvents, id),
    voucher: fromCoupons.getCouponById(state.coupons, id),
    coupon: fromCoupons.getCouponById(state.coupons, id),
    uniqueCoupon: fromCoupons.getCouponById(state.coupons, id),
    department: fromDepartments.getDepartmentById(state.departments, id),
    category: fromCategories.getCategoryById(state.categories, id),
    // siblingDeals: fromSiblingDeals.getSiblingDealById(state.siblingDeal, id),
    subcategory: fromSubcategories.getSubcategoryById(state.subcategories, id),
    ticker: fromTickers.getTickerById(state.tickers, id),
    tag: fromTags.getTagById(state.tags, id),
    comment: fromComments.getCommentById(state.comments, id),
    newsletter: fromNewsletters.getNewsletterById(state.newsletters, id),
    customList: fromCustomList.getCustomListById(state.customList, id),
    affinityData: fromAffinityData.getUserAffinityData(state.users.affinityData)
  }[type]
}

// This handles building relationships if they were an array or hash
//
export const relationshipsForResource = (state, resource, key) => {
  const data = _.get(resource?.relationships, `${key}.data`)

  if (data instanceof Array) {
    // Resolve as array
    return _.compact(_.map(data, item => getResourceFromType(state, item || { type: undefined, id: undefined })))
  }

  // Resolve as hash
  return getResourceFromType(
    state,
    _.get(resource?.relationships, `${key}.data`) || { type: undefined, id: undefined }
  )
}

export const getResource = (state, { type, id }) => {
  const resource = getResourceFromType(state, { type, id })

  // Handle returning null if we cannot find what we are looking for
  if (resource === undefined) { return undefined }

  // Build out relationships so we can merge them back into the main object
  const relationships = {}

  _.each(
    _.keys(resource?.relationships, {}), key => (
      _.extend(relationships, {
        [key]: relationshipsForResource(state, resource, key)
      })
    )
  )

  return { ...resource, ...{ relationships } }
}

// Users
export const getUserBySlug = (state, slug) => {
  const user = fromUsers.getUserBySlug(state.users, slug)
  if (user === undefined) { return null }
  return getResource(state, { type: user.type, id: user.id })
}

// Stores
export const getStoreBySlug = (state, slug) => {
  const store = fromStores.getStoreBySlug(state.stores, slug)
  if (store === undefined) { return null }
  return getResource(state, { type: store.type, id: store.id })
}

// Brands
export const getBrandBySlug = (state, slug) => {
  const brand = fromBrands.getBrandBySlug(state.brands, slug)
  if (brand === undefined) { return null }
  return getResource(state, { type: brand.type, id: brand.id })
}

// Department
export const getDepartmentBySlug = (state, slug) => {
  const department = fromDepartments.getDepartmentBySlug(state.departments, slug)
  if (department === undefined) { return null }
  return getResource(state, { type: department.type, id: department.id })
}
// Refactoring how we store departments and categories in redux to improve performance
// export const getAllDepartments = state => _.map(state.departments.byId, department => getDepartmentBySlug(state, department.attributes.slug))
export const getAllDepartments = state => state.departments.byId

// Category
export const getCategoryBySlug = (state, slug) => {
  const category = fromCategories.getCategoryBySlug(state.categories, slug)
  if (category === undefined) { return null }
  return getResource(state, { type: category.type, id: category.id })
}

// Subcategory
export const getSubcategoryBySlug = (state, slug) => {
  const subcategory = fromSubcategories.getSubcategoryBySlug(state.subcategories, slug)
  if (subcategory === undefined) { return null }
  return getResource(state, { type: subcategory.type, id: subcategory.id })
}

// Deals
export const getDealBySlug = (state, slug) => {
  const deal = fromDeals.getDealBySlug(state.deals, slug)
  if (deal === undefined) { return null }
  return getResource(state, { type: deal.type, id: deal.id })
}

export const getDealsByIds = (state, ids) => {
  const deals = _.map(ids, id => fromDeals.getDealById(state.deals, id))
  if (_.isEmpty(deals)) { return null }
  return deals
}

// Coupons
export const getCouponBySlug = (state, slug) => {
  const coupon = fromCoupons.getCouponBySlug(state.coupons, slug)
  if (coupon === undefined) { return null }
  return getResource(state, { type: coupon.type, id: coupon.id })
}

// SaleEvents
export const getSaleEventBySlug = (state, slug) => {
  const sale_event = fromSaleEvents.getSaleEventBySlug(state.saleEvents, slug)
  if (sale_event === undefined) { return null }
  return getResource(state, { type: sale_event.type, id: sale_event.id })
}

// Departments
export const getDepartments = state => _.map(state.departments.byId, item => getResource(state, { type: item.type, id: item.id }))

// Categories
export const getCategories = state => _.map(state.categories.byId, item => getResource(state, { type: item.type, id: item.id }))

// SubCategories
export const getSubcategories = state => _.map(state.subcategories.byId, item => getResource(state, { type: item.type, id: item.id }))

// Tags
export const getTags = state => _.map(state.tags.byId, item => getResource(state, { type: item.type, id: item.id }))

// Newsletters
export const getNewsletterBySlug = (state, slug) => {
  const newsletter = fromNewsletters.getNewsletterBySlug(state.newsletters, slug)
  if (newsletter === undefined) { return null }
  return getResource(state, { type: newsletter.type, id: newsletter.id })
}

// Custom List
export const getCustomList = (state, slug) => {
  const customList = fromCustomList.getCustomList(state.customList, slug)
  if (customList === undefined) { return null }
  return getResource(state, { type: customList.type, id: customList.id })
}

export default combineReducers({
  deals,
  users,
  brands,
  stores,
  coupons,
  tags,
  saleEvents,
  departments,
  categories,
  // siblingDeals,
  subcategories,
  tickers,
  comments,
  userActivities,
  newsletters,
  customList,
  affinityData
})
