import {
    call, put, select, takeEvery
  } from 'redux-saga/effects'

  import * as actionTypes from '../../actions/types'
  import * as actions from '../../actions'
  import { getBaseUrl } from '../../reducers'
  import * as requester from '../../requesters'

  function* loadGotoUrl( { data } ) {
    const baseUrl = yield select(getBaseUrl)
    const { response, error } = yield call(requester.getGotoUrl, baseUrl, data)
    yield (response) ? put(actions.gotoUrlRequestSuccess(response)) : put(actions.gotoUrlRequestFailure(error))
  }

  function* watchGotoUrlSagas() {
    yield takeEvery(actionTypes.GOTO_URL_REQUEST_START, loadGotoUrl)
  }

  export {
    watchGotoUrlSagas
  }
