import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchUserById ({ data }) {
  yield put(actions.userRequestStart())

  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getUserByIdentifier, baseUrl, data.id)

  if (response) {
    yield put(actions.userRequestSuccess(response))
  } else {
    yield put(actions.userRequestFailure(error))
  }
}

function* fetchTopStores({ data }) {
  yield put(actions.topStoresRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { by_followed_user: data.id }
  const { response, error } = yield call(requester.getTopStores, baseUrl, params)
  yield (response) ? put(actions.topStoresRequestSuccess(response)) : put(actions.topStoresRequestFailure(error))
}

function* fetchTopBrands({ data }) {
  const baseUrl = yield select(getBaseUrl)
  yield put(actions.topBrandsRequestStart())
  const params = { by_followed_user: data.id }
  const { response, error } = yield call(requester.getTopBrands, baseUrl, params)
  yield (response) ? put(actions.topBrandsRequestSuccess(response)) : put(actions.topBrandsRequestFailure(error))
}

function* fetchTopDealhunters({ data }) {
  yield put(actions.topDealHuntersRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { by_followed_user: data.id }
  const { response, error } = yield call(requester.getTopDealHunters, baseUrl, params)
  yield (response) ? put(actions.topDealHuntersRequestSuccess(response)) : put(actions.topDealHuntersRequestFailure(error))
}

function* fetchData(data) {
  yield all([
    fork(fetchUserById, data),
    fork(fetchTopBrands, data),
    fork(fetchTopStores, data),
    fork(fetchTopDealhunters, data)
  ])
}

function* watchProfileActivityPageSaga() {
  yield takeLatest(actionTypes.LOAD_PROFILE_ACTIVITY_PAGE, fetchData)
}

export {
  watchProfileActivityPageSaga
}