import React, { Component } from "react"

import AccountSettings from "./AccountSettings"
import EmailNotification from "./EmailNotification"
import PublicProfileLoader from "./PublicProfileLoader"
import DeleteAccount from "./DeleteAccount"
export default class ProfileSettings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      about: null,
    }
  }

  aboutChangeHandler = about => {
    this.setState({ about })
  }

  render() {
    const {
      currentUser,
      type,
    } = this.props

    const { about } = this.state

    switch (type) {
      case "account":
        return <AccountSettings username={currentUser?.attributes?.username} />
      case "email":
        return <EmailNotification currentUser={currentUser} />
      case "public-profile-settings":
        return (
          <PublicProfileLoader
            aboutChange={this.aboutChangeHandler}
            about={about}
            currentUser={currentUser} />
        )
      case "delete-account":  
      return <DeleteAccount />    
      default:
        return <div></div>
    }
  }
}
