import axios from 'axios'

export const seoRequester = (baseUrl, data, params = {}) => {
  if (!!data.category) {
    const path = `${baseUrl}/categories/${data.category}/footer_content`
    return axios.get(path, {})
      .then(({ data, status }) => ({ response: data, status }))
      .catch(error => ({ error }))
  } else if (!!data.department) {
    const path = `${baseUrl}/departments/${data.department}/footer_content`
    return axios.get(path, {})
      .then(({ data, status }) => ({ response: data, status }))
      .catch(error => ({ error }))
  } else if (!!data.store) {
    const path = `${baseUrl}/stores/${data.store}/footer_content`
    return axios.get(path, {})
      .then(({ data, status }) => ({ response: data, status }))
      .catch(error => ({ error }))
  } else if (!!data.brand) {
    const path = `${baseUrl}/brands/${data.brand}/footer_content`
    return axios.get(path, {})
      .then(({ data, status }) => ({ response: data, status }))
      .catch(error => ({ error }))
  }
}
