import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoginRedirect from '../../../../components/utils/LoginRedirect'

import { heartIcon, heartFillIcon } from '../../../../components/Icons'
import { isLoggedIn } from '../../reducers'
import { triggerFollowing, followingNotifyEnd } from '../../actions'
import { handleNativeRedirectWebView } from '../../utilis/NativeDataShare'

export default function FollowButton ({
  item, buttonClasses,
  activeIcon, activeText,
  inactiveIcon, inactiveText,
  activeClass, inactiveClass,
  mini, central, isCalendar,
  follow_type, onClick
}) {
  const dispatch = useDispatch()
  const userLoggedIn = useSelector(state => isLoggedIn(state))
  const [redirect, setRedirect] = useState(false)

  let inactiveIconToShow = inactiveIcon
  let activeIconToShow = activeIcon
  const inactiveTextToShow = inactiveText || ''
  const activeTextToShow = activeText || ''

  if (!isCalendar) {
    inactiveIconToShow = inactiveIcon || heartIcon
    activeIconToShow = activeIcon || heartFillIcon
  }

  function handleButtonClass () {
    if (isCalendar) {
      return null
    }
    return ''
  }

  const handleTriggerFollow = (e, data) => {
    e.preventDefault()

    if (onClick) { onClick() }
    dispatch(triggerFollowing({ ...data, follow_type }))

    setTimeout(() => {
      dispatch(followingNotifyEnd({ ...data, follow_type }))
    }, 3000)
  }
  const handleNativeShare = () => {
    if (window.ReactNativeWebView) {
      handleNativeRedirectWebView(`{"type":"follow", "path":"${window.location.href}"}`)
    } else {
      setRedirect(true)
    }
  }
  if (redirect) return <LoginRedirect prevUrl={window.location.pathname} message={`You must be signed in to follow ${item?.attributes?.name || item?.name || 'Listings'}`} />

  if (!userLoggedIn) {
    return (
      <button
        type='button'
        onClick={() => handleNativeShare()}
        className={`btn ${handleButtonClass()} ${buttonClasses} ${!central ? '' : null}`}
      >
        <div className='btn-content'>
          <img src={inactiveIconToShow} />
          {' '}
          {inactiveTextToShow}
        </div>
      </button>
    )
  }
  const { type, id } = item

  if (type && id) {
    const followed = item?.attributes?.followed

    const iconToShow = followed ? activeIconToShow : inactiveIconToShow
    const textToShow = followed ? activeTextToShow : inactiveTextToShow
    const heartClass = followed ? activeClass : inactiveClass

    if (mini) {
      return (
        <div className={`${buttonClasses} ${heartClass} btn-content`} onClick={e => handleTriggerFollow(e, { type, id, followed })}>
          <img src={iconToShow} />
        </div>
      )
    }

    return (
      <button
        type='button'
        className={`${buttonClasses} ${heartClass} ${!central ? 'follow-btn' : null} ${followed ? 'active' : ''}`}
        onClick={e => handleTriggerFollow(e, { type, id, followed })}
      >
        <div className='btn-content'>
          <img src={iconToShow} />
          {' '}
          {textToShow}
        </div>
      </button>
    )
  }
  return null
}
