import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import Loader from '../../../components/Loader'
import ListingCard from './ListingCard'
import ChevronRight from './ChevronRight'
import useScrollToSection from './BiInfiniteScroll/useScrollToSection'
import { setCarouselLoading, setCarouselLoaded } from '../actions/carousel'
import { customWidgetsRequestStart } from '../actions/custom_widget'
// import { cyberMondayBadge } from '../../../../javascript/components/Icons'

function CustomWidget ({ id, position, sectionId }) {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const { department, category, subcategory } = useParams()
  const sectionRef = useRef(null)
  const [refIsSet, setRefIsSet] = useState(false)
  const { request: { data, loading } } = useSelector(state => state?.listings?.merchandisePick)
  const [wgData, setWgData] = useState(data || [])
  useScrollToSection({ ref: sectionRef, eleId: 'hsref' })

  useEffect(() => {
    if (sectionRef?.current && !refIsSet) {
      setRefIsSet(true)
      dispatch(setCarouselLoaded({ carousel: sectionId }))
    }
  })

  useEffect(() => {
    data.length && setWgData(data)
    !loading && setIsLoading(false)
  }, [data, loading])

  useEffect(() => {
    if (!isLoading && !loading) {
      if (position) {
        const isDataAvailable = _.some(data, cw => {
          return cw.attributes?.position === position
        }) || false
        if (!isDataAvailable) {
          dispatch(customWidgetsRequestStart({ by_position: position }))
          setIsLoading(true)
        }
      } else if (id) {
        const isDataAvailable = _.some(data, cw => {
          return cw.id === id
        }) || false
        if (!isDataAvailable) {
          dispatch(customWidgetsRequestStart({ by_id: id }))
          setIsLoading(true)
        }
      }
    }
  }, [position, id])

  useEffect(() => {
    if (!(department || category || subcategory)) {
      dispatch(setCarouselLoading({ carousel: sectionId }))
    }

    if (!loading) {
      const widgets = data?.filter(li => li.attributes.position === position) || []
      if (widgets.length) {
        dispatch(setCarouselLoaded({ carousel: sectionId }))
      } else {
        dispatch(setCarouselLoaded({ carousel: sectionId }))
      }
    }
  }, [])

  useEffect(() => {
    const widgets = data?.filter(li => li.attributes.position === position) || []
    const widget = widgets[0]

    if (!loading && (widgets.length === 0 || !widget?.items?.data?.length)) {
      dispatch(setCarouselLoaded({ carousel: sectionId }))
    }
  }, [data, loading])

  if (department || category || subcategory) return null
  const widgets = wgData?.filter(li => (li?.attributes?.position === position) || (li?.id === id))

  if (widgets?.length > 0) {
    const widget = widgets[0]
    const bgColor = widget?.attributes?.bg_color || ''
    const items = widget?.items?.data || []
    const included = widget?.items?.included || []
    const customWidgetId = `custom-widget-${widget.id}`
    return (
      items.length
        ? (
          <div id={customWidgetId} ref={sectionRef}>
            <LazyLoad>
              <section className='section-group top-50'>
                <div className='container-fluid full-width-container'>
                  <div className={`section-bg ${widget?.attributes?.view_more_link && 'manual-bg'} `} style={{ backgroundColor: bgColor }} />
                </div>
                <div className={`section-group-body ${widget?.attributes?.view_more_link && 'manual-merch-body'} `}>
                  <div className='container'>
                  {/* <img className='bf-tag-small' src={cyberMondayBadge} /> */}
                    <div className='listing-group-header'>
                      <div className='d-flex justify-content-between'>
                        <div>
                          <h2 className='carousel-title'>{widget?.attributes?.title}</h2>
                          {widget?.attributes?.description && (
                            <div className='subtitle'>
                              {widget?.attributes?.description}
                            </div>
                          )}
                        </div>
                        {widget?.attributes?.view_more_link && (
                          <a
                            href={widget?.attributes?.view_more_link}
                            onClick={() => sectionId && localStorage.setItem('hsref', sectionId)}
                            className='btn btn-outline-dark rounded-pill px-5 btn-view-all d-none d-md-block'
                          >
                            View More
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <CustomWidgetBody
                    items={items}
                    included={included}
                    sectionId={sectionId}
                    title={widget?.attributes?.title}
                  />
                  {widget?.attributes?.view_more_link && (
                    <div className='d-xs-block d-md-none d-flex justify-content-center'>
                      <a
                        href={widget?.attributes?.view_more_link}
                        onClick={() => sectionId && localStorage.setItem('hsref', sectionId)}
                        className='btn btn-outline-dark rounded-pill px-5 btn-view-all btn-view-all-mobile'
                      >
                        View More
                      </a>
                    </div>)}
                </div>
              </section>
            </LazyLoad>
          </div>
          )
        : ''
    )
  }
  return null
}

function CustomWidgetBody ({ showViewBtn, items = [], included, title, sectionId }) {
  const myRef = useRef(null)
  if (!items.length) return <Loader isLoading />
  const widgetPicksArr = _.orderBy(items, 'attributes.position', [])
  return (
    <>
      <div className='container'>
        <div className='listing-carousel listing-group-header'>
          <div className='d-flex listing-group-carousel' ref={myRef}>
            <ChevronRight offset={300} ref={myRef} direction='left' />
            {widgetPicksArr?.length && _.take(widgetPicksArr, 10).map((item, index) => {
              const {
                relationships: { item: { data } }
              } = item
              if (data) {
                if (item?.attributes?.item?.id) {
                  const cardId = item.attributes.item.id
                  const getCard = included.filter(li => li.id === `${cardId}`)[0]
                  item.attributes.item.stores = getCard?.attributes?.stores
                }

                if (item?.attributes?.item?.brand_id) {
                  const brandId = item?.attributes?.item.brand_id
                  const getBrand = included.filter(li => li.id === `${brandId}`)[0]
                  item.attributes.item.brand_name = getBrand?.attributes?.name
                  item.attributes.item.brand_logo = getBrand?.links?.logo_url
                }

                if (item?.attributes?.item?.store_id) {
                  const storeId = item?.attributes?.item?.store_id
                  const getStore = included.filter(li => li.id === `${storeId}`)[0]
                  item.attributes.item.store_name = getStore?.attributes?.name
                  item.attributes.item.store_logo = getStore?.attributes?.logo_url
                }

                if (item?.attributes?.item?.impressions_count) {
                  item.attributes.item.impressionsCount = item?.attributes?.item?.impressions_count
                }

                if (data.type === 'sale_event') {
                  data.type = 'saleEvent'
                  item.attributes.item.sale_event_image = item?.attributes?.item.sale_event_image?.url
                }

                if (item?.attributes?.item?.coupon_image?.url) {
                  item.attributes.item.coupon_image = item?.attributes?.item?.coupon_image?.url
                }

                const { type, id } = data

                return (
                  <CustomWidgetItem
                    sectionId={sectionId}
                    item={item?.attributes?.item}
                    key={`custom-widget-${type}-${id}`}
                    type={type}
                    id={id}
                    index={index}
                    title={title}
                    sponsored={item?.attributes?.sponsored}
                  />
                )
              }
              return null
            })}
            <ChevronRight offset={300} ref={myRef} direction='right' />
          </div>
        </div>
      </div>
      {showViewBtn
        ? (
          <div className='d-flex justify-content-center'>
            <button
              type='button'
              className='btn btn-outline-dark rounded-pill mx-2 px-4'
              onClick={showViewBtn}
            >
              View today’s offers
            </button>
          </div>
          )
        : ''}
    </>
  )
}

function CustomWidgetItem ({ type, id, index, item, title, sectionId, sponsored }) {
  const wrapperClass = {
    deal: 'listing-price-drop',
    saleEvent: 'event',
    coupon: 'coupon-large-image',
    uniqueCoupon: 'coupon-large-image',
    customList: ''
  }[type]

  return (
    <ListingCard
      sectionId={sectionId}
      isYourTopList
      listItem={item}
      wrapperClass={wrapperClass}
      type={type}
      id={id}
      position={index + 1}
      showRank
      currentSection={title}
      sponsoredFlag={sponsored}
    />
  )
}

export default React.memo(CustomWidget)
