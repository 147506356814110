import { combineReducers } from 'redux'

import * as actions from '../../actions/types'

const Personalise = (state = {}, action) => {
  switch (action.type) {
    case actions.GET_USER_EMAIL:
      return { }
    case actions.SET_INTIALLISE_PERSONALISE :
      return { ...state, isPersonalise: action.data }
    case actions.SET_USER_EMAIL:
      return { ...action.data }
    case actions.SET_PERSONALISE_MODAL:
      return { ...state, show: action.data }
    default:
      return state
  }
}

export default combineReducers({
  Personalise
})
