import * as types from "./types"

export const couponRequestStart = data => ({
  type: types.COUPON_REQUEST_START,
  data
})

export const couponRequestSuccess = data => ({
  type: types.COUPON_REQUEST_SUCCESS,
  data
})

export const couponRequestFailure = error => ({
  type: types.COUPON_REQUEST_FAILURE,
  error
})