import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
// import Pluralize from 'react-pluralize'
import { useInView } from 'react-intersection-observer'
import positionDataLayer from '../../../components/dataLayers/positionDataLayer'
import { USE_IN_VIEW_OPTIONS } from '../../../../constant'
import { renderStoreBrandStats, getOrderStats }  from '../../utils/renderBrandStoreStats'

export default function StoreCard ({
  wrapperClass,
  item,
  isRankingCard,
  item: { attributes, links },
  position,
  currentSection,
  sectionPosition,
  sectionId
  // showStats
}) {
  const { name } = attributes
  const [storeStats, setStoreStats] = useState([])

  const [refStoreCard, inView] = useInView(USE_IN_VIEW_OPTIONS)

  useEffect(() => {
    if (inView) {
      positionDataLayer(item, 'product-impression', 'product-link', position, currentSection, sectionPosition)
    }
  }, [inView])

  useEffect(() => {
    const allowedKeys = ['dealsCount', 'saleEventsCount', 'couponsCount', 'productsCount', 'followersCount']
    const objectOrder = {
      dealsCount: 0,
      saleEventsCount: 0,
      couponsCount: 0,
      productsCount: 0,
      followersCount: 0
    }
     let modifiedStoreStats= getOrderStats(attributes,allowedKeys,objectOrder)
    setStoreStats(() => ([
      ...modifiedStoreStats
    ]));
  }, [attributes])

  if (isRankingCard) {
    return (
      <div className={`${wrapperClass}`} ref={refStoreCard}>
        <div className='card card-store-brand-list'>
        {renderStoreBrandStats(storeStats,'list')}
          <Link
            to={{ pathname: links.storeUrl, state: { storeName: name } }}
            className='d-block'
            onClick={() => {
              positionDataLayer(item, 'card-click', 'product-link', position, currentSection, sectionPosition)
              sectionId && localStorage.setItem("hsref", sectionId)
            }}
          >
            <div className='card-img-top card-img-top-list'>
              <img className='listing-img' src={links.logoUrl} />
            </div>
          </Link>
          <Link to={{ pathname: links.storeUrl, state: { storeName: name } }}>
            <div className='d-flex justify-content-center align-items-center store-brand-footer store-brand-footer-list'>
              View all from&nbsp;<b className='mb-0'>{attributes.name}</b>
            </div>
          </Link>

        </div>
      </div>
    )
  }
  return (
    <div className='listing-card' ref={refStoreCard}>
      <div className='card card-store-brand'>
      {renderStoreBrandStats(storeStats)}
        <Link
          to={{ pathname: links.storeUrl, state: { storeName: name } }}
          onClick={() => {
            positionDataLayer(item, 'card-click', 'product-link', position, currentSection, sectionPosition)
            sectionId && localStorage.setItem("hsref", sectionId)
          }}
        >
          <div className='card-img-top'>
            <LazyLoadImage
              effect='opacity'
              className='mr-xl-2 img-fluid sidebar-logo'
              alt={attributes.name}
              src={links.logoUrl}
            />
          </div>
        </Link>
        <Link to={{ pathname: links.storeUrl, state: { storeName: name } }}>
          <div className='d-flex justify-content-center align-items-center store-brand-footer'>
            View all from&nbsp;<b className='mb-0'>{name}</b>
          </div>
        </Link>
      </div>
    </div>
  )
}

