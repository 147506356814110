
import Modal from 'react-bootstrap/Modal'
import {
  crossIconWhiteThick
} from '../../../../components/Icons'
import { EXTENSION_ACTIVATION_VIDEO } from '../../../../../constant'

function IphoneExtActivationVideo ({ show = false, handleClose = null, handleTurnOnSafari = null }) {
  return (
    <Modal centered className='iphone-ext-activation-video-modal' show={show} onHide={handleClose}>
      <Modal.Body>
        <button className='close-btn' onClick={handleClose}> <img src={crossIconWhiteThick} className='play-icon' /> CLOSE </button>
        <video controls playsInline='playsinline' loop autoPlay='autoplay'>
          <source src={EXTENSION_ACTIVATION_VIDEO} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        {handleTurnOnSafari &&
          <button className='action-btn' onClick={handleTurnOnSafari}>Turn it on in Safari</button>}
      </Modal.Body>
    </Modal>
  )
}

export default IphoneExtActivationVideo
