import React, { Component } from "react"

import Meta from "../../../../../components/Meta"
import PasswordForm from "./PasswordForm"
import EmailForm from "./EmailForm"
import AvatarForm from "./AvatarForm"

class AccountSettings extends Component {
  render() {
    const { username } = this.props
    return (
      <section>
        <Meta
          title={`Settings | ${username} | Little Birdie`}
          name="Settings Page"
          description={`See ${username} on Little Birdie. See all their price drops, price drops, votes, vouchers, sale events and more! `} />
        <h3>
          Your Profile
          {" "}
          {username}
        </h3>
        <div className="card settings-card mt-3">
          <div className="card-body">
            <AvatarForm />
            <hr />
            <EmailForm />
            <hr />
            <PasswordForm />
          </div>
        </div>
      </section>
    )
  }
}

export default AccountSettings
