import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import createReducer from '../../../components/utils/createReducer'

import listings, * as fromListings from './listings'
import data, * as fromData from './data'
import cms, * as fromCms from './cms'
import categories, * as fromCategories from './categories'
import comments, * as fromComments from './comments'
import goto, * as fromGoto from './goto'
import search from './search'
import menu from './menu'
import notifications from './notifications'
import saleEventCalendar from './saleEventCalendar'
import currentUser from './currentUser'
import experiments from './experiments'
import affiTopDepartment from './topDepartments/affinity_top_department'
import merchTopDepartment from './topDepartments/merch_top_department'
import affinityTopCategory from './topCategory'
import personalise from './personalise'
import { gon } from '../../../../constant'
import carousel from './carousel'
import common from './common'

function setIsMobile (a) {
  if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(a.substr(0, 4))) {
    return true
  }
  return false
}

const config = createReducer(
  function (a) {
    const { ...initialState } = gon || {}
    initialState.is_mobile = setIsMobile(a)
    return initialState
  }(navigator.userAgent || navigator.vendor || window.opera)
)({})

export default history => combineReducers({
  router: connectRouter(history),
  config,
  listings,
  data,
  categories,
  cms,
  comments,
  search,
  menu,
  notifications,
  saleEventCalendar,
  currentUser,
  experiments,
  goto,
  affiTopDepartment,
  merchTopDepartment,
  personalise,
  affinityTopCategory,
  carousel,
  common
})

// Config
export const getBaseUrl = state => state.config.urls.api
export const getElasticBaseUrl = state => state.config.urls.elastic_api
export const getGotoBaseUrl = state => state.config.base_url

// Routes
export const getRouter = state => state.router

// Current User
export const getCurrentUser = state => state.currentUser.current_user
export const isLoggedIn = state => getCurrentUser(state) !== null
export const isUserLoading = state => state.currentUser.current_user_loading
export const getUserEmail = state => state.personalise.Personalise

// Mobile View
export const getIsMobile = state => state.config.is_mobile

// Search
export const getSearchKeyword = state => state.search.keyword
export const getIsSearchInputActive = state => state.search.inputSearchActive
export const getIsOverlayOpen = state => state.search.searchOverlayOpen.isOpen

// Top Affinity Category
export const getAffinityTopCategory = state => state.topCategory

// Experiments
export const getAllExperiments = ({ experiments }) => {
  return experiments?.experiments?.data
}

export const getExperimentDetailsById = ({ experiments }, id) => {
  const getData = (experiments?.data && Array.isArray(experiments.data) && experiments.data) || []
  const getExperiment = getData.filter(li => li.name === id)
  const experimentDetails = getExperiment.length ? getExperiment[0] : {}
  let variants = []
  let weights = []
  if (experimentDetails) {
    const allVariants = experimentDetails.variants
    variants = _.map(allVariants, 'name')
    weights = _.map(allVariants, 'weight').map(li => Number(li))
  }
  return { ...experimentDetails, variants, weights }
}

export const getActiveExperiment = ({ experiments }) => {
  return experiments?.experiments?.activeExperiments
}

export const getCategoryByAttributes = ({ data }, value = '') => _.filter(data.categories.byId, (e) => e.attributes.name.toLowerCase() === value.toLowerCase())

// Listings
export const isFetchingListFromType = (state, listType) => fromListings.isFetchingListFromType(state.listings, listType)
export const getListIdsFromType = (state, listType) => fromListings.getListIdsFromType(state.listings, listType)
export const getTopDealIds = state => fromListings.getTopDealIds(state.listings)
export const getTopPriceDropIds = state => fromListings.getTopPriceDropIds(state.listings)
export const getTopStoreIds = state => fromListings.getTopStoreIds(state.listings)
export const getTopBrandIds = state => fromListings.getTopBrandIds(state.listings)
export const getTopDealHunterIds = state => fromListings.getTopDealHunterIds(state.listings)
export const getTopCouponIds = state => fromListings.getTopCouponIds(state.listings)
export const getTopSaleEventIds = state => fromListings.getTopSaleEventIds(state.listings)
export const getTopCurrentSaleEventIds = state => fromListings.getTopCurrentSaleEventIds(state.listings)
export const getTopUpcomingSaleEventIds = state => fromListings.getTopUpcomingSaleEventIds(state.listings)
export const getNewsletterIds = state => fromListings.getNewsletterIds(state.listings)
export const getSiblingDealIds = state => fromListings.getSiblingDealIds(state.listings)

// CMS
export const getActiveTickerIds = state => fromCms.getActiveTickerIds(state.cms)

// SEO
export const getSeoContent = state => fromCms.getSeoContent(state.cms)
export const isFetchingSeoContent = state => fromCms.isFetchingSeoContents(state.cms)

// Categories
export const getCategoriesByDepartmentId = (state, id) => (id ? fromCategories.getCategoriesByDepartmentId(state.categories, id) : null)
export const isFetchingCategoriesByDepartment = (state, id) => (id ? fromCategories.isFetchingCategoriesByDepartment(state.categories, id) : true)

// API Status
export const isFetchingTopDeals = state => fromListings.isFetchingTopDeals(state.listings)
export const isFetchingTopPriceDrops = state => fromListings.isFetchingTopPriceDrops(state.listings)
export const isFetchingTopStores = state => fromListings.isFetchingTopStores(state.listings)
export const isFetchingTopBrands = state => fromListings.isFetchingTopBrands(state.listings)
export const isFetchingTopDealHunters = state => fromListings.isFetchingTopDealHunters(state.listings)
export const isFetchingTopCoupons = state => fromListings.isFetchingTopCoupons(state.listings)
export const isFetchingTopSaleEvents = state => fromListings.isFetchingTopSaleEvents(state.listings)
export const isFetchingTopCurrentSaleEvents = state => fromListings.isFetchingTopCurrentSaleEvents(state.listings)
export const isFetchingTopUpcomingSaleEvents = state => fromListings.isFetchingTopUpcomingSaleEvents(state.listings)
export const isFetchingFollowedSaleEvents = state => fromListings.isFetchingFollowedSaleEvents(state.listings)
export const isFetchingFollowedDeals = state => fromListings.isFetchingFollowedDeals(state.listings)
export const isFetchingFollowedCoupons = state => fromListings.isFetchingFollowedCoupons(state.listings)
export const isFetchingNewsletters = state => fromListings.isFetchingNewsletters(state.listings)
export const isFetchingSiblingDeals = state => fromListings.isFetchingSiblingDeals(state.listings)

// Data
export const getResource = (state, { type, id }) => fromData.getResource(state.data, { type, id })
export const getUserBySlug = (state, slug) => fromData.getUserBySlug(state.data, slug)
export const getDepartmentBySlug = (state, slug) => fromData.getDepartmentBySlug(state.data, slug)
export const getCategoryBySlug = (state, slug) => fromData.getCategoryBySlug(state.data, slug)
export const getSubcategoryBySlug = (state, slug) => fromData.getSubcategoryBySlug(state.data, slug)
export const getStoreBySlug = (state, slug) => fromData.getStoreBySlug(state.data, slug)
export const getBrandBySlug = (state, slug) => fromData.getBrandBySlug(state.data, slug)
export const getDealBySlug = (state, slug) => fromData.getDealBySlug(state.data, slug)
export const getDealsByIds = (state, ids) => fromData.getDealsByIds(state.data, ids)
export const getCouponBySlug = (state, slug) => fromData.getCouponBySlug(state.data, slug)
export const getSaleEventBySlug = (state, slug) => fromData.getSaleEventBySlug(state.data, slug)
export const getNewsletterBySlug = (state, slug) => fromData.getNewsletterBySlug(state.data, slug)

export const getDepartments = state => fromData.getDepartments(state.data)
export const getCategories = (state, filter) => fromData.getCategories(state.data, filter)
export const getSubcategories = (state, filter) => fromData.getSubcategories(state.data, filter)
export const getTags = (state, identifier) => fromData.getTags(state.data, identifier)
export const getAllDepartments = state => fromData.getAllDepartments(state.data)

// Comments
export const getCommentsIds = state => fromComments.getCommentsIds(state.comments)
export const isFetchingComments = state => fromComments.isFetchingComments(state.comments)
export const getCommentsCount = state => fromComments.getCommentsCount(state.comments)

// Goto
export const getGotoUrl = state => fromGoto.getGotoUrl(state.goto)

export const getBeCdnLoaded = state => state.common.beCdnLoaded
