import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl, isUserLoading } from "../../reducers"
import * as requester from "../../requesters"

function* fetchTopStores({ data }) {
  yield put(actions.topStoresRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_department: _.get(data, "department"),
    per_page: 30
  }
  const userLoading = yield select(isUserLoading)
    const { response, error } = yield call(requester.getTopStores, baseUrl, params)
    yield (response) ? put(actions.topStoresRequestSuccess(response)) : put(actions.topStoresRequestFailure(error))
}

function* fetchTopBrands({ data }) {
  yield put(actions.topBrandsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_department: _.get(data, "department"),
    per_page: 30
  }
  const userLoading = yield select(isUserLoading)
    const { response, error } = yield call(requester.getTopBrands, baseUrl, params)
    yield (response) ? put(actions.topBrandsRequestSuccess(response)) : put(actions.topBrandsRequestFailure(error))
}

function* fetchTopCoupons({ data }) {
  yield put(actions.topCouponsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_department: _.get(data, "department"),
    by_category: _.get(data, "category"),
    "by_order[column]": _.get(data, "by_order.column"),
    "by_order[direction]": _.get(data, "by_order.direction"),
    per_page: 10
  }
  const userLoading = yield select(isUserLoading)
    const { response, error } = yield call(requester.getTopCoupons, baseUrl, params)
    yield (response) ? put(actions.topCouponsRequestSuccess(response)) : put(actions.topCouponsRequestFailure(error))
}

function* fetchTodaysTopPicks() {
  const baseUrl = yield select(getBaseUrl)
  const state = yield select()
  if (!Object.keys(state?.listings?.topPicks?.byIds)?.length > 0) {
    yield put(actions.topPicksRequestStart())
    const { response, error, meta } = yield call(requester.getTodaysTopPicks, baseUrl, {})
    yield (response) ? put(actions.topPicksRequestSuccess({ response, meta })) : put(actions.topPicksRequestFailure(error))
  }
}

function* fetchYourTopPicks () {
  const baseUrl = yield select(getBaseUrl)
  const affinityData = JSON.parse(sessionStorage.getItem('user_affinities'))
  const state = yield select()
  if (affinityData?.length > 0 && !state?.listings?.yourTopPicks?.byIds?.deals) {
    yield put(actions.yourTopPicksRequestStart())
    const { response, error } = yield call(requester.getYourTodaysTopPicks, baseUrl, {})
    yield (response) ? put(actions.yourTopPicksRequestSuccess({ response })) : put(actions.yourTopPicksRequestFailure(error))
  }
}

function* fetchTopSaleEvents({ data }) {
  yield put(actions.topSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_department: _.get(data, "department"),
    by_category: _.get(data, "category"),
    per_page: 10
  }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topSaleEventsRequestSuccess(response)) : put(actions.topSaleEventsRequestFailure(error))
}

function* fetchTopCurrentSaleEvents({ data }) {
  yield put(actions.topCurrentSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_department: _.get(data, "department"),
    by_category: _.get(data, "category"),
    current: true,
    per_page: 10
  }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topCurrentSaleEventsRequestSuccess(response)) : put(actions.topCurrentSaleEventsRequestFailure(error))
}

function* fetchTopUpcomingSaleEvents({ data }) {
  yield put(actions.topUpcomingSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_department: _.get(data, "department"),
    by_category: _.get(data, "category"),
    upcoming: true,
    per_page: 10
  }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topUpcomingSaleEventsRequestSuccess(response)) : put(actions.topUpcomingSaleEventsRequestFailure(error))
}

// Cba Exclusive Picks
function* fetchCbaExclusivePicks() {
  const baseUrl = yield select(getBaseUrl)
  yield put(actions.cbaExclusiveRequestStart())
  const userLoading = yield select(isUserLoading)
    const { response, error, meta } = yield call(requester.getCbaExclusivePicks, baseUrl, {})
    yield (response) ? put(actions.cbaExclusiveRequestSuccess({ response, meta })) : put(actions.cbaExclusiveRequestFailure(error))
}

function* fetchData(action) {
  yield all([
    fork(fetchTopStores, action),
    fork(fetchTopBrands, action),
    fork(fetchTopCoupons, action),
    fork(fetchTodaysTopPicks, action),
    fork(fetchYourTopPicks, action),
    fork(fetchCbaExclusivePicks, action)
  ])
}

function* fetchDepartmentCategoryPageData(action) {
  yield all([
    fork(fetchTopSaleEvents, action),
    // fork(fetchTopCurrentSaleEvents, action),
    // fork(fetchTopUpcomingSaleEvents, action)
  ])
}

function* watchListingsPageSaga () {
  yield takeLatest(actionTypes.LOAD_LISTINGS_PAGE, fetchData)
  yield takeLatest(actionTypes.LOAD_DEPARTMENT_CATEGORY_PAGE, fetchDepartmentCategoryPageData)
  yield takeLatest(actionTypes.USER_AFFINITY_REQUEST_SUCCESS, fetchYourTopPicks)
  yield takeLatest(actionTypes.NARRAT_TOP_PICK_REQUEST_START, fetchTodaysTopPicks)
}

export {
  watchListingsPageSaga
}
