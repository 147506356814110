import * as types from "./types"

export const tagRequestStart = data => ({
  type: types.TAGS_REQUEST_START,
  data
})

export const tagRequestSuccess = data => ({
  type: types.TAGS_REQUEST_SUCCESS,
  data
})

export const tagRequestFailure = error => ({
  type: types.TAGS_REQUEST_FAILURE,
  error
})