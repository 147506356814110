import {
  all, call, put, select, fork, takeEvery
} from 'redux-saga/effects'

import * as actionTypes from '../../actions/types'
import * as actions from '../../actions'
import { getBaseUrl, isUserLoading } from '../../reducers'
import * as requester from '../../requesters'

function* fetchMerchandisePicks ({ data }) {
  const baseUrl = yield select(getBaseUrl)
  const userLoading = yield select(isUserLoading)
    const { response, error, meta } = yield call(requester.getCustomWidget, baseUrl, { data })
    yield (response) ? put(actions.customWidgetsRequestSuccess({ response, meta })) : put(actions.customWidgetsRequestFailure(error))
}

function* fetchData (data) {
  yield all([
    fork(fetchMerchandisePicks, data)
  ])
}

function* watchCustomWidgetySaga () {
  yield takeEvery(actionTypes.CUSTOMWIDGETS_PICK_REQUEST_START, fetchData)
}

export {
  watchCustomWidgetySaga
}
