import { combineReducers } from "redux"

import * as actionTypes from "../../actions/types"

const request = (state = null, action) => {
  switch (action.type) {
    case actionTypes.TOP_PICK_REQUEST_START: {
      const { response } = action
      const todaysTopPick = response?.todaysTopPick || {}
      return todaysTopPick
    }
    case actionTypes.TOP_PICK_REQUEST_SUCCESS: {
      return actionTypes.TOP_PICK_REQUEST_SUCCESS
    }
    case actionTypes.TOP_PICK_REQUEST_FAILURE: {
      return actionTypes.TOP_PICK_REQUEST_FAILURE
    }
    default: {
      return state
    }
  }
}

const byIds = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TOP_PICK_REQUEST_SUCCESS: {
      const { response: { todaysTopPick } } = action
      return todaysTopPick || {}
    }
    case actionTypes.TOP_PICK_REQUEST_FAILURE: {
      return {}
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  byIds,
  request
})