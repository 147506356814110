import { combineReducers } from "redux"

import * as actionTypes from "../actions/types"

function showUserMenu(state = false, action) {
  switch (action.type) {
    case actionTypes.SHOW_USER_SLIDER:
      return true
    case actionTypes.HIDE_USER_SLIDER:
      return false
    case actionTypes.TOGGLE_USER_SLIDER:
      return !state
    default:
      return state
  }
}

function showCatMenu(state = false, action) {
  switch (action.type) {
    case actionTypes.SHOW_CAT_SLIDER:
      return true
    case actionTypes.HIDE_CAT_SLIDER:
      return false
    case actionTypes.TOGGLE_CAT_SLIDER:
      return !state
    default:
      return state
  }
}

function selectedDepartment(state = null, action) {
  switch (action.type) {
    case actionTypes.CAT_SLIDER_RESET_DEPARTMENT:
    case actionTypes.SHOW_CAT_SLIDER:
      return null
    case actionTypes.CAT_SLIDER_SELECT_DEPARTMENT:
      return action.department
    default:
      return state
  }
}

export default combineReducers({
  showUserMenu,
  showCatMenu,
  selectedDepartment
})
