import React, { useRef, useState } from 'react'
import {
    extEnableV3,
    extHeader,
    extIcon,
    infoFilled,
    birdAndArrow,
    postInstall1,
} from "../../../../components/Icons";

const PageBadge = ({total, current}) => {
    return(
        <div className='page-badge'>
            <span className='badge-text'>{current}/{total}</span>
        </div>
    )
}

const EnableExtension = () => {

    const [showWalkthrough, setShowWalkthrough] = useState(false);
    const walkthroughRef = useRef(null);

    const walkthroughClick = () => {
        setShowWalkthrough(!showWalkthrough);
        if (walkthroughRef !== null && !showWalkthrough) {
            walkthroughRef?.current?.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
        } else if (showWalkthrough){
            walkthroughRef?.current?.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
        }
    }

    return(
        <>
            <div className={'be-view enable'} style={{marginTop:'-56px'}}>
                <div>
                    <img src={extHeader} alt="header" style={{width:'100%'}} />
                    <span className='title header' style={{top: '18px'}}>
                        Enable the <span className={'sub-text'}> extension </span> on Safari
                    </span>
                </div>

                <PageBadge total={2} current={1} />
                <div className={'enable-extension'}>
                    <div>
                        <ul className={'steps'}>
                            <li className={'step'}> <span className='step-sub-title'>
                            1.</span> Look for Birdie, tap on <span className='bold'> <span className='small'>A</span>A </span> in the search bar and then go to
                                <span className='bold'>"Manage extensions"</span>
                                <img src={extIcon} className='ext-icon' alt={'ext_icon'}  />
                                <div className='sub-text'>Can’t find <span className='small'>A</span>A? It might be at the top instead</div>
                            </li>
                            <li className={'step'} style={{position:'relative', marginTop:'20px' }}> <span className='step-sub-title'>2.</span> Toggle on "Little Birdie"
                                <img src={extEnableV3} className={'ext-enable'} />
                                <div>
                                    then select "done"
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div onClick={walkthroughClick} style={{padding: '20px 0px', pointerEvents:'auto'}}>
                        <img src={infoFilled} alt={'info-icon'} />
                        <span className='info-text'>{`${showWalkthrough ? 'Hide' : 'Show'} walkthrough`}</span>
                    </div>

                    <div className='pointer-box'>
                        <img src={birdAndArrow} className='bird-arrow' />
                    </div>
                </div>


                <div className={'walkthrough-container'} ref={walkthroughRef}>
                {
                    showWalkthrough && (
                            <img src={postInstall1} className='walkthrough' />
                    ) || null
                }

                </div>
            </div>
        </>
    )
}

export default EnableExtension
