import { combineReducers } from "redux"

import createReducer from "../../../../components/utils/createReducer"

import * as actions from "../../actions/types"

const commentsFetchSuccess = (state, { data }) => _.reverse(_.sortBy(_.uniq(_.concat(state, _.map(data.comment, item => parseInt(item.id, 10))))))
const commentSubmitSuccess = (state, { data }) => _.reverse(_.sortBy(_.uniq(_.concat(state, _.map(Object.keys(data.comment), id => parseInt(id, 10))))))
const setCommentsCount = (state, { data }) => { return data.metadata.comment_count}
const resetCommentsCount = (state) => 0;

const byId = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.COMMENTS_REQUEST_SUCCESS]: commentsFetchSuccess,
  [actions.COMMENT_SUBMIT_SUCCESS]: commentSubmitSuccess
})

const commentsCount = createReducer(0)({
  [actions.RESET_COMMENTS_COUNT]: resetCommentsCount,
  [actions.COMMENT_SUBMIT_SUCCESS]: setCommentsCount
})

const request = createReducer(null)({
  [actions.COMMENTS_REQUEST_START]: (_, { type }) => type,
  [actions.COMMENTS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.COMMENTS_REQUEST_FAILURE]: (_, { type }) => type
})

const commentSubmitFailedMessage = (state, error) => error.error

const submitCommentErrors = createReducer([])({
  [actions.COMMENT_SUBMITTED]: () => [],
  [actions.COMMENT_SUBMIT_FAILURE]: commentSubmitFailedMessage
})

export const getCommentsIds = state => _.values(state.byId)
export const getCommentsCount = state => state.commentsCount
export const isFetchingComments = state => _.includes([undefined, null, actions.COMMENTS_REQUEST_START], state.request)

export default combineReducers({
  byId,
  request,
  submitCommentErrors,
  commentsCount
})
