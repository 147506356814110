import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchNewDeals() {
  yield put(actions.newDealsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_recent_price_dropped: true,
    "by_order[column]": "last_price_date",
    "by_order[direction]": "desc",
    per_page: 8
  }
  const { response, error } = yield call(requester.getTopDeals, baseUrl, params)
  yield (response) ? put(actions.newDealsRequestSuccess(response)) : put(actions.newDealsRequestFailure(error))
}

function* fetchNewCoupons() {
  yield put(actions.newCouponsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    "by_order[column]": "published_at",
    "by_order[direction]": "desc",
    per_page: 8
  }
  const { response, error } = yield call(requester.getTopCoupons, baseUrl, params)
  yield (response) ? put(actions.newCouponsRequestSuccess(response)) : put(actions.newCouponsRequestFailure(error))
}

function* fetchNewSaleEvents() {
  yield put(actions.newSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    "by_order[column]": "published_at",
    "by_order[direction]": "desc",
    per_page: 4
  }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.newSaleEventsRequestSuccess(response)) : put(actions.newSaleEventsRequestFailure(error))
}

function* fetchTopDealHunters() {
  yield put(actions.topDealHuntersRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { per_page: 8 }
  const { response, error } = yield call(requester.getTopDealHunters, baseUrl, params)
  yield (response) ? put(actions.topDealHuntersRequestSuccess(response)) : put(actions.topDealHuntersRequestFailure(error))
}

function* fetchTopStores({ data }) {
  yield put(actions.topStoresRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_department: _.get(data, "department"),
    per_page: 8
  }
  const { response, error } = yield call(requester.getTopStores, baseUrl, params)
  yield (response) ? put(actions.topStoresRequestSuccess(response)) : yield put(actions.topStoresRequestFailure(error))
}

function* fetchTopBrands({ data }) {
  yield put(actions.topBrandsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_department: _.get(data, "department"),
    per_page: 8
  }
  const { response, error } = yield call(requester.getTopBrands, baseUrl, params)
  yield (response) ? put(actions.topBrandsRequestSuccess(response)) : yield put(actions.topBrandsRequestFailure(error))
}

function* fetchTopUpcomingSaleEvents({ data }) {
  yield put(actions.topUpcomingSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_department: _.get(data, "department"),
    by_category: _.get(data, "category"),
    upcoming: true
  }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topUpcomingSaleEventsRequestSuccess(response)) : put(actions.topUpcomingSaleEventsRequestFailure(error))
}

function* fetchData(action) {
  yield all([
    fork(fetchNewDeals, action),
    fork(fetchTopDealHunters, action),
    fork(fetchTopStores, action),
    fork(fetchTopBrands, action),
    fork(fetchNewCoupons, action),
    fork(fetchNewSaleEvents, action),
    fork(fetchTopUpcomingSaleEvents, action)
  ])
}

function* watchNewListingsPageSaga() {
  yield takeLatest(actionTypes.LOAD_NEW_LISTINGS_PAGE, fetchData)
}

export {
  watchNewListingsPageSaga
}
