import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import {
  RESOURCE_FETCHED_SUCCESS, SALE_EVENT_REQUEST_START, SALE_EVENT_REQUEST_SUCCESS, SALE_EVENT_REQUEST_FAILURE
} from "../../../actions/types"

const saleEventsFetchSuccess = (state, { data }) => ({ ...state, ...data.saleEvent })
// Top Deals
const byId = createReducer({})({
  [RESOURCE_FETCHED_SUCCESS]: saleEventsFetchSuccess
})

const getSaleEventStatusCode = (state = {}, action) => {
  switch (action.type) {
    case SALE_EVENT_REQUEST_START:
      return {}
    case SALE_EVENT_REQUEST_SUCCESS:
      return { ...action.data }
    case SALE_EVENT_REQUEST_FAILURE:
      return { ...action.error }
    default:
      return state
  }
}
export const getSaleEventById = (state, saleEventId) => state.byId[saleEventId]
export const getSaleEventBySlug = (state, slug) => _.find(state.byId, { attributes: { slug } })

export default combineReducers({
  byId,
  getSaleEventStatusCode
})
