import React, { useEffect, useRef, useState } from 'react'
import * as braze from '@braze/web-sdk';

import { LB_NID } from '../../../../../constant';
import {
    birdAndArrow,
    iconClose,
    infoFilled,
    permissionHeader,
    polygonWarn,
    postInstall2
} from '../../../../components/Icons'

const PageBadge = ({ total, current }) => {
    return (
        <div className='page-badge'>
            <span className='badge-text'>{current}/{total}</span>
        </div>
    )
}


const Permission = () => {

    const [showWalkthrough, setShowWalkthrough] = useState(false);
    const walkthroughRef = useRef(null);

    useEffect(() => {
        braze.changeUser(LB_NID);
        braze.getUser().setCustomUserAttribute('EXTENSION_INSTALLED', 'Y');
        braze.requestImmediateDataFlush();
    }, [])

    const walkthroughClick = () => {
        setShowWalkthrough(!showWalkthrough);
        if (walkthroughRef !== null && !showWalkthrough) {
            walkthroughRef?.current?.scrollIntoView({ behavior: 'smooth', block: "start", inline: "nearest" });
        } else if (showWalkthrough) {
            walkthroughRef?.current?.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
        }
    }

    return (
        <>
            <div className={'be-view enable'} style={{ marginTop: '-56px' }}>
                <div>
                    <img src={permissionHeader} alt="header" style={{ width: '100%' }} />
                    <div className='title header' style={{ top: '-15px' }}>
                        <span className={'sub-text'} style={{ fontWeight: '700' }}> Always Allow </span>
                        <div style={{ fontWeight: '700' }}>on every website</div>
                        <div className='sub-header'>To compare prices while you shop, you need to grant permission to Little Birdie</div>
                    </div>
                </div>

                <PageBadge total={2} current={2} />
                <div className={'enable-extension'}>
                    <div>
                        <ul className={'steps'} >
                            <li className={'step'}>
                                <span className='step-sub-title'>
                                    4.{' '}
                                </span>
                                Once again... tap on <span className='bold'> <span className='small'>A</span>A </span> in the search bar
                                <div className='sub-text' style={{ marginLeft: '12px' }}>Can’t find <span className='small'>A</span>A? It might be at the top instead</div>
                            </li>

                            <li className={'step'}>
                                <span className='step-sub-title'>
                                    5.{' '}
                                </span>
                                Then tap <span className='bold'> Little Birdie </span>
                                <img src={polygonWarn} height={18} width={20} style={{ marginBottom: 8 }} />
                            </li>

                            <li className={'step'}>
                                <span className='step-sub-title'>
                                    6.{' '}
                                </span>
                                Select <span className='bold'>  Always Allow </span>
                            </li>

                            <li className={'step'}>
                                <span className='step-sub-title'>
                                    7.{' '}
                                </span>
                                Select <span className='bold'>  Always Allow on every website</span>
                            </li>

                        </ul>
                    </div>

                    <div className='privacy-policy'>We do not sell your personal information. Ever. <div>It’s all in our <a className='privacy-link' to="/privacy">privacy policy.</a></div></div>

                    <div onClick={walkthroughClick} style={{ padding: '20px 0px', pointerEvents: 'auto' }}>
                        <img src={infoFilled} alt={'info-icon'} />
                        <span className='info-text'>{`${showWalkthrough ? 'Hide' : 'Show'} walkthrough`}</span>
                    </div>

                    <div className='pointer-box'>
                        <img src={birdAndArrow} className='bird-arrow' />
                    </div>
                </div>

                <div className='walkthrough-container' ref={walkthroughRef}>
                    {
                        showWalkthrough && (
                            <img src={postInstall2} className='walkthrough' />
                        ) || null
                    }
                </div>
            </div>
        </>
    )
}

export default Permission;
