/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getAllDeals = (baseUrl, data = {}) => {
  const path = `${baseUrl}/stores/${data.by_store}/all-products`

  return axios.get(path, {})
    .then(({ data, status }) => ({
      response: {
        data: normalize(data),
        ids: _.get(data, "meta.order", [])
      },
      status
    }))
    .catch(error => { error })
}

export const getTopDeals = (baseUrl, data = {}) => {
  const path = `${baseUrl}/top_lists/deals`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({
      response: {
        data: normalize(data),
        ids: _.get(data, "meta.order", [])
      },
      status
    }))
    .catch(error => { error })
}

// ToDo: Remove Deal: Method unused
// export const getTopPriceDrops = (baseUrl, data = {}) => {
//   const path = `${baseUrl}/top_lists/price_drops`

//   return axios.get(path, { params: data })
//     .then(({ data, status }) => ({ response: normalize(data), status }))
//     .catch(error => { error })
// }

export const getTopDealHunters = (baseUrl, data = {}) => {
  const path = `${baseUrl}/top_lists/deal_hunters`

  return axios.get(path, { params: data })
    .then(({ data = [], status = "" }) => ({
      response: {
        data: normalize(data),
        ids: _.get(data, "meta.order", [])
      },
      status
    }))
    .catch(error => { error })
}

export const getTopStores = (baseUrl, data = {}) => {
  const path = `${baseUrl}/top_lists/stores`

  return axios.get(path, { params: data })
    .then(({ data = [], status = "" }) => ({
      response: {
        data: normalize(data),
        ids: _.get(data, "meta.order", [])
      },
      status
    }))
    .catch(error => { error })
}

export const getTopBrands = (baseUrl, data = {}) => {
  const path = `${baseUrl}/top_lists/brands`

  return axios.get(path, { params: data })
    .then(({ data = [], status = "" }) => ({
      response: {
        data: normalize(data),
        ids: _.get(data, "meta.order", [])
      },
      status
    }))
    .catch(error => { error })
}

export const getTopCoupons = (baseUrl, data = {}) => {
  const path = `${baseUrl}/top_lists/coupons`
  let doNormalize = true
  let params = {
    ...data
  }

  if(data.hasOwnProperty('doNormalize') && !data.doNormalize) {
    doNormalize = false
    delete params['doNormalize']
  }

  return axios.get(path, { params })
    .then(({ data, status, headers }) => ({
      response: doNormalize ? {
        data: normalize(data),
        ids: _.get(data, "meta.order", [])
      }: data,
      status,
      headers
    }))
    .catch(error => { error })
}

export const getTopSaleEvents = (baseUrl, data = {}) => {
  const path = `${baseUrl}/top_lists/sale_events`
  let doNormalize = true
  let params = {
    ...data
  }

  if(data.hasOwnProperty('doNormalize') && !data.doNormalize) {
    doNormalize = false
    delete params['doNormalize']
  }

  return axios.get(path, { params })
    .then(({ data, status, headers }) => ({
      response: doNormalize ? {
        data: normalize(data),
        ids: _.get(data, "meta.order", [])
      }: data,
      status,
      headers
    }))
    .catch(error => { error })
}

export const getNewlySubmitted = (baseUrl, data = {}) => {
  const path = `${baseUrl}/top_lists/newly_submitted`

  return axios.get(path, { params: data })
    .then(({ data = [], status = "" }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}
