import React, { Component } from 'react'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class Notifications extends Component {
  render () {
    return (
      <div>
        <ToastContainer
          enableMultiContainer
          containerId="main" />

        <ToastContainer
          enableMultiContainer
          containerId="axios"
          closeButton={false}
          limit={1} />

        {/* <ToastContainer
          enableMultiContainer
          containerId="cable"
          position="bottom-right"
          className="toast-thin"
          autoClose={3000}
          limit={1} /> */}
      </div>
    )
  }
}

export default Notifications
