/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const trigerVote = (baseUrl, { type, id, direction }) => {
  const directionUrl = {
    up: "upvote",
    down: "downvote"
  }[direction]
  const resourceTypeUrl = {
    saleEvent: "SaleEvent",
    coupon: "Coupon",
    deal: "Deal"
  }[type]

  const votingUrl = `${baseUrl}/voting/${resourceTypeUrl}/${id}/${directionUrl}`

  return axios.put(votingUrl, {}).then(({ data, status }) => ({ response: normalize(data), status }))
  .catch(error => ({ error }))
}