import { useEffect } from 'react'
import { Builder } from '@builder.io/react'
import { useSelector, Provider, useDispatch } from 'react-redux'
import { getCurrentUser } from '../../../reducers'

const NIDGenerator = () => {
  const currentUser = useSelector(getCurrentUser)
  useEffect(() => {
    let nid = localStorage.getItem('lbnid')
    if (!nid) {
      nid = crypto.randomUUID()
      localStorage.setItem('lbnid', nid)
    }
    const uid = 'nid-' + nid
    if (currentUser) window.snowplow && window.snowplow('setUserId', uid + '_' + currentUser?.id)
    else window.snowplow && window.snowplow('setUserId', uid)
    return () => {}
  }, [])
  return <></>
}

Builder.registerComponent(NIDGenerator, {
  name: 'NIDGenerator',
  inputs: [],
  image: 'https://tabler-icons.io/static/tabler-icons/icons-png/a-b.png'
})
