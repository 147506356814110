import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchDealBySlug({ identifier }) {
  yield put(actions.dealRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error, status, errorMessage } = yield call(requester.getDealByIdentifier, baseUrl, identifier)
  yield (response) ? put(actions.dealRequestSuccess({ response, status, errorMessage })) : put(actions.dealRequestFailure(error))
}

function* fetchSiblingDealsBySlug({ identifier }) {
  yield put(actions.siblingDealsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getSiblingDealsByIdentifier, baseUrl, identifier)
  yield (response) ? put(actions.siblingDealsRequestSuccess(response)) : put(actions.siblingDealsRequestFailure(error))
}

function* fetchData(data) {
  yield all([
    fork(fetchDealBySlug, data),
    fork(fetchSiblingDealsBySlug, data)
  ])
}


function* watchDealPageSaga() {
  yield takeLatest(actionTypes.LOAD_DEAL_PAGE, fetchData)
}

export {
  watchDealPageSaga
}
