/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getSubcategories = (baseUrl, data = {}) => {
  const path = `${baseUrl}/subcategories`

  return axios.get(path, { params: data })
    .then(({ data = [], status = "" }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const getSubcategory = (baseUrl, subcategoryId, data = {}) => {
  const path = `${baseUrl}/subcategories/${subcategoryId}`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}