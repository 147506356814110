/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getSaleEventByIdentifier = (baseUrl, identifier) => {
  const path = `${baseUrl}/sale_events/${identifier}`
  return axios.get(path, {})
    .then(({ data, status }) => ({ response: normalize(data), status, errorMessage: data?.errors }))
    .catch(error => {
      return error
    })
}

export const getSaleEvents = (baseUrl, data = {}) => {
  const path = `${baseUrl}/sale_events`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const setSaleEventNotification = (baseUrl, data = {}) => {
  const { saleEventId, active } = data
  const path = `${baseUrl}/notifications/sale_event/${saleEventId}`

  return axios.put(path, { active })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}