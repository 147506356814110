import {
  all, call, put, select, takeLatest, fork, takeEvery
} from 'redux-saga/effects'

import * as actionTypes from '../../actions/types'
import * as actions from '../../actions'
import { getBaseUrl } from '../../reducers'
import * as requester from '../../requesters'

function* fetchDataByDep({ data }) {
  const affinityData = JSON.parse(sessionStorage.getItem('user_affinities'))
  const params = {
    affinity: data.isPersonalised ? affinityData[0]?.affinity || {} : {},
    gender: data.isPersonalised ? affinityData[0]?.gender || '' : '',
    department_names: [data?.name]
  }
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getDepartmentTopPicks, baseUrl, params)
  yield response ? put(actions.affiTopDepRequestSuccess({ response, dep: data.slug })) : put(actions.affiTopDepRequestFailure({ error, dep: data.slug}))
}

function* fetchData (data) {
  yield all([
    fork(fetchDataByDep, data)
  ])
}

function* watchAffiTopDepSaga () {
  yield takeEvery(actionTypes.AFFI_TOP_DEP_START, fetchData)
}

export {
  watchAffiTopDepSaga
}
