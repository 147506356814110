import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useInView } from 'react-intersection-observer'
import moment from 'moment'

import {
  FooterListingCounts,
  ListingTitleStoreName
} from '../../../../components/Labels'
import RankLabel from '../../../Labels/RankLabel'
import { noImage, personalisedHearts, megaPhone } from '../../../Icons'
import positionDataLayer from '../../../dataLayers/positionDataLayer'
import { USE_IN_VIEW_OPTIONS } from '../../../../../constant'

export const cardClass = {
  '% Discount': 'coupon-percentage',
  '$ Discount': 'coupon-value',
  'Free Shipping': 'coupon-free-shipping',
  'Bonus Item': 'coupon-free-gift',
  Other: 'coupon-special-offer'
}

export default function NormalCouponCard (props) {
  const {
    position,
    showRank,
    wrapperClass,
    item,
    currentSection,
    sectionPosition,
    sectionId,
    isPersonalised,
    isSponsored
  } = props
  const {
    type, id,
    attributes,
    links,
    relationships
  } = item
  const {
    name,
    couponType,
    upvotesCount, commentsCount, impressionsCount,
    offer, expireAt
  } = attributes || {}
  const {
    store
  } = relationships || {}

  const isExclusive = attributes?.isCbaExclusive?.toString() === 'true' || attributes?.is_cba_exclusive?.toString() === 'true' || attributes?.type?.toLowerCase() == 'uniquecoupon'

  const [refNormalCouponCard, inView] = useInView(USE_IN_VIEW_OPTIONS)

  useEffect(() => {
    if (inView) {
      positionDataLayer(item, 'product-impression', 'product-link', position, currentSection, sectionPosition, isPersonalised)
    }
  }, [inView])

  const getOfferText = (offer) => {
    if(offer?.includes('$')) {
      let decimalVal = offer?.split('.')[1]
      if(decimalVal && decimalVal?.substring(0, 2) === '00') {
        return offer.replace('.00','');
      }else {
        return offer
      }
    } else {
      return offer
    }
  }

  return (
    <div className={`listing-card listing-coupons ${wrapperClass}`} ref={refNormalCouponCard}>
      {(position && showRank) && (
        <RankLabel rank={position} />
      )}
      <div className={`card card-coupon ${cardClass[couponType]} ${isExclusive ? 'exclusive': ''}`}>
        <Link
          className='link-wrapper'
          to={links.couponUrl}
          onClick={() => {
            positionDataLayer(item, 'card-click', 'product-link', position, currentSection, sectionPosition, isPersonalised)
            sectionId && localStorage.setItem('hsref', sectionId)
          }}
        >
          <div className='card-img-top coupon'>
            <LazyLoadImage
              className='img-fluid store-img'
              alt={name}
              src={links.imageUrl || noImage}
            />
          </div>
          <div className='card-body'>
            <div className='coupon-title-info'>
              <div className='coupon-store'>
                <ListingTitleStoreName store={store} />
              </div>
              <div className='card-text'>
                {name}
              </div>
            </div>
            <div className='coupon-offer-info'>
              <div className='coupon-type'>
                {`${getOfferText(offer)}`}
              </div>
              <div className='d-flex align-items-center'>
                <div className='col-6 end-date-info'>{expireAt ? 'Ends ' + moment(expireAt).format('DD/MM/YYYY') : ''}</div>
                <div className='col-6 views-info p-0'>
                  <FooterListingCounts {...{
                    type, id, name, upvotesCount, commentsCount, impressionsCount, wrapperClass: 'list-inline mb-0 d-flex justify-content-around'
                  }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer'>
            <div className={`card-meta-info ${isSponsored && 'justify-content-between'}`}>
              Get Promo Code
              {isExclusive && <span className='exclusive-badge'>
                  <img src={personalisedHearts} width='16px' height='16px' />
                  Exclusive
                </span>
              }
              {isSponsored && !isExclusive &&
                <span className='sponsored-badge'>
                  <img src={megaPhone} width='16px' height='16px' />
                  Sponsored
                </span>}
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}
