import {
  all, fork
} from "redux-saga/effects"

import { watchInitSaga } from "./sagas/init"
import { watchRouterChangeLocationSaga } from "./sagas/router"
import { watchTriggerVotingSaga } from "./sagas/components/votingSagas"
import { watchTriggerFollowingSaga } from "./sagas/components/followingSaga"
import { watchSeoSaga } from "./sagas/components/seoSaga"
import { watchTriggerNotifyPricedropSagas } from "./sagas/components/notifyPricedropSagas"
import { watchSaleEventNotificationUpdateSaga } from "./sagas/components/saleEventNotificationSaga"
import { watchTriggerUsedSaga } from "./sagas/components/uniqueCodeSaga"

import watchPageSagas from "./sagas/pages"
import lookupsSagas from "./sagas/lookups"
import commentsSagas from "./sagas/comments"
import gotoSagas from './sagas/goto'

export default function* RootSaga() {
  yield all([
    // fork(watchInitSaga), // Comment CR No Need: Comment watch Init saga
    fork(watchRouterChangeLocationSaga),
    fork(watchPageSagas),
    // fork(lookupsSagas), // Comment CR No Need: Comment Categories Department saga
    fork(commentsSagas),
    fork(watchTriggerVotingSaga),
    fork(watchTriggerFollowingSaga),
    fork(watchSeoSaga),
    fork(watchTriggerNotifyPricedropSagas),
    fork(watchSaleEventNotificationUpdateSaga),
    fork(watchTriggerUsedSaga),
    fork(gotoSagas)
  ])
}
