/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"
import { REACT_APP_BE_BASE_URL } from '../../../../constant'

export const getDealByIdentifier = (baseUrl, identifier) => {
  const path = `${baseUrl}/deals/${identifier}`

  return axios.get(path, {})
    .then(({ data, status }) => ({ response: normalize(data), status, errorMessage: data?.errors }))
    .catch(error => { error })
}

export const getElasticDealByIdentifier = (baseUrl, identifier) => {
  const path = `${baseUrl}/search_elastic/offer_detail?slug=${identifier}`

  return axios.post(path, {})
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}


export const getSiblingDealsByIdentifier = (baseUrl, identifier) => {
  const path = `${baseUrl}/deals/${identifier}/siblings`

  return axios.get(path, {})
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const getDeals = (baseUrl, data = {}) => {
  const path = `${baseUrl}/deals`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getDealPriceHistory = (baseUrl, identifier) => {
  const path = `${baseUrl}/deals/${identifier}/price_history`

  return axios.get(path, {})
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const notifyDealPricedrop = (baseUrl, identifier) => {
  const path = `${baseUrl}/deals/${identifier}/notify_price_drop`

  return axios.put(path, {})
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const getDealPriceComparison = (baseUrl, identifier) => {
  const path = `${baseUrl}/deals/${identifier}/price_comparison`

  return axios.get(path, {})
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const fetchOnDemandOfferPricing = (params, timeout) => {
  const path = `${REACT_APP_BE_BASE_URL}/offer/parse-detail`

  return axios.post(path, { ...params }, 
    {timeout: timeout || 15000})
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}