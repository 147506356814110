import React from "react"
import { Route, Redirect } from "react-router-dom"
import { toast } from "react-toastify"

const toastId = "private-route-error"
const containerId = "main"
const toastOptions =  {
  toastId,
  containerId
}

const ProtectedRoute = ({
  render, component: Component, currentUser, enableNotification = true, redirectedRoute = "/", ...rest
}) => (
  <Route
    {...rest}
    render={
      props => {
        if (currentUser) {
          return <Component {...rest} {...props} />
        }
          if (enableNotification) {
            toast.error(
              <div>
                <span><i className="fa fa-exclamation mr-2" /></span>
                You must be logged in to access this page.
              </div>,
              toastOptions
            )
          }
          return (
            <Redirect to={
              {
                pathname: redirectedRoute,
                state: {
                  from: props.location
                }
              }
            } />
          )
      }
  } />
)

export default ProtectedRoute