/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getUserActivityByIdentifier = ({
  baseUrl, identifier, requestPage, requestPerPage
}) => {
  const path = `${baseUrl}/activities`

  return axios.get(path, { params: { id: identifier, page: requestPage, per_page: requestPerPage } })
    .then(({ data, status, headers }) => ({
      response: normalize(data), status, meta: data.meta, headers
    }))
    .catch(error => { error })
}