import React from 'react'
import { googleLogin } from '../../../../../components/Icons'
import { useGoogleLogin } from '@react-oauth/google'
import { gon } from '../../../../../../constant'
import axios from 'axios'
import sendAuthToExtension from '../../../../../components/utils/SendAuthToExtension'

function GoogleSignInCustom ({ prevUrl, wtidCookie, handleGoogleSignInErrors }) {
  const login = useGoogleLogin({
    /***
      * Handle Google Sucess response
    */
    onSuccess: async (googleResponseData) => {
      const path = `${gon.urls.api}/omniauth/provider/auth`
      const queryData = {
        wtid: wtidCookie,
        id_token: googleResponseData.code,
        provider: 'google_oauth2'
      }
      return axios
        .post(path, queryData)
        .then((response) => {
          if (response.status !== 200) {
            handleGoogleSignInErrors(response?.data?.errors)
            return null
          }
          window.localStorage.setItem(
            'auth_token',
            response.headers['x-auth-token']
          )
          sendAuthToExtension(response.headers['x-auth-token'])
          window.localStorage.setItem(
            'csrf_token',
            response.headers['x-csrf-token']
          )
          window.location.href =
            typeof prevUrl === 'object'
              ? prevUrl.prevUrl || '/'
              : prevUrl || '/'
          return null
        })
        .catch((error) => handleGoogleSignInErrors(error?.data?.errors))
    },
    flow: 'auth-code',
    prompt: 'select_account',
    scope: 'email profile',

    onNonOAuthError: (error) => {
      if (error?.message) handleGoogleSignInErrors('There was an error authenticating with Google, please try again.')
    },
    /***
     * Handle Google failure response
    */
    onError: (error) => {
      let errorMessage = 'There was an error authenticating with Google, please try again.'
      if (error) {
        errorMessage = error
      }
      handleGoogleSignInErrors(errorMessage)
    }

  })

  return (
    <a
      className='animate shake btn btn-block d-flex align-items-center'
      style={{ cursor: 'pointer' }}
      onClick={() => login()}
    >
      <div className='social-img mr-3'>
        <img
          style={{ width: '100%' }}
          src={googleLogin}
        />
      </div>
      <span className='social-title'>Sign in with Google</span>
    </a>
  )
}
export default GoogleSignInCustom
