import * as types from './types'

export const customWidgetsRequestStart = (data) => ({
  type: types.CUSTOMWIDGETS_PICK_REQUEST_START,
  data
})

export const customWidgetsRequestSuccess = ({ response }) => ({
  type: types.CUSTOMWIDGETS_PICK_REQUEST_SUCCESS,
  response
})

export const customWidgetsRequestFailure = error => ({
  type: types.CUSTOMWIDGETS_PICK_REQUEST_FAILURE,
  error
})
