import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import {
  RESOURCE_FETCHED_SUCCESS
} from "../../../actions/types"

const customListFetchSuccess = (state, { data }) => ({ ...state, ...data.customList })

// Top customList
const byId = createReducer({})({
  [RESOURCE_FETCHED_SUCCESS]: customListFetchSuccess
})

export const getCustomList = (state, customListId) => state.byId[customListId]
export const getCustomListById = (state, customListId) => state.byId[customListId]

export default combineReducers({
  byId
})
