import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchTags({ identifier }) {
  yield put(actions.tagRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getTags, baseUrl, identifier)
  yield (response) ? put(actions.tagRequestSuccess(response)) : put(actions.tagRequestFailure(error))
}

function* fetchTopDealHunters() {
  yield put(actions.topDealHuntersRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    per_page: 4
  }
  const { response, error } = yield call(requester.getTopDealHunters, baseUrl, params)
  yield (response) ? put(actions.topDealHuntersRequestSuccess(response)) : put(actions.topDealHuntersRequestFailure(error))
}

function* fetchTopStores() {
  yield put(actions.topStoresRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    per_page: 4
  }
  const { response, error } = yield call(requester.getTopStores, baseUrl, params)
  yield (response) ? put(actions.topStoresRequestSuccess(response)) : put(actions.topStoresRequestFailure(error))
}

function* fetchTopBrands() {
  yield put(actions.topBrandsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    per_page: 4
  }
  const { response, error } = yield call(requester.getTopBrands, baseUrl, params)
  yield (response) ? put(actions.topBrandsRequestSuccess(response)) : put(actions.topBrandsRequestFailure(error))
}

function* fetchData(action) {
  yield all([
    fork(fetchTags, action),
    fork(fetchTopDealHunters, action),
    fork(fetchTopStores, action),
    fork(fetchTopBrands, action),
  ])
}

function* watchSubmitNewListingsPageSaga() {
  yield takeLatest(actionTypes.LOAD_SUBMIT_NEW_LISTINGS_PAGE, fetchData)
}

export {
  watchSubmitNewListingsPageSaga
}