import { combineReducers } from "redux"

import * as actionTypes from "../../../actions/types"

const bySlug = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.USER_ACTIVITY_REQUEST_SUCCESS: {
      const {
        data: {
          identifier,
          data: newData,
          headers: {
            "x-page": currentPage,
            "x-total": totalCount,
            "x-total-pages": totalPages,
            "x-next-page": nextPage
          }
        }
      } = action
      const currentPageInt = parseInt(currentPage, 10)
      const totalCountInt = parseInt(totalCount, 10)
      const totalPagesInt = parseInt(totalPages, 10)
      const lastPage = nextPage === ""
      const existingData = _.get(state, `${identifier}.data`, [])
      const existingCurrentPage = _.get(state, `${identifier}.currentPage`, 1)
      // if new requested data page less then existing requested page, reset existing data with new data
      const data = (currentPageInt <= existingCurrentPage) ? newData : existingData.concat(newData)
      return {
        ...state,
        ...{
          [identifier]: {
            data,
            totalPages: totalPagesInt,
            currentPage: currentPageInt,
            totalCount: totalCountInt,
            lastPage
          }
        }
      }
    }
    case actionTypes.LOCATION_CHANGED: {
      return {}
    }
    default: {
      return state
    }
  }
}

const itemsPerPage = (state = 20) => state

export default combineReducers({
  bySlug,
  itemsPerPage
})