import * as types from './types'

export const userRequestStart = data => ({
  type: types.USER_REQUEST_START,
  data
})

export const userRequestSuccess = data => ({
  type: types.USER_REQUEST_SUCCESS,
  data
})

export const userRequestFailure = error => ({
  type: types.USER_REQUEST_FAILURE,
  error
})

export const currentUserUpdate = data => ({
  type: types.UPDATE_CURRENT_USER,
  data
})

export const isCurrentUserLoading = (data) => {
  return {
    type: types.GET_CURRENT_USER_START,
    data
  }
}

export const userAffinityRequestStart = data => ({
  type: types.USER_AFFINITY_REQUEST_START,
  data
})

export const userAffinityRequestSuccess = data => ({
  type: types.USER_AFFINITY_REQUEST_SUCCESS,
  data
})

export const userAffinityRequestFailure = error => ({
  type: types.USER_AFFINITY_REQUEST_FAILURE,
  error
})
