import * as types from './types'

// Top Category Data from Affinity
export const affiTopCategoryRequestStart = data => ({
  type: types.AFFINITY_TOP_CATEGORY_START,
  data
})

export const affiTopCategoryRequestSuccess = data => ({
  type: types.AFFINITY_TOP_CATEGORY_SUCCESS,
  data
})

export const affiTopCategoryRequestFailure = error => ({
  type: types.AFFINITY_TOP_CATEGORY_FAILURE,
  error
})
