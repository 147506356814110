import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import Loader from "../Loader"
import { labelTag } from "../Icons"
import PageSection from "../PageSection"
import positionDataLayer from '../../components/dataLayers/positionDataLayer'

class TopLists extends Component {
  renderViewAllButton = (currentSection, sectionPosition) => {
    const {
      viewAllUrl
    } = this.props

    if (viewAllUrl) {
      return (
        <Link
          className="btn btn-outline-dark rounded-pill btn-view-all"
          onClick={() => {positionDataLayer(null, 'view-all', 'view-all-link', null, currentSection, sectionPosition)}}
          to={viewAllUrl}>
          <div className='d-flex justify-content-center align-items-center ml-n3 btn-view-all'>View all</div> 
        </Link>
      )
    }
    return null
  }

  render() {
    const {
      title,
      description,
      wrapperClass,
      children,
      isLoading,
      titleClass,
      sectionClass,
      isHomePage,
      currentSection,
      sectionPosition
    } = this.props

    return (
      <Fragment>
        <PageSection
          icon={labelTag}
          iconClass="label-tag"
          title={title}
          isHomePage={isHomePage}
          viewAll={this.renderViewAllButton(currentSection, sectionPosition)}
          titleClass={titleClass}
          description={description}
          sectionClass={sectionClass}>
          {
            (isLoading) ? (
              <Loader isLoading />

            )
            : (
              <div className={`${wrapperClass}`}>
                { children }
              </div>
            )
          }
        </PageSection>
      </Fragment>
    )
 }
}

export default TopLists
