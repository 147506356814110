import React, { Component } from "react"
import { connect } from "react-redux"

import SidebarList from "../../../../components/SidebarList"
import ListingCard from "../ListingCard"

import * as reducers from "../../reducers"

class TopDealHuntersSidebarList extends Component {
  topListComponents = () => {
    const { topDealHunterIds, showAll, title } = this.props
    const itemType = "user"
    if (topDealHunterIds === undefined) { return ([]) }
    if (showAll) {
      return _.map(_.take(topDealHunterIds, topDealHunterIds.length), itemId => <ListingCard type={itemType} id={itemId} wrapperClass="" key={`${itemType}-${itemId}`} currentSection={`${title || 'TopDealHunters'}`} sectionPosition={1} />)
    }
      return _.map(_.take(topDealHunterIds, 6), itemId => <ListingCard type={itemType} id={itemId} wrapperClass="" key={`${itemType}-${itemId}`} currentSection={`${title || 'TopDealHunters'}`} sectionPosition={1} />)
    }

  render() {
    const {
      isFetchingTopDealHunters,
      title,
      description,
      showAll
    } = this.props

    return (
      <SidebarList
        title={title || "Top Spotters"}
        titleClass="mb-1"
        description={description}
        viewAllUrl="/top-spotters"
        isLoading={isFetchingTopDealHunters}
        showAll={showAll}>
        { this.topListComponents() }
      </SidebarList>
    )
  }
}

const mapStateToProps = state => ({
  topDealHunterIds: reducers.getTopDealHunterIds(state),
  isFetchingTopDealHunters: reducers.isFetchingTopDealHunters(state),
})

export default connect(mapStateToProps, {})(TopDealHuntersSidebarList)
