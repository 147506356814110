import React, { Component, createRef } from "react"
import { connect } from "react-redux"
import TopLists from "../../../../components/TopLists"
import ListingCard from "../ListingCard"
import * as reducers from "../../reducers"
import ChevronRight from "../ChevronRight"

class TopCouponsList extends Component {
  constructor() {
    super()
    this.myRef = createRef()
  }

  topListComponents = () => {
    const { topCouponIds, currentSection, sectionPosition } = this.props
    if (_.isEmpty(topCouponIds)) { return ([]) }
    return _.map(topCouponIds, (couponId, index) => <ListingCard type="coupon" id={couponId} position={index + 1} key={`top-sales-{}-${couponId}`} currentSection={currentSection} sectionPosition={sectionPosition} />)
  }

  render() {
    const {
      isFetchingTopCoupons,
      title,
      description,
      wrapperClass,
      viewAllUrl,
      department,
      category,
      subcategory,
      currentSection,
      sectionPosition
    } = this.props

    let titleName

    if (subcategory) {
      titleName = `${subcategory.attributes.name} Vouchers`
    } else if (category) {
      titleName = 'Vouchers'
    } else if (department) {
      titleName = 'Vouchers'
    } else {
      titleName = `Vouchers`
    }

    if (!_.isEmpty(this.topListComponents())) {
      return (
        <TopLists
          title={title || titleName}
          titleClass="mb-1"
          description={description || "We've browsed thousands of vouchers to bring you only the best right here."}
          isLoading={isFetchingTopCoupons}
          wrapperClass={wrapperClass || "listing-carousel"}
          viewAllUrl={viewAllUrl}
          currentSection={currentSection}
          sectionPosition={sectionPosition}
          type="vouchers">
          <div className="d-flex listing-group-carousel" ref={this.myRef}>
            <ChevronRight offset={300} ref={this.myRef} direction="left" />
            { this.topListComponents() }
            <ChevronRight offset={300} ref={this.myRef} direction="right" />
          </div>
        </TopLists>
      )
    }
    return null
  }
}

const mapStateToProps = state => ({
  topCouponIds: reducers.getTopCouponIds(state),
  isFetchingTopCoupons: reducers.isFetchingTopCoupons(state)
})

export default connect(mapStateToProps, {})(TopCouponsList)
