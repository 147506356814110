import React, { Component } from "react"

import DealHunterNormalCard from "./DealHunterNormalCard"
import DealHunterWideCard from "./DealHunterWideCard"
import CommenterCard from "./CommenterCard"
import DealHunterCenterCard from "./DealHunterCenterCard"

const ListingCardStyles = {
  normal: DealHunterNormalCard,
  comment: CommenterCard,
  wide: DealHunterWideCard,
  center: DealHunterCenterCard
}

class DealHunterCard extends Component {
  rankLabel = () => {
    const {
      position,
      item
    } = this.props

    const { attributes } = item

    const rank = position || attributes.rank

    if (rank > 50) { return (null) }
    return (<div className="deal-rank">{rank}</div>)
  }

  render() {
    const {
      wrapperClass,
      item,
      position,
      currentSection,
      sectionPosition,
      cardStyle,
      showStats,
      sectionId
    } = this.props

    const style = cardStyle || "normal"
    const DealHunterCardComponent = ListingCardStyles[style]

    if (typeof ListingCardStyles[style] !== "undefined" && style !== undefined) {
      return (
        <DealHunterCardComponent
          item={item}
          position={position}
          wrapperClass={wrapperClass}
          currentSection={currentSection}
          sectionPosition={sectionPosition}
          sectionId={sectionId}
          showStats={showStats} />
      )
    }
    return (null)
  }
}

export default DealHunterCard