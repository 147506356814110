import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Route, Switch } from 'react-router-dom'
import _ from 'lodash'

import ProtectedRoute from '../../../components/utils/ProtectedRoute'
import UnprotectedRoute from '../../../components/utils/UnprotectedRoute'
import InvalidRoute from '../../../components/utils/InvalidRoute'
import * as reducers from '../reducers'
import { initApplication, searchClearKeyword } from '../actions'
import Login from './pages/Account/Login'
import SettingsPage from './pages/profile/SettingsPage'
import ForgotPassword from './pages/Account/ForgotPassword'
import ChangePassword from './pages/Account/ChangePassword'
import ActivateExtension from './pages/ActivateExtension'
import { globals } from '../../../middlewares/AxiosNotifcationMiddleware'
import BuilderIOConfig from './pages/Builder'
// import CloseHomePage from '../CloseHomePage'

// import ExternalRoute from '../../../components/utils/ExternalRoute'
// import Register from './pages/Account/Register'
// import CalendarPage from './pages/profile/CalendarPage'
// import NewsletterPage from './pages/NewsletterPage'

// import SubmitListing from './pages/SubmitListing'
// import StorePage from './pages/StorePage'
// import BrandPage from './pages/BrandPage'

// import CmsPages from './pages/Cms/CmsPages'

// import SearchPage from './pages/SearchPage'

// import GoToInfoPage from './pages/GoToInfoPage'
// import GoToInfoPageCba from './pages/GoToInfoPageCba'
// import { DealPage, CouponPage, SaleEventPage } from './pages/ListingDetailsPage'
// import EditUsername from './pages/Account/EditUsername'
// import WelcomePage from './pages/NarrativePage'
// import DepartmentCategoryListPage from './pages/DepartmentCategoryListPage'
// import ListingPage from './pages/ListingPage'
// import RankingPage from './pages/RankingPage'
// import FollowingRankingPage from './pages/FollowingRankingPage'
// import NewListingPage from './pages/NewListingPage'
// import ActivityPage from './pages/profile/ActivityPage'
// import PublicProfilePage from './pages/profile/PublicProfilePage'
// import FollowingPage from './pages/profile/FollowingPage'

class Main extends Component {
  constructor (props) {
    super(props)
    this.state = {
      previousLocation: undefined,
      modalOpen: false
    }

    props.initApplication()
    props.searchClearKeyword()
  }

  componentDidMount () {
    globals.history = this.props.history
    const mainPreLoader = document.getElementById('mainPreLoader');
    if (mainPreLoader) {
      mainPreLoader.style.display = 'none';
    }
  }

  static getDerivedStateFromProps (props, state) {
    const modalOpen = ((props.location.state || {}).isModal || false)

    let prevLocation = props.location

    if (props.history.action !== 'POP' && !modalOpen) {
      prevLocation = props.location
    } else {
      prevLocation = state.previousLocation
    }

    return {
      ...state,
      ...{
        modalOpen,
        previousLocation: prevLocation
      }
    }
  }

  render () {
    const {
      currentUser,
      location,
      location: {
        pathname
      }
    } = this.props

    // return (
    //   <Switch>
    //     {/* LOGIN AND REGISTER ROUTES */}
    //     <UnprotectedRoute
    //       exact
    //       currentUser={currentUser}
    //       path='/login'
    //       component={() => <Login prevUrl={_.get(location, 'state.from') || '/'} />}
    //       redirectedRoute={document.refferer}
    //     />
    //     <UnprotectedRoute
    //       exact
    //       currentUser={currentUser}
    //       path='/register'
    //       component={() => <Register prevUrl={_.get(location, 'state.from') || '/'} />}
    //       redirectedRoute={document.refferer}
    //     />
    //     <UnprotectedRoute
    //       exact
    //       currentUser={currentUser}
    //       path='/users/password/new'
    //       component={ForgotPassword}
    //       redirectedRoute='/login'
    //     />
    //     <UnprotectedRoute
    //       exact
    //       currentUser={currentUser}
    //       path='/users/password/edit'
    //       component={ChangePassword}
    //       redirectedRoute='/'
    //     />
    //     <Route exact path='/shop/store/:slug' component={StorePage} />
    //     <Route exact path='/shop/brand/:slug' component={BrandPage} />
    //     <Route
    //       exact
    //       path={[
    //         '/goto/deal',
    //         '/goto/coupon',
    //         '/goto/sale_event'
    //       ]}
    //       component={GoToInfoPage} />
    //     <Route
    //       exact
    //       path={[
    //         '/cba/deal/:slug',
    //         '/cba/voucher/:slug',
    //         '/cba/sale-event/:slug'
    //       ]}
    //       component={GoToInfoPageCba}
    //     />
    //     <Route
    //       exact
    //       path='/shop/deal/:slug'
    //       component={({ match }) => (
    //         <>
    //           <DealPage match={match} location={location} />
    //         </>
    //       )} />
    //     <Route
    //       exact
    //       path={[
    //         '/shop/voucher/:slug',
    //         '/shop/coupon/:slug'
    //       ]}
    //       component={({ match }) => (
    //         <>
    //           <CouponPage match={match} location={location} />
    //         </>
    //       )} />
    //     <Route
    //       exact
    //       path='/shop/sale-event/:slug'
    //       component={({ match }) => (
    //         <>
    //           <SaleEventPage match={match} location={location} />
    //         </>
    //       )} />
    //     {/* Activate extension ios pages */}
    //     <Route
    //       exact
    //       path={[
    //         '/mobile/ext/enable',
    //         '/mobile/ext/permissions-details',
    //         '/mobile/ext/partial-permission',
    //       ]}
    //       component={props => <ActivateExtension {...props} key={window.location.pathname} />}
    //     />
    //     {/* Store/Brand ranking pages */}
    //     <Route
    //       exact
    //       path='/welcome'
    //       component={props => <WelcomePage {...props} key={window.location.pathname} />}
    //     />
    //     <Route
    //       exact
    //       path={[
    //         '/shop/store/:store/:listType',
    //         '/shop/brand/:brand/:listType'
    //       ]}
    //       component={props => <RankingPage {...props} key={window.location.pathname} />}
    //     />

    //     {/* List ranking pages */}
    //     {/* Fixed Lists */}
    //     <Route
    //       exact
    //       path={[
    //         // Root Collections Page
    //         '/collections',
    //         // With department filters
    //         '/:listType(top-features|top-deals|top-price-drops|top-sales-events|top-promo-codes|trending-brands|trending-stores|all-products)/:department?/:category?/:subcategory?',
    //         // Without department filters
    //         '/:listType(new-deals|new-price-drops|new-sales-events|new-promo-codes|trending-stores|top-spotters|trending-brands|top-upcoming-sales|top-current-sales|all-products)'
    //       ]}
    //       component={props => <RankingPage {...props} key={window.location.pathname} />}
    //     />

    //     {/* Custom Lists */}
    //     <Route
    //       exact
    //       path={[
    //         // With department filters
    //         '/collections/:listType/:department?/:category?',
    //         // Without department filters
    //         '/collections/:listType'
    //       ]}
    //       component={props => <RankingPage {...props} key={window.location.pathname} />}
    //     />

    //     <Route
    //       exact
    //       path={[
    //         '/shop/:department/view-all',
    //         '/shop/:department/:category/view-all',
    //         '/shop/:department/:category/:subcategory/view-all',
    //       ]}
    //       component={({ match }) => (
    //         <>
    //           <DepartmentCategoryListPage match={match} location={location} />
    //         </>
    //       )} />

    //     {/* Listing Pages for Home and department/category/subcategory scoping */}
    //     <Route
    //       exact
    //       path={[
    //         '/',
    //         '/shop/:department',
    //         '/shop/:department/:category',
    //         '/shop/:department/:category/:subcategory'
    //       ]}
    //       component={({ match }) => (
    //         <>
    //           <ListingPage match={match} location={location} />
    //         </>
    //       )} />

    //     <Route
    //       exact
    //       path={['/new']}
    //       component={({ match }) => (
    //         <>
    //           <NewListingPage match={match} location={location} />
    //         </>
    //       )} />

    //     {/* Protected Routes (User authentication required) */}
    //     <ProtectedRoute
    //       exact
    //       currentUser={currentUser}
    //       path='/edit_username'
    //       component={EditUsername}
    //       enableNotification={false}
    //       redirectedRoute='/login'
    //     />

    //     <ProtectedRoute
    //       exact
    //       currentUser={currentUser}
    //       path='/spotter/:slug/my-calendar'
    //       component={({ match }) => (
    //         <>
    //           <CalendarPage match={match} location={location} />
    //         </>
    //       )}
    //       enableNotification={false}
    //       redirectedRoute='/login'
    //     />

    //     <ProtectedRoute
    //       exact
    //       path='/spotter/:slug/following'
    //       currentUser={currentUser}
    //       redirectedRoute='/login'
    //       component={({ match }) => (
    //         <>
    //           <FollowingPage match={match} location={location} />
    //         </>
    //       )} />

    //     {/* Public Routes */}
    //     <Route exact path='/spotter/:slug/my-activity' component={ActivityPage} />
    //     <Route exact path='/search/:keyword' component={SearchPage} />

    //     <ProtectedRoute
    //       exact
    //       currentUser={currentUser}
    //       path='/spotter/:slug/:settings(account|my-calendar)'
    //       component={SettingsPage}
    //       redirectedRoute='/login'
    //     />
    //     <ProtectedRoute
    //       exact
    //       currentUser={currentUser}
    //       path='/spotter/:slug/account/:settings(email|public-profile-settings|delete-account)'
    //       component={SettingsPage}
    //       redirectedRoute='/login'
    //     />
    //     <ProtectedRoute
    //       exact
    //       currentUser={currentUser}
    //       path={['/spotter/:user/following/:listType(deals|vouchers|sales-events|followed-best-sellers)']}
    //       redirectedRoute='/login'
    //       component={({ match }) => (
    //         <>
    //           <FollowingRankingPage match={match} location={location} />
    //         </>
    //       )} />

    //     <ProtectedRoute
    //       exact
    //       currentUser={currentUser}
    //       path='/submit-listing'
    //       component={SubmitListing}
    //       enableNotification={false}
    //       redirectedRoute='/login'
    //     />

    //     <Route
    //       exact
    //       path='/spotter/:slug'
    //       component={({ match }) => (
    //         <>
    //           <PublicProfilePage match={match} location={location} />
    //         </>
    //       )} />

    //     <Route
    //       exact
    //       path={['/spotter/:user/:listType(deals|vouchers|sales-events|best-sellers)']}
    //       component={({ match }) => (
    //         <>
    //           <FollowingRankingPage match={match} location={location} />
    //         </>
    //       )} />

    //     <Route
    //       exact
    //       path='/newsletters/:slug'
    //       component={({ match }) => (
    //         <Fragment>
    //           <NewsletterPage match={match} location={location} />
    //         </Fragment>
    //       )} />

    //     {/* Non react HTML page links. We want to use react router to handle this link and redirect */}
    //     {/* <Route exact path='/login' render={() => { window.location.href = 'login' }} /> */}
    //     {/* <Route exact path='/logout' render={() => { window.location.href = 'logout' }} /> */}


    //     {/* External Routes */}
    //     {/* <ExternalRoute exact path='/goto/:resource_type/:resource_id' /> */}

    //     {/* CMS Routes */}
    //     {/* Builder.io Routes */}
    //     <Route path='/' render={() => <BuilderIOConfig url={pathname} />} />
    //     {/* <Route path='/' render={() => <CmsPages url={pathname} />} /> */}

    //     {/* Invalid route sends you to home page (for now) */}
    //     <Route path='/*' render={() => <InvalidRoute />} />

    //   </Switch>
    // )

    return (
      <Switch>
        <UnprotectedRoute
          exact
          currentUser={currentUser}
          path={['/auth', '/login', '/register']}
          component={() => <Login prevUrl={_.get(location, 'state.from') || '/'} />}
          redirectedRoute={document.refferer}
        />

        <UnprotectedRoute
          exact
          currentUser={currentUser}
          path='/users/password/new'
          component={ForgotPassword}
          redirectedRoute='/login'
        />

        <UnprotectedRoute
          exact
          currentUser={currentUser}
          path='/users/password/edit'
          component={ChangePassword}
          redirectedRoute='/'
        />

        <ProtectedRoute
          exact
          currentUser={currentUser}
          path='/spotter/:slug/:settings(account|my-calendar)'
          component={SettingsPage}
          redirectedRoute='/login'
        />

        <ProtectedRoute
          exact
          currentUser={currentUser}
          path='/spotter/:slug/account/:settings(email|public-profile-settings|delete-account)'
          component={SettingsPage}
          redirectedRoute='/login'
        />

        {/* Home Page */}
        {/* <Route
          exact
          path='/'
          component={() => <CloseHomePage />}
        /> */}

        {/* Extension Activation Pages */}
        <Route
          exact
          path={[
            '/mobile/ext/enable',
            '/mobile/ext/permissions-details',
            '/mobile/ext/partial-permission'
          ]}
          component={props => (
            <ActivateExtension {...props} key={window.location.pathname} />
          )}
        />

        {/* CMS & Builder.io Routes */}
        <Route 
          path='/' 
          render={() => <BuilderIOConfig url={pathname} />} 
        />

        {/* Invalid Routes */}
        <Route 
          path='/*' 
          render={() => <InvalidRoute />} 
        />
      </Switch>
    )
  }
}

const mapStateToProps = state => {
  return ({
    currentUser: reducers.getCurrentUser(state)
  })
}

export default withRouter(connect(mapStateToProps, {
  initApplication,
  searchClearKeyword
})(Main))
