import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import * as actions from "../../../actions/types"

const departmentsFetchSuccess = (state, { data }) => ({ ...state, ...data.department })

const byId = createReducer({})({
  [actions.RESOURCE_FETCHED_SUCCESS]: departmentsFetchSuccess
})

export const getDepartmentById = (state, departmentId) => state.byId[departmentId]
export const getDepartmentIds = state => Object.keys(state.byId)
export const getDepartmentBySlug = (state, slug) => _.find(state.byId, { attributes: { slug } })

export default combineReducers({
  byId
})