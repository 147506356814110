import React, { Component } from "react"
import axios from "axios"
import Meta from "../../../../components/Meta"
import { connect } from "react-redux"
import swal from "sweetalert"
import { getBaseUrl } from "../../reducers"
import { gon } from "../../../../../constant"
import { currentUserUpdate } from "../../actions"

class PublicProfileSettings extends Component {
  constructor(props) {
    super(props)
    const {
      currentUser: {
        attributes: {
          public_profile_settings: {
            show_activity_feed,
            show_followed_brands,
            show_followed_stores,
            show_followed_coupons,
            show_followed_products,
            show_followed_spotters,
            show_followed_price_drops,
            show_followed_upcoming_sales
          },
        },
      },
      about
    } = this.props

    const about_length = _.isEmpty(about) ? 0 : about.length

    this.state = {
      public_profile_settings: {
        show_activity_feed,
        show_followed_brands,
        show_followed_stores,
        show_followed_coupons,
        show_followed_products,
        show_followed_spotters,
        show_followed_price_drops,
        show_followed_upcoming_sales
      },
      charsCount: 1200 - about_length,
      buttonDesabled: true
    }
  }

  submitForm = () => {
    const {
      public_profile_settings
    } = this.state

    const { baseUrl, about } = this.props
    const path = `${baseUrl}/users/update`
    return axios.patch(path, { public_profile_settings, about })
      .then(response => {
        this.setState({ buttonDesabled: true })

        if (response.status === 200) {
            axios.get(`${baseUrl}/users/${gon.current_user.id}`)
              .then(response => {
                if (response?.data?.data) {
                  window.localStorage.setItem("current_user", JSON.stringify(response.data.data))
                  gon.current_user = response.data.data
                  this.props.currentUserUpdate(response.data.data)
                }

                return response
              })
              .catch(error => error)

          swal({
            title: "Your settings have been updated",
            button: "OK!"
          })
        }
      })
      .catch(error => (error))
  }

  handleChange = (event, type) => {
    const {
      public_profile_settings
    } = this.state

    public_profile_settings[type] = event.target.checked
    this.setState({ public_profile_settings })
    this.setState({ buttonDesabled: false })
  }

  // Handles on character change for the comment box
  handleUpdateComment = e => {
    const { aboutChange } = this.props
    // eslint-disable-next-line react/no-unused-state
    aboutChange(e.target.value)
    this.setState({ charsCount: (1200 - e.target.value.length) })
    this.setState({ buttonDesabled: false })
  }

  render() {
    const {
      currentUser: {
        attributes: {
          username,
          public_profile_settings: {
            show_activity_feed,
            show_followed_brands,
            show_followed_stores,
            show_followed_coupons,
            show_followed_products,
            show_followed_spotters,
            show_followed_price_drops,
            show_followed_upcoming_sales
          }
        }
      },
      about
    } = this.props

    const {
      charsCount,
      buttonDesabled
    } = this.state

    return (
      <section>
        <Meta
          name="My Public Profile"
          title={`Settings | ${username} | Little Birdie`}
          description="Find and Save with the best price drops, vouchers and sale events across millions of products from hundreds of stores in Fashion, Tech, Grocery, and more." />
        <h3>My public profile</h3>
        <div className="card settings-card mt-3">
          <div className="card-body px-md-4 pt-4">
            <p>
              Your feed is currently set to public so you can earn rewards and allow other users to follow you.
              Simply uncheck the buttons below if you wish to turn parts of your feed from public to private.
            </p>
            <ul className="list-unstyled my-4">
              <li className="mb-3">
                <div className="custom-control custom-checkbox">
                  <input className="custom-control-input" id="customCheck1" type="checkbox" defaultChecked={show_activity_feed} onChange={e => this.handleChange(e, "show_activity_feed")} />
                  <label className="custom-control-label" htmlFor="customCheck1">
                    My Activity Feed
                  </label>
                </div>
              </li>
              <li className="mb-3">
                <div className="custom-control custom-checkbox">
                  <input className="custom-control-input" id="customCheck2" type="checkbox" defaultChecked={show_followed_stores} onChange={e => this.handleChange(e, "show_followed_stores")} />
                  <label className="custom-control-label" htmlFor="customCheck2">
                    Stores You&apos;re following
                  </label>
                </div>
              </li>
              <li className="mb-3">
                <div className="custom-control custom-checkbox">
                  <input className="custom-control-input" id="customCheck3" type="checkbox" defaultChecked={show_followed_brands} onChange={e => this.handleChange(e, "show_followed_brands")} />
                  <label className="custom-control-label" htmlFor="customCheck3">
                    Brands You&apos;re following
                  </label>
                </div>
              </li>
              <li className="mb-3">
                <div className="custom-control custom-checkbox">
                  <input className="custom-control-input" id="customCheck4" type="checkbox" defaultChecked={show_followed_spotters} onChange={e => this.handleChange(e, "show_followed_spotters")} />
                  <label className="custom-control-label" htmlFor="customCheck4">
                    Spotters You&apos;re following
                  </label>
                </div>
              </li>
              <li className="mb-3">
                <div className="custom-control custom-checkbox">
                  <input className="custom-control-input" id="customCheck5" type="checkbox" defaultChecked={show_followed_products} onChange={e => this.handleChange(e, "show_followed_products")} />
                  <label className="custom-control-label" htmlFor="customCheck5">
                    Products You&apos;re following
                  </label>
                </div>
              </li>
              <li className="mb-3">
                <div className="custom-control custom-checkbox">
                  <input className="custom-control-input" id="customCheck6" type="checkbox" defaultChecked={show_followed_coupons} onChange={e => this.handleChange(e, "show_followed_coupons")} />
                  <label className="custom-control-label" htmlFor="customCheck6">
                    Vouchers You&apos;re following
                  </label>
                </div>
              </li>
              <li className="mb-3">
                <div className="custom-control custom-checkbox">
                  <input className="custom-control-input" id="customCheck8" type="checkbox" defaultChecked={show_followed_upcoming_sales} onChange={e => this.handleChange(e, "show_followed_upcoming_sales")} />
                  <label className="custom-control-label" htmlFor="customCheck8">
                    Upcoming Sales
                  </label>
                </div>
              </li>
              <li className="mb-3">
                <div className="custom-control custom-checkbox">
                  <input className="custom-control-input" id="customCheck7" type="checkbox" defaultChecked={show_followed_price_drops} onChange={e => this.handleChange(e, "show_followed_price_drops")} />
                  <label className="custom-control-label" htmlFor="customCheck7">
                    Price Drops, Sales & Vouchers recommended for you
                  </label>
                </div>
              </li>
            </ul>
            <hr/>
            <section className="pt-4">
              <h4>
                About yourself
              </h4>
              <div className="form-group">
                <label>
                  Tell the community a little about yourself
                </label>
                <textarea
                  id="commentBox"
                  className="form-control"
                  value={about || undefined}
                  maxLength={1200}
                  onChange={this.handleUpdateComment}
                  rows="6">
                </textarea>
                <div className="text-right">
                  <small className="text-muted">
                    {`Characters left ${charsCount}`}
                  </small>
                </div>
              </div>
            </section>
            {
              buttonDesabled
              ? (
                <button
                  className="btn btn-main rounded-pill btn-lg disabled"
                  type="submit">
                  Update
                </button>
                )
              : (
                <button
                  className="btn btn-main rounded-pill btn-lg"
                  type="submit"
                  onClick={() => this.submitForm()}>
                  Update
                </button>
                )
            }
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps, { currentUserUpdate })(PublicProfileSettings)
