import { combineReducers } from 'redux'

import createReducer from '../../../../components/utils/createReducer'

import * as actions from '../../actions/types'

const depDataFetchSuccess = (state, { data }) => { return { ...state, [data.dep]: data.response } }

const listByDep = createReducer([])({
  [actions.MERCH_TOP_DEP_SUCCESS]: depDataFetchSuccess
})

const request = createReducer({})({
  [actions.MERCH_TOP_DEP_START]: (state, action) => { return { ...state, [action.data.slug]: action.type } },
  [actions.MERCH_TOP_DEP_SUCCESS]: (state, action) => { return { ...state, [action.data.dep]: action.type } },
  [actions.MERCH_TOP_DEP_FAILURE]: (state, action) => { return { ...state, [action.data.dep]: action.type } }
})

export default combineReducers({
  request,
  listByDep
})
