import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

// Top Lists
function* fetchTopCoupons(baseUrl, params) {
  if (_.find([params], { page: 1 }) !== undefined) {
    yield put(actions.topCouponsRefresh())
  }

  yield put(actions.topCouponsRequestStart())
  const { response, error } = yield call(requester.getTopCoupons, baseUrl, params)
  yield (response) ? put(actions.topCouponsRequestSuccess(response)) : put(actions.topCouponsRequestFailure(error))
}

function* fetchTopSalesEvents(baseUrl, params) {
  // If we are requesting page 1, we clear our current list
  if (_.find([params], { page: 1 }) !== undefined) {
    yield put(actions.topSaleEventsClear())
  }

  yield put(actions.topSaleEventsRequestStart())
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topSaleEventsRequestSuccess(response)) : put(actions.topSaleEventsRequestFailure(error))
}

function* fetchTopStores(baseUrl, params) {
  if (_.find([params], { page: 1 }) !== undefined) {
    yield put(actions.topStoresRefresh())
  }
  yield put(actions.topStoresRequestStart())
  const { response, error } = yield call(requester.getTopStores, baseUrl, params)
  yield (response) ? put(actions.topStoresRequestSuccess(response)) : put(actions.topStoresRequestFailure(error))
}

function* fetchTopBrands(baseUrl, params) {
  if (_.find([params], { page: 1 }) !== undefined) {
    yield put(actions.topBrandsRefresh())
  }
  yield put(actions.topBrandsRequestStart())
  const { response, error } = yield call(requester.getTopBrands, baseUrl, params)
  yield (response) ? put(actions.topBrandsRequestSuccess(response)) : put(actions.topBrandsRequestFailure(error))
}

function* fetchTopDealhunters(baseUrl, params) {
  yield put(actions.topDealHuntersRequestStart())
  const { response, error } = yield call(requester.getTopDealHunters, baseUrl, params)
  yield (response) ? put(actions.topDealHuntersRequestSuccess(response)) : put(actions.topDealHuntersRequestFailure(error))
}

function* fetchListData({ data }) {
  const baseUrl = yield select(getBaseUrl)
  const params = {
    page: _.get(data, "page", 1),
    by_store: _.get(data, "store"),
    by_brand: _.get(data, "brand"),
    by_department: _.get(data, "department"),
    by_category: _.get(data, "category"),
    by_subcategory: _.get(data, "subcategory"),
    // Shared
    by_brands: _.get(data, "by_brands"),
    by_stores: _.get(data, "by_stores"),
    by_departments: _.get(data, "by_departments"),
    by_categories: _.get(data, "by_categories"),
    by_subcategories: _.get(data, "by_subcategories"),
    // Sale Event Scopes
    by_date_range: _.get(data, "by_date_range"),
    // Deal Scopes
    by_payment_methods: _.get(data, "by_payment_methods"),
    by_price_range: _.get(data, "by_price_range"),
    by_discount_range: _.get(data, "by_discount_range"),
    by_last_price_range: _.get(data, "by_last_price_range"),
    // Coupon Scopes
    by_coupon_types: _.get(data, "by_coupon_types"),
    "by_order[column]": _.get(data, "by_order.column"),
    "by_order[direction]": _.get(data, "by_order.direction")
  }


  if (data.current) { _.extend(params, { current: true }) }
  if (data.upcoming) { _.extend(params, { upcoming: true }) }
  yield {
    "top-promo-codes": call(fetchTopCoupons, baseUrl, params),
    "top-coupons": call(fetchTopCoupons, baseUrl, params),
    "top-sales-events": call(fetchTopSalesEvents, baseUrl, params),
    "new-sales-events": call(fetchTopSalesEvents, baseUrl, params),
    "new-promo-codes": call(fetchTopCoupons, baseUrl, params),
    "trending-stores": call(fetchTopStores, baseUrl, params),
    "top-spotters": call(fetchTopDealhunters, baseUrl, params),
    "trending-brands": call(fetchTopBrands, baseUrl, params)
  }[data.listType]
}

function* fetchBrand({ data }) {
  if (_.get(data, "brand") == null) {
    yield null
  } else {
    const baseUrl = yield select(getBaseUrl)
    yield call(requester.getBrandByIdentifier, baseUrl, _.get(data, "brand"))
  }
}

function* fetchStore({ data }) {
  if (_.get(data, "store") == null) {
    yield null
  } else {
    const baseUrl = yield select(getBaseUrl)
    yield call(requester.getStoreByIdentifier, baseUrl, _.get(data, "store"))
  }
}

function* fetchSubcategory({ data }) {
  if (_.get(data, "subcategory") == null) {
    yield null
  } else {
    const baseUrl = yield select(getBaseUrl)
    yield call(requester.getSubcategory, baseUrl, _.get(data, "subcategory"))
  }
}

// Top Brands
function* fetchTopBrandsSidebarList({ data }) {
  if (data.listType !== "trending-brands") {
    yield put(actions.topBrandsRequestStart())
    const baseUrl = yield select(getBaseUrl)

    const params = {
      by_department: _.get(data, "department"),
      per_page: 10
    }

    const { response, error } = yield call(requester.getTopBrands, baseUrl, params)
    yield (response) ? put(actions.topBrandsRequestSuccess(response)) : put(actions.topBrandsRequestFailure(error))
  }
}

// Top Stores
function* fetchTopStoresSidebarList({ data }) {
  if (data.listType !== "trending-stores") {
    yield put(actions.topStoresRequestStart())
    const baseUrl = yield select(getBaseUrl)

    const params = {
      by_department: _.get(data, "department"),
      per_page: 10
    }

    const { response, error } = yield call(requester.getTopStores, baseUrl, params)
    yield (response) ? put(actions.topStoresRequestSuccess(response)) : put(actions.topStoresRequestFailure(error))
  }
}

// Top DealHunters
function* fetchTopDealHuntersSidebarList({ data }) {
  if (data.listType !== "top-spotters") {
    yield put(actions.topDealHuntersRequestStart())
    const baseUrl = yield select(getBaseUrl)

    const params = {
      by_department: _.get(data, "department"),
      per_page: 8
    }

    const { response, error } = yield call(requester.getTopDealHunters, baseUrl, params)
    yield (response) ? put(actions.topDealHuntersRequestSuccess(response)) : put(actions.topDealHuntersRequestFailure(error))
  }
}

// Top Upcoming Sales
function* fetchTopUpcomingSaleEventsSidebarList({ data }) {
  yield put(actions.topUpcomingSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)

  const params = {
    by_department: _.get(data, "department"),
    by_category: _.get(data, "category"),
    upcoming: true,
    current: false
  }

  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield response ? put(actions.topUpcomingSaleEventsRequestSuccess(response)) : put(actions.topUpcomingSaleEventsRequestFailure(error))
}

// Top Current Sales
function* fetchTopCurrentSalesSidebarList({ data }) {
  yield put(actions.topCurrentSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)

  const params = {
    by_department: _.get(data, "department"),
    by_category: _.get(data, "category"),
    upcoming: false,
    current: true
  }

  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topCurrentSaleEventsRequestSuccess(response)) : put(actions.topCurrentSaleEventsRequestFailure(error))
}

function* fetchData(action) {
  yield all([
    fork(fetchTopBrandsSidebarList, action),
    fork(fetchTopStoresSidebarList, action),
    fork(fetchTopDealHuntersSidebarList, action),
    fork(fetchTopUpcomingSaleEventsSidebarList, action),
    fork(fetchTopCurrentSalesSidebarList, action),
    fork(fetchListData, action),
    fork(fetchBrand, action),
    fork(fetchStore, action),
    fork(fetchSubcategory, action)
  ])
}

function* watchRankingsPageSaga() {
  yield all([
    takeLatest(actionTypes.LOAD_RANKINGS_PAGE, fetchData),
    takeLatest(actionTypes.REFRESH_RANKING_LIST, fetchListData)
  ])
}

export {
  watchRankingsPageSaga
}
