import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  // categoryIcon,
  // priceDropIcon,
  // percentIcon,
  // couponIcon,
  chevronRight,
  chevronLeft,
  // postIconNew,
  // heartIcon,
  extensionIcon,
  // arrowFromLeft
} from '../../../../../components/Icons'
import { 
  Link, 
  // useHistory 
} from 'react-router-dom'
import IconBox from './IconBox'
import MobileNavBarContentBox from './MobileNavBarContentBox'
import { hideUserSlider, hideCatSlider } from '../../../actions'
// import { handleNativeRedirectWebView } from '../../../utilis/NativeDataShare'
import Loader from '../../../../../components/Loader'
import { isUserLoading } from '../../../reducers'

export default function SignedInList({ user, showExtension }) {
  const [showInitialComponent, setShowInitialComponents] = useState(true)
  const [showAccountOrDepartment, setShowAccountOrDepartment] = useState(false)
  const [showBackButton, setShowBackButton] = useState(true)
  const [activeField, setActiveField] = useState('about')
  const dispatch = useDispatch()
  const { showCatMenu } = useSelector(state => state.menu)
  const userLoading = useSelector(isUserLoading)
  
  useEffect(() => {
    if (showCatMenu) {
      setShowInitialComponents(false)
      setActiveField('categories')
      setShowAccountOrDepartment(true)
    }
  }, [showCatMenu])

  function renderAccountOrDepartment (column) {
    if(userLoading.loading) return  
    setShowInitialComponents(false)
    setActiveField(column)
    setShowAccountOrDepartment(true)
  }

  function handleBack () {
    setShowInitialComponents(true)
    setActiveField('about')
    setShowAccountOrDepartment(false)
  }

  function toggleShowBackButton () {
    setShowBackButton(!showBackButton)
  }

  function handleSliderClose () {
    dispatch(hideUserSlider())
    dispatch(hideCatSlider())
  }

  // const heartUrl = `/spotter/${user?.attributes?.slug}/following`
  // const history = useHistory()
  // const handleFollow = () => {
  //   dispatch(hideUserSlider())
  //   dispatch(hideCatSlider())
  //   history.push(heartUrl)
  // }

  // const nid = localStorage.getItem('lbnid')

  return (
    <>
      {showInitialComponent && (
        <>
          <div className='nav-side-bar'>
            <div className='user-slider-links'>
              <ul className='navbar-nav nav-tab-options'>
                <li className='nav-sidebar-item'>
                  <div className='nav-sidebar-item-wrapper d-flex justify-content-between' onClick={() => renderAccountOrDepartment('account')}>
                    <div className='d-flex'>
                      <IconBox {...{
                        title: 'Account',
                        imageClassName: 'rounded-circle',
                        hoverIcon: user.links?.avatar?.url,
                        icon: user.links?.avatar?.url
                      }}
                      />
                    </div>
                    {userLoading?.loading ? <Loader size={24} /> : <img src={chevronRight} />}
                  </div>
                </li>

                {
                  showExtension && (
                    <li className='nav-sidebar-item' >
                      <Link to='/mobile/ext/enable' className='nav-sidebar-item-wrapper d-flex align-items-center' onClick={handleSliderClose}>
                        <IconBox {...{
                          title: 'Browser Extension',
                          hoverIcon: extensionIcon,
                          icon: extensionIcon
                        }}
                        />
                        {/* <div className='new-pill'>
                          <span className="new-pill-text"> New </span>
                        </div> */}
                      </Link>
                    </li>
                  ) || null
                }

                {/* <li className='nav-sidebar-item'>
                  <div className='nav-sidebar-item-wrapper d-flex justify-content-between' onClick={() => renderAccountOrDepartment('categories')}>
                    <div className='d-flex'>
                      <IconBox {...{
                        title: 'Departments',
                        hoverIcon: categoryIcon,
                        icon: categoryIcon
                      }}
                      />
                    </div>
                    <img src={chevronRight} />
                  </div>
                </li> */}
                {/* ToDo: Remove Deal: Remove Price Drops Link */}
                {/* <li className='nav-sidebar-item'>
                  <Link to='/top-price-drops' className='nav-sidebar-item-wrapper' onClick={handleSliderClose}>
                    <IconBox {...{
                      title: 'Price Drops',
                      hoverIcon: priceDropIcon,
                      icon: priceDropIcon
                    }}
                    />
                  </Link>
                </li> */}
                {/* <li className='nav-sidebar-item'>
                  <Link to='/top-sales-events' className='nav-sidebar-item-wrapper' onClick={handleSliderClose}>
                    <IconBox {...{
                      title: 'Sale Events',
                      hoverIcon: percentIcon,
                      icon: percentIcon
                    }}
                    />
                  </Link>
                </li> */}
                {/* <li className='nav-sidebar-item'>
                  <Link to='/top-promo-codes' className='nav-sidebar-item-wrapper' onClick={handleSliderClose}>
                    <IconBox {...{
                      title: 'Promo Codes',
                      hoverIcon: couponIcon,
                      icon: couponIcon
                    }}
                    />
                  </Link>
                </li> */}
                {/* <li className='nav-sidebar-item'>
                  <Link className='nav-sidebar-item-wrapper' to={heartUrl} onClick={() => handleFollow()}>
                    <IconBox {...{
                      title: 'Following',
                      hoverIcon: heartIcon,
                      icon: heartIcon
                    }}
                    />
                  </Link>
                </li> */}
                {/* <li className='nav-sidebar-item'>
                  <Link to='/submit-listing' className='nav-sidebar-item-wrapper' onClick={handleSliderClose}>
                    <IconBox {...{
                      title: 'Post',
                      hoverIcon: postIconNew,
                      icon: postIconNew
                    }}
                    />
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className='nav-tab-content'>
            <MobileNavBarContentBox {...{
              user,
              activeField
            }}
            />
          </div>
        </>
      )}
      {showAccountOrDepartment && (
        <>
          {showBackButton && (
            <div className='d-inline-flex pl-0 mobile-nav-back'>
              <div className='btn btn-sm btn-outline-light nav-icon' onClick={() => handleBack()}>
                <img src={chevronLeft} />
              </div>
              {activeField === 'categories' && <div className='mobile-nav-heading'>Choose department</div>}
            </div>
          )}
          <MobileNavBarContentBox {...{ user, activeField, handleBackButton: toggleShowBackButton }} />
        </>
      )}
    </>
  )
}
