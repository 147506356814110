/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getAdvertisements = (baseUrl, params) => {
  const path = `${baseUrl}/marketing/adverts`

  return axios.get(path, { params })
    .then(({ data = [], status = "" }) => ({
      response: {
        data: normalize(data),
        ids: _.get(data, "meta.order", [])
      },
      status
    }))
    .catch(error => { error })
}
