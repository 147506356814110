import normalize from "json-api-normalizer"

import handleAxiosRequestErrors from "../components/utils/handleAxiosRequestErrors"

const resourceFetchedSuccess = data => ({
  type: "@@axios/DATA_RETRIEVED",
  data
})
export default class AxiosResourceMiddleware {
  constructor(store) {
    this.store = store
  }

  onRequest(config) {
    return config
  }

  onSync(promise) {
    return promise
  }

  onResponse(response) {
    if (response.status) {
      const data = normalize(JSON.parse(response.data))
      const { status } = response
      if (gon.env !== "development") handleAxiosRequestErrors(status)
      this.store.dispatch(resourceFetchedSuccess(data))
    }
    return response
  }

  onResponseError(error) {
    return error.response || {  status:error.status || "", message: error.message, data: [] }
  }
}