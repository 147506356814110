import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import * as actionTypes from "../../../actions/types"

const storesFetchSuccess = (state, { data }) => ({ ...state, ...data.store })

// Top Stores
const byId = createReducer({})({
  [actionTypes.RESOURCE_FETCHED_SUCCESS]: storesFetchSuccess,
  [actionTypes.STORE_REQUEST_SUCCESS]: storesFetchSuccess
})

export const getStoreById = (state, storeId) => state.byId[storeId]
export const getStoreBySlug = (state, slug) => _.find(state.byId, { attributes: { slug } })

export default combineReducers({
  byId
})
