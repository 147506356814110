import {
  all, call, put, select, fork, takeEvery
} from 'redux-saga/effects'

import * as actionTypes from '../../actions/types'
import * as actions from '../../actions'
import { getBaseUrl } from '../../reducers'
import * as requester from '../../requesters'

function* fetchDataByCategory ({ data }) {
  const affinityData = JSON.parse(sessionStorage.getItem('user_affinities')) || []
  const params = {
    affinity: data.isPersonalised ? affinityData[0]?.affinity || {} : {},
    gender: data.isPersonalised ? affinityData[0]?.gender || '' : '',
    category_names: [data?.name]
  }
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getCategoryTopPicks, baseUrl, params)
  yield response
    ? put(actions.affiTopCategoryRequestSuccess({ response, category: data.slug, id: data.id }))
    : put(actions.affiTopCategoryRequestFailure({ error, category: data.slug }))
}

function* fetchData (data) {
  yield all([
    fork(fetchDataByCategory, data)
  ])
}

function* watchAffiTopCategorySaga () {
  yield takeEvery(actionTypes.AFFINITY_TOP_CATEGORY_START, fetchData)
}

export {
  watchAffiTopCategorySaga
}
