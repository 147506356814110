/* eslint-disable no-unused-expressions */
import axios from "axios"

export const updateNotificationSettings = (baseUrl, data) => {
  const path = `${baseUrl}/notifications/`

  return axios.patch(path, data)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}
