import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl, getCurrentUser } from "../../reducers"
import * as requester from "../../requesters"

function* fetchActiveSaleEvent() {
  yield put(actions.calendarSaleEventActiveRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const currentUser = yield select(getCurrentUser)
  const params = {
    by_published: true,
    by_upcoming: false,
    by_active: true,
    followed_by: currentUser.id,
    "by_order[column]": "updated_at",
    "by_order[direction]": "desc"
  }
  const { response, error, order } = yield call(requester.getCalendarSaleEvents, baseUrl, params)
  yield (response) ? put(actions.calendarSaleEventActiveRequestSuccess(order)) : put(actions.calendarSaleEventActiveRequestFailure(error))
}

function* fetchUpcomingSaleEvent() {
  yield put(actions.calendarSaleEventUpcomingRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const currentUser = yield select(getCurrentUser)
  const params = {
    by_published: true,
    by_upcoming: true,
    by_active: false,
    followed_by: currentUser.id,
    "by_order[column]": "updated_at",
    "by_order[direction]": "desc"
  }
  const { response, error, order } = yield call(requester.getCalendarSaleEvents, baseUrl, params)
  yield (response) ? put(actions.calendarSaleEventUpcomingRequestSuccess(order)) : put(actions.calendarSaleEventUpcomingRequestFailure(error))
}


function* fetchData(action) {
  yield all([
    fork(fetchActiveSaleEvent, action),
    fork(fetchUpcomingSaleEvent, action),
  ])
}

function* watchSaleEventCalendarPageSaga() {
  yield takeLatest(actionTypes.LOAD_SALE_EVENT_CALENDAR_PAGE, fetchData)
}


export {
  watchSaleEventCalendarPageSaga
}