import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import {
  RESOURCE_FETCHED_SUCCESS, COUPON_REQUEST_FAILURE, COUPON_REQUEST_START, COUPON_REQUEST_SUCCESS
} from "../../../actions/types"

const couponsFetchSuccess = (state, { data }) => ({ ...state, ...data.coupon })

// Top Deals
const byId = createReducer({})({
  [RESOURCE_FETCHED_SUCCESS]: couponsFetchSuccess
})
const getCouponStatusCode = (state = {}, action) => {
  switch (action.type) {
    case COUPON_REQUEST_START:
      return {}
    case COUPON_REQUEST_SUCCESS:
      return { ...action.data }
    case COUPON_REQUEST_FAILURE:
      return { ...action.error }
    default:
      return state
  }
}
export const getCouponById = (state, couponId) => state.byId[couponId]
export const getCouponBySlug = (state, slug) => _.find(state.byId, { attributes: { slug } })

export default combineReducers({
  byId,
  getCouponStatusCode
})
