import React from "react"
import { TopStoresMobileSidebarList } from "../../listings"

export default function StoreField() {
    return (
      <>
        <div className="side-block-field">
          <div className="side-block-title bg-white mobile-nav-store">
            <TopStoresMobileSidebarList title="Trending Stores" isMobileNavBar />
          </div>
        </div>
      </>
    )
}
