import {
  all, fork
} from "redux-saga/effects"

import { watchListingsPageSaga } from "./ListingsSagas"
import { watchNewListingsPageSaga } from "./NewListingsSagas"
import { watchRankingsPageSaga } from "./RankingsSagas"
import { watchStorePageSaga } from "./StoreSagas"
import { watchBrandPageSaga } from "./BrandSagas"
import { watchDealPageSaga } from "./DealSagas"
import { watchCouponPageSaga } from "./CouponSagas"
import { watchSaleEventPageSaga } from "./SaleEventSagas"
import { watchProfileActivityPageSaga } from "./ProfileActivitySagas"
import { watchSubmitNewListingsPageSaga } from "./SubmitNewListingsSagas"
import { watchProfileFollowingPageSaga } from "./ProfileFollowingSagas"
import { watchUserActivityRequestSaga } from "./UserActivitiesSaga"
import { watchSearchKeywordUpdate } from "./searchSagas"
import { watchFollowingRankingsPageSaga } from "./FollowingRankingsSagas"
import { watchSaleEventCalendarPageSaga } from "./saleEventCalendarSagas"
import { watchSaleEventCalendarRemoveSaleEventSaga } from "./calendarPageRemoveSaleEventSaga"
import { watchPublicProfilePageSaga } from "./PublicProfileSagas"
import { watchUserRequestSaga } from './UserSaga'
import { watchAffiTopDepSaga } from './AffiTopDepSaga'
import { watchMerchTopDepSaga } from './MerchTopDepSaga'
import { watchAffiTopCategorySaga } from './AffinityTopCategorySaga'
import { watchCustomWidgetySaga } from './CustomWidgetSaga'
import { watchDepartmentCategoryListSaga } from './DepartmentCategoryListSaga'

export default function* RootSaga() {
  yield all([
    fork(watchListingsPageSaga),
    fork(watchNewListingsPageSaga),
    fork(watchSubmitNewListingsPageSaga),
    fork(watchRankingsPageSaga),
    fork(watchStorePageSaga),
    fork(watchBrandPageSaga),
    fork(watchDealPageSaga),
    fork(watchCouponPageSaga),
    fork(watchSaleEventPageSaga),
    fork(watchProfileActivityPageSaga),
    fork(watchProfileFollowingPageSaga),
    fork(watchSearchKeywordUpdate),
    fork(watchUserActivityRequestSaga),
    fork(watchFollowingRankingsPageSaga),
    fork(watchSaleEventCalendarPageSaga),
    fork(watchSaleEventCalendarRemoveSaleEventSaga),
    fork(watchPublicProfilePageSaga),
    fork(watchUserRequestSaga),
    fork(watchAffiTopDepSaga),
    fork(watchMerchTopDepSaga),
    fork(watchAffiTopCategorySaga),
    fork(watchCustomWidgetySaga),
    fork(watchDepartmentCategoryListSaga)
  ])
}
