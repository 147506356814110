import React, { Component } from 'react'
import { connect } from 'react-redux'

import Loader from '../../../../../../components/Loader'
import Meta from '../../../../../../components/Meta'
import Sidebar from '../Sidebar'
import ProfileSettings from '../../../ProfileSettings'
import { getCurrentUser } from '../../../../reducers'

class SettingsPage extends Component {
  renderMainContent () {
    const {
      currentUser,
      match: {
        params: {
          settings
        }
      }
    } = this.props

    if (currentUser === null) return (<Loader isLoading />)

    return (<ProfileSettings type={settings} currentUser={currentUser} />)
  }

  render () {
    const {
      currentUser
    } = this.props

    return (
      <div className="settings-page">
        <Meta
          title="LittleBirdie.com.au - The Best Price Drops, Vouchers, Sales and Discounts"
          name="My Account Details"
          description={`See ${currentUser?.attributes?.username} on Little Birdie. See all their price drops, price drops, votes, vouchers, sale events and more! `} />
        <div className="container">
          <div className="row main-wrapper">
            <div className="col-lg-3 sidebar">
              <Sidebar />
            </div>
            <div className="col ml-auto main-content">
              { this.renderMainContent() }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state)
})

export default connect(mapStateToProps, {})(SettingsPage)
