import {
  call, put, select, takeLatest
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* submitComment({ data }) {
  yield put(actions.commentSubmitRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, metadata, status, error } = yield call(requester.postComment, baseUrl, data)
  yield (status === 201) ? put(actions.commentSubmitRequestSuccess({...response, metadata})): put(actions.commentSubmitRequestFailure(error))
}

function* watchCommentSubmitSaga() {
  yield takeLatest(actionTypes.COMMENT_SUBMITTED, submitComment)
}

export {
  watchCommentSubmitSaga
}