import { combineReducers } from "redux"

import * as actionTypes from "../../actions/types"

function activeSaleEventIds(state = [], action) {
  switch (action.type) {
    case actionTypes.CALENDAR_REMOVE_SALE_EVENT:
      return _.filter(state, id => id !== parseInt(action.id, 10))
    case actionTypes.CALENDAR_SALE_EVENT_ACTIVE_REQUEST_SUCCESS:
    case actionTypes.CALENDAR_UPDATE_ACTIVE_LIST:
      return action.data
    case actionTypes.CALENDAR_SALE_EVENT_ACTIVE_REQUEST_FAILURE:
      return []
    default:
      return state
  }
}

function activeSaleEventRequesting(state = false, action) {
  switch (action.type) {
    case actionTypes.LOAD_SALE_EVENT_CALENDAR_PAGE:
      return true
    case actionTypes.CALENDAR_SALE_EVENT_ACTIVE_REQUEST_SUCCESS:
    case actionTypes.CALENDAR_SALE_EVENT_ACTIVE_REQUEST_FAILURE:
      return false
    default:
      return state
  }
}

function upcomingSaleEventIds(state = [], action) {
  switch (action.type) {
    case actionTypes.CALENDAR_REMOVE_SALE_EVENT:
      return _.filter(state, id => id !== parseInt(action.id, 10))
    case actionTypes.CALENDAR_SALE_EVENT_UPCOMING_REQUEST_SUCCESS:
      case actionTypes.CALENDAR_UPDATE_UPCOMING_LIST:
      return action.data
    case actionTypes.CALENDAR_SALE_EVENT_UPCOMING_REQUEST_FAILURE:
      return []
    default:
      return state
  }
}

function upcomingSaleEventRequesting(state = false, action) {
  switch (action.type) {
    case actionTypes.LOAD_SALE_EVENT_CALENDAR_PAGE:
      return true
    case actionTypes.CALENDAR_SALE_EVENT_UPCOMING_REQUEST_SUCCESS:
    case actionTypes.CALENDAR_SALE_EVENT_UPCOMING_REQUEST_FAILURE:
      return false
    default:
      return state
  }
}

export default combineReducers({
  activeSaleEventIds,
  activeSaleEventRequesting,
  upcomingSaleEventIds,
  upcomingSaleEventRequesting
})