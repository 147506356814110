import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'

import TopLists from '../../../../components/TopLists'
import { DealCard } from "../../../../components/ElasticListingCards"
import * as reducers from '../../reducers'
import ChevronRight from '../ChevronRight'

class AllProductsList extends Component {
  constructor () {
    super()
    this.myRef = createRef()
  }

  topListComponents () {
    const { currentSection, sectionPosition, topDealList } = this.props
    const _topDealList = topDealList && topDealList.map(deal => { return { ...deal, deal_views: 0 } })
    if (_.isEmpty(_topDealList)) { return ([]) }
    return _.map(_.take(_topDealList, 8), (deal, index) =>
      <DealCard
        deal={deal}
        currentSection={currentSection}
        sectionPosition={sectionPosition}
        key={`deal-${deal.id}`}
      />
    )
  }

  render () {
    const {
      isFetchingTopPriceDrops,
      title,
      description,
      wrapperClass,
      viewAllUrl,
      department,
      category,
      subcategory,
      store,
      brand,
      currentSection,
      sectionPosition
    } = this.props

    let titleName

    if (subcategory) {
      titleName = subcategory.attributes.name
    } else if (category) {
      titleName = category.attributes.name
    } else if (department) {
      titleName = department.attributes.name
    } else {
      titleName = ''
    }

    if (!_.isEmpty(this.topListComponents())) {
      return (
        <TopLists
          title={title || `Discover More Products from ${titleName}`}
          description={description || `Browse ${store?.attributes?.productsCount || brand?.attributes?.productsCount} products.`}
          isLoading={isFetchingTopPriceDrops}
          wrapperClass={wrapperClass || 'row'}
          currentSection={currentSection}
          sectionPosition={sectionPosition}
          viewAllUrl={viewAllUrl}
          titleClass='mb-1'
          type='price drops'
        >
          <div className='d-flex listing-group-carousel' ref={this.myRef}>
            <ChevronRight offset={300} ref={this.myRef} direction='left' />
            {this.topListComponents()}
            <ChevronRight offset={300} ref={this.myRef} direction='right' />
          </div>
        </TopLists>
      )
    }
    return null
  }
}

const mapStateToProps = state => ({
  topPriceDropIds: reducers.getTopDealIds(state),
  isFetchingTopPriceDrops: reducers.isFetchingTopDeals(state)
})

export default connect(mapStateToProps, {})(AllProductsList)
