import { combineReducers } from "redux"
import createReducer from "../../../components/utils/createReducer"

import * as actionTypes from "../actions/types"

export const keyword = createReducer("")({
  [actionTypes.SEARCH_SET_KEYWORD]: (state, { data }) => {
    if (state?.searchTerm !== data?.searchTerm || !_.isEqual(state?.searchMeta, data?.searchMeta)) {
      return data
    }
    return state
  },
  [actionTypes.SEARCH_GET_KEYWORD]: (state, { data }) => data,
  [actionTypes.SEARCH_CLEAR_KEYWORD]: () => ""
})

export const inputSearchActive = createReducer(false)({
  [actionTypes.SEARCH_SET_ACTIVE]: (state, { data }) => data
})

export const searchOverlayOpen = createReducer(false)({
  [actionTypes.SEARCH_SET_ISOVERLAY_OPEN]: (state, { data = { isOpen: false } }) => data
})

function requestState(state = "init", action, itemType) {
  if (action.itemType !== itemType) return state
  switch (action.type) {
    case actionTypes.SEARCH_REQUEST_SUCCESS:
      return "request_success"
    case actionTypes.SEARCH_REQUEST_FAILURE:
      return "request_failed"
    case actionTypes.SEARCH_SET_KEYWORD:
    case actionTypes.SEARCH_CLEAR_KEYWORD:
      return "init"
    default:
      return state
  }
}

function totalCount(state = null, action, itemType) {
  if (action.itemType !== itemType) return state
  switch (action.type) {
    case actionTypes.SEARCH_REQUEST_SUCCESS:
      return parseInt(action.data.headers["x-total"], 10)
    case actionTypes.SEARCH_REQUEST_FAILURE:
    case actionTypes.SEARCH_SET_KEYWORD:
    case actionTypes.SEARCH_CLEAR_KEYWORD:
      return null
    default:
      return state
  }
}

function currentPage(state = null, action, itemType) {
  if (action.itemType !== itemType) return state
  switch (action.type) {
    case actionTypes.SEARCH_REQUEST_SUCCESS:
      return parseInt(action.data.headers["x-page"], 10)
    case actionTypes.SEARCH_REQUEST_FAILURE:
    case actionTypes.SEARCH_SET_KEYWORD:
    case actionTypes.SEARCH_CLEAR_KEYWORD:
      return null
    default:
      return state
  }
}

function nextPage(state = null, action, itemType) {
  if (action.itemType !== itemType) return state
  switch (action.type) {
    case actionTypes.SEARCH_REQUEST_SUCCESS:
      if (Number.isNaN(parseInt(action.data.headers["x-next-page"], 10))) return null
      return parseInt(action.data.headers["x-next-page"], 10)
    case actionTypes.SEARCH_REQUEST_FAILURE:
    case actionTypes.SEARCH_SET_KEYWORD:
    case actionTypes.SEARCH_CLEAR_KEYWORD:
      return null
    default:
      return state
  }
}

function totalPages(state = null, action, itemType) {
  if (action.itemType !== itemType) return state
  switch (action.type) {
    case actionTypes.SEARCH_REQUEST_SUCCESS:
      return parseInt(action.data.headers["x-total-pages"], 10)
    case actionTypes.SEARCH_REQUEST_FAILURE:
    case actionTypes.SEARCH_SET_KEYWORD:
    case actionTypes.SEARCH_CLEAR_KEYWORD:
      return null
    default:
      return state
  }
}

function order(state = [], action, itemType) {
  if (action.itemType !== itemType) return state
  switch (action.type) {
    case actionTypes.SORT:
      if (action.order) {
        return _.reverse(_.sortBy(action.data[itemType], [action.sort_by]))
      }
      return _.sortBy(action.data[itemType], [action.sort_by])
    case actionTypes.SEARCH_REQUEST_SUCCESS:
      return action.data.order
    case actionTypes.SEARCH_REQUEST_FAILURE:
    case actionTypes.SEARCH_SET_KEYWORD:
    case actionTypes.SEARCH_CLEAR_KEYWORD:
      return []
    default:
      return state
  }
}

const items = itemType => combineReducers({
  requestState: (state, action) => requestState(state, action, itemType),
  totalCount: (state, action) => totalCount(state, action, itemType),
  totalPages: (state, action) => totalPages(state, action, itemType),
  currentPage: (state, action) => currentPage(state, action, itemType),
  nextPage: (state, action) => nextPage(state, action, itemType),
  order: (state, action) => order(state, action, itemType),
})

export const searchItems = ["deals", "sale_events", "coupons", "stores", "brands"]

const itemReducers = () => {
  const reducers = {}
  searchItems.forEach(item => {
    reducers[item] = items(item)
  })
  return reducers
}

const results = combineReducers(itemReducers())

const loadPerPage = (state = 20) => state
const resultCountCap = (state = 100) => state

export default combineReducers({
  loadPerPage,
  resultCountCap,
  keyword,
  results,
  inputSearchActive,
  searchOverlayOpen
})
