import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { expandMore, expandLess } from './Icons';

const ReadMore = ({ description = '', className = '' }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollHeight > textRef.current.clientHeight + 2);
    }
  }, [description]); // Re-run this effect if the description changes

  const toggleReadMore = () => {
    setIsExpanded(prev => !prev);
  };

  if (!description) return null;

  return (
    <div className={className}>
      <p
        ref={textRef}
        style={{
          // overflow: isExpanded ? 'scroll' : 'hidden',
          // maxHeight: isExpanded ? '120px' : 'none', // Adjusted for 3 lines
          overflow: 'hidden',
          display: '-webkit-box',
          lineClamp: isExpanded ? 'unset' : 3,
          WebkitLineClamp: isExpanded ? 'unset' : 3,
          WebkitBoxOrient: 'vertical',
          overflow: isExpanded ? 'visible' : 'hidden', // Adjust for iOS compatibility
          textOverflow: 'ellipsis',
          // paddingRight: '7px',
        }}
      >
        {description}
      </p>
      {isTruncated && (
        <Button className='p-0 m-0' style={{ fontSize: '14px', lineHeight: '16.8px' }} variant="link" onClick={toggleReadMore}>
          {isExpanded ? 'View less' : 'View more'}
          <img src={isExpanded ? expandLess : expandMore} alt='expand' className='ml-2' />
        </Button>
      )}
    </div>
  );
};

export default ReadMore;
