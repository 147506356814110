import React, { Component, createRef } from "react"
import { connect } from "react-redux"
import TopLists from "../../../../components/TopLists"
import ListingCard from "../ListingCard"
import * as reducers from "../../reducers"
import { getTopSaleEvents } from "../../requesters"
import ChevronRight from "../ChevronRight"

class TopSalesEventsList extends Component {
  constructor() {
    super()
    this.myRef = createRef()
  }

  state = {
    ids: []
  }

  async componentDidMount() {
    const { department, baseUrl, isHomePage } = this.props
    const params = {
      by_department: _.get(department, "attributes.slug"),
      per_page: 10
    }

    if (department && isHomePage) {
      const { response: { ids } } = await getTopSaleEvents(baseUrl, params)
      this.setState({ ids })
    }
  }

  renderHomePageTopSaleEventList = () => {
    const { currentSection, sectionPosition } = this.props
    const { ids } = this.state
    if (_.isEmpty(ids)) { return ([]) }
    return (

      <div className="d-flex listing-group-carousel" ref={this.myRef}>
        <ChevronRight offset={300} ref={this.myRef} direction="left" />
        {_.map(ids, (saleEventId, index) => <ListingCard type="saleEvent" id={saleEventId} wrapperClass="" position={index + 1} key={`top-sales-{}-${saleEventId}`} currentSection={currentSection} sectionPosition={sectionPosition} />)}
        <ChevronRight offset={300} ref={this.myRef} direction="right" />
      </div>
    )
  }

  topListComponents = () => {
    const { topSaleEventIds, currentSection, sectionPosition } = this.props
    if (_.isEmpty(topSaleEventIds)) { return ([]) }
    return (
      <div className="d-flex listing-group-carousel" ref={this.myRef}>
        <ChevronRight offset={300} ref={this.myRef} direction="left" />
        {_.map(topSaleEventIds, (saleEventId, index) => <ListingCard type="saleEvent" id={saleEventId} wrapperClass="" position={index + 1} key={`top-sales-{}-${saleEventId}`} currentSection={currentSection} sectionPosition={sectionPosition} />)}
        <ChevronRight offset={300} ref={this.myRef} direction="right" />
      </div>
    )
  }

  render() {
    const {
      isFetchingTopSaleEvents,
      title,
      description,
      wrapperClass,
      viewAllUrl,
      department,
      category,
      subcategory,
      isHomePage,
      currentSection,
      sectionPosition
    } = this.props

    let titleName

    if (subcategory) {
      titleName = `${subcategory.attributes.name} Sales`
    } else if (category) {
      titleName = 'Sales'
    } else if (department) {
      titleName = 'Sales'
    } else {
      titleName = `Trending Sales`
    }

    if (!_.isEmpty(this.topListComponents())) {
      return (
        <TopLists
          title={title || titleName}
          description={description || "Be quick, dive into the hottest sales."}
          isLoading={isFetchingTopSaleEvents}
          wrapperClass={wrapperClass}
          viewAllUrl={viewAllUrl || "/top-sales-events"}
          titleClass="mb-1"
          isHomePage={isHomePage}
          currentSection={currentSection}
          sectionPosition={sectionPosition}
          type="sales">
          { isHomePage ? this.renderHomePageTopSaleEventList() : this.topListComponents() }
        </TopLists>
      )
    }
    return null
  }
}

const mapStateToProps = state => ({
  topSaleEventIds: reducers.getTopSaleEventIds(state),
  isFetchingTopSaleEvents: reducers.isFetchingTopSaleEvents(state),
  baseUrl: reducers.getBaseUrl(state)
})

export default connect(mapStateToProps, {})(TopSalesEventsList)
