import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchBrandBySlug({ data }) {
  yield put(actions.brandRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getBrandByIdentifier, baseUrl, data.brand)
  yield response ? put(actions.brandRequestSuccess(response)) : put(actions.brandRequestFailure(error))
}

function* fetchTopDeals({ data }) {
  yield put(actions.topDealsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { by_brand: _.get(data, "brand") }
  const { response, error } = yield call(requester.getTopDeals, baseUrl, params)
  yield response ? put(actions.topDealsRequestSuccess(response)) : put(actions.topDealsRequestFailure(error))
}

function* fetchTopCoupons({ data }) {
  yield put(actions.topCouponsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { by_brand: _.get(data, "brand") }
  const { response, error } = yield call(requester.getTopCoupons, baseUrl, params)
  yield response ? put(actions.topCouponsRequestSuccess(response)) : put(actions.topCouponsRequestFailure(error))
}

function* fetchTopSaleEvents({ data }) {
  yield put(actions.topSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { by_brand: _.get(data, "brand") }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield response ? put(actions.topSaleEventsRequestSuccess(response)) : put(actions.topSaleEventsRequestFailure(error))
}

function* fetchTopDealHunters({ data }) {
  yield put(actions.topDealHuntersRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_brand: _.get(data, "brand"),
    per_page: 8
  }
  const { response, error } = yield call(requester.getTopDealHunters, baseUrl, params)
  yield (response) ? put(actions.topDealHuntersRequestSuccess(response)) : put(actions.topDealHuntersRequestFailure(error))
}

function* fetchTopStores({ data }) {
  yield put(actions.topStoresRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_brand: _.get(data, "brand"),
    per_page: 8
  }
  const { response, error } = yield call(requester.getTopStores, baseUrl, params)
  yield (response) ? put(actions.topStoresRequestSuccess(response)) : put(actions.topStoresRequestFailure(error))
}

function* fetchTopUpcomingSaleEvents({ data }) {
  yield put(actions.topUpcomingSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_brand: _.get(data, "brand"),
    upcoming: true
  }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topUpcomingSaleEventsRequestSuccess(response)) : put(actions.topUpcomingSaleEventsRequestFailure(error))
}

function* fetchData(data) {
  yield all([
    fork(fetchBrandBySlug, data),
    fork(fetchTopDeals, data),
    fork(fetchTopCoupons, data),
    fork(fetchTopSaleEvents, data),
    fork(fetchTopDealHunters, data),
    fork(fetchTopStores, data),
    fork(fetchTopUpcomingSaleEvents, data),
  ])
}

function* watchBrandPageSaga() {
  yield takeLatest(actionTypes.LOAD_BRAND_PAGE, fetchData)
}

export {
  watchBrandPageSaga
}