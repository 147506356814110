import React from "react"
import { Redirect } from "react-router-dom"


const LoginRedirect = ({
  prevUrl = "/",
  message = "You must be logged in to view this content",
  backCount = -1,
  isCba,
  url
}) => {
  url = isCba ? url = "/register" : url = "/login"
  return <Redirect
    to={{
      pathname: url,
      state: {
        from: {
          prevUrl,
          message,
          backCount,
          isCba
        }
      }
    }} />
  }

export default LoginRedirect
