import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import {
  ACTIVE_TICKERS_REQUEST_SUCCESS,
  LOCATION_CHANGED
} from "../../../actions/types"

// Tickers
const activeTickersFetchSuccess = (state, { data }) => ({ ...state, ...data.ticker })

const byId = createReducer([])({
  [ACTIVE_TICKERS_REQUEST_SUCCESS]: activeTickersFetchSuccess,
  [LOCATION_CHANGED]: () => ({})
})

export const getTickerById = (state, tickerId) => state.byId[tickerId]

export default combineReducers({
  byId
})
