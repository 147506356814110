import React from 'react'
import { toast } from 'react-toastify'

import messages from './errorMessage'

const toastId = 'axios-message'
const containerId = 'axios'
const toastOptions = {
  toastId,
  containerId
}

export default class AxiosNotifcationMiddleware {
  onRequest (config) {
    return config
  }

  onSync (promise) {
    return promise
  }

  onResponse (response) {
    const data = JSON.parse(response?.data || '{}')
    const notice = _.get(data, 'meta.notice')

    if (notice !== undefined) {
      toast.success(
        <div>
          <span><i className='fa fa-exclamation mr-2' /></span>
          {notice}
        </div>,
        toastOptions
      )
    }
    return response || {}
  }

  onResponseError (error) {
    const data = JSON.parse(error?.response?.data || '{}')

    if (data.message !== undefined && data.id === undefined) {
      toast.error(
        <div>
          <span><i className='fa fa-exclamation mr-2' /></span>
          {data.message}
        </div>,
        toastOptions
      )
    } else if (error.code === 'ECONNABORTED') {
      toast.error(
        <div>
          <span><i className='fa fa-exclamation mr-2' /></span>
          Looks like the server is taking to long to respond, please try again in sometime
        </div>,
        toastOptions
      )
    } else if (!error.response) {
      console.log(messages.No_Response)
    } else if (error.response && messages[`${error.response.status}`]) {
      if (error.response.status !== 401 || error.response.status !== 408) {
        toast.error(
          <div>
            <span><i className='fa fa-exclamation mr-2' /></span>
            {messages[error.response.status]}
          </div>,
          toastOptions
        )
      } else {
        console.log(error.response)
      }
    } else if (error.response && error.response.status === 404) {
      globals.history.push('/not-found')
    } else if (error.response && error.response.status >= 500) {
      console.log(messages.server_error)
    } else {
      toast.error(
        <div>
          <span><i className='fa fa-exclamation mr-2' /></span>
          {messages.unknown}
        </div>,
        toastOptions
      )
    }

    return error.response || { status: error.status || '', message: error.message, data: [] }
  }
}
export const globals = { history: {} }
