import React from 'react'

import messageOne from '../../../assets/images/message/500_message_1.png'
import messageTwo from '../../../assets/images/message/500_message_2.png'
import messageThree from '../../../assets/images/message/500_message_3.png'
import Bird from '../../../assets/images/branding/Bird.svg'

import './styles.css'

export default function Page500 () {
  return (
    <div className='rails-default-error-page'>
      <section className='page-container'>
        <img className='logo p-bottom-16' src={Bird} alt='bird' />
        <img className='img' src={messageOne} alt='our feathers are a bit ruffled' />
        <img className='img' src={messageTwo} alt='we&apos;ll be back' />
        <img className='img' src={messageThree} alt='online soon' />
      </section>
    </div>
  )
}
