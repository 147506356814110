import React from "react"
import { TopBrandsMobileSidebarList } from "../../listings"

export default function BrandField() {
    return (
      <>
        <div className="side-block-field">
          <div className="side-block-title bg-white mobile-nav-store">
            <TopBrandsMobileSidebarList title="Trending Brands" isMobileNavBar />
          </div>
        </div>
      </>
    )
}
