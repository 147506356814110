import * as types from "./types"

// Top Deals
export const topDealsRequestStart = data => ({
  type: types.TOP_DEALS_REQUEST_START,
  data
})

export const topDealsRequestSuccess = data => ({
  type: types.TOP_DEALS_REQUEST_SUCCESS,
  data
})

export const topDealsRequestFailure = error => ({
  type: types.TOP_DEALS_REQUEST_FAILURE,
  error
})

export const topDealsRefresh = data => ({
  type: types.TOP_DEALS_REFRESH,
  data
})

// NEW Deals
export const newDealsRequestStart = data => ({
  type: types.NEW_DEALS_REQUEST_START,
  data
})

export const newDealsRequestSuccess = data => ({
  type: types.NEW_DEALS_REQUEST_SUCCESS,
  data
})

export const newDealsRequestFailure = error => ({
  type: types.NEW_DEALS_REQUEST_FAILURE,
  error
})

export const newDealsRefresh = () => ({
  type: types.NEW_DEALS_REFRESH
})

// PriceDrops
export const topPriceDropsRequestStart = data => ({
  type: types.TOP_PRICE_DROPS_REQUEST_START,
  data
})

export const topPriceDropsRequestSuccess = data => ({
  type: types.TOP_PRICE_DROPS_REQUEST_SUCCESS,
  data
})

export const topPriceDropsRequestFailure = error => ({
  type: types.TOP_PRICE_DROPS_REQUEST_FAILURE,
  error
})

// Cashbacks
export const topCashBacksRequestStart = data => ({
  type: types.TOP_CASH_BACKS_REQUEST_START,
  data
})

export const topCashBacksRequestSuccess = data => ({
  type: types.TOP_CASH_BACKS_REQUEST_SUCCESS,
  data
})

export const topCashBacksRequestFailure = error => ({
  type: types.TOP_CASH_BACKS_REQUEST_FAILURE,
  error
})

// Stores
export const topStoresRequestStart = data => ({
  type: types.TOP_STORES_REQUEST_START,
  data
})

export const topStoresRequestSuccess = data => ({
  type: types.TOP_STORES_REQUEST_SUCCESS,
  data
})

export const topStoresRequestFailure = error => ({
  type: types.TOP_STORES_REQUEST_FAILURE,
  error
})

// Brands
export const topBrandsRequestStart = data => ({
  type: types.TOP_BRANDS_REQUEST_START,
  data
})

export const topBrandsRequestSuccess = data => ({
  type: types.TOP_BRANDS_REQUEST_SUCCESS,
  data
})

export const topBrandsRequestFailure = error => ({
  type: types.TOP_BRANDS_REQUEST_FAILURE,
  error
})

// Deal Hunters
export const topDealHuntersRequestStart = data => ({
  type: types.TOP_DEAL_HUNTERS_REQUEST_START,
  data
})

export const topDealHuntersRequestSuccess = data => ({
  type: types.TOP_DEAL_HUNTERS_REQUEST_SUCCESS,
  data
})

export const topDealHuntersRequestFailure = error => ({
  type: types.TOP_DEAL_HUNTERS_REQUEST_FAILURE,
  error
})

// Coupons
export const topCouponsRequestStart = data => ({
  type: types.TOP_COUPONS_REQUEST_START,
  data
})

export const topCouponsRequestSuccess = data => ({
  type: types.TOP_COUPONS_REQUEST_SUCCESS,
  data
})

export const topCouponsRequestFailure = error => ({
  type: types.TOP_COUPONS_REQUEST_FAILURE,
  error
})

export const topCouponsRefresh = () => ({
  type: types.TOP_COUPONS_REFRESH
})

// NEW Coupons
export const newCouponsRequestStart = data => ({
  type: types.NEW_COUPONS_REQUEST_START,
  data
})

export const newCouponsRequestSuccess = data => ({
  type: types.NEW_COUPONS_REQUEST_SUCCESS,
  data
})

export const newCouponsRequestFailure = error => ({
  type: types.NEW_COUPONS_REQUEST_FAILURE,
  error
})

// Sale Events

export const topSaleEventsClear = data => ({
  type: types.TOP_SALE_EVENTS_CLEAR,
  data
})

export const topSaleEventsRequestStart = data => ({
  type: types.TOP_SALE_EVENTS_REQUEST_START,
  data
})

export const topSaleEventsRequestSuccess = data => ({
  type: types.TOP_SALE_EVENTS_REQUEST_SUCCESS,
  data
})

export const topSaleEventsRequestFailure = error => ({
  type: types.TOP_SALE_EVENTS_REQUEST_FAILURE,
  error
})

// New Sale Events
export const newSaleEventsRequestStart = data => ({
  type: types.NEW_SALE_EVENTS_REQUEST_START,
  data
})

export const newSaleEventsRequestSuccess = data => ({
  type: types.NEW_SALE_EVENTS_REQUEST_SUCCESS,
  data
})

export const newSaleEventsRequestFailure = error => ({
  type: types.NEW_SALE_EVENTS_REQUEST_FAILURE,
  error
})

// Current Sale Events
export const topCurrentSaleEventsRequestStart = data => ({
  type: types.TOP_CURRENT_SALE_EVENTS_REQUEST_START,
  data
})

export const topCurrentSaleEventsRequestSuccess = data => ({
  type: types.TOP_CURRENT_SALE_EVENTS_REQUEST_SUCCESS,
  data
})

export const topCurrentSaleEventsRequestFailure = error => ({
  type: types.TOP_CURRENT_SALE_EVENTS_REQUEST_FAILURE,
  error
})

// Upcoming Sale Events
export const topUpcomingSaleEventsRequestStart = data => ({
  type: types.TOP_UPCOMING_SALE_EVENTS_REQUEST_START,
  data
})

export const topUpcomingSaleEventsRequestSuccess = data => ({
  type: types.TOP_UPCOMING_SALE_EVENTS_REQUEST_SUCCESS,
  data
})

export const topUpcomingSaleEventsRequestFailure = data => ({
  type: types.TOP_UPCOMING_SALE_EVENTS_REQUEST_FAILURE,
  data
})

// Newsletters
export const newslettersRequestStart = data => ({
  type: types.NEWSLETTERS_REQUEST_START,
  data
 })

 export const newslettersRequestSuccess = data => ({
  type: types.NEWSLETTERS_REQUEST_SUCCESS,
  data
 })

 export const newslettersRequestFailure = error => ({
  type: types.NEWSLETTERS_REQUEST_FAILURE,
  error
})

// Sibling deals
export const siblingDealsRequestStart = data => ({
  type: types.SIBLING_DEALS_REQUEST_START,
  data
})

export const siblingDealsRequestSuccess = data => ({
  type: types.SIBLING_DEALS_REQUEST_SUCCESS,
  data
})

export const siblingDealsRequestFailure = error => ({
  type: types.SIBLING_DEALS_REQUEST_FAILURE,
  error
})
