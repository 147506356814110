import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import moment from 'moment'
import { useInView } from 'react-intersection-observer'

import {
  // RankLabel,
  FooterListingCounts
} from '../../components/Labels'
import {
  // LittleBirdieTag,
  upvoteActiveIcon,
  noImage,
  LBLogo,
  priceDecrease15,
  priceDecrease16to30,
  priceDecrease31plus,
  priceIncrease15,
  priceIncrease16to30,
  priceIncrease31plus,
  refreshClock,
  iconCheckGreenCircle
} from '../../components/Icons'

import changePriceDropLabel from '../utils/changePriceDropLabel'
import positionDataLayer from '../../components/dataLayers/positionDataLayer'
import { USE_IN_VIEW_OPTIONS, gon } from '../../../constant'
import { fetchOnDemandOfferPricing } from '../../apps/App/requesters/DealsRequester'
import { percentageDifference } from '../../apps/App/components/pages/Common/helpers'

function formattedPrice (price) {
  if (price >= 0) {
    return (price).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: (price % 1 === 0) ? 0 : 2
    })
  }
  return null
}

function dollerCents (price) {
  const cents = price?.toString()?.split('.')?.[1] || 0
  return cents > 0 ? cents?.length === 1 ? cents * 10 : cents : ''
}

function discountText (discount) {
  return (discount * 100).toFixed()
}

const formattedPercent = new Intl.NumberFormat('en-US', { signDisplay: 'exceptZero' })
const numberFormatter = new Intl.NumberFormat('en-US')

const DealCard = ({ deal, wrapperClass, position, currentSection, sectionPosition, hanldeStorePagination, mobileColumn }) => {
  const [isUpvoted, setIsUpvoted] = useState(deal.upvoted)
  const [refDealCard, inView] = useInView(USE_IN_VIEW_OPTIONS)
  const [performingOds, setPerformingOds] = useState(false)
  const [odsDeal, setOdsDeal] = useState(null)
  const [showUnableToUpdate, setShowUnableToUpdate] = useState(false)
  const role = gon?.current_user?.role || ''
  const isAdmin = ['admin', 'data_entry'].includes(role)

  useEffect(() => {
    if (deal.refresh && position <= 8 && odsDeal === null && !performingOds && deal.vendor_id) {
      setPerformingOds(true)
      setTimeout(() => {
        updatePrice()
      }, position * 300)
    }
  }, [])

  useEffect(() => {
    if (inView) {
      positionDataLayer(item, 'product-impression', 'product-link', position, currentSection, sectionPosition)
    }
  }, [inView])

  const newDate = new Date(0)
  const lastPriceDate = /[a-zA-Z]/g.test(deal.last_price_date) ? new Date(deal.last_price_date) : newDate.setUTCSeconds(parseInt(deal.last_price_date.substring(0, 10), 10))

  let discountInPercentage = discountText(deal.price_shift)

  const minDiscount = Math.abs(parseInt(discountInPercentage, 10))
  let showDiscount = moment().diff(moment(lastPriceDate), 'days') <= 28 ? /\/top-price-drop/i.test(window.location.pathname) ? minDiscount >= 5 : minDiscount >= 1 : false

  if (!deal) return null

  if (odsDeal?.deal?.price) {
    discountInPercentage = percentageDifference(odsDeal.deal.price, deal.price)
    showDiscount = Math.abs(parseInt(discountInPercentage, 10)) >= 1
  }

  if (Math.abs(discountInPercentage) > 80) {
    showDiscount = false
  }

  const canRefresh = deal.refresh && deal.vendor_id && deal.source_store_id && !performingOds && odsDeal === null

  function updateDealLikeCount () {
    setIsUpvoted(!isUpvoted)
    if (isUpvoted) {
      deal.deal_likes = parseInt(deal.deal_likes, 10) - 1
    } else {
      deal.deal_likes = parseInt(deal.deal_likes, 10) + 1
    }
  }

  function handleImageError (event) {
    event.target.onError = null
    event.target.src = noImage
  }

  const item = { ...deal, type: 'deal' }

  const updatePrice = (event) => {
    event?.preventDefault()
    event?.stopPropagation()
    if (deal?.id) {
      setPerformingOds(true)
      fetchOnDemandOfferPricing({
        url: deal.product_url,
        store_id: deal.source_store_id,
        vendor_id: deal.vendor_id
      }).then(({ response }) => {
        if(!response?.deal && isAdmin) {
          setShowUnableToUpdate(true)
        }
        setOdsDeal(response || { deal: null })
      })
        .catch((error) => {
          if(isAdmin) setShowUnableToUpdate(true)
          
          setOdsDeal({ deal: null })
        })
        .finally(() => {
          setPerformingOds(false)
        })
    }
  }

  return (
    <div className={`listing-card ${wrapperClass}`} ref={refDealCard}>
      <div className={`card card-price-drop ${mobileColumn && 'card-price-drop-2w'}`} id={deal.id}>
        <Link
          className='link-wrapper'
          to={`/shop/deal/${deal.slug}`}
          onClick={() => {
            hanldeStorePagination && hanldeStorePagination()
            positionDataLayer(item, 'card-click', 'product-link', position, currentSection, sectionPosition)
          }}
        >
          <div className={`card-img-top deal ${mobileColumn && 'card-image-top-2w'}`}>
            {deal?.brand_logo && (
              <div className={`brand-logo ${mobileColumn && 'brand-logo-2w'}`}>
                <LazyLoadImage
                  className='img-fluid'
                  alt={deal.brand_name}
                  src={deal.brand_logo}
                />
              </div>
            )}
            <LazyLoadImage
              className='img-fluid'
              alt={deal.name}
              onError={e => handleImageError(e)}
              src={deal.image_url || noImage}
            />
          </div>
          <div className='card-body deal'>
            <div className={`deal-name ${mobileColumn && 'deal-name-2w'}`}>
              <p className={`card-text mb-0 this-atleast ${mobileColumn && 'card-text-2w'}`}>
                {deal?.name?.length <= 80 ? deal?.name : deal?.name?.substring(0, 80) + '...'}
              </p>
              <FooterListingCounts
                type='deal'
                id={deal.id.toString()}
                name={deal.name}
                upvotesCount={deal.deal_likes}
                commentsCount={deal.deal_comments}
                impressionsCount={deal.deal_views}
                onFollow={() => updateDealLikeCount()}
                upvoted={isUpvoted}
                downvoteImage={isUpvoted ? upvoteActiveIcon : undefined}
                wrapperClass='list-inline mb-0 d-flex justify-content-around'
              />
            </div>
            <div className={`card-title ${mobileColumn && 'card-title-2w'}`}>
              <div className='d-flex justify-content-between align-items-center h-100'>
                <div className='col-6 price-details'>
                  <h4 className={`d-flex price-now ${mobileColumn && 'price-now-2w'}`}>
                    <span className={`doller-symbol ${mobileColumn && 'doller-symbol-2w'}`}>$</span>{`${numberFormatter.format(Math.floor(odsDeal?.deal?.price || deal.price))}`}<span className={`cents ${mobileColumn && 'cents-2w'}`}>{`${dollerCents(odsDeal?.deal?.price || deal.price)}`}</span>
                  </h4>
                  {showUnableToUpdate && <div className={`d-flex price-was align-items-center text-nowrap ${mobileColumn && 'price-was-2w'}`}>Unable to update</div>}
                  {
                    (!showUnableToUpdate && (showDiscount || odsDeal)) &&
                    (
                      <>
                        <div className={`d-flex price-was align-items-center text-nowrap ${mobileColumn && 'price-was-2w'}`}>
                          {
                            !odsDeal?.deal &&
                              <>
                                {
                                formattedPrice(deal.price_prev) > 0
                                  ? <>
                                    <span className='mr-1'>Was </span>
                                    <span>
                                      {`${formattedPrice(deal.price_prev)}`}
                                    </span>
                                  </>
                                  : <>Updated</>
                              }

                                <span className={`last-price-date ${mobileColumn && 'last-price-date-2w'}`}>
                                  {
                                  ((new Date()) - lastPriceDate) < 30000
                                    ? <div>Just Now</div>
                                    : <div>{changePriceDropLabel(moment(lastPriceDate).fromNow())}</div>
                                }
                                </span>
                              </>
                          }
                          {
                            odsDeal?.deal &&
                              <span>
                                <img className='check' src={iconCheckGreenCircle} /> Updated now
                              </span>
                          }
                        </div>
                        {
                          mobileColumn &&
                            <div>
                              {!odsDeal?.deal &&
                                <span className='last-price-date-mb'>
                                  {
                                  ((new Date()) - lastPriceDate) < 30000
                                    ? <div>Just Now</div>
                                    : <div>{changePriceDropLabel(moment(lastPriceDate).fromNow())}</div>
                                }
                                </span>}
                            </div>
                        }
                      </>
                    )
                  }
                </div>
                <div className='col-6'> {/* deal-price-up-label deal-price-drop-label */}
                  <div className='d-flex align-items-center justify-content-end'>
                    {canRefresh &&
                      <button className='update-price' onClick={updatePrice}>
                        <img className='mr-1' src={refreshClock} />
                        <span>Update Price</span>
                      </button>}
                    {performingOds &&
                      <div className='deal-checking price'>
                        <div className='dot-blink' />
                        <span className='text-sm'>Checking Price</span>
                      </div>}

                    {(showDiscount && !canRefresh && !performingOds ) &&
                      <div className={`deal-price-drop-label ${discountInPercentage > 0 && 'deal-price-up-label'}`}>
                        {discountInPercentage < 0 ? <img className={`price-drop-img ${mobileColumn && 'price-drop-img-2w'}`} src={discountInPercentage < -30 ? priceDecrease31plus : discountInPercentage < -15 ? priceDecrease16to30 : priceDecrease15} /> : null}
                        {discountInPercentage > 0 ? <img className={`price-drop-img ${mobileColumn && 'price-drop-img-2w'}`} src={discountInPercentage > 30 ? priceIncrease31plus : discountInPercentage > 15 ? priceIncrease16to30 : priceIncrease15} /> : null}
                        <div className={`pricedrop ${mobileColumn && 'pricedrop-2w'}`}>
                          {Math.abs(discountInPercentage) > 500 ? discountInPercentage < 500 ? '-500%+' : '500%+' : formattedPercent.format(discountInPercentage) + '%'}
                        </div>
                      </div>}
                    {/* <div className='deal-price-drop-label deal-price-up-label'>
                      <img className={`price-drop-img ${mobileColumn && 'price-drop-img-2w'}`} src={priceIncrease15} />
                      <div className={`pricedrop ${mobileColumn && 'pricedrop-2w'}`}>
                        +20%
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* {
                  (showDiscount && false) &&
                  (
                    <div className={`col-6 deal-price-drop-label ${discountInPercentage > 0 && 'deal-price-up-label'}`}>
                      <div className='d-flex align-items-center justify-content-around h-100 px-3'>
                        {discountInPercentage < 0 ? <img className={`price-drop-img ${mobileColumn && 'price-drop-img-2w'}`} src={discountInPercentage < -30 ? priceDecrease31plus : discountInPercentage < -15 ? priceDecrease16to30 : priceDecrease15} /> : null}
                        {discountInPercentage > 0 ? <img className={`price-drop-img ${mobileColumn && 'price-drop-img-2w'}`} src={discountInPercentage > 30 ? priceIncrease31plus : discountInPercentage > 15 ? priceIncrease16to30 : priceIncrease15} /> : null}
                        <div className={`pricedrop ${mobileColumn && 'pricedrop-2w'}`}>
                          {Math.abs(discountInPercentage) > 500 ? discountInPercentage < 500 ? '-500%+' : '500%+' : formattedPercent.format(discountInPercentage) + '%'}
                        </div>
                      </div>
                    </div>
                  )
                } */}
              </div>
            </div>
          </div>
          <div className={`card-footer ${mobileColumn && 'card-footer-2w'}`}>
            <div className={`card-meta-info mx-auto ${mobileColumn && 'card-meta-info-2w'}`}>
              {
                deal?.stores?.length > 1
                  ? <>Compare prices from <span className='deal-store-nm'>{deal?.stores?.length} stores</span></>
                  : <>From <span className='deal-store-nm'>{deal.stores?.[0]?.name || deal?.store_name}</span></>
              }
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default DealCard
