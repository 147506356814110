import { combineReducers } from 'redux'

import createReducer from '../../../../components/utils/createReducer'

import * as actions from '../../actions/types'

import { gon } from '../../../../../constant'

const current_user = createReducer(gon.current_user || null)({
  [actions.UPDATE_CURRENT_USER]: (_state, { data }) => data
})

const current_user_loading = createReducer({ loading: false })({
  [actions.GET_CURRENT_USER_START]: (_state, { data }) => ({ loading: data })
})

export default combineReducers({
  current_user,
  current_user_loading
})
