import React, { Component } from "react"
import { connect } from "react-redux"

import ListCarousel from "../../../../components/ListCarousel"
import ListCarouselSlide from "../../../../components/ListCarousel/ListCarouselSlide"
import { homePageListingCarouselWrapper } from "../../../../helpers/carousel"
import ListingCard from "../ListingCard"

import * as reducers from "../../reducers"

class TopDealsList extends Component {
  topListComponents = () => {
    const { topDealIds } = this.props
    if (topDealIds === undefined) { return ([]) }
    return homePageListingCarouselWrapper(ListCarouselSlide, ListingCard, "deal", topDealIds)
  }

  render() {
    const {
      isFetchingTopDeals,
      title,
      description,
      wrapperClass,
      viewAllUrl
    } = this.props

    return (
      <ListCarousel
        title={title || "Trending Deals"}
        description={description || "The best product deals from the last 48hrs based on the community and relevance"}
        isLoading={isFetchingTopDeals}
        wrapperClass={wrapperClass || "top-deals mx-0"}
        viewAllUrl={viewAllUrl}>
        { this.topListComponents() }
      </ListCarousel>
    )
  }
}

const mapStateToProps = state => ({
  topDealIds: reducers.getTopDealIds(state),
  isFetchingTopDeals: reducers.isFetchingTopDeals(state)
})

export default connect(mapStateToProps, {})(TopDealsList)
