import React, { Component } from "react"
import { connect } from "react-redux"

import TopLists from "../../../../components/TopLists"
import ListingCard from "../ListingCard"
import * as reducers from "../../reducers"

class NewCouponsList extends Component {
  newListComponents = () => {
    const { newCouponIds, title, currentSection, sectionPosition} = this.props
    if (newCouponIds === undefined) { return ([]) }
    return _.map(newCouponIds, (couponId, index) => <ListingCard type="coupon" id={couponId} wrapperClass="" position={index + 1} key={`top-coupons-${couponId}`} currentSection={`${currentSection || title || 'Newly Added Vouchers'}`} sectionPosition={sectionPosition} />)
  }

  render() {
    const {
      isFetchingNewCoupons,
      title,
      description,
      wrapperClass,
      viewAllUrl,
      currentSection,
      sectionPosition
    } = this.props

    return (
      <TopLists
        title={title || "Newly Added Vouchers"}
        titleClass="mb-1"
        description={description || "We've browsed thousands of vouchers to bring you only the best right here."}
        isLoading={isFetchingNewCoupons}
        wrapperClass={wrapperClass || "row"}
        currentSection={currentSection}
        sectionPosition={sectionPosition}
        viewAllUrl={viewAllUrl}
        type="vouchers">
        { this.newListComponents() }
      </TopLists>
    )
  }
}

const mapStateToProps = state => ({
  newCouponIds: reducers.getListIdsFromType(state, "get-new-vouchers"),
  isFetchingNewCoupons: reducers.isFetchingListFromType(state, "get-new-vouchers")
})

export default connect(mapStateToProps, {})(NewCouponsList)
