import React from 'react'
import Page500 from './Page_500'

export default class ErrorBoundries extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError () {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  render () {
    return this.state.hasError ? <Page500 /> : this.props.children
  }
}
