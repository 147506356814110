import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getRouter } from '../../reducers'
import { useMediaQuery } from 'react-responsive'
import _ from 'lodash'

const useScrollToSection = ({ref, eleId, offset = 0}) => {
  const [isScroll, setIsScroll] = useState(false)
  const { location } = useSelector(getRouter)
  const loadingCarousels = useSelector((state) => state.carousel.loadingCarousels)
  const lowerQueryParam = _.transform(location?.query || {}, function (result, val, key) {
    result[key.toLowerCase()] = val
  })
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const scrollId = lowerQueryParam.scroll
  let isScrollSection = false

  const scrollTo = (offset, callback) => {
    const fixedOffset = offset;
    const onScroll = function () {
      // if (window.pageYOffset === fixedOffset) {
      if(isScrollSection) {
          window.removeEventListener('scroll', onScroll)
          callback()
      }
    }

    window.addEventListener('scroll', onScroll)
    onScroll()
    window.scrollTo({
      top: offset,
    })
  }

  const getOffset = (element) => {
    if (!element || (element && !element.getClientRects().length)) {
      return { top: 0, left: 0 }
    }

    let rect = element.getBoundingClientRect()
    let win = element.ownerDocument.defaultView

    return ({
      top: rect.top + win.pageYOffset,
      left: rect.left + win.pageXOffset
    })
  }

  const debounceScroll = useCallback(_.debounce((element) => {
    const excludeOffset = isMobile ? 15 : 100
    const smoothScroll = 200
    const targetTop = Math.round(getOffset(element).top - excludeOffset - offset)

    isScrollSection = true
    scrollTo(targetTop - smoothScroll, () => {
      setTimeout(()=> {
        window.scrollBy({
          top: smoothScroll,
          behavior: 'smooth'
        })
      }, 100)
    })
    // window.scrollTo({
    //   top: targetTop,
    //   // behavior: 'smooth'
    // })
  }, 500), [])

  useEffect(() => {
    if (!ref.current) {
      return
    }
    const containerId = scrollId || localStorage.getItem(eleId)
    const offsetTop = getOffset(ref?.current).top

    if (
      containerId &&
      containerId === ref?.current?.id &&
      offsetTop &&
      !isScroll &&
      loadingCarousels?.length == 0
      ) {
        setIsScroll((pre)=> true)
        debounceScroll(ref?.current)
    }
  }, [ref.current, scrollId, loadingCarousels])

  useEffect(()=>{
    let timer = setTimeout(()=>{
      isScroll && localStorage.removeItem(eleId)
    }, 2000)
    return ()=>{
      clearTimeout(timer)
    }
  },[isScroll])

  return [isScroll, setIsScroll]
}

export default useScrollToSection
