import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  // categoryIcon,
  userIcon,
  // priceDropIcon,
  // percentIcon,
  // couponIcon,
  // chevronRight,
  chevronLeft,
  // personalisedHearts,
  extensionIcon
} from '../../../../../components/Icons'
import { Link, useHistory } from 'react-router-dom'
import IconBox from './IconBox'
import MobileNavBarContentBox from './MobileNavBarContentBox'
import { hideUserSlider, hideCatSlider } from '../../../actions'
import { handleNativeRedirectWebView } from '../../../utilis/NativeDataShare'

export default function SignedOutList(props) {
  const [showInitialComponent, setShowInitialComponents] = useState(true)
  const [showAccountOrDepartment, setShowAccountOrDepartment] = useState(false)
  const [showBackButton, setShowBackButton] = useState(true)
  const [activeField, setActiveField] = useState('about')
  const dispatch = useDispatch()
  const history = useHistory()
  const { showCatMenu } = useSelector(state => state.menu)

  useEffect(() => {
    if (showCatMenu) {
      setShowInitialComponents(false)
      setActiveField('categories')
      setShowAccountOrDepartment(true)
    }
  }, [showCatMenu])

  function renderAccountOrDepartment(column) {
    setShowInitialComponents(false)
    setActiveField(column)
    setShowAccountOrDepartment(true)
  }

  function handleBack() {
    setShowInitialComponents(true)
    setActiveField('about')
    setShowAccountOrDepartment(false)
  }

  function toggleShowBackButton() {
    setShowBackButton(!showBackButton)
  }

  function handleSliderClose() {
    dispatch(hideUserSlider())
    dispatch(hideCatSlider())
  }

  const handleNativeJoinLogin = path => {
    dispatch(hideUserSlider())
    dispatch(hideCatSlider())
    if (window.ReactNativeWebView) {
      handleNativeRedirectWebView(`{"type":"${path}", "path":"${window.location.href}"}`)
    } else {
      history.push({
        pathname: '/' + path,
        state: { from: window.location.pathname }
      })
    }
  }
  return (
    <>
      {showInitialComponent && (
        <>
          <div className='nav-side-bar'>
            <div className='user-slider-links'>
              <ul className='navbar-nav nav-tab-options'>
                <li
                  className='nav-sidebar-item'
                >
                  <div className='nav-sidebar-item-wrapper d-flex justify-content-between'>
                    <div className='d-flex' onClick={() => handleNativeJoinLogin('login')}>
                      <IconBox {...{
                        title: 'Sign In',
                        hoverState: false,
                        imageClassName: 'rounded-circle',
                        hoverIcon: userIcon,
                        icon: userIcon
                      }}
                      />
                    </div>
                  </div>
                </li>

                {
                  props?.showExtension && (
                    <li className='nav-sidebar-item' >
                      <Link to='/mobile/ext/enable' className='nav-sidebar-item-wrapper d-flex align-items-center' onClick={handleSliderClose}>
                        <IconBox {...{
                          title: 'Browser Extension',
                          hoverIcon: extensionIcon,
                          icon: extensionIcon
                        }}
                        />
                        {/* <div className='new-pill'>
                          <span className="new-pill-text"> New </span>
                        </div> */}
                      </Link>
                    </li>
                  ) || null
                }

                {/* <li className='nav-sidebar-item'>
                  <div className='nav-sidebar-item-wrapper' onClick={() => handleNativeJoinLogin('register')}>
                    <div className='d-flex'>
                      <IconBox {...{
                        title: 'Personalise my feed',
                        hoverIcon: personalisedHearts,
                        icon: personalisedHearts
                      }}
                      />
                    </div>
                  </div>
                </li> */}
                {/* <li className='nav-sidebar-item'>
                  <div className='nav-sidebar-item-wrapper d-flex justify-content-between' onClick={() => renderAccountOrDepartment('categories')}>
                    <div className='d-flex'>
                      <IconBox {...{
                        title: 'Departments',
                        hoverIcon: categoryIcon,
                        icon: categoryIcon
                      }}
                      />
                    </div>
                    <img src={chevronRight} />
                  </div>
                </li> */}
                {/* ToDo: Remove Deal: Remove Price Drops Link */}
                {/* <li className='nav-sidebar-item'>
                  <Link to='/top-price-drops' className='nav-sidebar-item-wrapper' onClick={handleSliderClose}>
                    <IconBox {...{
                      title: 'Price Drops',
                      hoverIcon: priceDropIcon,
                      icon: priceDropIcon
                    }}
                    />
                  </Link>
                </li> */}
                {/* <li className='nav-sidebar-item'>
                  <Link to='/top-sales-events' className='nav-sidebar-item-wrapper' onClick={handleSliderClose}>
                    <IconBox {...{
                      title: 'Sale Events',
                      hoverIcon: percentIcon,
                      icon: percentIcon
                    }}
                    />
                  </Link>
                </li> */}
                {/* <li className='nav-sidebar-item'>
                  <Link to='/top-promo-codes' className='nav-sidebar-item-wrapper' onClick={handleSliderClose}>
                    <IconBox {...{
                      title: 'Promo Codes',
                      hoverIcon: couponIcon,
                      icon: couponIcon
                    }}
                    />
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className='nav-tab-content'>
            <MobileNavBarContentBox {...{
              activeField
            }}
            />
          </div>
        </>
      )}

      {showAccountOrDepartment && (
        <>
          {showBackButton && (
            <div className='d-inline-flex pl-0 mobile-nav-back'>
              <div className='btn btn-sm btn-outline-light nav-icon' onClick={() => handleBack()}>
                <img src={chevronLeft} />
              </div>
              {activeField === 'categories' && <div className='mobile-nav-heading'>Choose department</div>}
            </div>
          )}
          <MobileNavBarContentBox {...{ activeField, handleBackButton: toggleShowBackButton }} />
        </>
      )}
    </>
  )
}
