import _ from "lodash"
import * as actions from "../../actions/types"

const initialState = {
    loadingCarousels: null,
}

const carousel = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_CAROUSEL_LOADING:
            let loadingCarousels = state.loadingCarousels ? [...state.loadingCarousels] : []

            if(loadingCarousels.indexOf(action.data.carousel) < 0) {
               loadingCarousels = [ ...loadingCarousels, action.data.carousel]
            }

            return { ...state, loadingCarousels }
        case actions.SET_CAROUSEL_LOADED:
            return { ...state, loadingCarousels: _.without(state.loadingCarousels || [], action.data.carousel)}
        case actions.RESET_CAROUSEL_LOADED:
            return {...state, loadingCarousels: null}
        default:
            return state
    }
}

export default carousel