import React, { Component } from "react"

import Slider from "react-slick"

import { Link } from "react-router-dom"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Loader from "../../components/Loader"

import PageSection from "../../components/PageSection"

import {
  chevronLeft,
  chevronRight
} from "../../components/Icons"

class ListCarousel extends Component {
  sliderPrev = () => {
    this.slider.slickPrev()
  }

  sliderNext = () => {
    this.slider.slickNext()
  }

  render() {
    const {
      title,
      description,
      wrapperClass,
      children,
      isLoading,
      viewAllUrl,
    } = this.props

    const notEnoughSlides = children.length < 6
    const slickKlass = notEnoughSlides ? "col-12 left-align-slick" : "col-12"

    const carouselSettings = {
      className: `${slickKlass} px-0`,
      dots: false,
      arrows: false,
      touchMove: false,
      infinite: false,
      initialSlide: 0,
      speed: 500
    }

    // No data hide it all
    if (!isLoading && children.length === 0) { return (null) }

    return (
      <PageSection
        title={title}
        description={description}>
        <div className={`row listing-card-row mt-3 ${wrapperClass}`}>
          {
            (isLoading)
            ? <Loader isLoading />
            : (
              <Slider
                ref={arg => { this.slider = arg }}
                {...carouselSettings}>
                { children }
              </Slider>
            )
          }
        </div>
        {
          (isLoading || children.length === 0)
          ? null
          : (
            <div className="d-flex justify-content-center align-items-center">
              <Link
                to={viewAllUrl}>
                <div className="btn btn-main rounded-pill">View All</div>
              </Link>
              <div className="carousel-buttons d-flex">
                <div onClick={this.sliderPrev} className="px-2 scroll-button"><img src={chevronLeft} /></div>
                <div onClick={this.sliderNext} className="px-2 scroll-button"><img src={chevronRight} /></div>
              </div>
            </div>
          )
        }
      </PageSection>
    )
  }
}

export default ListCarousel