/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getTodaysTopPicks = (baseUrl, data = {}) => {
  const path = `${baseUrl}/todays_top_picks`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}