import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import * as actionTypes from "../../../actions/types"

const usersFetchSuccess = (state, { data }) => ({ ...state, ...data.user })

const byId = createReducer({})({
  [actionTypes.RESOURCE_FETCHED_SUCCESS]: usersFetchSuccess,
  [actionTypes.USER_REQUEST_SUCCESS]: usersFetchSuccess
})

export const getUserById = (state, userId) => state.byId[userId]
export const getUserBySlug = (state, slug) => _.find(state.byId, { attributes: { slug } })

// User affinity data
const usersAffinityFetchSuccess = (state, {data}) => {
  sessionStorage.setItem('user_affinities', JSON.stringify(data.data))
  return ({ ...state, ...data })
}

const request = createReducer(null)({
  [actionTypes.USER_AFFINITY_REQUEST_START]: (_, { type }) => type,
  [actionTypes.USER_AFFINITY_REQUEST_SUCCESS]: (_, { type }) => type,
  [actionTypes.USER_AFFINITY_REQUEST_FAILURE]: (_, { type }) => type
})

const affinityData = createReducer({})({
  [actionTypes.USER_AFFINITY_REQUEST_SUCCESS]: usersAffinityFetchSuccess,
})

export const getUserAffinityData = (state) => state.affinityData


export default combineReducers({
  byId,
  request,
  affinityData
})
