import React, { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import Loader from '../../../components/Loader'
import { TOP_PICK_REQUEST_START, TOP_PICK_REQUEST_SUCCESS } from '../actions/types'
import ListingCard from './ListingCard'
// import DealTracker from './MainNavbar/DealTracker'
import ChevronRight from './ChevronRight'
import useScrollToSection from './BiInfiniteScroll/useScrollToSection'
import { setCarouselLoading, setCarouselLoaded } from '../actions/carousel'
// import { cyberMondayBadge } from '../../../../javascript/components/Icons'

export default function TodaysTopPicks ({isYourTopPickShow, showViewBtn, sectionId, fromNarrat}) {
  const { department, category, subcategory } = useParams()
  const sectionRef = useRef(null)
  const { request } = useSelector(state => state.listings.topPicks)
  const dispatch = useDispatch()

  useScrollToSection({ ref: sectionRef, eleId: 'hsref' })

  useEffect(() => {
    if (!(department || category || subcategory)) {
      dispatch(setCarouselLoading({ carousel: sectionId }))
    }
  }, [])

  useEffect(() => {
    if (sectionRef?.current) {
      dispatch(setCarouselLoaded({ carousel: sectionId }))
    }
  }, [request, sectionRef?.current])

  if (department || category || subcategory) return null

  if ([TOP_PICK_REQUEST_START, TOP_PICK_REQUEST_SUCCESS].includes(request)) {
    return (
      <section className="section-group top-50" ref={sectionRef} id={sectionId}>
        <div className="container-fluid full-width-container">
          <div className="section-bg" />
        </div>
        <div className="section-group-body">
          <div className="container">
            {/* <img className='bf-tag-small' src={cyberMondayBadge} /> */}
            <div className="listing-group-header">
              <h2 className="carousel-title">Top Offers in Australia today</h2>
              {/* <div className="subtitle">
                <DealTracker />
              </div> */}
            </div>
          </div>
          <TopPicksBody isYourTopPickShow={isYourTopPickShow} showViewBtn={showViewBtn} fromNarrat={fromNarrat} />
        </div>
      </section>
    )
  }

  return null
}

function TopPicksBody ({isYourTopPickShow, showViewBtn, sectionId, fromNarrat}) {
  const myRef = useRef(null)
  const { request, byIds } = useSelector(state => state.listings.topPicks)
  const state = useSelector(state => state)
  if (request === TOP_PICK_REQUEST_START) return <Loader isLoading />
  const topPicksArr = _.orderBy(byIds, 'attributes.position', [])
  return (
    <>
    <div className='container'>
      <div className='listing-carousel listing-group-header'>
        <div className='d-flex listing-group-carousel' ref={myRef}>
          <ChevronRight offset={300} ref={myRef} direction='left' />
          {topPicksArr?.length && _.take(topPicksArr, 10).map((item, index) => {
            const {
              relationships: { featurable: { data } }
            } = item
            if (data) {
              const { type, id } = data
              return <TopPickItem key={`${type}-${id}`} sectionId={sectionId} type={type} id={id} index={index} isYourTopPickShow={isYourTopPickShow} fromNarrat={fromNarrat} />
            }
            return null
          })}
          <ChevronRight offset={300} ref={myRef} direction='right' />
        </div>
      </div>
    </div>
    {showViewBtn ? (
      <div className='d-flex justify-content-center'>
        <button
          type='button'
          className='btn btn-outline-dark rounded-pill mx-2 px-4'
          onClick={showViewBtn}
        >
          View today’s offers
        </button>
      </div>
    ) : (
      ""
    )}
    </>
  )
}

function TopPickItem ({ type, id, index, isYourTopPickShow, fromNarrat, sectionId }) {
  const wrapperClass = {
    deal: 'listing-price-drop',
    saleEvent: 'event',
    coupon: 'coupon-large-image',
    uniqueCoupon: 'coupon-large-image',
    customList: ''
  }[type]

  return <ListingCard sectionId={sectionId} wrapperClass={wrapperClass} type={type} id={id} position={index + 1} showRank currentSection={fromNarrat ? 'Narrative Discovery' : 'TodaysTopPicks'} sectionPosition={fromNarrat ? 3 : isYourTopPickShow ? 2 : 1} />
}
