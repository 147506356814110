import {
  call, put, select, takeLatest
} from 'redux-saga/effects'

import * as actionTypes from '../../actions/types'
import { seoContentRequestSuccess, seoContentRequestFailure } from '../../actions'
import { seoRequester } from '../../requesters'
import { getBaseUrl } from '../../reducers'

function* triggerGetSeoContent ({ data }) {
  const baseUrl = yield select(getBaseUrl)

  const { response, error } = yield call(seoRequester, baseUrl, data)
  if (response) {
    yield put(seoContentRequestSuccess(response))
  } else {
    yield put(seoContentRequestFailure(error))
  }
}

function* watchSeoSaga () {
  yield takeLatest(actionTypes.SEO_CONTENT_REQUEST_START, triggerGetSeoContent)
}

export {
  watchSeoSaga
}
