import React from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { withRouter } from 'react-router-dom'
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import AppleSignin from 'react-apple-signin-auth'
import { Spinner } from "react-bootstrap"

import GoogleSignInCustom from './GoogleSignInCustom'

import {
  // FACEBOOK_APP_ID,
  gon,
  LB_APPLE_CLIENT_ID,
  LB_APPLE_REDIRECT_URI_ID
} from '../../../../../../constant'

import {
  // facebookLogin,
  impressionIcon,
  aboutIcon,
  crossIcon,
  appleIcon,
  dotEmail,
  backArrow
} from '../../../../../components/Icons'

import sendAuthToExtension from '../../../../../components/utils/SendAuthToExtension'

const AuthError = ({ errors, setErrors }) => {
  if (!Array.isArray(errors) || errors.length === 0) return null;

  return (
    <div className='alert alert-danger alert-block errors-label rounded'>
      {/* <div className='d-flex justify-content-end'> */}
        <button
          onClick={() => setErrors([])}
          className='close'
          data-dismiss='alert'
          type='button'
          aria-label="Close">
          <img src={crossIcon} alt="Close" width="16" height="16" />
        </button>
      {/* </div> */}
      <ul className='list-unstyled mb-0'>
        {errors.map((message, index) => (
          <li key={index} className='d-flex text-dark mb-2'>
            <img src={aboutIcon} className='mr-2 mt-1' alt="Info" width="20" height="20" />
            <span>{message}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    const { cookies } = props;
    const queryParams = new URLSearchParams(window.location.search);
    const typeParam = queryParams.get('type');

    this.state = {
      loading: false,
      wtidCookie: cookies.get('wtid') || '',
      errors: [],
      type: 'password',
      formData: {
        wtid: cookies.get('wtid') || '',
        login: undefined,
        password: undefined,
        remember_me: false
      },
      authType: typeParam === 'signup' ? 'signup' : 'login',
      showEmailForm: false,
    };
  }

  componentDidMount() {
    trackForm()
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { formData, authType } = this.state;
    const { prevUrl } = this.props;

    if (!formData.login || !formData.password) {
      this.setState({ errors: ['Username/email or password not valid'] });
      return;
    }

    this.setState({ errors: [] });
    this.setState({ loading: true });
    
    const path = authType === 'signup' ? `${gon.urls.api}/auth/register` : `${gon.urls.api}/auth/login`;
    const authData = authType === 'signup' ? {
      wtid: formData.wtid,
      user: {
        email: formData.login,
        password: formData.password,
        password_confirmation: formData.password,
        remember_me: formData.remember || false,
      }
    } : formData;

    try {
      const response = await axios.post(path, authData);

      if (response.status !== 200) {
        this.setState({ loading: false });
        this.setState({ errors: response.data.errors });
        return;
      }

      this.handleSuccessfulRegistration(response.headers)

      const redirectUrl = typeof prevUrl === 'object' ? prevUrl.prevUrl || '/' : prevUrl || '/';
      window.location.href = redirectUrl;
    } catch (error) {
      this.setState({ errors: error.response?.data?.errors || ['An error occurred'] });
      this.setState({ loading: false });
    }
  }

  handleSuccessfulRegistration = (headers) => {
    window.localStorage.setItem('auth_token', headers['x-auth-token'])
    sendAuthToExtension(headers['x-auth-token'])
    window.localStorage.setItem('csrf_token', headers['x-csrf-token'])
  }

  onSuccessGoogle = (responseData) => {
    const { prevUrl } = this.props

    const fetchData = async (googleResponseData) => {
      const path = `${gon.urls.api}/omniauth/provider/auth`

      let queryData = {
        wtid: this.state.wtidCookie,
        id_token: googleResponseData.tokenId,
        provider: 'google_oauth2'
      }

      return axios
        .post(path, queryData)
        .then((response) => {
          if (response.status !== 200) {
            this.setState({ errors: response?.data?.errors })
            return null
          }
          window.localStorage.setItem(
            'auth_token',
            response.headers['x-auth-token']
          )
          sendAuthToExtension(response.headers['x-auth-token'])
          window.localStorage.setItem(
            'csrf_token',
            response.headers['x-csrf-token']
          )

          window.location.href =
            typeof prevUrl === 'object'
              ? prevUrl.prevUrl || '/'
              : prevUrl || '/'
          return null
        })
        .catch((error) => this.setState({ errors: error?.data?.errors }))
    }
    fetchData(responseData)
  }

  /***
   * Handle Google failure response
   */
  handleGoogleSignInErrors = (responseData) => {
    let errorMessage =
      'There was an error authenticating with Google, please try again.'
    if (responseData) {
      errorMessage = responseData
    }
    this.setState({ errors: [errorMessage] })
  }

  /***
   * Handle facebook success response
   */
  responseFacebook = (responseData) => {
    const { prevUrl } = this.props

    const fetchData = async (facebookResponseData) => {
      const path = `${gon.urls.api}/omniauth/provider/auth`

      let queryData = {
        wtid: this.state.wtidCookie,
        id_token: facebookResponseData.accessToken,
        provider: 'facebook'
      }

      return axios
        .post(path, queryData)
        .then((response) => {
          if (response.status !== 200) {
            this.setState({ errors: response?.data?.error })
            return null
          }
          window.localStorage.setItem(
            'auth_token',
            response.headers['x-auth-token']
          )
          sendAuthToExtension(response.headers['x-auth-token'])
          window.localStorage.setItem(
            'csrf_token',
            response.headers['x-csrf-token']
          )

          window.location.href =
            typeof prevUrl === 'object'
              ? prevUrl.prevUrl || '/'
              : prevUrl || '/'
          return null
        })
        .catch((error) => {
          this.setState({
            errors: [
              'There was an error authenticating with Facebook, please try again.'
            ]
          })
        })
    }
    fetchData(responseData)
  }

  /**
   * Handle Facebook failure response
   */
  onFacebookFailure = (failedResponseData) => {
    let errorMessage = ['There was an error authenticating with Facebook, please try again.']
    this.setState({ errors: [errorMessage] })
  }

  handleAttributeChange = (event) => {
    const { formData } = this.state
    const { name, value } = event

    formData[name] = value

    this.setState({ formData })
  }

  /**
   * Handle Apple login success response
  */
  responseAppleLogin = (response) => {
    const { prevUrl } = this.props
    const fetchData = async (appleResponseData) => {
      const path = `${gon.urls.api}/omniauth/provider/apple_id`

      let queryData = {
        wtid: this.state.wtidCookie,
        id_token: appleResponseData?.authorization?.id_token,
        platform: 'web'
      }

      return axios
        .post(path, queryData)
        .then((response) => {
          if (response.status !== 200) {
            this.setState({ errors: response?.data?.error })
            return null
          }
          window.localStorage.setItem(
            'auth_token',
            response.headers['x-auth-token']
          )
          sendAuthToExtension(response.headers['x-auth-token'])
          window.localStorage.setItem(
            'csrf_token',
            response.headers['x-csrf-token']
          )

          window.location.href =
            typeof prevUrl === 'object'
              ? prevUrl.prevUrl || '/'
              : prevUrl || '/'
          return null
        })
        .catch((error) => {
          this.setState({
            errors: [
              'There was an error authenticating with Apple, please try again.'
            ]
          })
        })
    }
    fetchData(response)
  }
  /**
   * Handle Apple login failure response
  */
  onAppleFailure = (failedResponseData) => {
    let errorMessage = ['There was an error authenticating with Apple, please try again.']

    this.setState({ errors: [errorMessage] })
  }

  backClick = () => {
    if (this.state.showEmailForm) {
      this.setState({
        showEmailForm: false
      });
    } else {
      this.props.history.push('/');
    }
  }

  toggleAuthType = (event) => {
    event.preventDefault();
    const newAuthType = this.state.authType === 'login' ? 'signup' : 'login';

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('type', newAuthType);
    const newRelativePathQuery = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState(null, '', newRelativePathQuery);

    this.setState({ authType: newAuthType });
  }

  render() {
    const {
      errors,
      formData,
      formData: { remember_me },
      showEmailForm,
      authType,
      loading
    } = this.state
    const {
      prevUrl: { message, prevUrl, backCount, isCba }
    } = this.props
    // const prevPage = prevUrl?.includes('/shop')
    return (
      <>
        <div className='login-register'>
          <div className='d-flex justify-content-center'>
            <div className='card-container'>
              <div className='card device-form'>
                <div className='card-body pb-0'>

                  <div className="back-button-container">
                    <button
                      className="btn btn-link d-flex align-items-center"
                      onClick={this.backClick}
                    >
                      <img className='back-img' src={backArrow} />
                      Back
                    </button>
                  </div>
                  <div className='text-center mb-3'>
                    <img src='/assets/images/branding/auth-logo.svg' />
                  </div>
                  {!showEmailForm &&
                    <h6>{authType == 'login' ? 'Log in ' : 'Sign up to experience the best of Little Birdie'} </h6>
                  }

                  {/* Error */}
                  <AuthError errors={errors} setErrors={(errors) => this.setState({ errors })} />

                  {/* AuthForm Continue with email */}
                  {showEmailForm &&
                    <form onSubmit={this.handleSubmit}>
                      <div className='form-group'>
                        <input
                          className='form-control form-control--theme'
                          placeholder='Email'
                          type='text'
                          name='login'
                          id='user_login'
                          onChange={(e) =>
                            this.handleAttributeChange({
                              name: e.currentTarget.name,
                              value: e.currentTarget?.value.toLowerCase()
                            })
                          }
                        />
                      </div>
                      <div className='form-group'>
                        <div className='form-field-password d-flex'>
                          <input
                            className='form-control form-control--theme'
                            type={this.state.type}
                            placeholder='Password'
                            name='password'
                            id='user_password'
                            onChange={(e) =>
                              this.handleAttributeChange({
                                name: e.currentTarget.name,
                                value: e.currentTarget.value
                              })
                            }
                          />
                          <img
                            onClick={() =>
                              this.setState({
                                type:
                                  this.state.type === 'text' ? 'password' : 'text'
                              })
                            }
                            src={impressionIcon}
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between form-group form-check pl-0'>
                        <div className='custom-control custom-checkbox '>
                          <input
                            name='user[remember_me]'
                            type='hidden'
                            defaultValue={0}
                          />
                          <input
                            className='custom-control-input'
                            type='checkbox'
                            defaultValue={false}
                            name='remember_me'
                            id='user_remember_me'
                            onChange={() =>
                              this.setState({
                                formData: {
                                  ...formData,
                                  remember_me: !remember_me
                                }
                              })
                            }
                          />
                          <label
                            className='custom-control-label'
                            htmlFor='user_remember_me'>
                            Remember me
                          </label>
                        </div>
                        {authType == 'login' &&
                          <div className='device-links align-self-end mb-0 t-14'>
                            <Link to='/users/password/new' className='text-budgee font-weight-bold lh-20'>Forgot your password?</Link>
                          </div>
                        }
                      </div>
                      <div className='text-center pt-4'>
                        <div className='form-group'>
                          <button
                            type='submit'
                            className='btn btn-main rounded-pill'
                          >
                            {loading ? (
                              <Spinner animation="border" variant="secondary" size="sm" />
                            ) : (
                              authType === 'login' ? 'Sign in' : 'Sign up'
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  }

                  {/* AuthOptions */}
                  {!showEmailForm &&
                    <div className='text-center margin-bottom'>
                      <div className='d-inline-flex flex-column justify-content-center'>
                        <div className='form-group align-self-center social-login w-100 rounder-corners'>
                          <GoogleSignInCustom prevUrl={this.props.prevUrl} wtidCookie={this.state.wtidCookie} handleGoogleSignInErrors={(errors) => this.handleGoogleSignInErrors(errors)} />
                        </div>
                        {/* <div className='form-group align-self-center social-login w-100 rounder-corners'>
                          <FacebookLogin
                            appId={FACEBOOK_APP_ID}
                            fields='name,email,picture'
                            scope='public_profile,email'
                            callback={this.responseFacebook}
                            disableMobileRedirect={true}
                            onFailure={this.onFacebookFailure}
                            cssClass='my-facebook-button-class'
                            render={(renderProps) => (
                              <a
                                className='btn btn-block animate shake pointer d-flex align-items-center'
                                onClick={renderProps.onClick}>
                                <div className='social-img mr-3'>
                                  <img
                                    style={{ width: '100%' }}
                                    src={facebookLogin}
                                  />
                                </div>
                                <span>Sign in with Facebook</span>
                              </a>
                            )}
                            icon={facebookLogin}
                          />
                        </div> */}
                        <div className='form-group mb-0 align-self-center social-login w-100 rounder-corners'>
                          <AppleSignin
                            /** Auth options passed to AppleID.auth.init() */
                            authOptions={{
                              /** Client ID - eg: 'com.example.com' */
                              clientId: `${LB_APPLE_CLIENT_ID}`,
                              /** Requested scopes, seperated by spaces - eg: 'email name' */
                              scope: 'email name',
                              /** Apple's redirectURI - must be one of the URIs you added to the serviceID - the undocumented trick in apple docs is that you should call auth from a page that is listed as a redirectURI, localhost fails */
                              redirectURI: `${LB_APPLE_REDIRECT_URI_ID}`,
                              usePopup: true
                            }}
                            className="apple-auth-btn"
                            onSuccess={(response) => this.responseAppleLogin(response)}
                            onError={(error) => this.onAppleFailure(error)}
                            buttonExtraChildren="Continue with Apple"
                            render={(props) => (
                              <a
                                className='btn btn-block animate shake pointer d-flex align-items-center'
                                {...props}>
                                <div className='social-img-apple-id'>
                                  <img
                                    style={{ width: '100%' }}
                                    src={appleIcon}
                                  />
                                </div>
                                <span>Sign in with Apple ID</span>
                              </a>
                            )}
                            icon={appleIcon}
                          />
                        </div>
                      </div>
                      <div className=" align-self-center register-divider">
                        <hr className="divider" />
                      </div>
                      <div className='d-inline-flex flex-column justify-content-center'>

                        <div className='form-group align-self-center social-login w-100 rounder-corners'>
                          <a
                            onClick={() => { this.setState({ showEmailForm: true }) }}
                            className='btn btn-block animate shake pointer d-flex align-items-center'
                          >
                            <div className='social-img-apple-id'>
                              <img
                                style={{ width: '100%', height: '30px' }}
                                src={dotEmail}
                              />
                            </div>
                            <span>Continue with Email</span>
                          </a>
                        </div>


                      </div>
                    </div>
                  }

                  <div className='device-links d-flex justify-content-center align-items-center t-14'>
                    {authType == 'login' ? "Don't have an account?" : "Have an account?"}
                    <a href="#" onClick={this.toggleAuthType} className='nav-link p-0 pl-1 text-underline text-uppercase text-budgee font-weight-bold'>
                      {authType == 'login' ? 'Sign up' : 'Log in'}
                    </a>
                  </div>
                </div>

                {!showEmailForm &&
                  <div className='device-links text-center'>
                    By joining in you agree to our&nbsp;
                    <a href='/terms' target='_new'>
                      Terms and Conditions
                    </a>
                    ,&nbsp;
                    <a
                      href='https://help.littlebirdie.com.au/hc/en-us/articles/360057583591'
                      target='_new'>
                      Community Standards
                    </a>
                    {' and'}
                    &nbsp;
                    <a href='/privacy' target='_new'>
                      Privacy Policy
                    </a>
                    .
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(withCookies(Login));
