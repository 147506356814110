import {
  all, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"

function* removeSaleEvent({ id: saleEventId }) {
  const { upcomingSaleEventIds, activeSaleEventIds } = yield select(state => state.saleEventCalendar)
  if (activeSaleEventIds.includes(saleEventId)) {
    const list = [...activeSaleEventIds]
    _.remove(list, n => n === saleEventId)
    yield put(actions.calendarUpdateActiveList(list))
  } else if (upcomingSaleEventIds.includes(saleEventId)) {
    const list = [...upcomingSaleEventIds]
    _.remove(list, n => n === saleEventId)
    yield put(actions.calendarUpdateUpcomingList(list))
  }
  yield put(actions.triggerFollowing({ type: "saleEvent", id: saleEventId }))
}


function* fetchData(action) {
  yield all([
    fork(removeSaleEvent, action),
  ])
}

function* watchSaleEventCalendarRemoveSaleEventSaga() {
  yield takeLatest(actionTypes.CALENDAR_REMOVE_SALE_EVENT, fetchData)
}


export {
  watchSaleEventCalendarRemoveSaleEventSaga
}