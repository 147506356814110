import React, { Component } from "react"
import { Link } from "react-router-dom"

import { LazyLoadImage } from "react-lazy-load-image-component"

import FollowButton from "../../../../apps/App/components/buttons/FollowButton"

class UpcomingSale extends Component {
  handleClickUpvote = () => {
    const { onClickUpvote, item } = this.props
    onClickUpvote({ item })
  }

  handleClickDownvote = () => {
    const { onClickDownvote, item } = this.props
    onClickDownvote({ item })
  }

  handleClickComments = () => {
    const { onClickComments, item } = this.props
    onClickComments({ item })
  }

  render() {
    const {
      wrapperClass,
      item,
      item: {
        attributes,
        links,
        relationships: {
          store
        }
      },
      sectionId
    } = this.props

    return (
      <div className={`listing-card text-center card-event-sm ${wrapperClass}`}>
        <div className="card shadow-sm border-0">
          <div className="card-body">
            <div className="card-image">
              <Link
               to={links.saleEventUrl}
               onClick={()=> sectionId && localStorage.setItem('hsref', sectionId)}>
                <LazyLoadImage
                  effect="opacity"
                  className="img-fluid listing-image mx-auto mb-1"
                  style={{ objectFit: "cover" }}
                  alt={attributes.name}
                  src={links.imageUrl} />
              </Link>
            </div>
            <div className="card-content">
              <div className="card-title">
                {_.get(store, "attributes.name")}
              </div>
              <div className="card-text small mb-0">
                {attributes.name}
              </div>
              <div className="text-muted small mb-1">
                <i>
                  {attributes.startIn}
                </i>
              </div>
              <FollowButton
                item={item}
                buttonClasses="btn rounded-pill btn-peach add-to-calendar my-2 my-xl-0"
                central
                isCalendar
                activeText="Added to calendar"
                inactiveText="Add to calendar"
                follow_type="calendar" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UpcomingSale