import { combineReducers } from "redux"

import * as actionTypes from "../../actions/types"

const request = (state = null, action) => {
  switch (action.type) {
    case actionTypes.CBA_EXCLUSIVE_PICK_REQUEST_START: {
      return actionTypes.CBA_EXCLUSIVE_PICK_REQUEST_START
    }
    case actionTypes.CBA_EXCLUSIVE_PICK_REQUEST_SUCCESS: {
      return actionTypes.CBA_EXCLUSIVE_PICK_REQUEST_SUCCESS
    }
    case actionTypes.CBA_EXCLUSIVE_PICK_REQUEST_FAILURE: {
      return actionTypes.CBA_EXCLUSIVE_PICK_REQUEST_FAILURE
    }
    default: {
      return state
    }
  }
}

const byIds = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CBA_EXCLUSIVE_PICK_REQUEST_SUCCESS: {
      const { response: { cbaExclusivePick } } = action
      return cbaExclusivePick || {}
    }
    case actionTypes.CBA_EXCLUSIVE_PICK_REQUEST_FAILURE: {
      return {}
    }
    default: {
      return state
    }
  }
}

export default combineReducers({
  byIds,
  request
})