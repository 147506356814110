import {
    all, fork
  } from 'redux-saga/effects'

  import { watchGotoUrlSagas } from './GotoUrlSaga'

  export default function* RootSaga() {
    yield all([
      fork(watchGotoUrlSagas),
    ])
  }
