import { combineReducers } from 'redux'

import createReducer from '../../../../components/utils/createReducer'

import * as actions from '../../actions/types'

const depDataFetchSuccess = (state, { data }) => { return { ...state, [data.dep]: data.response } }

const listByDep = createReducer([])({
  [actions.AFFI_TOP_DEP_SUCCESS]: depDataFetchSuccess,
  [actions.USER_AFFINITY_REQUEST_SUCCESS]: (state, action) => []
})

const request = createReducer({})({
  [actions.AFFI_TOP_DEP_START]: (state, action) => { return { ...state, [action.data.slug]: action.type } },
  [actions.AFFI_TOP_DEP_SUCCESS]: (state, action) => { return { ...state, [action.data.dep]: action.type } },
  [actions.AFFI_TOP_DEP_FAILURE]: (state, action) => { return { ...state, [action.data.dep]: action.type } }
})

const depOrder = createReducer({})({
  [actions.TOP_DEP_ORDER_STORE]: (state, action) => { return { ...state, ...action?.data } },
  [actions.USER_AFFINITY_REQUEST_SUCCESS]: (state, action) => { return {} }
})

export default combineReducers({
  request,
  listByDep,
  depOrder
})
