import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchSaleEventBySlug({ identifier }) {
  yield put(actions.saleEventRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error, status, errorMessage } = yield call(requester.getSaleEventByIdentifier, baseUrl, identifier)
  yield (response) ? put(actions.saleEventRequestSuccess({ response, status, errorMessage })) : put(actions.saleEventRequestFailure(error))
}

function* fetchTopBrands() {
  const baseUrl = yield select(getBaseUrl)
  yield put(actions.topBrandsRequestStart())
  const { response, error } = yield call(requester.getTopBrands, baseUrl, { per_page: 8 })
  yield (response) ? put(actions.topBrandsRequestSuccess(response)) : put(actions.topBrandsRequestFailure(error))
}

function* fetchTopUpcomingSaleEvents() {
  const baseUrl = yield select(getBaseUrl)
  yield put(actions.topUpcomingSaleEventsRequestStart())
  const params = {
    upcoming: true
  }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topUpcomingSaleEventsRequestSuccess(response)) : put(actions.topUpcomingSaleEventsRequestFailure(error))
}

function* fetchTopCurrentSaleEvents() {
  const baseUrl = yield select(getBaseUrl)
  yield put(actions.topCurrentSaleEventsRequestStart())
  const params = {
    current: true
  }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topCurrentSaleEventsRequestSuccess(response)) : put(actions.topCurrentSaleEventsRequestFailure(error))
}

function* fetchTopDealHunters() {
  yield put(actions.topDealHuntersRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getTopDealHunters, baseUrl, { per_page: 8 })
  yield (response) ? put(actions.topDealHuntersRequestSuccess(response)) : put(actions.topDealHuntersRequestFailure(error))
}

function* fetchData(data) {
  yield all([
    fork(fetchSaleEventBySlug, data),
    fork(fetchTopBrands, data),
    fork(fetchTopUpcomingSaleEvents, data),
    fork(fetchTopCurrentSaleEvents, data),
    fork(fetchTopDealHunters, data)
  ])
}

function* watchSaleEventPageSaga() {
  yield takeLatest(actionTypes.LOAD_SALE_EVENT_PAGE, fetchData)
}

export {
  watchSaleEventPageSaga
}