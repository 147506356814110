import React, { Component } from "react"
import { Link } from "react-router-dom"

import { LazyLoadImage } from "react-lazy-load-image-component"

class CurrentSaleCard extends Component {
  handleClickUpvote = () => {
    const { onClickUpvote, item } = this.props
    onClickUpvote({ item })
  }

  handleClickDownvote = () => {
    const { onClickDownvote, item } = this.props
    onClickDownvote({ item })
  }

  handleClickComments = () => {
    const { onClickComments, item } = this.props
    onClickComments({ item })
  }

  render() {
    const {
      wrapperClass,
      item: {
        attributes,
        links,
        relationships: {
          store
        }
      },
      sectionId,
    } = this.props

    return (
      <div className={`listing-card text-center card-event-sm current-sale-card ${wrapperClass}`}>
        <div className="card shadow-sm border-0">
          <div className="card-body">
            <div className="card-image">
              <Link
                to={links.saleEventUrl}
                onClick={()=> sectionId && localStorage.setItem('hsref', sectionId)}>
                <LazyLoadImage
                  effect="opacity"
                  className="img-fluid listing-image mx-auto mb-1"
                  style={{ objectFit: "cover" }}
                  alt={attributes.name}
                  src={links.imageUrl} />
              </Link>
            </div>
            <div className="card-content">
              <div className="card-title">
                {_.get(store, "attributes.name")}
              </div>
              <div className="card-text small mb-0">
                {attributes.name}
              </div>
              <div className="text-muted small mb-1">
                <i>
                  {attributes.endIn}
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CurrentSaleCard