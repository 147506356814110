import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { hideUserSlider } from '../../../actions'
import { handleNativeRedirectWebView } from '../../../utilis/NativeDataShare'
import { LB_NATIVE_APP_VERSION } from '../../../../../../constant'

export default function AboutField () {
  const dispatch = useDispatch()
  const history = useHistory()
  const handleNativeRedirect = (redirectUrl, type, redirectSource) => {
    if (window.ReactNativeWebView) {
      handleNativeRedirectWebView(`{"type":"${type}", "path":"${window.location.href}"}`)
    } else {
      dispatch(hideUserSlider())
      if (redirectSource === 'external') {
        window.open(`${redirectUrl}`, '_parent')
      } else if (redirectSource === 'internal') {
        history.push(`${type}`)
      }
    }
  }
  return (
    <>
      <div className='side-block-field about'>
        <ul className='list-group'>
          {/* <li className='list-group-item'><a href='javascript:void(0)' onClick={() => { handleNativeRedirect('https://help.littlebirdie.com.au/hc/en-us/articles/360057583671', 'about', 'external') }}>About Us</a></li> */}
          <li className='list-group-item'>
            <Link
              to='/privacy'
              onClick={(event) => {
                event.preventDefault()
                handleNativeRedirect('privacy', '/privacy', 'internal')
              }}
            >
              Privacy Policy
            </Link>
          </li>
          <li className='list-group-item'>
            <Link
              to='/terms'
              onClick={(event) => {
                event.preventDefault()
                handleNativeRedirect('terms', '/terms', 'internal')
              }}
            >
              Terms & Conditions
            </Link>
          </li>
          {/* <li className='list-group-item'><a href='javascript:void(0)' onClick={() => { handleNativeRedirect('https://help.littlebirdie.com.au/hc/en-us/articles/360057583591', 'community', 'external') }}>Community Rules</a></li> */}
          {/* <li className='list-group-item'><a href='https://help.littlebirdie.com.au/hc/en-us/requests/new' onClick={() => { dispatch(hideUserSlider()) }}>Advertise</a></li> */}
          <li className='list-group-item'><a href='#' onClick={(e) => { e.preventDefault(); handleNativeRedirect('https://help.littlebirdie.com.au', 'contact', 'external') }}>Contact us</a></li>
          <li className='list-group-item' style={{display: 'flex',  justifyContent: 'end'}}><small>{LB_NATIVE_APP_VERSION ?  'version ' + LB_NATIVE_APP_VERSION : ''}</small></li>
        </ul>
      </div>
    </>
  )
}
