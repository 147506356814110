import React from 'react'
import LBBirdText from '../../../../../../assets/images/icons/LB-bird-text.png'
import useWindowSize from '../../../hooks/useWindowSize'
import UserMenuSlider from '../MobileNav/UserMenuSlider'
import { useSelector } from 'react-redux';
import { getRouter } from '../../../reducers';

function index() {
  const { width } = useWindowSize()
  const breakWidth = 1200
  const { location } = useSelector(getRouter)
  const homePage = (location.pathname === '/' || location.pathname === '' || location.pathname === '/home')
  const showNavbar = !window.ReactNativeWebView && homePage

  return (
    <>
      {width < breakWidth && (
        <>
          <UserMenuSlider />
          {/* <CatMenuSlider /> */}
        </>
      )}
      {showNavbar &&
        <div className='fixed-top main-nav'>
          <nav className='navbar navbar-expand-xl'>
            <div className='container-fluid justify-content-center'>
              <a className='navbar-brand mr-0 bg-transparent' href='/'>
                <img src={LBBirdText} style={{ height: '42px !important' }} />
              </a>
            </div>
          </nav>
        </div>
      }
    </>
  )
}

export default index
