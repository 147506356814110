import React, { Component } from "react"
import { connect } from "react-redux"
import swal from "sweetalert"

import { updateNotificationSettings } from "../../requesters"
import { getBaseUrl } from "../../reducers"
import { currentUserUpdate } from "../../actions"
import Loader from "../../../../components/Loader"

class EmailNotification extends Component {
  constructor(props) {
    super(props)
    const {
      currentUser: {
        attributes
      }
    } = this.props

    this.state = {
      global: attributes?.notification_status
    }
  }

  submitForm = (value) => {
    this.setState({ global: value })
    const { baseUrl, currentUserUpdate } = this.props

    async function handleSubmit() {
      const { response } = await updateNotificationSettings(baseUrl, { global: value })
      if (response.status === "ok") {
        currentUserUpdate(response.current_user)
        swal({
          title: "Your email notifications have been updated",
          button: "OK!"
        })
      }
    }

    handleSubmit()
  }

  render() {
    const {
      currentUser: {
        attributes
      }
    } = this.props
    const { global } = this.state
    const disabled = global != undefined? attributes?.notification_status === global :attributes?.notification_status
    return (
      <section>
        <h3>
          Email notifications
        </h3>
        <div className="card settings-card mt-3">
          <div className="card-body">
            {attributes ?
            <>
            <h4>Don&apos;t worry, we hate spam too!</h4>
            We want to ensure that you’re not being bombarded by daily emails, so we’ll only email you when we think we’ve got something you’ll really enjoy!
            <section className="pt-4">
              <h4>Your email notifications</h4>
              <div className="email-notif-switch">
                <label class="switch">
                  <input defaultChecked={attributes?.notification_status || false} onClick={()=>this.submitForm(!attributes?.notification_status)} type="checkbox" />
                  <span class="slider round"></span>
                </label>
                <label className="switch-label">
                  I agree to receive email notifications from Little Birdie
                </label>
              </div>
            </section>
            </>
            : <Loader />}
          </div>
        </div>
      </section>
    )
  }
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps, {
  currentUserUpdate
})(EmailNotification)
