/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getStoreByIdentifier = (baseUrl, identifier) => {
  const path = `${baseUrl}/stores/${identifier}`

  return axios.get(path, {})
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const getStores = (baseUrl, data = {}) => {
  const path = `${baseUrl}/stores`
  return axios.get(path, { params: data })
    .then(({ data, status }) => ({
      response: {
        data: normalize(data),
        ids: _.get(data, "meta.order", [])
      },
      status
    }))
    .catch(error => { error })
}

export const getOrderedTopStores = (baseUrl, data = {}) => {
  const path = `${baseUrl}/top_lists/stores`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({
      response: {
        data: normalize(data),
        ids: _.get(data, "meta.order", [])
      },
      status
    }))
    .catch(error => { error })
}


export const getStoreReviewsByIdentifier = (baseUrl, identifier, data = {}) => {
  const path = `${baseUrl}/stores/${identifier}/reviews?limit=100`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => error)
}