import * as types from "./types"

export * from "./lists"
export * from "./users"
export * from "./cms"
export * from "./stores"
export * from "./brands"
export * from "./deals"
export * from "./coupons"
export * from "./sale_events"
export * from "./pages"
export * from "./user_activities"
export * from "./departments"
export * from "./tags"
export * from "./categories"
export * from "./sub_categories"
export * from "./voting"
export * from "./following"
export * from "./comments"
export * from "./search"
export * from "./menu"
export * from "./top_picks"
export * from "./your_top_picks"
export * from "./cba_exclusive_picks"
export * from "./sale_event_calendar"
export * from "./unique_codes"
export * from './experiments'
export * from './goto'
export * from './affi_top_department'
export * from './merch_top_department'
export * from './personalise'
export * from './custom_widget'
export * from './affinity_top_category'
export * from './carousel'

export const initApplication = () => ({
  type: types.INIT_APPLICATION
})
