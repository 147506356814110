/* eslint-disable no-unused-expressions */
import axios from 'axios'
import normalize from 'json-api-normalizer'

export const getTopElasticDeals = (baseUrl, data) => {
  const path = `${baseUrl}/search_elastic/top_list`

  const id = 'deal_search'
  const params = _.extend(data, id)

  return axios.post(path, params)
    .then(({ data = [], status = '', headers }) => ({ response: data, status, headers }))
    .catch(error => error)
}

export const getAllElasticDeals = (baseUrl, data) => {
  const path = `${baseUrl}/search_elastic/all_products`
  const params = data

  return axios.post(path, params)
    .then(({ data = [], status = '', headers }) => ({ response: data, status, headers }))
    .catch(error => error)
}

export const getElasticResults = (baseUrl, data) => {
  const path = `${baseUrl}/search_elastic/search`

  const id = 'deal_search_v2'
  const params = _.extend(data, id)

  return axios.post(path, params)
    .then(({ data, status, headers }) => ({ response: data, status, headers }))
    .catch(error => { error })
}

// For vouchers search results
export const getElasticVouchersResults = (baseUrl, data) => {
  const path = `${baseUrl}/search_elastic/voucher`

  const params = _.extend(data)

  return axios.post(path, params)
    .then(({ data, status, headers }) => ({ response: data, status, headers }))
    .catch(error => { error })
}

export const getElasticSaleEventResults = (baseUrl, params) => {
  const path = `${baseUrl}/search_elastic/sale_event`

  return axios.post(path, params)
    .then(({ data, status, headers }) => ({ response: data, status, headers }))
    .catch(error => { error })
}

export const getDealsByKeyword = (baseUrl, data) => {
  // we really need global api, it is returning same result as of search api and if we remove it,
  // it is not affecting search page results,
  // but affects top sale event & vouchers results, which are empty if we move from search page to it

  const path = `${baseUrl}/search_elastic/global`
  return axios.post(path, data)
    .then(({ data, status, headers }) => ({
      response: normalize(data), status, order: data.meta.order, headers
    }))
    .catch(error => { error })
}

export const getElasticSuggestions = (baseUrl, keyword) => {
  const path = `${baseUrl}/search_elastic/suggestions`
  const params = {
    id: 'deal_search',
    query: keyword
  }

  return axios.post(path, params)
    .then(({ data, status, headers }) => ({ response: data, status, headers }))
    .catch(error => { error })
}

export const getElasticGlobalSuggestions = (baseUrl, data, pageContext) => {
  const path = `${baseUrl}/search_elastic/global_suggessions`
  const params = {
    query: data.keyword || data || '',
    pagecontext_key: pageContext.pageContextKey,
    pagecontext_value: pageContext.pageContextValue
  }

  return axios.post(path, params)
    .then(({ data, status, headers }) => ({ response: data, status, headers }))
    .catch(error => { error })
}

export const getAssociationByKeyword = (baseUrl, data) => {
  const path = `${baseUrl}/search_elastic/filter_options`

  return axios.post(path, data)
    .then(({ data, status, headers }) => ({ response: data, status, headers }))
    .catch(error => { error })
}

export const getTopListFilters = (baseUrl, data) => {
  const path = `${baseUrl}/search_elastic/top_list_filter_options`

  return axios.post(path, data)
    .then(({ data, status, headers }) => ({ response: data, status, headers }))
    .catch(error => { error })
}

export const getAllDealFilters = (baseUrl, data) => {
  const path = `${baseUrl}/search_elastic/deal_filter_options`

  return axios.post(path, data)
    .then(({ data, status, headers }) => ({ response: data, status, headers }))
    .catch(error => { error })
}


export const getAllFilterOptions = (baseUrl, data, type) => {
  let endpoint = 'all_filter_options' // Search Keyword

  if(type === 'top-list') {
    endpoint = 'all_top_list_filter_options'
  }
  else if (type === 'all-deal') {
    endpoint = 'all_deal_filter_options'
  }

  const path = `${baseUrl}/search_elastic/${endpoint}`

  return axios.post(path, data)
    .then(({ data = {}, status, headers }) => ({ response: data, status, headers }))
    .catch(error => { error })
}
