/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const followingRequester = (baseUrl, { type, id, action }) => {
  const resourceTypeUrl = {
    saleEvent: "SaleEvent",
    coupon: "Coupon",
    deal: "Deal",
    user: "User",
    store: "Store",
    brand: "Brand",
    department: "Department"
  }[type]
  const params = action ? { action: action } : {}
  const votingUrl = `${baseUrl}/following/${resourceTypeUrl}/${id}`

  return axios.put(votingUrl, params).then(({ data, status }) => ({ response: normalize(data), status }))
  .catch(error => ({ error }))
}
