export const handleNativeRedirectWebView = (message) => {
  let parsedMessgae = JSON.parse(message)  
  
  parsedMessgae = {
    ...parsedMessgae,
    "previousSessionStorage": JSON.stringify(window.sessionStorage)
  }
  
  return window.ReactNativeWebView?.postMessage(JSON.stringify(parsedMessgae))
}

export const restorePreviosSessionStorage = () => {
  if(window.ReactNativeWebView) {
    let previousSessionStorage = window.localStorage.getItem('previousSessionStorage') || ''
    try {
      if(previousSessionStorage.length) 
        previousSessionStorage = JSON.parse(previousSessionStorage)
    } catch (error) {
      previousSessionStorage = null
    }

    if(previousSessionStorage && typeof previousSessionStorage === 'object' && previousSessionStorage !== null) {
      for(const sessionKey in previousSessionStorage) {
        window.sessionStorage.setItem(sessionKey, previousSessionStorage[sessionKey])
      }
    }
  }
}

export const findPosition = (obj, offset = 0) => {
  var currenttop = 0;
  if (obj?.offsetParent) {
     do {
         currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
     return [currenttop-offset];
    }
 }
