import React from 'react'

import { impressionIcon } from '../../components/Icons'

export default function FooterListingCounts ({
  upvotesCount, commentsCount, impressionsCount, wrapperClass, type, id, liClass, countClass, name,
  onFollow, upvoted, downvoteImage
}) {
  let modifiedCountValue = impressionsCount
  if (/^[0-9]*$/.test(impressionsCount)) {
    if (impressionsCount <= 999) modifiedCountValue = impressionsCount <= 10 ? '' : impressionsCount
    else if (impressionsCount >= 1000 && impressionsCount < 1000000) {
      const r = impressionsCount % 1000
      const n = (impressionsCount - r) / 1000
      const number = r > 500 ? n + 1 : n
      modifiedCountValue = number + 'K'
    } else if (impressionsCount >= 1000000 && impressionsCount < 999999999) {
      const r = impressionsCount % 1000000
      const n = (impressionsCount - r) / 1000000
      const number = r > 500000 ? n + 1 : n
      modifiedCountValue = number + 'M'
    } else {
      modifiedCountValue = '999M+'
    }
  }

  return (
    <>
      {
        modifiedCountValue &&
          <div className='align-items-center d-flex view-count justify-content-end' style={{ cursor: 'pointer' }}>
            <img src={impressionIcon} />
            <small>{modifiedCountValue} Views </small>
          </div>
      }
    </>
  )
}
