import { combineReducers } from "redux"

import createReducer from "../../../../components/utils/createReducer"

import * as actions from "../../actions/types"

import topPicks from "./top_picks"
import yourTopPicks from './your_top_picks'
import cbaExclusivePicks from "./cba_exclusive_picks"
import merchandisePick from './custom_widgets'

// Top Deals
const topDealsFetchSuccess = (state, { data }) => _.uniq(state.concat(data.ids))

const deal_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.TOP_DEALS_REFRESH]: () => [],
  [actions.TOP_DEALS_REQUEST_SUCCESS]: topDealsFetchSuccess
})

const deals_request = createReducer(null)({
  [actions.TOP_DEALS_REQUEST_START]: (_, { type }) => type,
  [actions.TOP_DEALS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.TOP_DEALS_REQUEST_FAILURE]: (_, { type }) => type
})

// Sibling deals
const siblingDealsFetchSuccess = (state, { data }) => _.map(data.deal, item => parseInt(item.id, 10))

const sibling_deal_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.SIBLING_DEALS_REQUEST_START]: () => [],
  [actions.SIBLING_DEALS_REQUEST_SUCCESS]: siblingDealsFetchSuccess
})

const sibling_deals_request = createReducer(null)({
  [actions.SIBLING_DEALS_REQUEST_START]: (_, { type }) => type,
  [actions.SIBLING_DEALS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.SIBLING_DEALS_REQUEST_FAILURE]: (_, { type }) => type
})

// Top Price Drops
const topPriceDropsFetchSuccess = (state, { data }) => _.uniq(state.concat(_.map(_.orderBy(data.deal, "attributes.rank"), item => parseInt(item.id, 10))))
const price_drop_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.TOP_PRICE_DROPS_REQUEST_SUCCESS]: topPriceDropsFetchSuccess
})

const price_drops_request = createReducer(null)({
  [actions.TOP_PRICE_DROPS_REQUEST_START]: (_, { type }) => type,
  [actions.TOP_PRICE_DROPS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.TOP_PRICE_DROPS_REQUEST_FAILURE]: (_, { type }) => type
})

// Top Brands
const topBrandsFetchSuccess = (state, { data }) => _.uniq(state.concat(data.ids))

const brand_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.TOP_BRANDS_REQUEST_SUCCESS]: topBrandsFetchSuccess,
  [actions.TOP_BRANDS_REFRESH]: () => [],
})

const brands_request = createReducer(null)({
  [actions.TOP_BRANDS_REQUEST_START]: (_, { type }) => type,
  [actions.TOP_BRANDS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.TOP_BRANDS_REQUEST_FAILURE]: (_, { type }) => type,
  [actions.TOP_BRANDS_REFRESH]: (_, { type }) => type
})

// Top Stores
// const topStoresFetchSuccess = (state, { data }) => _.uniq(state.concat(_.map(_.orderBy(data.store, "attributes.rank"), item => parseInt(item.id, 10))))
const topStoresFetchSuccess = (state, { data }) => _.uniq(state.concat(data.ids))

const store_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.TOP_STORES_REFRESH]: () => [],
  [actions.TOP_STORES_REQUEST_SUCCESS]: topStoresFetchSuccess
})

const stores_request = createReducer(null)({
  [actions.TOP_STORES_REQUEST_START]: (_, { type }) => type,
  [actions.TOP_STORES_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.TOP_STORES_REQUEST_FAILURE]: (_, { type }) => type,
  [actions.TOP_STORES_REFRESH]: (_, { type }) => type
})

// Top Deal Hunters
// const topDealHuntersFetchSuccess = (state, { data }) => _.uniq(state.concat(_.map(_.orderBy(data.user, "attributes.rank"), item => parseInt(item.id, 10))))
const topDealHuntersFetchSuccess = (state, { data }) => _.uniq(state.concat(data.ids))

const deal_hunter_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.TOP_DEAL_HUNTERS_REQUEST_SUCCESS]: topDealHuntersFetchSuccess
})

const deal_hunters_request = createReducer(null)({
  [actions.TOP_DEAL_HUNTERS_REQUEST_START]: (_, { type }) => type,
  [actions.TOP_DEAL_HUNTERS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.TOP_DEAL_HUNTERS_REQUEST_FAILURE]: (_, { type }) => type
})

// Top Coupons
const topCouponsFetchSuccess = (state, { data }) => _.uniq(state.concat(data.ids))


const coupon_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.TOP_COUPONS_REFRESH]: () => [],
  [actions.TOP_COUPONS_REQUEST_SUCCESS]: topCouponsFetchSuccess
})

const coupons_request = createReducer(null)({
  [actions.TOP_COUPONS_REQUEST_START]: (_, { type }) => type,
  [actions.TOP_COUPONS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.TOP_COUPONS_REFRESH]: (_, { type }) => type,
  [actions.TOP_COUPONS_REQUEST_FAILURE]: (_, { type }) => type
})

// Top Sale Events
// We now handle passing the order, which means the ordering of ids are handled by the server and we only need to pass the ids
// The requester has also changed to handle this
const topSaleEventsFetchSuccess = (state, { data }) => _.uniq(state.concat(data.ids))

const sale_event_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.TOP_SALE_EVENTS_CLEAR]: () => [],
  [actions.TOP_SALE_EVENTS_REQUEST_SUCCESS]: topSaleEventsFetchSuccess
})

const sale_events_request = createReducer(null)({
  [actions.TOP_SALE_EVENTS_REQUEST_START]: (_, { type }) => type,
  [actions.TOP_SALE_EVENTS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.TOP_SALE_EVENTS_REFRESH]: (_, { type }) => type,
  [actions.TOP_SALE_EVENTS_REQUEST_FAILURE]: (_, { type }) => type
})

// Top Current Sale Events
const topCurrentSaleEventsFetchSuccess = (state, { data }) => _.uniq(state.concat(data.ids))

const current_sale_event_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.TOP_CURRENT_SALE_EVENTS_REQUEST_SUCCESS]: topCurrentSaleEventsFetchSuccess
})

const current_sale_events_request = createReducer(null)({
  [actions.TOP_CURRENT_SALE_EVENTS_REQUEST_START]: (_, { type }) => type,
  [actions.TOP_CURRENT_SALE_EVENTS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.TOP_CURRENT_SALE_EVENTS_REQUEST_FAILURE]: (_, { type }) => type
})

// Top Upcoming Sale Events
const topUpcomingSaleEventsFetchSuccess = (state, { data }) => _.uniq(state.concat(data.ids))

const upcoming_sale_event_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.TOP_UPCOMING_SALE_EVENTS_REQUEST_SUCCESS]: topUpcomingSaleEventsFetchSuccess
})

const upcoming_sale_events_request = createReducer(null)({
  [actions.TOP_UPCOMING_SALE_EVENTS_REQUEST_START]: (_, { type }) => type,
  [actions.TOP_UPCOMING_SALE_EVENTS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.TOP_UPCOMING_SALE_EVENTS_REQUEST_FAILURE]: (_, { type }) => type
})

// NEW Deals
const newDealsFetchSuccess = (state, { data }) => _.uniq(state.concat(data.ids))

const new_deal_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.NEW_DEALS_REFRESH]: () => [],
  [actions.NEW_DEALS_REQUEST_SUCCESS]: newDealsFetchSuccess
})

const new_deals_request = createReducer(null)({
  [actions.NEW_DEALS_REQUEST_START]: (_, { type }) => type,
  [actions.NEW_DEALS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.NEW_DEALS_REQUEST_FAILURE]: (_, { type }) => type
})

// New Sales Events
const newSalesEventsFetchSuccess = (state, { data }) => _.uniq(state.concat(data.ids))

const new_sale_event_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.NEW_SALE_EVENTS_REQUEST_SUCCESS]: newSalesEventsFetchSuccess
})

const new_sale_events_request = createReducer(null)({
  [actions.NEW_SALE_EVENTS_REQUEST_START]: (_, { type }) => type,
  [actions.NEW_SALE_EVENTS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.NEW_SALE_EVENTS_REQUEST_FAILURE]: (_, { type }) => type
})

// New Coupons
const newCouponsFetchSuccess = (state, { data }) => _.uniq(state.concat(data.ids))

const new_coupon_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.NEW_COUPONS_REQUEST_SUCCESS]: newCouponsFetchSuccess
})

const new_coupons_request = createReducer(null)({
  [actions.NEW_COUPONS_REQUEST_START]: (_, { type }) => type,
  [actions.NEW_COUPONS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.NEW_COUPONS_REQUEST_FAILURE]: (_, { type }) => type
})

const followedSaleEventsFetchSuccess = (state, { data }) => _.uniq(state.concat(_.map(_.orderBy(data.saleEvent, ["attributes.updatedAt"], ["desc"]), item => parseInt(item.id, 10))))

const followed_sale_events_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.FOLLOWED_SALE_EVENTS_REQUEST_SUCCESS]: followedSaleEventsFetchSuccess
})

const followed_sale_events_request = createReducer(null)({
  [actions.FOLLOWED_SALE_EVENTS_REQUEST_START]: (_, { type }) => type,
  [actions.FOLLOWED_SALE_EVENTS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.FOLLOWED_SALE_EVENTS_REQUEST_FAILURE]: (_, { type }) => type
})

const followedDealsFetchSuccess = (state, { data }) => _.uniq(state.concat(_.map(_.orderBy(data.deal, ["attributes.updatedAt"], ["desc"]), item => parseInt(item.id, 10))))

const followed_deals_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.FOLLOWED_DEALS_REQUEST_SUCCESS]: followedDealsFetchSuccess
})

const followed_deals_request = createReducer(null)({
  [actions.FOLLOWED_DEALS_REQUEST_START]: (_, { type }) => type,
  [actions.FOLLOWED_DEALS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.FOLLOWED_DEALS_REQUEST_FAILURE]: (_, { type }) => type
})

const followedCouponsFetchSuccess = (state, { data }) => _.uniq(state.concat(_.map(_.orderBy(data.coupon, ["attributes.updatedAt"], ["desc"]), item => parseInt(item.id, 10))))

const followed_coupons_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.FOLLOWED_COUPONS_REQUEST_SUCCESS]: followedCouponsFetchSuccess
})

const followed_coupons_request = createReducer(null)({
  [actions.FOLLOWED_COUPONS_REQUEST_START]: (_, { type }) => type,
  [actions.FOLLOWED_COUPONS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.FOLLOWED_COUPONS_REQUEST_FAILURE]: (_, { type }) => type
})

// Newsletters

const newslettersFetchSuccess = (state, { data }) => _.uniq(state.concat(data.ids))
const newsletter_ids = createReducer([])({
  [actions.LOCATION_CHANGED]: () => [],
  [actions.NEWSLETTERS_REQUEST_SUCCESS]: newslettersFetchSuccess
})

const newsletters_request = createReducer(null)({
  [actions.NEWSLETTERS_REQUEST_START]: (_, { type }) => type,
  [actions.NEWSLETTERS_REQUEST_SUCCESS]: (_, { type }) => type,
  [actions.NEWSLETTERS_REQUEST_FAILURE]: (_, { type }) => type
})

export const getTopDealIds = state => _.values(state.topDeals.ids)
export const getTopPriceDropIds = state => _.values(state.topPriceDrops.ids)
export const getTopBrandIds = state => _.values(state.topBrands.ids)
export const getTopStoreIds = state => _.values(state.topStores.ids)
export const getTopDealHunterIds = state => _.values(state.topDealHunters.ids)
export const getTopCouponIds = state => _.values(state.topCoupons.ids)
export const getTopSaleEventIds = state => _.values(state.topSaleEvents.ids)
export const getTopCurrentSaleEventIds = state => _.values(state.topCurrentSaleEvents.ids)
export const getTopUpcomingSaleEventIds = state => _.values(state.topUpcomingSaleEvents.ids)
export const getSiblingDealIds = state => _.values(state.siblingDeals.ids)
export const getNewDealIds = state => _.values(state.newDeals.ids)
export const getNewSaleEventIds = state => _.values(state.newSaleEvents.ids)
export const getNewCouponIds = state => _.values(state.newCoupons.ids)
export const getFollowedSaleEventIds = state => _.values(state.followedSaleEvents.ids)
export const getFollowedDealIds = state => _.values(state.followedDeals.ids)
export const getFollowedCouponIds = state => _.values(state.followedCoupons.ids)
export const getNewsletterIds = state => _.values(state.newsletters.ids)

export const isFetchingTopDeals = state => _.includes([undefined, null, actions.TOP_DEALS_REQUEST_START], state.topDeals.request)
export const isFetchingTopPriceDrops = state => _.includes([undefined, null, actions.TOP_PRICE_DROPS_REQUEST_START], state.topPriceDrops.request)
export const isFetchingTopBrands = state => _.includes([undefined, null, actions.TOP_BRANDS_REQUEST_START], state.topBrands.request)
export const isFetchingTopStores = state => _.includes([undefined, null, actions.TOP_STORES_REQUEST_START], state.topStores.request)
export const isFetchingTopDealHunters = state => _.includes([undefined, null, actions.TOP_DEAL_HUNTERS_REQUEST_START], state.topDealHunters.request)
export const isFetchingTopCoupons = state => _.includes([undefined, null, actions.TOP_COUPONS_REQUEST_START], state.topCoupons.request)
export const isFetchingTopSaleEvents = state => _.includes([undefined, null, actions.TOP_SALE_EVENTS_REQUEST_START], state.topSaleEvents.request)
export const isFetchingTopCurrentSaleEvents = state => _.includes([undefined, null, actions.TOP_CURRENT_SALE_EVENTS_REQUEST_START], state.topCurrentSaleEvents.request)
export const isFetchingTopUpcomingSaleEvents = state => _.includes([undefined, null, actions.TOP_UPCOMING_SALE_EVENTS_REQUEST_START], state.topUpcomingSaleEvents.request)
export const isFetchingSiblingDeals = state => _.includes([undefined, null, actions.SIBLING_DEALS_REQUEST_START], state.siblingDeals.request)
export const isFetchingNewDeals = state => _.includes([undefined, null, actions.NEW_DEALS_REQUEST_START], state.newDeals.request)
export const isFetchingNewSaleEvents = state => _.includes([undefined, null, actions.NEW_SALE_EVENTS_REQUEST_START], state.newSaleEvents.request)
export const isFetchingNewCoupons = state => _.includes([undefined, null, actions.NEW_COUPONS_REQUEST_START], state.newCoupons.request)
export const isFetchingFollowedSaleEvents = state => _.includes([undefined, null, actions.FOLLOWED_SALE_EVENTS_REQUEST_START], state.followedSaleEvents.request)
export const isFetchingFollowedDeals = state => _.includes([undefined, null, actions.FOLLOWED_DEALS_REQUEST_START], state.followedDeals.request)
export const isFetchingFollowedCoupons = state => _.includes([undefined, null, actions.FOLLOWED_COUPONS_REQUEST_START], state.followedCoupons.request)
export const isFetchingNewsletters = state => _.includes([undefined, null, actions.NEWSLETTERS_REQUEST_START], state.newsletters.request)

// Other Functions
export const getListIdsFromType = (state, listType) => ({
  "top-deals": getTopDealIds(state),
  "top-price-drops": getTopDealIds(state),
  "top-promo-codes": getTopCouponIds(state),
  "top-coupons": getTopCouponIds(state),
  "top-sales-events": getTopSaleEventIds(state),
  "trending-stores": getTopStoreIds(state),
  "top-spotters": getTopDealHunterIds(state),
  "trending-brands": getTopBrandIds(state),
  "top-upcoming-sales": getTopUpcomingSaleEventIds(state),
  "top-current-sales": getTopCurrentSaleEventIds(state),
  "get-new-deals": getNewDealIds(state),
  "get-new-vouchers": getNewCouponIds(state),
  "get-new-sales-events": getNewSaleEventIds(state),
  "new-deals": getTopDealIds(state),
  "new-price-drops": getTopDealIds(state),
  "new-promo-codes": getTopCouponIds(state),
  "new-sales-events": getTopSaleEventIds(state),
  "sales-events": getFollowedSaleEventIds(state),
  deals: getFollowedDealIds(state),
  vouchers: getFollowedCouponIds(state),
  newsletters: getNewsletterIds(state)
}[listType]
)

export const isFetchingListFromType = (state, listType) => ({
  "top-deals": isFetchingTopDeals(state),
  "top-price-drops": isFetchingTopDeals(state),
  "top-promo-codes": isFetchingTopCoupons(state),
  "top-coupons": isFetchingTopCoupons(state),
  "top-sales-events": isFetchingTopSaleEvents(state),
  "trending-stores": isFetchingTopStores(state),
  "top-spotters": isFetchingTopDealHunters(state),
  "trending-brands": isFetchingTopBrands(state),
  "top-upcoming-sales": isFetchingTopUpcomingSaleEvents(state),
  "top-current-sales": isFetchingTopCurrentSaleEvents(state),
  "get-new-deals": isFetchingNewDeals(state),
  "get-new-vouchers": isFetchingNewCoupons(state),
  "get-new-sales-events": isFetchingNewSaleEvents(state),
  "new-deals": isFetchingTopDeals(state),
  "new-price-drops": isFetchingTopDeals(state),
  "new-promo-codes": isFetchingTopCoupons(state),
  "new-sales-events": isFetchingTopSaleEvents(state),
  "sales-events": isFetchingFollowedSaleEvents(state),
  "followed-best-sellers": isFetchingFollowedSaleEvents(state),
  deals: isFetchingFollowedDeals(state),
  vouchers: isFetchingFollowedCoupons(state),
  newsletters: isFetchingNewsletters(state)
}[listType]
)

export default combineReducers({
  topDeals: combineReducers({ ids: deal_ids, request: deals_request }),
  topPriceDrops: combineReducers({ ids: price_drop_ids, request: price_drops_request }),
  topBrands: combineReducers({ ids: brand_ids, request: brands_request }),
  topStores: combineReducers({ ids: store_ids, request: stores_request }),
  topDealHunters: combineReducers({ ids: deal_hunter_ids, request: deal_hunters_request }),
  topCoupons: combineReducers({ ids: coupon_ids, request: coupons_request }),
  topSaleEvents: combineReducers({ ids: sale_event_ids, request: sale_events_request }),
  topCurrentSaleEvents: combineReducers({ ids: current_sale_event_ids, request: current_sale_events_request }),
  topUpcomingSaleEvents: combineReducers({ ids: upcoming_sale_event_ids, request: upcoming_sale_events_request }),
  siblingDeals: combineReducers({ ids: sibling_deal_ids, request: sibling_deals_request }),
  newDeals: combineReducers({ ids: new_deal_ids, request: new_deals_request }),
  newSaleEvents: combineReducers({ ids: new_sale_event_ids, request: new_sale_events_request }),
  newCoupons: combineReducers({ ids: new_coupon_ids, request: new_coupons_request }),
  followedSaleEvents: combineReducers({ ids: followed_sale_events_ids, request: followed_sale_events_request }),
  followedDeals: combineReducers({ ids: followed_deals_ids, request: followed_deals_request }),
  followedCoupons: combineReducers({ ids: followed_coupons_ids, request: followed_coupons_request }),
  newsletters: combineReducers({ ids: newsletter_ids, request: newsletters_request }),
  topPicks,
  yourTopPicks,
  cbaExclusivePicks,
  merchandisePick
})
