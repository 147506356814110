import {
  all, call, put, select, takeLatest, fork
} from 'redux-saga/effects'

import * as actionTypes from '../../actions/types'
import * as actions from '../../actions'
import { getBaseUrl } from '../../reducers'
import { getAffinityData } from '../../requesters'

function* fetchUserAffinityData({ data }) {
  const baseUrl = yield select(getBaseUrl)
  const { response, status, error } = yield call(getAffinityData, baseUrl, data)
  yield (status === 201) ? put(actions.userAffinityRequestSuccess(response)) : put(actions.userAffinityRequestFailure(error))
}

function* watchUserRequestSaga() {
  yield takeLatest(actionTypes.USER_AFFINITY_REQUEST_START, fetchUserAffinityData)
}

export {
  watchUserRequestSaga
}
