import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import SidebarList from '../../../../components/SidebarList'
import ListingCard from '../ListingCard'

import { getBaseUrl } from '../../reducers'
import { getTopBrands } from '../../requesters'

import MobileSidebarList from '../../../../components/MobileSideBarList'

function TopBrandsMobileSidebarList (props) {
  const [topBrandIds, setTopBrandIds] = useState([])
  const [isFetchingTopBrands, setIsFetchingTopBrands] = useState(true)
  const baseUrl = useSelector(getBaseUrl)

  const {
    title,
    description,
    isMobileNavBar,
    department,
    category,
    subcategory,
    showAll,
    viewAllUrl
  } = props

  useEffect(() => {
    const params = {
      per_page: 10
    }

    async function fetchData () {
      const { response } = await getTopBrands(baseUrl, params)
      if (_.get(response, 'data.brand')) {
        setTopBrandIds(response.ids)
      }
      setIsFetchingTopBrands(false)
    }
    fetchData()
  }, [])

  const topListComponents = isMobileNavBar => {
    const itemType = 'brand'
    if (topBrandIds === undefined) { return ([]) }
    if (showAll) {
      return _.map(_.take(topBrandIds, topBrandIds.length), itemId => <ListingCard type={itemType} id={itemId} wrapperClass='' key={`${itemType}-${itemId}`} isMobileNavBar={isMobileNavBar} currentSection={`${title || 'Top Brands'}`} sectionPosition={1} />)
    }
    return _.map(_.take(topBrandIds, 30), itemId => <ListingCard type={itemType} id={itemId} wrapperClass='' key={`${itemType}-${itemId}`} isMobileNavBar={isMobileNavBar} currentSection={`${title || 'Top Brands'}`} sectionPosition={1} />)
  }

  let titleName
  let descriptionText

  if (subcategory) {
    titleName = `${subcategory.attributes.name} Brands`
  } else if (category) {
    titleName = `${category.attributes.name} Brands`
  } else if (department) {
    titleName = `${department.attributes.name} Brands`
  } else {
    titleName = 'Shop by brand'
    descriptionText = 'View the best offers from your favourite brands'
  }

  if (isMobileNavBar) {
    return (
      <MobileSidebarList
        title={title || 'Top Brands'}
        type='Brands'
        description={description || descriptionText}
        viewAllUrl={viewAllUrl || '/trending-brands'}
        isLoading={isFetchingTopBrands}
      >
        {topListComponents(isMobileNavBar)}
      </MobileSidebarList>
    )
  }

  return (
    <SidebarList
      title={title || titleName}
      titleClass='text-navy mb-1'
      description={description || descriptionText}
      viewAllUrl={viewAllUrl || '/trending-brands'}
      isLoading={isFetchingTopBrands}
      showAll={showAll}
    >
      {topListComponents()}
    </SidebarList>
  )
}

export default TopBrandsMobileSidebarList
