import React, { Component } from "react"
import { connect } from "react-redux"

import SidebarList from "../../../../components/SidebarList"
import ListingCard from "../ListingCard"

import * as reducers from "../../reducers"

class TopUpcomingSalesSidebarList extends Component {
  topListComponents = () => {
    const { topUpcomingSaleEventIds, showAll, title } = this.props
    const itemType = "saleEvent"
    const cardStyle = "upcoming"
    if (topUpcomingSaleEventIds === undefined) { return ([]) }
    if (showAll) {
      return _.map(_.take(topUpcomingSaleEventIds, topUpcomingSaleEventIds.length), itemId => <ListingCard type={itemType} cardStyle={cardStyle} id={itemId} key={`${itemType}-${itemId}`} currentSection={`${title || 'TopUpcomingSales'}`} sectionPosition={1} />)
    }
    return _.map(_.take(topUpcomingSaleEventIds, 4), itemId => <ListingCard type={itemType} cardStyle={cardStyle} id={itemId} key={`${itemType}-${itemId}`} currentSection={`${title || 'TopUpcomingSales'}`} sectionPosition={1} />)
    }

  render() {
    const {
      isFetchingTopUpcomingSaleEvents,
      title,
      description,
      showAll
    } = this.props

    return (
      <SidebarList
        title={title || "Upcoming Sales"}
        titleClass="mb-1"
        description={description}
        viewAllUrl="/top-upcoming-sales"
        isLoading={isFetchingTopUpcomingSaleEvents}
        showAll={showAll}>
        { this.topListComponents() }
      </SidebarList>
    )
  }
}

const mapStateToProps = state => ({
  topUpcomingSaleEventIds: reducers.getTopUpcomingSaleEventIds(state),
  isFetchingTopUpcomingSaleEvents: reducers.isFetchingTopUpcomingSaleEvents(state),
})

export default connect(mapStateToProps, {})(TopUpcomingSalesSidebarList)
