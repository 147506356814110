import React from "react"

import { css } from "@emotion/core"
import PuffLoader from "react-spinners/PuffLoader"

const override = css`
  display: block;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  border-color: red;
`

const Loader = ({
  isLoading = true, color, size, css
}) => (
  <PuffLoader
    css={css || override}
    color={color || "#17a66b"}
    size={size || 60}
    loading={isLoading} />
  )

export default Loader