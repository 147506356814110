import * as types from "./types"

export const yourTopPicksRequestStart = () => ({
  type: types.YOUR_TOP_PICK_REQUEST_START
})

export const yourTopPicksRequestSuccess = ({ response }) => ({
  type: types.YOUR_TOP_PICK_REQUEST_SUCCESS,
  response
})

export const yourTopPicksRequestFailure = error => ({
  type: types.YOUR_TOP_PICK_REQUEST_FAILURE,
  error
})

export const yourTopPickOrderStore = data => ({
  type: types.YOUR_TOP_PICK_ORDER_STORE,
  data
})

export const yourTopPickOrderStoreReset = () => ({
  type: types.YOUR_TOP_PICK_RESET
})
