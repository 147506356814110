import * as types from './types'

export const gotoUrlRequestStart = data => ({
  type: types.GOTO_URL_REQUEST_START,
  data
})

export const gotoUrlRequestSuccess = (response) => ({
  type: types.GOTO_URL_REQUEST_SUCCESS,
  response
})

export const gotoUrlRequestFailure = error => ({
  type: types.GOTO_URL_REQUEST_FAILURE,
  error
})
