import * as types from "./types"

export const triggerUsed = data => ({
  type: types.USED_TRIGGERED,
  data
})

export const usedStart = data => ({
  type: types.USED_START,
  data
})

export const usedSuccess = data => ({
  type: types.USED_SUCCESS,
  data
})

export const usedFailure = data => ({
  type: types.USED_FAILURE,
  data
})
