import React, { Component } from "react"
import { connect } from "react-redux"

import TopLists from "../../../../components/TopLists"
import ListingCard from "../ListingCard"
import * as reducers from "../../reducers"

class NewSalesEventsList extends Component {
  newListComponents = () => {
    const { newSaleEventIds, title, currentSection, sectionPosition} = this.props
    if (newSaleEventIds === undefined) { return ([]) }
    return _.map(_.take(newSaleEventIds, 4), (saleEventId, index) => <ListingCard type="saleEvent" id={saleEventId} wrapperClass="" position={index + 1} key={`top-sales-${saleEventId}`} currentSection={`${currentSection || title || 'Newly Added Sales'}`} sectionPosition={sectionPosition} />)
  }

  render() {
    const {
      isFetchingNewSaleEvents,
      title,
      description,
      wrapperClass,
      viewAllUrl,
      currentSection,
      sectionPosition
    } = this.props

    return (
      <TopLists
        title={title || "Newly Added Sales"}
        description={description || "Right at your fingertips, dive into the hottest sales from these top brands and stores. Limited time only!"}
        isLoading={isFetchingNewSaleEvents}
        wrapperClass={wrapperClass || "row"}
        currentSection={currentSection}
        sectionPosition={sectionPosition}
        viewAllUrl={viewAllUrl || "/top-sales-events"}
        titleClass="mb-1"
        type="sales">
        { this.newListComponents() }
      </TopLists>
    )
  }
}

const mapStateToProps = state => ({
  newSaleEventIds: reducers.getListIdsFromType(state, "get-new-sales-events"),
  isFetchingNewSaleEvents: reducers.isFetchingListFromType(state, "get-new-sales-events"),
})

export default connect(mapStateToProps, {})(NewSalesEventsList)
