/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

// Get stored cookie using javascript
const getCookieValue = (name) => (
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

export const getCbaExclusivePicks = (baseUrl, data = {}) => {
  const path = `${baseUrl}/new_cba_offers`

  const wtid = getCookieValue('wtid')

  return axios.get(path, {
    headers: {
      WTID: wtid || ''
    },
    params: data
  })
    .then(({ data, status }) => {
      if (data.included !== undefined) {
        const includedArr = data.included
        const newMappedData = includedArr.map((item, index) => {
          if (item.type === "sale_event" || item.type === "coupon") {
            const dataItem = {
              id: index + 1,
              type: "cba_exclusive_pick",
              attributes: {
                created_at: item?.attributes?.created_at,
              },
              relationships: {
                cba_exclusive: {
                  data: {
                    id: item.id,
                    type: item.type
                  }
                }
              }
            }
            return dataItem
          }
          return false
        })
        data.data = _.compact(newMappedData)
      }

      return { response: normalize(data), status }
    })
    .catch(error => { error })
}
