import React, { useState } from 'react'
import ModalWrapper from '../../components/Modals/ModalWrapper'
import axios from 'axios'
import { gon } from '../../../../../constant'
import { handleNativeRedirectWebView } from '../../utilis/NativeDataShare'

export default function DeleteAccount () {
  const [showModal, setShowModal] = useState(false)

  const handleDelete = () => {
    const path = `${gon.urls.api}/users`
    const authToken = window.localStorage.getItem('auth_token')
    if (authToken) {
      axios.delete(path, {
        headers: {
          auth_token: authToken
        }
      })
        .then(response => {
          if (response.status === 200) {
            setShowModal(true)
            window.localStorage.clear()
            if (window.ReactNativeWebView) {
              handleNativeRedirectWebView(`{"type":"delete-user", "path":"${window.location.href}"}`)
            } else {
              setTimeout(() => {
                window.location.href = '/'
              }, 1000)
            }
          }
        })
    }
  }
  return (
    <>
      <section>
        <h3>
          Delete your account
        </h3>
        <div className='card settings-card mt-3'>
          <div className='card-body'>
            <p>To request your account and information be deleted, please click the link below. </p>
            <p>We do not keep or store any of your information unless we are required or entitled to by law. Your request for deletion will be completed within 15 mins.</p>
            <p>Once your account is deleted, you will no longer have access to any saved details or member benefits, like price drop alerts or exclusive voucher codes.</p>
            <button
              className='btn btn-main rounded-pill mr-3 btn-size'
              type='submit'
              data-toggle='modal' data-target='#delete-account'
            >
              Request account be deleted
            </button>
          </div>
        </div>
      </section>
      <ModalWrapper id='delete-account'>
        {
          showModal ?
            <div>
              <p>Thank you.</p>
              <p>Your request for deletion will be completed within 15 mins.</p>
            </div>
            :
            <div className='d-flex flex-wrap align-items-center mb-3'>
              <h3 className='delete-text m-0 pr-1'>Are you sure you want to delete your account?</h3>
              <div className='d-flex align-items-center'>
                <a
                  className='btn btn-main rounded-pill mr-3 btn-size'
                  style={{ cursor: 'pointer' }}
                  onClick={handleDelete}
                  data-toggle='modal' data-target='#delete-success-msg'
                >
                  Yes, please delete my account
                </a>
                <a data-dismiss='modal' className='mb-0 underline-text'>No, please don’t delete</a>
              </div>
            </div>

        }

      </ModalWrapper>
    </>
  )
}
