import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useInView } from 'react-intersection-observer'
import positionDataLayer from '../../dataLayers/positionDataLayer'
import { USE_IN_VIEW_OPTIONS } from '../../../../constant'
import { renderStoreBrandStats, getOrderStats }  from '../../utils/renderBrandStoreStats'

export default function BrandCard ({
  wrapperClass,
  item,
  isRankingCard,
  item: { attributes, links },
  // showStats,
  position,
  currentSection,
  sectionPosition,
  sectionId
}) {
  const [brandStats, setBrandStats] = useState([])

  const [refBrandCard, inView] = useInView(USE_IN_VIEW_OPTIONS)

  useEffect(() => {
    if (inView) {
      positionDataLayer(item, 'product-impression', 'product-link', position, currentSection, sectionPosition)
    }
  }, [inView])

  useEffect(() => {
    const allowedKeys = ['dealsCount', 'saleEventsCount', 'couponsCount', 'productsCount', 'followersCount']
    const objectOrder = {
      dealsCount: 0,
      saleEventsCount: 0,
      couponsCount: 0,
      productsCount: 0,
      followersCount: 0
    }
    let modifiedBrandStats= getOrderStats(attributes,allowedKeys,objectOrder)
    setBrandStats(() => ([
      ...modifiedBrandStats,
    ]));

  }, [attributes])

  if (isRankingCard) {
    return (
      <div className={`${wrapperClass}`} ref={refBrandCard}>
        <div className='card card-store-brand-list'>
          { renderStoreBrandStats(brandStats,'list') }
          <Link
            to={{ pathname: links.brandUrl, state: { brandName: attributes.name } }}
            className='d-block'
            onClick={() => {
              positionDataLayer(item, 'card-click', 'product-link', position, currentSection, sectionPosition)
              sectionId && localStorage.setItem("hsref", sectionId)
            }}
          >
            <div className='card-img-top card-img-top-list'>
              <img className='listing-img' src={links.logoUrl} />
            </div>
          </Link>
          <Link to={{ pathname: links.brandUrl, state: { brandName: attributes.name } }}>
            <div className='d-flex justify-content-center align-items-center store-brand-footer store-brand-footer-list'>
              View all from&nbsp;<b className='mb-0'>{attributes.name}</b>
            </div>
          </Link>

        </div>
      </div>
    )
  }
  return (
    <div className='listing-card'  ref={refBrandCard}>
      <div className='card card-store-brand'>
      { renderStoreBrandStats(brandStats) }
        <Link
          to={{ pathname: links.brandUrl, state: { brandName: attributes.name } }}
          onClick={() => {
            positionDataLayer(item, 'card-click', 'product-link', position, currentSection, sectionPosition)
            sectionId && localStorage.setItem("hsref", sectionId)
          }}
        >
          <div className='card-img-top'>
            <LazyLoadImage
              effect='opacity'
              className='mr-xl-2 img-fluid sidebar-logo'
              alt={attributes.name}
              src={links.logoUrl}
            />
          </div>
        </Link>
        <Link to={{ pathname: links.brandUrl, state: { brandName: attributes.name } }}>
          <div className='d-flex justify-content-center align-items-center store-brand-footer'>
            View all from&nbsp;<b className='mb-0'>{attributes.name}</b>
          </div>
        </Link>
      </div>
    </div>
  )
}
