import * as types from "./types"

export const loadComments = data => ({
  type: types.LOAD_COMMENTS,
  data
})

// Loading
export const commentsRequestStart = data => ({
  type: types.COMMENTS_REQUEST_START,
  data
})

export const commentsRequestSuccess = data => ({
  type: types.COMMENTS_REQUEST_SUCCESS,
  data
})

export const commentsRequestFailure = error => ({
  type: types.COMMENTS_REQUEST_FAILURE,
  error
})

// Submitting
export const submitComment = data => ({
  type: types.COMMENT_SUBMITTED,
  data
})

export const commentSubmitRequestStart = data => ({
  type: types.COMMENT_SUBMIT_START,
  data
})

export const commentSubmitRequestSuccess = data => ({
  type: types.COMMENT_SUBMIT_SUCCESS,
  data
})

export const commentSubmitRequestFailure = error => ({
  type: types.COMMENT_SUBMIT_FAILURE,
  error
})

export const resetCommentsCount = () => ({
  type: types.RESET_COMMENTS_COUNT
})
