import React, { Component } from "react"
import { connect } from "react-redux"

import SidebarList from "../../../../components/SidebarList"
import ListingCard from "../ListingCard"

import * as reducers from "../../reducers"
import MobileSidebarList from "../../../../components/MobileSideBarList"
import { setCarouselLoading, setCarouselLoaded } from '../../actions/carousel'

class TopBrandsSidebarList extends Component {
  constructor(props) {
    super(props)
    props.setCarouselLoading({ carousel: props.sectionId })
  }

  componentDidMount(){
    this.props.setCarouselLoaded({ carousel: this.props.sectionId })
  }

  topListComponents = isMobileNavBar => {
    const { topBrandIds, showAll, currentSection, sectionPosition, sectionId } = this.props
    const itemType = "brand"
    if (topBrandIds === undefined) { return ([]) }
    if (showAll) {
      return _.map(_.take(topBrandIds, topBrandIds.length), (itemId, index) => <ListingCard sectionId={sectionId} type={itemType} id={itemId} wrapperClass="" key={`${itemType}-${itemId}`} isMobileNavBar={isMobileNavBar} position={index + 1} currentSection={currentSection} sectionPosition={sectionPosition} />)
    }
    return _.map(_.take(topBrandIds, 30), (itemId, index) => <ListingCard sectionId={sectionId} type={itemType} id={itemId} wrapperClass="" key={`${itemType}-${itemId}`} isMobileNavBar={isMobileNavBar} position={index + 1} currentSection={currentSection} sectionPosition={sectionPosition} />)
  }

  render() {
    const {
      isFetchingTopBrands,
      title,
      description,
      isMobileNavBar,
      department,
      category,
      subcategory,
      showAll,
      viewAllUrl,
      currentSection,
      sectionPosition,
      sectionId
    } = this.props
    let titleName
    let descriptionText

    if (subcategory) {
      titleName = `${subcategory.attributes.name} Brands`
    } else if (category) {
      titleName = 'Brands'
    } else if (department) {
      titleName = `${department.attributes.name} Brands`
      descriptionText = 'View the best offers from your favourite brands'
    } else {
      titleName = `Shop by brand`
      descriptionText = 'View the best offers from your favourite brands'
    }

    if (isMobileNavBar) {
      return (
        <MobileSidebarList
          title={title || "Top Brands"}
          type="Brands"
          description={description || descriptionText}
          viewAllUrl={viewAllUrl || "/trending-brands"}
          currentSection={currentSection}
          sectionPosition={sectionPosition}
          isLoading={isFetchingTopBrands}
          sectionId={sectionId}
          >
          { this.topListComponents(isMobileNavBar) }
        </MobileSidebarList>
      )
    }

    return (
      <SidebarList
        title={title || titleName}
        titleClass="mb-1"
        description={description || descriptionText}
        viewAllUrl={viewAllUrl || "/trending-brands"}
        isLoading={isFetchingTopBrands}
        currentSection={currentSection}
        sectionPosition={sectionPosition}
        homepageBrandStoreClass='homepageBrandStore'
        showAll={showAll}
        sectionId={sectionId}
        >
        { this.topListComponents() }
      </SidebarList>
    )
  }
}

const mapStateToProps = state => ({
  topBrandIds: reducers.getTopBrandIds(state),
  isFetchingTopBrands: reducers.isFetchingTopBrands(state),
})

export default connect(mapStateToProps, {setCarouselLoading, setCarouselLoaded})(TopBrandsSidebarList)
