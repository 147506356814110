import { data } from 'jquery'
import { combineReducers } from 'redux'

import * as actions from '../../actions/types'

const topCategory = (state = {}, action) => {
  switch (action.type) {
    case actions.AFFINITY_TOP_CATEGORY_START:
      return { ...state, isLoading: true }
    case actions.AFFINITY_TOP_CATEGORY_SUCCESS: {
      const updatedState = state.data || {}
      if (updatedState?.[`cat_${action?.data?.id}`] === undefined) {
        updatedState[`cat_${action?.data?.id}`] = action.data
      }
      return { ...state, isLoading: false, data: updatedState || {} }
    }
    case actions.AFFINITY_TOP_CATEGORY_FAILURE:
      return { ...state, isLoading: false }
    default:
      return state
  }
}

export default combineReducers({
  topCategory
})
