import React, { useState, useRef, useEffect } from "react"

import { Modal } from 'react-bootstrap'
import { closeIcon, lbBirdFlyLine, iconCheckGreenCircle, cashrewardIcon } from './Icons'
import safariIcon from '../../assets/images/safari.png'
import { isDesktopChrome } from '../apps/App/utilis/detectBrowser'
import ReadMore from './ReadMore'

export default function ItemQuickPopup(props) {
  const {
    showCardDetail,
    setShowCardDetail,
    cardDetail,
    setCardDetail
  } = props

  const showCardDetailRef = useRef(showCardDetail);

  const isReactNativeWebView = !!window.ReactNativeWebView
  const isNativeIos = isReactNativeWebView && window.localStorage?.getItem('device_os') === 'ios'
  const extEnable = window.localStorage?.getItem('ext_enable') === 'true'
  const extPermission = window.localStorage?.getItem('ext_permission') === 'true'
  const extPermissionPartial = window.localStorage?.getItem('ext_permission_Partial') === 'true'
  const isNativeAndroid = isReactNativeWebView && window.localStorage?.getItem('device_os') === 'android'

  const extSetupDone = extEnable && (extPermission || extPermissionPartial)

  useEffect(() => {
    showCardDetailRef.current = showCardDetail; // Update ref whenever count changes
  }, [showCardDetail]);

  const [fly, setFly] = useState(false)

  const onHide = () => {
    setShowCardDetail(false)
    setCardDetail(null)
    setFly(false)
  }

  const startFly = (inApp = false) => {
    if(!cardDetail?.linkUrl) {
      onHide()
      return
    }

    let linkUrl = new URL(cardDetail.linkUrl)
    
    if(cardDetail.cardType === 'sale-event') {
      linkUrl.searchParams.append('source', 'lb-home');
    }

    if (isReactNativeWebView && !inApp && !extSetupDone && cardDetail.cardType !== 'cashrewards') {
      let parsedMessgae = {
        type: 'redirect-via-new-home',
        data: {
          ...cardDetail,
          linkUrl: linkUrl.href,
          inApp
        }
      }
      window.ReactNativeWebView?.postMessage(JSON.stringify(parsedMessgae))
      onHide()
    } else {
      setFly(Date.now())

      const textInput = document.getElementById('couponCodeInput');

      if (textInput) {
        textInput.select();
        textInput.setSelectionRange(0, 99999); // For mobile devices
        navigator.clipboard.writeText(textInput.value)
      }

      setTimeout(() => {
        if (!showCardDetailRef.current) return

        if (isReactNativeWebView) {
          let parsedMessgae = {
            type: 'redirect-via-new-home',
            data: {
              ...cardDetail,
              linkUrl: linkUrl.href,
              inApp
            }
          }

          window.ReactNativeWebView?.postMessage(JSON.stringify(parsedMessgae))
        } else {
          window.location.href = linkUrl.href
        }
        onHide()
      }, 3000)
    }
  }

  let extInstallUri = null
  let showExtInstallLink = false

  if (cardDetail?.cardType === 'sale-event' && isDesktopChrome) {
    showExtInstallLink = true
    extInstallUri = 'https://chromewebstore.google.com/detail/little-birdie-get-the-bes/adpflddbbcjgdknjnadjbealaahnbhkl'
  }

  if (cardDetail?.cardType === 'sale-event' && isNativeIos && !extSetupDone) {
    showExtInstallLink = true
    extInstallUri = 'javascript:void(0)'
  }

  const installNowClicked = () => {
    if (isReactNativeWebView && isNativeIos) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({
        type: 'redirect-via-new-home',
        data: {
          linkUrl: `${window.location.origin}/mobile/ext/enable`,
          inApp: false,
        }
      }))
    }
  }

  return (
    <Modal show={showCardDetail} className='item-detail-quick-popup' onHide={onHide} centered>
      {cardDetail &&
        <Modal.Body>
          
          <button type='button' className='close' onClick={onHide}>
            <img src={closeIcon} />
          </button>
          {!fly && <>
            <div className='top-section'>
              <img className='main-img' src={cardDetail.mainImage} alt={cardDetail?.offerTitle} />
              {cardDetail.storeLogo && <img className='store-img' src={cardDetail.storeLogo} alt={cardDetail?.storeName} />}
            </div>
            <div className='middle-section text-center'>
              <h4 className='store-name'>
                {cardDetail.storeName || ''}
              </h4>
              <h4 className='title'>
                {cardDetail.offerTitle}
              </h4>
              {(cardDetail.cardType == 'coupon' && cardDetail.couponCode) &&
                <p className="copy-text mt-2">CODE: {cardDetail.couponCode}</p>
              }

              <hr />
              {cardDetail?.termsCondition && <>
                <h4 className='terms'>Terms and conditions apply</h4>
                {cardDetail?.termsCondition && <ReadMore className='terms-value' description={cardDetail?.termsCondition} />}
                {/* <p className='terms-value'>{cardDetail?.termsCondition}</p> */}
              </>
              }

              <div className={`btn-grp ${isNativeIos && cardDetail.cardType !== 'cashrewards' ? 'native-mobile-view' : 'browser-view'} ${isNativeIos && (!extSetupDone || cardDetail.cardType !== 'sale-event') ? 'direction-column' : ''}`}>
                <button onClick={() => startFly(cardDetail.cardType !== 'cashrewards')} className={`${cardDetail.cardType == 'cashrewards' ? 'cashrewards' : ''} in-app`}>
                  {cardDetail.cardType == 'sale-event' && (isNativeIos ? 'Shop in app' : 'Shop now and compare')}
                  {cardDetail.cardType == 'coupon' && (isNativeIos ? 'Copy code and shop in app' : 'Copy code and shop now')}
                  {cardDetail.cardType == 'cashrewards' && 'Fly to  Cashrewards'}
                </button>
                {(cardDetail.cardType !== 'cashrewards' && isNativeIos) &&
                  <button onClick={() => startFly(false)} className="safari">
                    <img src={safariIcon} width={24} height={24} />
                    <span>
                      {extSetupDone ? (cardDetail.cardType == 'sale-event' ? 'Shop in Safari' : 'Copy code and Shop in Safari') : 'Enable Browser Extension to compare prices in Safari'}
                    </span>
                  </button>
                }
              </div>
              {showExtInstallLink && <a onClick={installNowClicked} target={isDesktopChrome ? '_blank' : '_self'} href={extInstallUri}>Don’t have the extension? Install now</a>}
              {cardDetail.endDate && <p className="term-date mt-3 mb-0">{cardDetail.endDate}</p>}
            </div>
          </>
          }
          {fly && <div className="fly-box">
            {(cardDetail.cardType == 'coupon' && cardDetail.couponCode) && <div className="mb-3">
              <h2 className="copy-title">Code Copied</h2>
              <div>
                <img className='copy-check' width={20} src={iconCheckGreenCircle} />
                <span className="copy-text">{cardDetail.couponCode}</span>
              </div>
            </div>}
            <h2 className="fly-box__title">Flying you to {cardDetail.cardType == 'cashrewards' ? 'Cashrewards' : cardDetail.storeName} </h2>
            {cardDetail.cardType == 'sale-event' && <>
              <p className="fly-box__desc">
                {isNativeAndroid ? <>Little Birdie will automatically pop-up to compare prices on valid product pages</> :
                  <>If the Little Birdie extension is enabled.
                    Little Birdie will automatically pop-up to compare prices on product pages.
                  </>
                }
              </p>
            </>
            }
            <div className="fly-box__animation">
              <img src={`${lbBirdFlyLine}?fly=${fly}`} className="fly-box__gif" />
              <img className={`store-img ${cardDetail.cardType}`} src={cardDetail.cardType == 'cashrewards' ? cashrewardIcon : cardDetail.storeLogo} alt={cardDetail?.storeName} />
            </div>
          </div>}
          {cardDetail.couponCode &&
            <input hidden value={cardDetail.couponCode} id="couponCodeInput" />
          }
        </Modal.Body>
      }
    </Modal>
  )
}