export function debounce (func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export const percentageDifference = (final, prev) => {
  return (100 * ((final - prev)/ prev)).toFixed()
}

export const getOffset = (element) => {
  if (!element || (element && !element.getClientRects().length)) {
    return { top: 0, left: 0 }
  }

  let rect = element.getBoundingClientRect()
  let win = element.ownerDocument.defaultView

  return ({
    top: rect.top + win.pageYOffset,
    left: rect.left + win.pageXOffset
  })
}


export const isValidUrl = urlString => {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};