import {
  all, call, put, select, takeLatest, fork
} from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import { followingStart, followingSuccess, followingFailure } from "../../actions"
import { followingRequester } from "../../requesters"
import { getBaseUrl } from "../../reducers"

function* triggerFollowing({ data }) {
  yield put(followingStart(data))
  const baseUrl = yield select(getBaseUrl)

  const { response, error } = yield call(followingRequester, baseUrl, data)
  if (response) {
    yield put(followingSuccess({ ...response, data }))
  } else {
    yield put(followingFailure(error))
  }
}

function* triggerNewFollowingNotification() {
  yield true
}

function* triggerClearFollowingNotification() {
  yield false
}

function* triggerClearNewCalendarNotification() {
  yield false
}

function* fetchData(data) {
  yield all([
    fork(triggerFollowing, data)
  ])
}

function* setNewFollowingNotification(data) {
  yield all([
    fork(triggerNewFollowingNotification, data)
  ])
}

function* clearAllFollowingNotification() {
  yield all([
    fork(triggerClearFollowingNotification),
    fork(triggerClearNewCalendarNotification)
  ])
}

function* watchTriggerFollowingSaga() {
  yield takeLatest(actionTypes.FOLLOWING_TRIGGERED, fetchData)
  yield takeLatest(actionTypes.FOLLOWING_SUCCESS, setNewFollowingNotification)
  yield takeLatest(actionTypes.FOLLOWING_NOTIFY_END, clearAllFollowingNotification)
}

export {
  watchTriggerFollowingSaga
}