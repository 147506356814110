import * as types from "./types"

export const brandRequestStart = data => ({
  type: types.BRAND_REQUEST_START,
  data
})

export const brandRequestSuccess = data => ({
  type: types.BRAND_REQUEST_SUCCESS,
  data
})

export const brandRequestFailure = error => ({
  type: types.BRAND_REQUEST_FAILURE,
  error
})

export const topBrandsRefresh = data => ({
  type: types.TOP_BRANDS_REFRESH,
  data
})