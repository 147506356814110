import { Builder } from '@builder.io/react'
import { useEffect } from 'react'

function ScrollToSection () {
  useEffect(() => {
    const hashParam = window.location.hash
    const ele = document.getElementById('delete-account')
    if (ele && hashParam === '#delete-account') {
      ele?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [])
  return (
    <>&nbsp;</>
  )
}
export default ScrollToSection

Builder.registerComponent(ScrollToSection, {
  name: 'ScrollToSection',
  inputs: [{ eleId: 'eleId', type: 'text' }],
  image: 'https://tabler-icons.io/static/tabler-icons/icons-png/arrow-narrow-up.png'
})
