import React, { Component } from "react"
import { connect } from "react-redux"

import TopLists from "../../../../components/TopLists"
import Meta from "../../../../components/Meta"
import ListingCard from "../ListingCard"
import * as reducers from "../../reducers"

class NewDealsList extends Component {
  newListComponents = () => {
    const { newDealIds, title, currentSection, sectionPosition} = this.props
    if (newDealIds === undefined) { return ([]) }
    return _.map(_.take(newDealIds, 8), (priceDropId, index) => <ListingCard type="deal" id={priceDropId} wrapperClass="" position={index + 1} key={`top-deals-${priceDropId}`} currentSection={`${currentSection || title || 'Newly Added Price Drops'}`} sectionPosition={sectionPosition} />)
  }

  render() {
    const {
      isFetchingNewDeals,
      title,
      description,
      wrapperClass,
      viewAllUrl,
      currentSection,
      sectionPosition
    } = this.props

    return (
      <>
        <Meta
          name="View Individual Newsletter"
          title="The Latest Sale Events, Price Drops and Vouchers | Little Birdie"
          description="Find and Save with the latest deals, vouchers, price drops and sale events across millions of products from hundreds of stores in Fashion, Tech, Grocery, and more." />

        <TopLists
          title={title || "Newly Added Price Drops"}
          description={description || "For the latest and greatest savings, take advantage of all the best offers in one spot from all your favourite brands and stores."}
          isLoading={isFetchingNewDeals}
          wrapperClass={wrapperClass || "row"}
          titleClass="mb-1"
          currentSection={currentSection}
          sectionPosition={sectionPosition}
          viewAllUrl={viewAllUrl}
          type="new deals">
          { this.newListComponents() }
        </TopLists>
      </>
    )
  }
}

const mapStateToProps = state => ({
  newDealIds: reducers.getListIdsFromType(state, "get-new-deals"),
  isFetchingNewDeals: reducers.isFetchingListFromType(state, "get-new-deals")
})

export default connect(mapStateToProps, {})(NewDealsList)
