import { combineReducers } from "redux"

import createReducer from "../../../../../components/utils/createReducer"

import {
  RESOURCE_FETCHED_SUCCESS,
  LOCATION_CHANGED
} from "../../../actions/types"

const newslettersFetchSuccess = (state, { data }) => ({ ...state, ...data.newsletter })

// Newsletters
const byId = createReducer({})({
  [RESOURCE_FETCHED_SUCCESS]: newslettersFetchSuccess,
  [LOCATION_CHANGED]: () => ({})
})


export const getNewsletterById = (state, newsletterId) => state.byId[newsletterId]
export const getNewsletterBySlug = (state, slug) => _.find(state.byId, { attributes: { slug } })

export default combineReducers({
  byId
})
