import React from "react"
import { Link } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Pluralize from "react-pluralize"

import FollowButton from "../../../apps/App/components/buttons/FollowButton"

export default function CommenterCard({
  wrapperClass, item,
  item: {
    attributes,
    links
  }
}) {
  return (
    <div className={`media ${wrapperClass}`}>
      <Link className="link-wrapper" to={`/spotter/${item.attributes.slug}`}>
        <LazyLoadImage
          effect="opacity"
          className="mr-2 rounded-circle profile-img-sm"
          alt={attributes.name}
          src={links.avatar.thumb.url} />
      </Link>
      <div className="media-body">
        <div className="d-flex align-items-center mt-1">
          <div className="subtitle mr-2">
            <Link
              to={links.userUrl}>
              <div>{attributes.username}</div>
            </Link>
          </div>
          <FollowButton item={item} buttonClasses="btn rounded-pill btn-follow btn-peach follow-btn" />
        </div>
        <ul className="list-unstyled small d-none d-md-block mb-0">
          <li><Pluralize singular="follower" count={attributes.followersCount} /></li>
          <li><Pluralize singular="upvote" count={attributes.upvotedCount} /></li>
          <li>
            <Pluralize singular="offer" count={attributes.dealsCount} />
            {" "}
            {"submitted"}
          </li>
        </ul>
      </div>
    </div>
  )
}
