import * as types from "./types"


export const fetchDealBySlug = data => ({
  type: types.LOAD_DEAL_PAGE,
  identifier: data
})

export const dealRequestStart = data => ({
  type: types.DEAL_REQUEST_START,
  data
})

export const dealRequestSuccess = data => ({
  type: types.DEAL_REQUEST_SUCCESS,
  data
})

export const dealRequestFailure = error => ({
  type: types.DEAL_REQUEST_FAILURE,
  error
})

export const triggerNotifyPricedrop = data => ({
  type: types.NOTIFY_PRICEDROP_TRIGGERED,
  data
})

export const notifyPricedropStart = data => ({
  type: types.NOTIFY_PRICEDROP_START,
  data
})

export const notifyPricedropSuccess = data => ({
  type: types.NOTIFY_PRICEDROP_SUCCESS,
  data
})

export const notifyPricedropFailure = data => ({
  type: types.NOTIFY_PRICEDROP_FAILURE,
  data
})
