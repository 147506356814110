import * as types from "./types"

export const saleEventRequestStart = data => ({
  type: types.SALE_EVENT_REQUEST_START,
  data
})

export const saleEventRequestSuccess = data => ({
  type: types.SALE_EVENT_REQUEST_SUCCESS,
  data
})

export const saleEventRequestFailure = error => ({
  type: types.SALE_EVENT_REQUEST_FAILURE,
  error
})