import Cookies from 'universal-cookie'

function SetCbaCookie (wtid, setCookie) {
  const currentCookies = new Cookies()
  const cookieName = 'wtid'
  const currentCbaCookie = currentCookies.get(['wtid'])

  if (currentCbaCookie !== wtid) {
    const cookieAge = new Date()
    cookieAge.setDate(cookieAge.getDate() + 1)

    const options = {
      path: '/',
      expires: cookieAge,
      maxAge: 60 * 60 * 24,
      secure: true,
      SameSite: 'Lax'
    }
    setCookie(cookieName, wtid, options)
  }
}

export default SetCbaCookie
