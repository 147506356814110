import * as types from "./types"

export const cbaExclusiveRequestStart = () => ({
  type: types.CBA_EXCLUSIVE_PICK_REQUEST_START
})

export const cbaExclusiveRequestSuccess = ({ response }) => ({
  type: types.CBA_EXCLUSIVE_PICK_REQUEST_SUCCESS,
  response
})

export const cbaExclusiveRequestFailure = error => ({
  type: types.CBA_EXCLUSIVE_PICK_REQUEST_FAILURE,
  error
})
