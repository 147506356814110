import { useEffect } from 'react'
import { Builder } from '@builder.io/react'
import sendNidToExtension from '../../../../../components/utils/SendNidToExtension'

const SendNidToBE = () => {
  useEffect(() => {
    sendNidToExtension()
  }, [])
  return <></>
}

Builder.registerComponent(SendNidToBE, {
  name: 'SendNidToBE',
  inputs: [],
  image: 'https://tabler-icons.io/static/tabler-icons/icons-png/a-b.png'
})
