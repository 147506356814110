import * as types from './types'

// Top Dep Data from Affinity
export const affiTopDepRequestStart = data => ({
  type: types.AFFI_TOP_DEP_START,
  data
})

export const affiTopDepRequestSuccess = data => ({
  type: types.AFFI_TOP_DEP_SUCCESS,
  data
})

export const affiTopDepRequestFailure = error => ({
  type: types.AFFI_TOP_DEP_FAILURE,
  error
})

export const topDepOrderStore = data => ({
  type: types.TOP_DEP_ORDER_STORE,
  data
})
