/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getTags = (baseUrl, data = {}) => {
  const path = `${baseUrl}/tags`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const getTag = (baseUrl, tagId, data = {}) => {
  const path = `${baseUrl}/tags/${tagId}`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}