import { Builder } from '@builder.io/react'
import CustomWidget from '../../customWidget'

const BiCustomWidget = biData => <CustomWidget biData={biData} id={biData?.customWidgetId} position='' sectionId={biData?.sectionId} />

Builder.registerComponent(BiCustomWidget, {
  name: 'LBCustomWidget',
  inputs: [{ name: 'customWidgetId', type: 'Number' }, { name: 'sectionId', type: 'text' }],
  image: 'https://tabler-icons.io/static/tabler-icons/icons-png/a-b.png'
})
