import axios from "axios"

const allowedNonGetReq = [
  '/api/search_elastic/top_list',
  '/api/search_elastic/all_products',
]

export default class AxiosRequestMiddleware {
  runningRequests = {}

  onRequest(config) {
    let url = new URL(config.url)
    let endPoint = url.pathname

    // config.method.toLowerCase() !== 'get' &&
    if(allowedNonGetReq.indexOf(endPoint) === -1) {
      return config
    }

    let fromIndex = config?.params?.from || 0

    if(config?.data) {
      let parsedData = JSON.parse(config.data)

      if(parsedData.from)
        fromIndex = parsedData.from
    }

    if(this.runningRequests[endPoint]){
      if(this.runningRequests[endPoint][fromIndex]) {
        if(fromIndex == 0) {
          for (const page in this.runningRequests[endPoint]) {
            this.runningRequests[endPoint][page].controller.abort()
            delete this.runningRequests[endPoint][page]
          }
        } else {
          let prevParams = this.runningRequests[endPoint][fromIndex].params

          if (!_.isEqual(config.params, prevParams)) {
            this.runningRequests[endPoint][fromIndex].controller.abort()
            delete this.runningRequests[endPoint][fromIndex]
          }
        }
      }
    }
    else {
      this.runningRequests[endPoint] = {}
    }

    let controller = new AbortController()
    config.signal = controller.signal

    this.runningRequests[endPoint][fromIndex] = {
      params: config.params,
      controller
    }

    return config
  }

  onSync(promise) {
    return promise
  }

  onResponse(response) {
    this.clearRequest(response?.config)

    return response || {}
  }

  onResponseError(error) {
    this.clearRequest(error?.response?.config)
    if(axios.isCancel(error)) {
      return error?.response || {  status:error.status || "", message: error.message, data: [] }
    } else if(error?.response?.status >= 400 && error?.response?.status < 500 ){
   
      return error.response
    }

    throw new Error(error)
  }

  clearRequest(config){
    if(config) {
      let url = new URL(config.url)
      let endPoint = url.pathname
      if(this.runningRequests[endPoint]) {
        let fromIndex = config?.params?.from || 0

        if(config?.data) {
          let parsedData = JSON.parse(config.data)

          if(parsedData.from)
            fromIndex = parsedData.from
        }

        delete this.runningRequests[endPoint][fromIndex]
      }
    }
  }
}