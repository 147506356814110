/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getCouponByIdentifier = (baseUrl, identifier) => {
  const path = `${baseUrl}/coupons/${identifier}`

  return axios.get(path, {})
    .then(({ data, status }) => ({ response: normalize(data), status, errorMessage: data?.errors  }))
    .catch(error => { error })
}

export const getCoupons = (baseUrl, data = {}) => {
  const path = `${baseUrl}/coupons`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}