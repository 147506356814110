import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import {
  chevronLeft, chevronRight
} from "../../../../../components/Icons"

import Loader from "../../../../../components/Loader"
import activeAndSortedList from "../../../../../components/utils/activeAndSortedList"
import * as reducers from "../../../reducers"

import CategorySelect from "./CategorySelect"

class DepartmentField extends Component {
  state = {
    selected_department: undefined,
    showDepartment: true
  }

  handleClick = stateType => {
    const { handleBackButton } =  this.props
    handleBackButton()
    this.setState(stateType)
  }

  renderDepartmentLinks = departments => {
    const orderedActiveDepartmentsArr = activeAndSortedList(Object.values(departments))

    return (
      orderedActiveDepartmentsArr.map(department => {
        const preFiller = (
          <span className="dep-icon-mobile">
            <img className="nav-img" width='28px' height='24px' src={department.links.icon.url} />
          </span>
        )
         return (
           <Fragment key={`${department.attributes.slug}`}>
             <div onClick={() => this.handleClick({ selected_department: department, showDepartment: false })}>
               <li
                 style={{ fontWeight: "normal" }}
                 className="list-group-item">
                 <div className="truncate">
                   {department.links.icon.url && preFiller}
                   {department.attributes.name}
                 </div>
               </li>
             </div>
           </Fragment>
         )
         })
    )
  }

  renderCategories = selected_department => {
    const { hideSidebar } = this.props

    return (
      <>
        <div className="d-flex justify-content-between px-3 my-3">
          <button className="btn btn-sm btn-outline-dark back-to-categories pl-0" type="button" onClick={() => this.handleClick({ selected_department: undefined, showDepartment: true })}>
            <img src={chevronLeft} />
            Back
          </button>
          <Link
            key={selected_department.id}
            onClick={hideSidebar}
            to={`/shop/${selected_department.attributes.slug}`}
            className="btn btn-sm rounded-pill btn-main">
            <div className="fontFamily">
              View all
              {" "}
              {selected_department.attributes.name}
            </div>
            <img src={chevronRight} />
          </Link>
        </div>
        <CategorySelect department={selected_department} hideSidebar={hideSidebar} />
      </>
    )
  }

  render() {
    const { departments } = this.props
    const { selected_department, showDepartment } = this.state

    return (
      <div className="side-block-field">
        {showDepartment && ((_.isEmpty(departments))
        ? <div className="mt-4 mb-4"><Loader /></div>
        : <ul className="list-group department-list">{this.renderDepartmentLinks(departments)}</ul>)}
        {selected_department && <ul className="list-group">{this.renderCategories(selected_department)}</ul>}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  departments: reducers.getAllDepartments(state)
})

export default connect(mapStateToProps, {})(DepartmentField)
