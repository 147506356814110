// This function handles grouping ids together and creating a wrapper around
// cards for the carousel
//
import React from "react"

export const homePageListingCarouselWrapper = (Wrapper, Card, itemType, ids) => {
  const outerArray  = []
  let innerArray    = []
  let count         = 0

  // Iterates through each id and group them into smaller (innerArrays)
  // The first item is larger so we set a different col to it
  //
  _.each(ids, (itemId, index) => {
    const featured = (itemType === "saleEvent") ? true : index === 0
    innerArray.push(<Card type={itemType} id={itemId} position={index + 1} wrapperClass={featured ? "col-md-6 featured" : "col-6 col-lg-3"} key={`${itemType}-${itemId}`} />)
    count += featured ? 2 : 1
    if (count === 8) {
      outerArray.push(innerArray)
      count = 0
      innerArray = []
    }
  })

  // One last push if there are any items left
  if (innerArray.length > 0) {
    outerArray.push(innerArray)
    innerArray = []
  }

  return _.map(outerArray, (items, index) => (
    <Wrapper key={`slide-${index}`}>
      {items}
    </Wrapper>
  ))
}