/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const getNewsletters = (baseUrl, data = {}) => {
  const path = `${baseUrl}/newsletters`

  return axios.get(path, { params: data })
    .then(({ data, status }) => ({
      response: {
        data: normalize(data),
        ids: _.get(data, "meta.order", [])
      },
      status
    }))
    .catch(error => { error })
}

export const getNewsletterByIdentifier = (baseUrl, identifier) => {
  const path = `${baseUrl}/newsletters/${identifier}`

  return axios.get(path, {})
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}