import React from 'react'
import { useDispatch } from 'react-redux'
import { 
  Link, 
  // useHistory 
} from 'react-router-dom'
import {
  arrowFromLeft,
  userIcon,
  // emailIcon,
  // calendarIcon,
  // heartIcon,
  // publicProfile
} from '../../../../../components/Icons'
import { hideUserSlider } from '../../../actions'
import { handleNativeRedirectWebView } from '../../../utilis/NativeDataShare'

export default function AccountField ({ user }) {
  const dispatch = useDispatch()
  const nid = localStorage.getItem('lbnid')
  const slug = user?.attributes?.slug || ''
  const handlNativeLogout = () => {
    window.localStorage.clear()
    localStorage.setItem('lbnid', nid) // keep same nid between logouts as device is same.
    if (window.ReactNativeWebView) {
      handleNativeRedirectWebView(`{"type":"logout", "path":"${window.location.href}"}`)
    } else {
      window.location.href = '/'
    }
  }
  // const history = useHistory()
  // const handleNativeShare = () => {
  //   dispatch(hideUserSlider())
  //   history.push(`/spotter/${slug}`)
  // }
  // const heartUrl = `/spotter/${slug}/following`

  // const handleNativeFollow = () => {
  //   if (window.ReactNativeWebView) {
  //     handleNativeRedirectWebView(`{"type":"follow", "path":"${window.location.href}"}`)
  //   } else {
  //     dispatch(hideUserSlider())
  //     history.push(heartUrl)
  //   }
  // }

  return (
    <>
      <div className='side-block-field account sidebar-profile'>
        <ul className='list-group'>
          <li className='list-group-item'>
            <Link to={`/spotter/${slug}/account`} className='' onClick={() => { dispatch(hideUserSlider()) }}>
              <img className='nav-img' src={userIcon} />
              My Account Details
            </Link>
          </li>
          {/* <li className='list-group-item'>
            <Link to={`/spotter/${slug}/my-calendar`} className='' onClick={() => { dispatch(hideUserSlider()) }}>
              <img className='nav-img' src={calendarIcon} />
              {' '}
              My Calendar
              {' '}
            </Link>
          </li> */}
          {/* <li className='list-group-item'>
            <Link to={`/spotter/${slug}/account/email`} className='' onClick={() => { dispatch(hideUserSlider()) }}>
              <img className='nav-img' src={emailIcon} />
              Email Notification
            </Link>
          </li> */}
          {/* <li className='list-group-item'>
            <Link
              className=''
              to={`/spotter/${slug}`}
              onClick={(event) => {
                event.preventDefault()
                handleNativeShare()
              }}
            >
              <img className='nav-img' src={publicProfile} />
              {' '}
              My Public Profile
            </Link>
          </li> */}
          {/* <li className='list-group-item'>
            <Link
              className=''
              to={heartUrl}
              onClick={(event) => {
                event.preventDefault()
                handleNativeFollow()
              }}
            >
              <img className='nav-img' src={heartIcon} />
              {' '}
              Following
            </Link>
          </li> */}
          <li className='list-group-item'>
            <Link to='/logout' onClick={handlNativeLogout}>
              <img className='nav-img' src={arrowFromLeft} />
              Sign Out
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}
