import React from "react"
import { Link } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Pluralize from "react-pluralize"
import FollowButton from "../../../apps/App/components/buttons/FollowButton"

export default function DealHunterWideCard({
    wrapperClass, item,
    item: {
      attributes,
      links
    }
  }) {
  return (
    <div className={`${wrapperClass}`}>
      <div className="card text-center listing-card border-0">
        <div className="media d-block d-flex text-left mb-3 mb-md-0">
          <Link
            to={links.userUrl}>
            <LazyLoadImage
              effect="opacity"
              className="mr-2 rounded-circle img-70"
              alt={attributes.name}
              src={links.avatar.thumb.url} />
          </Link>
          <div className="media-body">
            <Link
              to={links.userUrl}>
              <div className="sub-title"><strong>{attributes.username}</strong></div>
            </Link>
            <ul className="list-unstyled small mb-2 d-none d-md-block">
              <li>
                <Pluralize singular="upvote" count={attributes.upvotedCount} />
              </li>
              <li>
                <Pluralize singular="downvote" count={attributes.downvotedCount} />
              </li>
              <li>
                <Pluralize singular="price drop" count={attributes.dealsCount} />
                {" submitted"}
              </li>
            </ul>
            <FollowButton item={item} buttonClasses="btn btn-follow" />
            <div className="my-2 small">
              <Pluralize singular="follower" count={attributes.followersCount} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
