import {
  all, call, put, select, takeLatest, fork
 } from "redux-saga/effects"

import * as actionTypes from "../../actions/types"
import * as actions from "../../actions"
import { getBaseUrl } from "../../reducers"
import * as requester from "../../requesters"

function* fetchUserById({ data }) {
  yield put(actions.userRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const { response, error } = yield call(requester.getUserByIdentifier, baseUrl, data.current_user.id)
  yield (response) ? put(actions.userRequestSuccess(response)) : put(actions.userRequestFailure(error))
}

// Top Lists
function* fetchTopDeals({ data }) {
  yield put(actions.topDealsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_followed_user: data.current_user.id
  }
  const { response, error } = yield call(requester.getTopDeals, baseUrl, params)
  yield (response) ? put(actions.topDealsRequestSuccess(response)) : put(actions.topDealsRequestFailure(error))
}

function* fetchTopCoupons({ data }) {
  yield put(actions.topCouponsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { by_followed_user: data.current_user.id }
  const { response, error } = yield call(requester.getTopCoupons, baseUrl, params)
  yield (response) ? put(actions.topCouponsRequestSuccess(response)) : put(actions.topCouponsRequestFailure(error))
}

function* fetchTopSaleEvents({ data }) {
  yield put(actions.topSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { by_followed_user: data.current_user.id }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topSaleEventsRequestSuccess(response)) : put(actions.topSaleEventsRequestFailure(error))
}

function* fetchFollowedBrands({ data }) {
  yield put(actions.topBrandsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { followed_by: data.current_user.id }
  const { response, error } = yield call(requester.getBrands, baseUrl, params)
  yield (response) ? put(actions.topBrandsRequestSuccess(response)) : put(actions.topBrandsRequestFailure(error))
}

function* fetchFollowedDealhunters({ data }) {
  yield put(actions.topDealHuntersRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { followed_by: data.current_user.id }
  const { response, error } = yield call(requester.getUsers, baseUrl, params)
  yield (response) ? put(actions.topDealHuntersRequestSuccess(response)) : put(actions.topDealHuntersRequestFailure(error))
}

function* fetchFollowedStores({ data }) {
  yield put(actions.topStoresRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = { followed_by: data.current_user.id }
  const { response, error } = yield call(requester.getStores, baseUrl, params)
  yield (response) ? put(actions.topStoresRequestSuccess(response)) : put(actions.topStoresRequestFailure(error))
}

function* fetchTopUpcomingSaleEvents({ data }) {
  yield put(actions.topUpcomingSaleEventsRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_department: _.get(data, "department"),
    by_category: _.get(data, "category"),
    upcoming: true
  }
  const { response, error } = yield call(requester.getTopSaleEvents, baseUrl, params)
  yield (response) ? put(actions.topUpcomingSaleEventsRequestSuccess(response)) : put(actions.topUpcomingSaleEventsRequestFailure(error))
}

function* fetchNewsletters({ data }) {
  yield put(actions.newslettersRequestStart())
  const baseUrl = yield select(getBaseUrl)
  const params = {
    by_followed_user: data.current_user.id
  }
  const { response, error } = yield call(requester.getNewsletters, baseUrl, params)
  yield (response) ? put(actions.newslettersRequestSuccess(response)) : put(actions.newslettersRequestFailure(error))
}

function* fetchData(action) {
  yield all([
    fork(fetchUserById, action),
    fork(fetchFollowedStores, action),
    fork(fetchFollowedDealhunters, action),
    fork(fetchTopDeals, action),
    fork(fetchTopSaleEvents, action),
    fork(fetchTopCoupons, action),
    fork(fetchFollowedBrands, action),
    fork(fetchTopUpcomingSaleEvents, action),
    fork(fetchNewsletters, action)
  ])
}

function* watchProfileFollowingPageSaga() {
  yield takeLatest(actionTypes.LOAD_PROFILE_FOLLOWING_PAGE, fetchData)
}

export {
  watchProfileFollowingPageSaga
}
