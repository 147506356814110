import React, { Component, createRef } from "react"
import { connect } from "react-redux"

import TopLists from "../../../../components/TopLists"
import ListingCard from "../ListingCard"

import * as reducers from "../../reducers"
import ChevronRight from "../ChevronRight"

class TopCurrentSalesSidebarList extends Component {
  constructor() {
    super()
    this.myRef = createRef()
  }

  topListComponents = () => {
    const {
      topCurrentSaleEventIds,
      title,
      currentSection,
      sectionPosition
    } = this.props
    const itemType = "saleEvent"
    if (topCurrentSaleEventIds === undefined) { return ([]) }
    return _.map(_.take(topCurrentSaleEventIds, 8), (itemId, index) => <ListingCard type={itemType} id={itemId} wrapperClass="" position={index + 1} key={`${itemType}-${itemId}`} currentSection={`${title || "Other live sales"}`} sectionPosition={sectionPosition} />)
  }

  render() {
    const {
      isFetchingTopCurrentSaleEvents,
      topCurrentSaleEventIds,
      title,
      description
    } = this.props

    if (_.isEmpty(topCurrentSaleEventIds) && !isFetchingTopCurrentSaleEvents) {
      return null
    }
    return (
      <TopLists
        title={title || "Other live sales"}
        description={description}
        wrapperClass="listing-carousel"
        isLoading={isFetchingTopCurrentSaleEvents}
        viewAllUrl="/top-current-sales"
        titleClass="mb-1"
        type="sales">
        <div className="d-flex listing-group-carousel" ref={this.myRef}>
          <ChevronRight offset={300} ref={this.myRef} direction="left" />
          { this.topListComponents() }
          <ChevronRight offset={300} ref={this.myRef} direction="right" />
        </div>
      </TopLists>
    )
  }
}

const mapStateToProps = state => ({
  topCurrentSaleEventIds: reducers.getTopCurrentSaleEventIds(state),
  isFetchingTopCurrentSaleEvents: reducers.isFetchingTopCurrentSaleEvents(state)
})

export default connect(mapStateToProps, {})(TopCurrentSalesSidebarList)
