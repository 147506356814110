import {
  all, call, put, select, takeLatest, fork
} from 'redux-saga/effects'

import * as actionTypes from '../../actions/types'
import * as actions from '../../actions'
import { getBaseUrl } from '../../reducers'
import { getDealsByKeyword } from '../../requesters'
// import { searchItems } from '../../reducers/search'

function* fetchSearchResult (keyword, reloadPage) {
  const itemType = 'deals'
  // const searchStore = yield select(state => state.search)
  // const { results } = searchStore
  // const { nextPage } = results[itemType]
  // const requestPage = reloadPage ? 1 : nextPage
  const requestPage = 0
  const loadPerPage = 50

  if (requestPage === null) {
    yield put(actions.searchRequestFailure({ itemType, error: 'no next page' }))
  } else {
    const baseUrl = yield select(getBaseUrl)

    const {
      response, error, meta, headers
    } = yield call(getDealsByKeyword, baseUrl, {
      itemType, query: keyword?.data?.searchTerm || '', requestPage, loadPerPage
    })

    if (response && meta) {
      let { order } = meta
      if (!reloadPage) {
        const existingOrder = yield select(state => state.search.results[itemType].order)
        order = existingOrder.concat(order)
      }
      yield put(actions.searchRequestSuccess({
        itemType, keyword, headers, order
      }))
    } else {
      yield put(actions.searchRequestFailure({ itemType, error }))
    }
  }
}

function* fetchNextPage ({ data }) {
  yield all([
    fork(fetchSearchResult, data)
  ])
}

// load search page search for everything
function* loadSearchPage (data) {
  yield all([
    fork(fetchSearchResult, data, true)
  ])
}

// ToDo: Remove Deal: Comment Deal API call on Search Page
function* watchSearchKeywordUpdate () {
  // yield takeLatest(actionTypes.SEARCH_SET_KEYWORD, loadSearchPage)
  // yield takeLatest(actionTypes.SEARCH_REQUEST_NEXTPAGE, fetchNextPage)
}

export {
  watchSearchKeywordUpdate
}
